import axios from 'axios';
import { BASE_URL } from 'constants/url';
import decryptData from 'secret/decryptData';
import DevLog from './DevLog';
const CheckUserStatus = async () => {
  await axios
    .get(BASE_URL + '/auth/users/me/', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `JWT ${decryptData('token')}`
      }
    })
    .then(response => {
      try {
        if (response.status === 200) {
          const { user_type, user_status } = response.data;
          localStorage.setItem('user_status', user_status);
          localStorage.setItem('user_type', user_type);
        }
      } catch (error) {
        DevLog(error);
      }
    })
    .catch(error => {
      if (error.code === 'ECONNABORTED') {
        DevLog('No internet connection');
      }
    });
};
export default CheckUserStatus;
