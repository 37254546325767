/* eslint-disable react/prop-types */
import React, { useContext, useEffect, useState } from 'react';
import { List, Input, Button, Row, Col } from 'antd';
import axios from 'axios';
import { BASE_URL } from 'constants/url';
import { toast } from 'react-toastify';

import { BsPinAngle } from 'react-icons/bs';
import { LuPinOff } from 'react-icons/lu';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import AppContext from 'context/Context';
import decryptData from 'secret/decryptData';
import DevLog from 'helpers/DevLog';

const LeaveComment = ({ id }) => {
  const [commentText, setCommentText] = useState('');
  const {
    config: { isDark }
  } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(true);
  const [comments, setComments] = useState([]);
  const [pinnedComments, setPinnedComments] = useState([]);

  const handleInputChange = e => {
    setCommentText(e.target.value);
  };
  const fetchComments = async () => {
    setIsLoading(true);
    DevLog({ id });
    await axios
      .get(BASE_URL + `/dashboard/order/${id}/comments/`, {
        headers: {
          Authorization: `JWT ${decryptData('token')}`,
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        DevLog({ response });
        setComments([
          ...comments,
          ...response.data.filter(comment => comment.is_pinned === false)
        ]);
        setPinnedComments([
          ...pinnedComments,
          ...response.data.filter(comment => comment.is_pinned === true)
        ]);
      })
      .catch(error => {
        DevLog({ error });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  useEffect(() => {
    fetchComments();
  }, []);
  const handleAddComment = async () => {
    if (commentText.trim() !== '') {
      await axios
        .post(
          BASE_URL + `/dashboard/order/${id}/comment/add/`,
          {
            comment: commentText
          },
          {
            headers: {
              Authorization: `JWT ${decryptData('token')}`,
              'Content-Type': 'application/json'
            }
          }
        )
        .then(response => {
          DevLog({ response });
          if (response.status === 201) {
            const newComment = {
              comment: commentText,
              order_comment_id: response.data.order_comment_id
            };
            setComments([...comments, newComment]);
            setCommentText('');
            toast.success(`Comment added successfully 🎉`, {
              theme: 'colored',
              position: 'top-right',
              autoClose: true
            });
          }
        })
        .catch(err => {
          DevLog({ err });
        });
    }
  };
  const handlePinComment = async index => {
    const commentToPin = comments[index];
    DevLog({ commentToPin });
    setPinnedComments([...pinnedComments, commentToPin]);
    setComments(comments.filter((_, i) => i !== index));
    await axios
      .patch(
        BASE_URL + `/dashboard/order/comment/${commentToPin.order_comment_id}/`,
        {
          is_pinned: true
        },
        {
          headers: {
            Authorization: `JWT ${decryptData('token')}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then(response => {
        response.status == 200 &&
          toast.success(`Comment pinned successfully 🎉`, {
            theme: 'colored',
            position: 'top-right',
            autoClose: true
          });
      })
      .catch(err => {
        DevLog({ pinned: err });
        return toast.error(`Pinning failed :(`, {
          theme: 'colored',
          position: 'top-right',
          autoClose: true
        });
      });
  };
  const handleUnpinComment = async index => {
    const commentToUnpin = pinnedComments[index];
    setComments([...comments, commentToUnpin]);
    setPinnedComments(pinnedComments.filter((_, i) => i !== index));
    await axios
      .patch(
        BASE_URL +
          `/dashboard/order/comment/${commentToUnpin.order_comment_id}/`,
        {
          is_pinned: false
        },
        {
          headers: {
            Authorization: `JWT ${decryptData('token')}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then(response => {
        if (response.status == 200) {
          return toast.success(`Comment unpinned successfully 🎉`, {
            theme: 'colored',
            position: 'top-right',
            autoClose: true
          });
        }
      })
      .catch(err => {
        DevLog({ pinned: err });
        return toast.error(`Unpinning failed :(`, {
          theme: 'colored',
          position: 'top-right',
          autoClose: true
        });
      });
  };
  if (isLoading) {
    return (
      <>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <List
              header={<div>Comments</div>}
              dataSource={[1, 1, 1, 1, 1, 1, 1, 1, 1]}
              renderItem={() => (
                <SkeletonTheme
                  baseColor={isDark ? '#fff' : '#ECF2FF'}
                  highlightColor={isDark ? '#444' : '#e5e5e5'}
                >
                  <Skeleton
                    style={{
                      marginBottom: '15px',
                      marginTop: '15px'
                    }}
                    width={350}
                    height={25}
                    className="mx-3"
                  />
                </SkeletonTheme>
              )}
            />
          </Col>
          <Col span={12}>
            <List
              header={<div>Pinned comments</div>}
              dataSource={[1, 1, 1, 1, 1, 1, 1, 1, 1]}
              renderItem={() => (
                <SkeletonTheme
                  baseColor={isDark ? '#fff' : '#ECF2FF'}
                  highlightColor={isDark ? '#444' : '#e5e5e5'}
                >
                  <Skeleton
                    style={{
                      marginBottom: '15px',
                      marginTop: '15px'
                    }}
                    width={350}
                    height={25}
                    className="mx-3"
                  />
                </SkeletonTheme>
              )}
            />
          </Col>
        </Row>
      </>
    );
  }
  return (
    <div>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Input
            value={commentText}
            onChange={handleInputChange}
            placeholder="Leave a comment..."
            onPressEnter={handleAddComment}
          />
        </Col>
        <Col span={12}>
          <Button type="primary" onClick={handleAddComment}>
            Add Comment
          </Button>
        </Col>
      </Row>
      <p>Comment length: {commentText.length}</p>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <List
            header={<div>Comments</div>}
            dataSource={comments}
            renderItem={(item, index) => (
              <List.Item>
                <span>{item.comment}</span>
                <Button
                  type="dashed"
                  className="rounded-5"
                  onClick={() => handlePinComment(index)}
                >
                  <BsPinAngle />
                </Button>
              </List.Item>
            )}
          />
        </Col>
        <Col span={12}>
          <List
            header={<div>Pinned Comments</div>}
            dataSource={pinnedComments}
            renderItem={(item, index) => (
              <List.Item>
                <span>{item.comment}</span>
                <Button
                  className="rounded-5"
                  type="dashed"
                  onClick={() => handleUnpinComment(index)}
                >
                  <LuPinOff />
                </Button>
              </List.Item>
            )}
          />
        </Col>
      </Row>
    </div>
  );
};

export default LeaveComment;
