import React from 'react';
import Lottie from 'lottie-react';
import blocked from '../../assets/denied.json'; // Assuming you have a blocked animation
import { Button, Card } from 'react-bootstrap';
import { FiLogOut } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

const AccountBlockedPage = () => {
  const navigate = useNavigate();

  return (
    <div className="p-5">
      <Card className="p-4 min-vh-100">
        <h1 className="text-center text-danger fs-6">
          Oh no! Your account has been blocked temporarily.
        </h1>
        <p className="text-center">
          We understand that sometimes, things don't go as planned. Your account
          has been temporarily blocked, but don't worry, we're here to help! If
          you have any questions please contact us +998 90 017 42 90
        </p>
        <div className="d-flex justify-content-center">
          <div className="w-25">
            <Lottie animationData={blocked} loop={true} />
          </div>
        </div>
        <div className="d-flex justify-content-center">
          <Button
            onClick={() => {
              localStorage.clear();
              navigate('/');
            }}
            className="fs-4"
          >
            Log out <FiLogOut size={28} />
          </Button>
        </div>
      </Card>
    </div>
  );
};

export default AccountBlockedPage;
