import React, { useState, useContext } from 'react';
import { Table, Button, Card } from 'react-bootstrap';
import { WearableCartContext } from './WearableCartContext';
import axios from 'axios';
import { BASE_URL } from 'constants/url';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import {
  productAddFailedTranslation,
  productAddedSuccessfullyTranslation
} from 'languages/MessageTranslations';
import { useLanguage } from 'provider/LanguageProvider';
import decryptData from 'secret/decryptData';
import DevLog from 'helpers/DevLog';

const WearableCheckoutPage = () => {
  const { language } = useLanguage();
  const { cartItems, setCartItems } = useContext(WearableCartContext);
  const [itemDetails, setItemDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [price, setPrice] = useState(0);
  const [supplier_product_quantity, setQuantity] = useState(0);
  const navigate = useNavigate();
  {
    price, supplier_product_quantity;
  }
  // Create a new Set by converting the input array to Set
  const uniqueSet = new Set(cartItems.map(item => JSON.stringify(item)));

  // Convert the Set back to an array by using the spread operator
  const uniqueArray = [...uniqueSet].map(item => JSON.parse(item));
  const handlePriceChange = (index, price) => {
    const updatedItems = [...itemDetails];
    if (!updatedItems[index]) {
      updatedItems[index] = {};
    }
    updatedItems[index].price = price;
    setItemDetails(updatedItems);
    setPrice(price);
  };

  const handleQuantityChange = (index, supplier_product_quantity) => {
    const updatedItems = [...itemDetails];
    if (!updatedItems[index]) {
      updatedItems[index] = {};
    }
    updatedItems[index].supplier_product_quantity = supplier_product_quantity;
    setItemDetails(updatedItems);
    setQuantity(supplier_product_quantity);
  };

  const handlePlaceOrder = async () => {
    setIsLoading(true);
    const cartWithDetails = uniqueArray.map((item, index) => {
      const { product_id } = item;
      const { price, supplier_product_quantity } = itemDetails[index];
      return { product: product_id, price, supplier_product_quantity };
    });

    await axios
      .post(BASE_URL + '/store/supplier/product/add/', cartWithDetails, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `JWT ${decryptData('token')}`
        }
      })
      .then(response => {
        const { status } = response;
        if (status == 201) {
          setCartItems([]);
          toast.success(productAddedSuccessfullyTranslation[language], {
            theme: 'colored'
          });
        }
      })
      .catch(err => {
        DevLog(err);
        toast.error(productAddFailedTranslation[language], {
          theme: 'colored'
        });
      })
      .finally(() => {
        setIsLoading(false);
        navigate('/supplier/e-commerce/product/upload-new-wearable');
      });
  };

  if (isLoading) {
    return (
      <>
        <h1>Wearable Catalog</h1>
        <h1>Loading ...</h1>{' '}
      </>
    );
  }
  uniqueArray.length == 0 && navigate('/supplier/e-commerce/product/Wearable');
  return (
    <Card className="p-4">
      <Table striped bordered hover>
        <thead>
          <tr>
            <th className="text-800">Brand</th>
            <th className="text-800">Model</th>
            <th className="text-800">More</th>
            <th className="text-800">Lowest Price</th>
            <th className="text-800">Quantity</th>
            <th className="text-800">Price</th>
          </tr>
        </thead>
        <tbody>
          {uniqueArray.map((item, index) => (
            <tr key={index}>
              <td className="text-800">
                {item.brand} {item.color}
              </td>
              <td className="text-800">
                {item.model_name} {item.color}
              </td>
              <td className="text-800">
                {item.model_number} , weight: {item.weight}kg
              </td>
              <td className="text-800">
                {item.lowest_price == null ? '-' : item.lowest_price}
              </td>
              <td className="text-800">
                <input
                  type="number"
                  className="form-control"
                  value={itemDetails[index]?.supplier_product_quantity || ''}
                  onChange={e => handleQuantityChange(index, e.target.value)}
                />
              </td>
              <td className="text-800">
                <input
                  type="number"
                  className="form-control"
                  value={itemDetails[index]?.price || ''}
                  onChange={e => handlePriceChange(index, e.target.value)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Button variant="primary" onClick={handlePlaceOrder}>
        Place Order
      </Button>
    </Card>
  );
};

export default WearableCheckoutPage;
