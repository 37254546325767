import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card, Row, Col } from 'react-bootstrap';
import { BASE_URL } from 'constants/url';
import { BsPencilSquare } from 'react-icons/bs';
import { BsTrashFill } from 'react-icons/bs';
import { Link } from 'react-router-dom';

import TableSkeleton from 'components/common/skeletons/table skleton/TableSkleton';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import SoftBadge from 'components/common/SoftBadge';
import { toast } from 'react-toastify';
import { Button } from 'antd';
import decryptData from 'secret/decryptData';
import DevLog from 'helpers/DevLog';

const AdminLaptops = () => {
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const columns = [
    {
      accessor: 'brand',
      Header: 'Brand'
    },
    {
      accessor: 'model_name',
      Header: 'Model'
    },
    {
      accessor: 'model_number',
      Header: 'Model number'
    },
    {
      accessor: 'ram',
      Header: 'RAM'
    },
    {
      accessor: 'storage',
      Header: 'Storage'
    },
    {
      accessor: 'processor',
      Header: 'Processor'
    },
    {
      accessor: 'video_card',
      Header: 'Video Card'
    },
    {
      accessor: 'color',
      Header: 'Color'
    },
    {
      accessor: 'specs',
      Header: 'Specs'
    },
    {
      accessor: 'code',
      Header: 'Code'
    },
    {
      accessor: 'action',
      Header: 'action',
      Cell: rowData => {
        const { model_name, product_id } = rowData.row.original;

        return (
          <>
            <Link
              className="badge text-info bg-soft-info hover-button rounded-5  mx-3 p-2"
              state={rowData.row.original}
              to={`/admin/e-commerce/product/laptops/edit/${model_name}`}
            >
              <BsPencilSquare size={20} />
            </Link>
            <SoftBadge
              pill
              className="p-2 text-danger bg-soft-danger hover-button"
              onClick={() => {
                if (window.confirm('Are you sure you want to delete?')) {
                  handleDelete(product_id);
                } else {
                  DevLog('User clicked Cancel');
                }
              }}
            >
              <BsTrashFill size={20} />
            </SoftBadge>
          </>
        );
      }
    }
  ];
  useEffect(() => {
    setIsLoading(true);
    // Fetch data using Axios
    axios
      .get(BASE_URL + '/dashboard/products/', {
        headers: {
          Authorization: `JWT ${decryptData('token')}`,
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        const laptops = response.data?.filter(item => {
          const { category_id } = item.category;
          return (
            category_id === JSON.parse(localStorage.getItem('laptopCategoryId'))
          );
        });
        setProducts(laptops);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const handleDelete = async productId => {
    // Handle delete logic for the product with the given productId
    DevLog('Delete product with ID:', productId);
    await axios
      .patch(
        BASE_URL + '/dashboard/product/update/' + productId + '/',
        { is_active: false },
        {
          headers: {
            Authorization: `JWT ${decryptData('token')}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then(response => {
        DevLog({ response });
        if (response.status === 200) {
          setProducts(
            products.filter(
              p => p.product_id !== productId && p.is_active === true
            )
          );
          return toast.success(
            `Product successfully deleted, Product ID: ${productId}`,
            {
              theme: 'colored',
              position: 'top-right',
              autoClose: true
            }
          );
        }
      })
      .catch(err => {
        DevLog(err);
      })
      .finally(() => {});
  };

  if (isLoading) {
    return <TableSkeleton lines={products.length + 10} />;
  }

  return (
    <Card className="p-4 text-800">
      <AdvanceTableWrapper
        columns={columns}
        data={products}
        sortable
        pagination
        perPage={5}
      >
        <Row className="flex-end-center mb-3">
          <Col xs="auto" sm={6} lg={6}>
            <h3 className="text-primary fw-light">List of laptops</h3>
          </Col>
          <Col xs="auto" sm={6} lg={6}>
            <AdvanceTableSearchBox table />
          </Col>
          <Col xs="auto" sm={6} lg={6}>
            <div className="d-flex justify-content-end">
              <Link to={'/admin/e-commerce admin/product/upload_laptop'}>
                <Button className="mt-3" type="primary">
                  Create new Laptop +
                </Button>
              </Link>
            </div>
          </Col>
        </Row>
        <AdvanceTable
          table
          headerClassName="bg-200 text-900 text-nowrap align-middle"
          rowClassName="align-middle white-space-nowrap"
          tableProps={{
            bordered: true,
            striped: true,
            className: 'fs--1 mb-0 overflow-hidden'
          }}
        />
        <div className="mt-3">
          <AdvanceTableFooter
            rowCount={products.length}
            table
            rowInfo
            navButtons
            rowsPerPageSelection
          />
        </div>
      </AdvanceTableWrapper>
    </Card>
  );
};

export default AdminLaptops;
