import React from 'react';
import { Alert } from 'react-bootstrap';

const BlockedSupplierForm = () => {
  return (
    <div>
      {' '}
      <Alert variant="danger" className="px-3 py-3">
        Your account is blocked by our moderators.
      </Alert>
    </div>
  );
};

export default BlockedSupplierForm;
