/* eslint-disable react/prop-types */
import SoftBadge from 'components/common/SoftBadge';
import { BASE_URL_WITHOUT_API } from 'constants/url';
import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { FiDownloadCloud } from 'react-icons/fi';
import { Link } from 'react-router-dom';
const OrderInfoAdmin = ({ x, payment_type }) => {
  const {
    address_line_1,
    address_line_2,
    city,
    contact_name,
    contact_number,
    country,
    shipping_type,
    bank_receipt_file
  } = x;
  return (
    <Card className="mb-3 text-700">
      <Card.Body>
        <Row>
          <Col md={6} lg={4} className="mb-4 mb-lg-0">
            <h5 className="mb-3 fs-0">Billing Information</h5>
            <h6 className="mb-2">Contact name: {contact_name}</h6>
            <p className="mb-1 fs--1">{country}</p>
            <p className="mb-1 fs--1">{city}</p>
            <p className="mb-0 fs--1">
              <strong>
                Phone: <a href={`tel:${contact_number}`}>{contact_number}</a>
              </strong>
            </p>
          </Col>
          <Col md={6} lg={4} className="mb-4 mb-lg-0">
            <h5 className="mb-3 fs-0">
              Shipping Type:{' '}
              <SoftBadge pill className="mx-2" variant="info">
                {shipping_type}
              </SoftBadge>
            </h5>
            {shipping_type !== 'PICKUP' && (
              <>
                <h5 className="mb-3 fs-0">Shipping Address</h5>
                <h6 className="mb-3">Address line: {address_line_1}</h6>
                <h6 className="mb-3">Address line 2: {address_line_2}</h6>
              </>
            )}
          </Col>
          <Col md={6} lg={4}>
            <h5 className="mb-3 fs-0">
              Payment Type:{' '}
              <SoftBadge pill className="mx-2" variant="info">
                {payment_type}
              </SoftBadge>
            </h5>
            {bank_receipt_file !== null ? (
              <Link
                to={`${BASE_URL_WITHOUT_API}/${bank_receipt_file}`}
                variant="outline-primary"
                target="blank"
                className=" btn  btn-outline-primary rounded-pill me-1 mb-1"
              >
                Download cheque <FiDownloadCloud />
              </Link>
            ) : (
              <p className="text-danger">Cheque has not been uploaded yet</p>
            )}
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default OrderInfoAdmin;
