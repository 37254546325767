import React from 'react';
import TransactionSummary from './TransactionSummary';
import { transactionSummary } from 'data/dashboard/saas';

const Saas = () => {
  return (
    <>
      <TransactionSummary data={transactionSummary} />
    </>
  );
};

export default Saas;
