/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import {
  Form,
  Button,
  Row,
  Col,
  FormGroup,
  ProgressBar,
  Image
} from 'react-bootstrap';
import axios from 'axios';
import { BASE_URL } from 'constants/url';
import { toast } from 'react-toastify';
import {
  fileUploadFailedTranslation,
  fileUploadedSuccessfullyTranslation
} from 'languages/MessageTranslations';
import { useLanguage } from 'provider/LanguageProvider';
import decryptData from 'secret/decryptData';
import DevLog from 'helpers/DevLog';
import { useNavigate } from 'react-router-dom';
import ImageModal from './ImageModal';

const CustomFileUpload = ({ id, setIsUploaded }) => {
  const navigate = useNavigate();
  const { language } = useLanguage();
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState('Choose File');
  const [uploadProgress, setUploadProgress] = useState(0);
  const [imagePreview, setImagePreview] = useState(null);

  const handleFileChange = e => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    setFileName(selectedFile.name);

    // Create a URL for image preview
    const fileReader = new FileReader();
    fileReader.onload = e => {
      setImagePreview(e.target.result);
    };
    fileReader.readAsDataURL(selectedFile);
  };

  const handleFileUpload = async () => {
    if (file) {
      try {
        const formData = new FormData();
        formData.append('bank_receipt_file', file); // Use the correct form field name

        const token = decryptData('token');

        const config = {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `JWT ${token}`
          },
          onUploadProgress: progressEvent => {
            const { loaded, total } = progressEvent;
            const progress = Math.round((loaded * 100) / total);
            setUploadProgress(progress);
          }
        };

        const response = await axios.patch(
          BASE_URL + `/store/order/${id}/bank-receipt/upload/`,
          formData,
          config
        );
        DevLog(response);
        if (response.status === 204) {
          setIsUploaded(true);
          // move back and forth
          navigate('/customer/e-commerce/get/orders');

          return toast.success(
            fileUploadedSuccessfullyTranslation[language] +
              `\nfile: ${fileName}`,
            {
              theme: 'colored',
              position: 'top-right',
              autoClose: true
            }
          );
        }
      } catch (error) {
        console.error('Error uploading file:', error);
        return toast.error(
          fileUploadFailedTranslation[language] + `\nfile: ${fileName}`,
          {
            theme: 'colored',
            position: 'top-right',
            autoClose: true
          }
        );
      }
    } else {
      DevLog('No file selected');
    }
  };

  const handleDiscard = () => {
    // Clear the selected file and image preview
    setFile(null);
    setFileName('Choose File');
    setImagePreview(null);
  };

  return (
    <>
      <Row>
        <Col md={12}>
          <Form>
            <FormGroup>
              <Form.Label>
                {fileName === 'Choose File' ? '' : fileName}
              </Form.Label>
              <Form.Control onChange={handleFileChange} type="file" size="sm" />
            </FormGroup>
          </Form>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          {uploadProgress > 0 && (
            <ProgressBar
              animated
              variant="success"
              className="mt-2"
              now={uploadProgress}
              label={`${uploadProgress}%`}
            />
          )}
          {imagePreview && (
            <div>
              <Image
                src={imagePreview}
                alt="Preview"
                width={'200px'}
                className="mt-2"
              />
            </div>
          )}
          <div className="d-flex">
            <Button className="" onClick={handleFileUpload} variant="secondary">
              Upload
            </Button>
            {imagePreview && (
              <div>
                <Button
                  className=" ml-2 mx-2"
                  onClick={handleDiscard}
                  variant="danger"
                >
                  Discard
                </Button>
                <ImageModal>
                  <Image src={imagePreview} alt="Preview" className="mt-2" />
                </ImageModal>
              </div>
            )}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default CustomFileUpload;
