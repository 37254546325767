/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from 'react';
import { Form, Input, Button } from 'antd';
import axios from 'axios';
import { BASE_URL } from 'constants/url';
import { toast } from 'react-toastify';
import decryptData from 'secret/decryptData';
import DevLog from 'helpers/DevLog';

const RequiredHandlerForm = ({
  supplier_order_item_id,
  defaultValue,
  orderItems,
  setOrderItems,
  product
}) => {
  DevLog({ supplier_order_item_id });
  const onFinish = async values => {
    DevLog('Form data:', {
      delivered_quantity: parseInt(values.required_qty)
    });
    await axios
      .patch(
        BASE_URL + `/dashboard/suppliers/order/item/${supplier_order_item_id}/`,
        { delivered_quantity: parseInt(values.required_qty) },
        {
          headers: {
            Authorization: `JWT ${decryptData('token')}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then(res => {
        DevLog({ res });
        res.status == 200 && toast.success('Delivered quantity updated');
        // const filtered = orderItems.filter(i => {
        //   return i.supplier_order_item_id !== supplier_order_item_id;
        // });
        // setOrderItems([
        //   ...filtered,
        //   { ...product, delivered_quantity: parseInt(values.required_qty) }
        // ]);
        window.location.reload();
      })
      .catch(err => {
        DevLog({ err });
        return toast.error(err.message, { autoClose: true });
      });
  };

  const onFinishFailed = errorInfo => {
    DevLog('Form validation failed:', errorInfo);
  };

  return (
    <Form
      name="required_handler_form"
      onFinish={onFinish}
      layout="vertical"
      onFinishFailed={onFinishFailed}
      initialValues={{ required_qty: defaultValue }}
    >
      <Form.Item
        label="Required Qty"
        name="required_qty"
        rules={[
          {
            required: true,
            message: 'Please enter the required quantity!',
            min: 1
          }
        ]}
      >
        <Input />
      </Form.Item>

      {/* <Form.Item
        label="Comment"
        name="comment"
        rules={[
          {
            required: true,
            message: 'Please enter a comment!'
          }
        ]}
      >
        <Input.TextArea rows={4} />
      </Form.Item> */}

      <Form.Item>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default RequiredHandlerForm;
