export const fileUploadedSuccessfullyTranslation = {
  en: 'Congratulations🎉, file uploaded successfully!',
  ru: 'Поздравляем🎉, файл успешно загружен!',
  uz: 'Tabriklaymiz🎉, fayl muvaffaqiyatli yuklandi!'
};
export const fileUploadFailedTranslation = {
  en: 'Oops! Something went wrong while uploading the file. Please try again.',
  ru: 'Извините, произошла ошибка при загрузке файла. Пожалуйста, попробуйте снова.',
  uz: 'Xatolik yuz berdi! qayta urinib ko‘ring.'
};

export const paymentMethodChangedTranslation = {
  en: 'Payment method changed to Cash',
  ru: 'Способ оплаты изменен на Наличные',
  uz: "To'lov usuli Naqd pulga o‘zgartirildi"
};
export const paymentMethodChangeFailedTranslation = {
  en: 'Oops! Failed to change payment method to Cash. Please try again.',
  ru: 'Упс! Не удалось изменить способ оплаты на Наличные. Пожалуйста, попробуйте снова.',
  uz: 'Xatolik yuz berdi! To‘lov usulini Omonatga o‘zgartirish muvaffaqiyatli amalga oshirilmadi. Iltimos, qayta urinib ko‘ring.'
};

export const paymentMethodChangedToBankTranslation = {
  en: 'Payment method changed to Bank',
  ru: 'Способ оплаты изменен на Банк',
  uz: 'To‘lov usuli Bankga o‘zgartirildi'
};

export const paymentMethodChangeToBankFailedTranslation = {
  en: 'Oops! Failed to change payment method to Bank. Please try again.',
  ru: 'Упс! Не удалось изменить способ оплаты на Банк. Пожалуйста, попробуйте снова.',
  uz: 'Xatolik yuz berdi! To‘lov usulini Bankga o‘zgartirish muvaffaqiyatli amalga oshirilmadi. Iltimos, qayta urinib ko‘ring.'
};

export const detailsAddedSuccessfullyTranslation = {
  en: 'Your details have been successfully added to the server.',
  ru: 'Ваши данные успешно добавлены на сервер.',
  uz: "Sizning ma'lumotlaringiz serverga muvaffaqiyatli qo'shildi."
};

export const detailsAddFailedTranslation = {
  en: 'Oops! Failed to add your details to the server. Please try again.',
  ru: 'Упс! Не удалось добавить ваши данные на сервер. Пожалуйста, попробуйте снова.',
  uz: "Xatolik yuz berdi! Sizning ma'lumotlaringizni serverga qo'shishda muvaffaqiyatli amalga oshirilmadi. Iltimos, qayta urinib ko'ring."
};

export const orderCreatedSuccessfullyTranslation = {
  en: 'Success! Your order has been created successfully.',
  ru: 'Успешно! Ваш заказ был успешно создан.',
  uz: 'Muvaffaqiyat! Sizning buyurtmangiz muvaffaqiyatli yaratildi.'
};

export const orderCreationFailedTranslation = {
  en: 'Oops! Failed to create your order. Please try again or contact support team.',
  ru: 'Упс! Не удалось создать ваш заказ. Пожалуйста, попробуйте еще раз или свяжитесь с поддержкой.',
  uz: 'Xatolik yuz berdi! Sizning buyurtmangizni yaratish muvaffaqiyatli amalga oshirilmadi. Iltimos, qayta urinib ko‘ring yoki qo‘llab-quvvatlash hizmatiga murojaat qiling.'
};

export const productAddedToCartSuccessfullyTranslation = {
  en: 'Success! The product has been added to your cart.',
  ru: 'Успех! Продукт успешно добавлен в корзину.',
  uz: "Muvaffaqiyat! Mahsulot savatingizga qo'shildi."
};

export const productAddToCartFailedTranslation = {
  en: 'Oops! Failed to add the product to your cart. This may occur if you have an incomplete order or due to a technical error. Please try again or check your cart.',
  ru: 'Упс! Не удалось добавить продукт в корзину. Это может произойти, если у вас есть незавершенный заказ или из-за технической ошибки. Пожалуйста, попробуйте еще раз или проверьте корзину.',
  uz: "Xatolik yuz berdi! Mahsulotni savatingizga qo'shishda muvaffaqiyatli amalga oshirilmadi. Bu, sizda to'liq tugallanmagan buyurtma bo'lishi yoki texnik xato tufayli yuz berishi mumkin. Iltimos, qayta urinib ko‘ring yoki savatingizni tekshiring."
};

export const productQuantityUpdatedSuccessfullyTranslation = {
  en: 'Success! Product quantity has been updated successfully.',
  ru: 'Успех! Количество продукта успешно обновлено.',
  uz: 'Muvaffaqiyat! Mahsulot miqdori muvaffaqiyatli yangilandi.'
};

export const productQuantityUpdateFailedTranslation = {
  en: 'Oops! Failed to update product quantity. Please try again or check your input.',
  ru: 'Упс! Не удалось обновить количество продукта. Пожалуйста, попробуйте еще раз или проверьте введенные данные.',
  uz: "Xatolik yuz berdi! Mahsulot miqdorini yangilash muvaffaqiyatli amalga oshirilmadi. Iltimos, qayta urinib ko‘ring yoki kiritilgan ma'lumotlarni tekshiring."
};
//supplier
export const productAddedSuccessfullyTranslation = {
  en: 'Success! The product has been added successfully.',
  ru: 'Успех! Продукт успешно добавлен.',
  uz: 'Muvaffaqiyat! Mahsulot muvaffaqiyatli qo‘shildi.'
};
export const productAddFailedTranslation = {
  en: 'Oops! Failed to add the product. Please try again or check your input.',
  ru: 'Упс! Не удалось добавить продукт. Пожалуйста, попробуйте еще раз или проверьте введенные данные.',
  uz: 'Xatolik yuz berdi! Mahsulotni qo‘shish muvaffaqiyatli amalga oshirilmadi. Iltimos, qayta urinib ko‘ring yoki kiritilgan ma‘lumotlarni tekshiring.'
};
