/* eslint-disable react/prop-types */
import React, { useContext } from 'react';
import { Card } from 'react-bootstrap';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import PropTypes from 'prop-types';
import AppContext from 'context/Context';
import { List } from 'antd';

const CommentSkeleton = ({ color }) => {
  const {
    config: { isDark }
  } = useContext(AppContext);

  return (
    <Card
      style={{
        borderLeft: `3px solid ${color}`
      }}
      className="p-3 my-4 mx-0"
    >
      <SkeletonTheme
        baseColor={isDark ? '#333' : '#fafafa'}
        highlightColor={isDark ? '#444' : '#e5e5e5'}
      >
        <List.Item>
          <List.Item.Meta
            // avatar={<Avatar icon={<UserOutlined />} />}
            title={
              <b className="text-800">
                <Skeleton width={250} height={25} className="mx-3" />
              </b>
            }
            description={
              <p className="text-800">
                <Skeleton width={200} height={25} className="mx-3" />
              </p>
            }
          />
          <Skeleton width={300} height={25} className="mx-3" />
        </List.Item>
      </SkeletonTheme>
    </Card>
  );
};
CommentSkeleton.propTypes = {
  lines: PropTypes.number
};

export default CommentSkeleton;
