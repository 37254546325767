import axios from 'axios';
import Flex from 'components/common/Flex';
import SoftBadge from 'components/common/SoftBadge';
import OrderSummarySkeleton from 'components/common/skeletons/order summary/OrderSummarySkeleton';
import { BASE_URL } from 'constants/url';
import DevLog from 'helpers/DevLog';
import {
  paymentMethodChangeFailedTranslation,
  paymentMethodChangeToBankFailedTranslation,
  paymentMethodChangedToBankTranslation,
  paymentMethodChangedTranslation
} from 'languages/MessageTranslations';
import { useLanguage } from 'provider/LanguageProvider';
import React, { useContext, useEffect, useState } from 'react';
import { Card, Col, Form, ListGroup, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import decryptData from 'secret/decryptData';
import BankDetails from './Bank details/BankDetails';
import ShippingContext from './ShippingContext';
import ShippingFormCustom from './ShippingFormCustom';
import PaymentContext from './payment context/PaymentContext';
import usePPK from './shipping address form by country/StorePPK';
import usePriceType from './shipping address form by country/StorePPU';
import useCurrencyStore from 'currency/useCurrencyStore';

const CheckoutCustomer = () => {
  const { PPK } = usePPK();
  const { currency } = useCurrencyStore();
  const { price_per_unit } = usePriceType();
  const { language } = useLanguage();
  const { data } = useContext(ShippingContext);
  const { paymentMethod, setPaymentMethod } = useContext(PaymentContext);
  const [cartItems, setCartItems] = useState([]);
  const [summary, setSummary] = useState({});
  const [total, setTotal] = useState(0);
  const [totalWeight, setTotalWeight] = useState(0);
  // const [priceWithKg, setPriceWithKg] = useState(0);
  const fetchAllCartItems = async () => {
    await axios
      .get(BASE_URL + '/store/order/checkout/summary/', {
        headers: {
          'content-type': 'application/json',
          Authorization: `JWT ${decryptData('token')}`
        }
      })
      .then(response => {
        setSummary(response.data.order);
        DevLog({ cart: response });
        setTotalWeight(response.data?.order?.calculate_total_weight);
        response.data.order_items.map(item => {
          const { order_item_quantity, status, subtotal } = item;
          const p = {
            order_item_quantity,
            status,
            subtotal,
            ...item.supplier_product.product
          };

          return p;
        });
        setCartItems(response.data.order_items);
      })
      .catch(err => {
        DevLog(err);
      })
      .finally(() => {});
  };
  const paymentMethodTranslator = (paymentMethod, lan) => {
    if (paymentMethod === 'CASH' && lan == 'en') {
      return 'CASH';
    } else if (paymentMethod === 'BANK' && lan == 'en') {
      return 'BANK';
    } else if (paymentMethod === 'CASH' && lan == 'uz') {
      return 'NAQD PUL';
    } else if (paymentMethod === 'BANK' && lan == 'uz') {
      return 'BANK';
    } else if (paymentMethod === 'CASH' && lan == 'ru') {
      return 'НАЛИЧНЫЕ';
    } else if (paymentMethod === 'BANK' && lan == 'ru') {
      return 'БАНК';
    }
  };
  useEffect(() => {
    fetchAllCartItems();
  }, []);
  const CalculatePPK = () => {
    const { range_0_100, range_101_500, range_501_1000, range_1001_more } = PPK;
    if (totalWeight > 0 && totalWeight < 100) {
      DevLog(`totalWeight * range_0_100 ${totalWeight * range_0_100}`);
      setTotal(totalWeight * range_0_100);
    } else if (totalWeight > 101 && totalWeight < 500) {
      DevLog(`totalWeight * range_0_100 ${totalWeight * range_0_100}`);
      setTotal(totalWeight * range_101_500);
    } else if (totalWeight > 501 && totalWeight < 1000) {
      DevLog(`totalWeight * range_0_100 ${totalWeight * range_0_100}`);
      setTotal(totalWeight * range_501_1000);
    } else if (totalWeight > 1001 && totalWeight < 1000000) {
      DevLog(`totalWeight * range_0_100 ${totalWeight * range_0_100}`);
      setTotal(totalWeight * range_1001_more);
    }
  };
  useEffect(() => {
    DevLog({ totalWeight, PPK });
    CalculatePPK();
  }, [PPK]);
  useEffect(() => {
    if (price_per_unit) {
      const t = cartItems
        .map(i => {
          const { order_item_quantity, supplier_product } = i;
          const { price_per_unit } = supplier_product.product;
          const total = price_per_unit * order_item_quantity;
          return total;
        })
        .reduce((accumulator, currentValue) => {
          return accumulator + currentValue;
        }, 0);
      setTotal(t);
    }
  }, [price_per_unit, cartItems]);
  return (
    <div>
      <Row className="mb-2">
        <Col>
          <Card className="p-3">
            <h2 className="fs-3">
              {language === 'ru' && 'Страница оформления заказа'}
              {language === 'uz' && "To'lov Sahifasi"}
              {language === 'en' && 'Checkout Page'}
            </h2>
          </Card>
        </Col>
      </Row>
      <div className="row">
        <div className="col-md-6 ">
          <Card className="mb-2  p-3">
            <p className="my-2 fs-3 text-800">
              {language === 'en' && 'Payment Method'}
              {language === 'uz' && "To'lov usuli"}
              {language === 'ru' && 'Способ оплаты'}:{' '}
              {paymentMethodTranslator(paymentMethod, language)}
            </p>

            <div className="d-flex">
              <Form.Check
                className="text-800"
                onChange={async () => {
                  setPaymentMethod('CASH');
                  await axios
                    .patch(
                      BASE_URL + '/store/order/update/',
                      {
                        payment_type: 'CASH' // or BANK
                      },
                      {
                        headers: {
                          'Content-Type': 'application/json',
                          Authorization: `JWT ${decryptData('token')}`
                        }
                      }
                    )
                    .then(r => {
                      if (r.status === 204) {
                        return toast.success(
                          paymentMethodChangedTranslation[language],
                          {
                            theme: 'colored',
                            position: 'top-center'
                          }
                        );
                      }
                    })
                    .catch(err => {
                      return toast.error(
                        paymentMethodChangeFailedTranslation[language] +
                          `\nError: ${err.message}`,
                        {
                          theme: 'colored',
                          position: 'top-right'
                        }
                      );
                    });
                }}
                inline
                type="radio"
                id="item1Radio"
                label={paymentMethodTranslator('CASH', language)}
                defaultChecked
                name="inline-radio"
              />
              <Form.Check
                className="text-800"
                onChange={async () => {
                  setPaymentMethod('BANK');
                  await axios
                    .patch(
                      BASE_URL + '/store/order/update/',
                      {
                        payment_type: 'BANK' // or BANK
                      },
                      {
                        headers: {
                          'Content-Type': 'application/json',
                          Authorization: `JWT ${decryptData('token')}`
                        }
                      }
                    )
                    .then(r => {
                      if (r.status === 204) {
                        return toast.success(
                          paymentMethodChangedToBankTranslation[language],
                          {
                            theme: 'colored',
                            position: 'top-center'
                          }
                        );
                      }
                    })
                    .catch(err => {
                      return toast.error(
                        paymentMethodChangeToBankFailedTranslation[language] +
                          `\nError: ${err.message}`,
                        {
                          theme: 'colored',
                          position: 'top-right'
                        }
                      );
                    });
                }}
                inline
                type="radio"
                id="item2Radio"
                label={paymentMethodTranslator('BANK', language)}
                name="inline-radio"
              />
            </div>
            {paymentMethod === 'BANK' && <BankDetails />}
          </Card>
          <div className="">
            <ShippingFormCustom />
          </div>
          <br />
        </div>
        <div className="col-md-6 ">
          <Card className="p-2">
            <Card.Body>
              <Card.Title>
                {language == 'ru' && 'Краткое описание продукта'}
                {language == 'uz' && "Mahsulotlar haqida qisqacha ma'lumot"}
                {language == 'en' && 'Products Summary'}
              </Card.Title>
              <div className="my-2">
                <ListGroup>
                  {cartItems.length === 0 && (
                    <>
                      <OrderSummarySkeleton />
                    </>
                  )}
                  {DevLog({ cartItems })}
                  {cartItems?.map((p, i) => {
                    return (
                      <ListGroup.Item key={i}>
                        <Flex justifyContent="between" alignItems="center">
                          <p style={{ marginRight: '20px' }}>
                            {p.supplier_product.product.product_full_name}
                            <b className="mx-2"> x{p.order_item_quantity}</b>
                          </p>
                          <SoftBadge bg="secondary" className="me-2">
                            {currency}
                            {Number(p.subtotal).toFixed(2)}
                          </SoftBadge>
                        </Flex>
                      </ListGroup.Item>
                    );
                  })}
                </ListGroup>
              </div>
              <hr />

              <Card.Text className="text-700">
                {Number(summary?.total_cost).toLocaleString('en-AE', {
                  style: 'currency',
                  currency: currency,
                  currencyDisplay: 'symbol'
                })}
              </Card.Text>
              <Card.Text className="text-700">
                {language == 'en' && 'Shipping'}
                {language == 'uz' && 'Yetkazib berish'}
                {language == 'ru' && 'Доставка'}: {currency}+
                {JSON.parse(data)?.name}{' '}
                {Number(
                  Number(JSON.parse(data)?.price) + Number(total)
                ).toFixed(2)}
              </Card.Text>
              <Card.Text className="text-700">
                {language == 'en' && 'Total VAT'}
                {language == 'uz' && 'Umumiy QQS'}
                {language == 'ru' && 'Итого НДС'}: {currency}
                {'+'}
                {DevLog({ json: JSON.parse(data) })}
                {JSON.parse(data).vat == 0
                  ? 0
                  : (Number(summary?.total_cost) *
                      Number(JSON.parse(data).vat)) /
                    100}
              </Card.Text>
              <hr className="text-700" />
              <Card.Text className="text-700">
                {language == 'en' && 'Total'}
                {language == 'uz' && 'Jami'}
                {language == 'ru' && 'Всего'}:{' '}
                {(
                  total +
                  Number(summary?.total_cost) +
                  Number(JSON.parse(data)?.price) +
                  (Number(summary?.total_cost) * Number(JSON.parse(data).vat)) /
                    100
                ).toLocaleString('en-AE', {
                  style: 'currency',
                  currency: { currency },
                  currencyDisplay: 'symbol'
                })}
              </Card.Text>
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  );
};
export default CheckoutCustomer;
