/* eslint-disable react/prop-types */
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import DevLog from 'helpers/DevLog';
import React, { useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { BsFillCartPlusFill } from 'react-icons/bs';
import { MdDelete } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import useUserCart from 'state/cartStore';

const MobileTable = ({ mobile }) => {
  const navigate = useNavigate();
  const { Cart, addToCart, removeFromCart } = useUserCart();
  const [brands] = useState([
    ...new Set(
      mobile.map(b => {
        const { brand } = b;
        const brandName = brand;
        return brandName;
      })
    )
  ]);
  const filtered = mobile.map(phone => {
    // DevLog({ phone });
    const {
      brand,
      color,
      product_full_name,
      ram,
      specs,
      storage,
      product_id,
      lowest_price
    } = phone;
    const tableData = {
      brand,
      color,
      name: product_full_name,
      ram,
      specs,
      storage,
      product_id,
      lowest_price
    };
    return tableData;
  });
  const [MobilePhoneArray, setMobilePhoneArray] = useState(filtered);
  const filterBrands = brand => {
    if (brand == undefined || brand == 'Default select' || brand == null) {
      setMobilePhoneArray(filtered);
      return;
    }
    setMobilePhoneArray(filtered.filter(item => item.brand === brand));
  };
  const columns = [
    {
      accessor: 'brand',
      Header: 'Brand',
      headerProps: { className: 'text-900' }
    },

    {
      accessor: 'name',
      Header: 'Name',
      headerProps: { className: 'text-900' }
    },
    {
      accessor: 'ram',
      Header: 'RAM',
      headerProps: { className: 'text-900' }
    },
    {
      accessor: 'storage',
      Header: 'Storage',
      headerProps: { className: 'text-900' }
    },
    {
      accessor: 'specs',
      Header: 'Region',
      headerProps: { className: 'text-900' }
    },
    {
      accessor: 'color',
      Header: 'Color',
      headerProps: { className: 'text-900' }
    },
    {
      accessor: 'action',
      Header: 'Action',
      headerProps: { className: 'text-900' },
      Cell: rowData => {
        const { product_id } = rowData.row.original;
        const isAdded = Cart.some(c => c.product_id == product_id);
        DevLog({ Cart });
        const item = rowData.row.original;
        DevLog({ item });
        return !isAdded ? (
          <Button
            type="primary"
            onClick={() => {
              addToCart(item);
            }}
          >
            <BsFillCartPlusFill size={20} />
          </Button>
        ) : (
          <Button
            variant="danger"
            onClick={() => {
              removeFromCart(item.product_id);
            }}
          >
            <MdDelete size={20} />
          </Button>
        );
      }
    }
  ];

  return (
    <Card className="p-4 text-800">
      <AdvanceTableWrapper
        columns={columns}
        data={MobilePhoneArray}
        sortable
        pagination
        perPage={50}
      >
        <Row className="flex-end-center mb-3">
          <Col xs="12" sm={12} lg={4}>
            <Button
              onClick={() => {
                //supplier/e-commerce/shopping-cart-laptop
                navigate('/supplier/e-commerce/checkout-products');
              }}
              className="my-2"
            >
              Items in the cart {Cart.length}
            </Button>
          </Col>
          <Col xs="12" sm={12} lg={4}>
            <Form.Select
              className="my-2"
              size="small"
              onChange={e => {
                DevLog(e.target.value);
                filterBrands(e.target.value);
              }}
              // className="mx-3"
            >
              <option value={null}>Default select</option>
              {brands.map((b, i) => (
                <option key={i} value={b}>
                  {b}
                </option>
              ))}
            </Form.Select>
          </Col>
          <Col xs="12" sm={12} lg={4}>
            <AdvanceTableSearchBox className="my-2" table />
          </Col>
        </Row>
        <AdvanceTable
          table
          headerClassName="bg-200 text-nowrap align-middle"
          rowClassName="align-middle white-space-nowrap"
          tableProps={{
            bordered: true,
            striped: true,
            className: 'fs--1 mb-0 overflow-hidden'
          }}
        />
        <div className="mt-3">
          <AdvanceTableFooter
            rowCount={MobilePhoneArray.length}
            table
            rowInfo
            navButtons
            rowsPerPageSelection
          />
        </div>
      </AdvanceTableWrapper>
    </Card>
  );
};

export default MobileTable;
