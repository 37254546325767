/* eslint-disable react/prop-types */
// LanguageContext.js
import DevLog from 'helpers/DevLog';
import React, { createContext, useState, useContext } from 'react';
const LanguageContext = createContext();
export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState(
    localStorage.getItem('language') || 'en'
  );
  const changeLanguage = newLanguage => {
    setLanguage(newLanguage);
    localStorage.setItem('language', newLanguage);
    DevLog({ lang: localStorage.getItem('language') });
  };

  return (
    <LanguageContext.Provider value={{ language, changeLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => {
  return useContext(LanguageContext);
};
