import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { BASE_URL, BASE_URL_WITHOUT_API } from 'constants/url';
import TableSkeleton from 'components/common/skeletons/table skleton/TableSkleton';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import SoftBadge from 'components/common/SoftBadge';
import { AiFillEye } from 'react-icons/ai';
import decryptData from 'secret/decryptData';

const UpdateRequiredSuppliers = () => {
  const [updateRequiredUsersData, setUpdateRequiredUsersData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const fetchUpdateRequiredUsersData = async () => {
    setIsLoading(true);
    if (decryptData('token') !== null) {
      await axios
        .get(BASE_URL + '/dashboard/suppliers/?user_status=UPDATE_REQUIRED', {
          headers: {
            Authorization: `JWT ${decryptData('token')}`
          }
        })
        .then(res => {
          const data = res.data?.map(e => {
            return { ...e, ...e.supplier };
          });
          setUpdateRequiredUsersData(data);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    } else {
      navigate('/');
    }
  };
  const columns = [
    {
      accessor: 'business_name',
      Header: 'Business Name'
    },
    {
      accessor: 'country',
      Header: 'Country'
    },
    {
      accessor: 'contact_person',
      Header: 'Contact Person'
    },
    {
      accessor: 'bank_name',
      Header: 'Bank Name'
    },
    {
      accessor: 'license',
      Header: 'License',
      Cell: rowData => {
        const { license } = rowData.row.original;
        return license == undefined || license == null || license == '' ? (
          <p>Not uploaded yet</p>
        ) : (
          <Link target="blank" to={BASE_URL_WITHOUT_API + license}>
            View License
          </Link>
        );
      }
    },
    {
      accessor: 'certificate',
      Header: 'Certificate',
      Cell: rowData => {
        const { vat_certificate } = rowData.row.original;
        return vat_certificate == undefined ||
          vat_certificate == null ||
          vat_certificate == '' ? (
          <p>Not uploaded yet</p>
        ) : (
          <Link target="blank" to={BASE_URL_WITHOUT_API + vat_certificate}>
            View Certificate
          </Link>
        );
      }
    },
    {
      accessor: 'action',
      Header: 'Action',
      Cell: rowData => {
        const user = rowData.row.original;
        return (
          <>
            <Link
              to={'/admin/supplier/supplierPageWithComments'}
              state={user}
              className="d-flex justify-content-center"
            >
              <SoftBadge
                className="mx-0 cursor-pointer hover-button px-1 py-1 bg-soft-primary text-primary"
                bg="info"
                pill
              >
                <AiFillEye size={22} />
              </SoftBadge>
            </Link>
          </>
        );
      }
    }
  ];
  useEffect(() => {
    fetchUpdateRequiredUsersData();
  }, []);

  if (isLoading) {
    return <TableSkeleton lines={10} />;
  }
  return (
    <Card className="p-4 text-800">
      <AdvanceTableWrapper
        columns={columns}
        data={updateRequiredUsersData}
        sortable
        pagination
        perPage={5}
      >
        <Row className="flex-end-center mb-3">
          <Col xs="auto" sm={6} lg={6}>
            <h3 className="text-primary fw-light">
              List of update required suppliers
            </h3>
          </Col>
          <Col xs="auto" sm={6} lg={6}>
            <AdvanceTableSearchBox table />
          </Col>
        </Row>
        <AdvanceTable
          table
          headerClassName="bg-200 text-900 text-nowrap align-middle"
          rowClassName="align-middle white-space-nowrap"
          tableProps={{
            bordered: true,
            striped: true,
            className: 'fs--1 mb-0 overflow-hidden'
          }}
        />
        <div className="mt-3">
          <AdvanceTableFooter
            rowCount={updateRequiredUsersData.length}
            table
            rowInfo
            navButtons
            rowsPerPageSelection
          />
        </div>
      </AdvanceTableWrapper>
    </Card>
    // <Card className="p-3">
    //   <h3 className="mx-3 text-800">Update Required Users</h3>

    //   <Row>
    //     <Col lg={6}>
    //       <input
    //         type="text"
    //         placeholder="Search..."
    //         className="form-control my-2 mx-3"
    //         value={searchQuery}
    //         onChange={handleSearch}
    //       />
    //     </Col>
    //   </Row>

    //   {filteredUsers.length > 0 ? (
    //     <SimpleBarReact>
    //       <Table>
    //         <thead>
    //           <tr>
    //             <th scope="col">
    //               <p className="text-800">Business Name</p>
    //             </th>
    //             <th scope="col">
    //               <p className="text-800">Country</p>
    //             </th>
    //             <th scope="col">
    //               <p className="text-800">Contact Name</p>
    //             </th>
    //             <th scope="col">
    //               <p className="text-800">Bank Name</p>
    //             </th>

    //             <th scope="col">
    //               <p className="text-800">Get more</p>
    //             </th>
    //           </tr>
    //         </thead>
    //         <tbody>
    //           {filteredUsers.map((user, index) => (
    //             <tr key={index}>
    //               <td>
    //                 <p className="text-800">{user.supplier.business_name}</p>
    //               </td>

    //               <td>
    //                 <p className="text-800">{user.supplier.country}</p>
    //               </td>
    //               <td>
    //                 <p className="text-800">{user.supplier.contact_person}</p>
    //               </td>
    //               <td>
    //                 <p className="text-800">{user.supplier.bank_name}</p>
    //               </td>

    //               <td className="">

    //               </td>
    //             </tr>
    //           ))}
    //         </tbody>
    //       </Table>
    //     </SimpleBarReact>
    //   ) : (
    //     <NothingFound />
    //   )}
    // </Card>
  );
};
export default UpdateRequiredSuppliers;
