export const gamingConsoleWeightsInKg = [
  '',
  2.0, // 2 kg (e.g., Nintendo Switch)
  2.5, // 2.5 kg (e.g., PlayStation 4 Slim)
  3.0, // 3 kg (e.g., Xbox Series S)
  3.5, // 3.5 kg (e.g., PlayStation 5 Digital Edition)
  4.0, // 4 kg (e.g., Xbox Series X)
  4.5, // 4.5 kg (e.g., PlayStation 4 Pro)
  5.0 // 5 kg (e.g., Original Xbox One)
];
