/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import axios from 'axios';
import { BASE_URL } from 'constants/url';
import DevLog from 'helpers/DevLog';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import {
  Card,
  Col,
  OverlayTrigger,
  Row,
  Spinner,
  Table,
  Tooltip
} from 'react-bootstrap';
import decryptData from 'secret/decryptData';
import RequiredHandlerForm from '../order canvas/Required/RequiredHandlerForm';
import RequiredQtyCanvas from '../order canvas/RequiredQtyCanvas';

const OrderedProductsAdminSupplier = ({ supplier_order_id, shipping_fee }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [totalCost, setTotalCost] = useState(0);
  const [orderItems, setOrderItems] = useState([]);
  const subtotal = orderItems.reduce(
    (acc, curr) => curr.quantity * curr.rate + acc,
    0
  );

  const fetchOrderItems = async () => {
    setIsLoading(true);
    await axios
      .get(
        BASE_URL + `/dashboard/suppliers/order/${supplier_order_id}/items/`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `JWT ${decryptData('token')}`
          }
        }
      )
      .then(response => {
        DevLog({ response });
        setTotalCost(response.data.supplier_order_total_cost);
        const filtered = response.data.data.map(e => {
          const { product_full_name } = e.supplier_product.product;
          const { price } = e.supplier_product;
          const {
            required_quantity,
            delivered_quantity,
            supplier_order_item_id,
            supplier_order_item_status,
            subtotal
          } = e;
          return {
            product_full_name,
            price,
            supplier_order_item_id,
            required_quantity,
            delivered_quantity,
            supplier_order_item_status,
            subtotal
          };
        });
        DevLog({ filtered });
        setOrderItems(filtered);
      })
      .catch(error => {
        DevLog({ error });
      })
      .finally(() => {
        setIsLoading(false);
      });

    // setOrderItems(data);
  };

  useEffect(() => {
    fetchOrderItems();
  }, []);

  if (isLoading) {
    return (
      <Card className="p-4">
        <center>
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </center>
      </Card>
    );
  }

  return (
    <Card className="mb-3 text-800">
      <Card.Body>
        <div className="table-responsive fs--1">
          <Table striped className="border-bottom">
            <thead className="bg-200 text-900">
              <tr>
                <th className="border-0 text-800 ">Products</th>
                <th className="border-0 text-800  text-center">Required Qty</th>
                <th className="border-0 text-800  text-center">Received Qty</th>
                {/* <th className="border-0 text-800  text-center">Comment</th> */}
                <th className="border-0 text-800  text-center">Status</th>
                <th className="border-0 text-800  text-end">Rate</th>
                <th className="border-0 text-800  text-end">Amount</th>
              </tr>
            </thead>
            <tbody>
              {orderItems.map((product, i) => (
                <tr key={i} className="border-200">
                  <td className="align-middle text-800">
                    <h6 className="mb-0 text-nowrap">
                      {product.product_full_name}
                    </h6>
                  </td>
                  <td className="align-middle text-800 text-center">
                    {product.required_quantity}
                  </td>
                  <OverlayTrigger
                    placement={'left'}
                    overlay={
                      <Tooltip
                        style={{ position: 'fixed' }}
                        id={'tooltip-' + 'auto'}
                      >
                        Click to edit received quantity
                      </Tooltip>
                    }
                  >
                    <td className="align-middle cursor-pointer text-800 text-center">
                      <RequiredQtyCanvas
                        placement={'end'}
                        title={'Edit received quantity'}
                        name={product.delivered_quantity}
                      >
                        <RequiredHandlerForm
                          product={product}
                          orderItems={orderItems}
                          setOrderItems={setOrderItems}
                          supplier_order_item_id={
                            product.supplier_order_item_id
                          }
                          defaultValue={product.delivered_quantity}
                        />
                      </RequiredQtyCanvas>
                    </td>
                  </OverlayTrigger>
                  {/* <td className="align-middle cursor-pointer text-800 text-center">
                    <RequiredQtyCanvas
                      icon={<FaCommentDots color="blue" />}
                      placement={'end'}
                      title={'Comments received quantity'}
                      name={product.quantity}
                    >
                      <QuantityHistory
                        data={[
                          {
                            key: '1',
                            receivedQty: 100,
                            comment: 'Initial stock'
                          },
                          {
                            key: '2',
                            receivedQty: 50,
                            comment: 'Restock'
                          },
                          {
                            key: '3',
                            receivedQty: 75,
                            comment: 'Additional order'
                          }
                        ]}
                      />
                    </RequiredQtyCanvas>
                  </td> */}
                  <td className="align-middle cursor-pointer text-800 text-center">
                    {product.supplier_order_item_status}
                  </td>
                  <td className="align-middle text-800 text-end">
                    AED{' '}
                    {parseFloat(product.price).toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2
                    })}
                  </td>
                  <td className="align-middle text-800 text-end">
                    AED{' '}
                    {parseFloat(product.subtotal).toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2
                    })}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Row className="g-0 justify-content-end">
            <Col xs="auto">
              <Table borderless size="sm" className="fs--1 text-800 text-end">
                <tbody>
                  <tr className="border-top">
                    <th className="text-900 ">Total:</th>
                    <td className="fw-semi-bold text-800">
                      AED{' '}
                      {parseFloat(totalCost).toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2
                      })}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </div>
      </Card.Body>
    </Card>
  );
};

OrderedProductsAdminSupplier.propTypes = {
  total_cost: PropTypes.string,
  orderId: PropTypes.string,
  shipping_fee: PropTypes.string
};

export default OrderedProductsAdminSupplier;
