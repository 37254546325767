/* eslint-disable react/prop-types */
import axios from 'axios';
import { BASE_URL } from 'constants/url';
import useCurrencyStore from 'currency/useCurrencyStore';
import DevLog from 'helpers/DevLog';
import { SupplierOrdersDetailsPageTranslate } from 'languages/Language';
import PropTypes from 'prop-types';
import { useLanguage } from 'provider/LanguageProvider';
import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Spinner, Table } from 'react-bootstrap';
import decryptData from 'secret/decryptData';

const OrderedProductsCustomer = ({
  total_cost,
  orderId,
  shipping_fee,
  tax_fee
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [orderItems, setOrderItems] = useState([]);
  const { currency } = useCurrencyStore();

  const subtotal = orderItems.reduce(
    (acc, curr) => curr.quantity * curr.rate + acc,
    0
  );
  DevLog({ orderItems });
  const fetchOrderItems = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(BASE_URL + `/store/order/${orderId}/`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `JWT ${decryptData('token')}`
        }
      });
      const data = response.data?.map(item => {
        const { order_item_id, order_item_quantity } = item;
        const { price } = item.supplier_product;
        const {
          brand,
          model_name,
          color,
          processor,
          ram,
          storage,
          specs,
          video_card
        } = item.supplier_product.product;
        return {
          id: order_item_id,
          name: `${brand}, ${model_name == null ? '' : model_name + ','} ${
            color == null ? '' : color + ','
          } ${processor == null ? '' : processor + ','} ${
            ram == null ? '' : ram + ','
          } ${storage == null ? '' : storage} ${
            video_card == null ? '' : video_card
          }.`,
          desc: specs == null ? '-' : specs,
          quantity: order_item_quantity,
          rate: price
        };
      });
      setOrderItems(data);
    } catch (err) {
      DevLog(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchOrderItems();
  }, []);

  if (isLoading) {
    return (
      <Card className="p-4">
        <center>
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </center>
      </Card>
    );
  }
  const { language } = useLanguage();
  return (
    <Card className="mb-3 text-800">
      <Card.Body>
        <div className="table-responsive fs--1">
          <Table striped className="border-bottom">
            <thead className="bg-200 text-900">
              <tr>
                <th className="border-0 text-800 ">
                  {SupplierOrdersDetailsPageTranslate.table.Products[language]}
                </th>
                <th className="border-0 text-800  text-center">
                  {' '}
                  {language === 'en' && 'Quantity'}
                  {language === 'uz' && 'Miqdori'}
                  {language === 'ru' && 'Количество'}
                </th>
                <th className="border-0 text-800  text-end">
                  {' '}
                  {language === 'en' && 'Rate'}
                  {language === 'uz' && 'Narx'}
                  {language === 'ru' && 'Ставка'}
                </th>
                <th className="border-0 text-800  text-end">
                  {language === 'en' && 'Amount'}
                  {language === 'uz' && 'Miqdori'}
                  {language === 'ru' && 'Количество'}
                </th>
              </tr>
            </thead>
            <tbody>
              {orderItems.map(product => (
                <tr key={product.id} className="border-200">
                  <td className="align-middle text-800">
                    <h6 className="mb-0 text-nowrap">{product.name}</h6>
                    <p className="mb-0">{product.desc}</p>
                  </td>
                  <td className="align-middle text-800 text-center">
                    {product.quantity}
                  </td>
                  <td className="align-middle text-800 text-end">
                    {currency}{' '}
                    {parseFloat(product.rate).toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2
                    })}
                  </td>
                  <td className="align-middle text-800 text-end">
                    {currency}{' '}
                    {parseFloat(product.quantity * product.rate).toLocaleString(
                      undefined,
                      { maximumFractionDigits: 2, minimumFractionDigits: 2 }
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Row className="g-0 justify-content-end">
            <Col xs="auto">
              <Table borderless size="sm" className="fs--1 text-800 text-end">
                <tbody>
                  <tr>
                    <th className="text-900">
                      {language === 'en' && 'Subtotal'}
                      {language === 'uz' && 'Jami'}
                      {language === 'ru' && 'Итого'}:
                    </th>
                    <td className="fw-semi-bold text-800">
                      {currency}{' '}
                      {parseFloat(subtotal).toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2
                      })}
                    </td>
                  </tr>
                  <tr>
                    <th className="text-900">
                      {' '}
                      {language === 'en' && 'Tax'}
                      {language === 'uz' && 'Soliq'}
                      {language === 'ru' && 'Налог'}:
                    </th>
                    <td className="fw-semi-bold text-800">
                      +{currency} {tax_fee}
                    </td>
                  </tr>
                  <tr>
                    <th className="text-900">
                      {' '}
                      {language === 'en' && 'Shipping fee'}
                      {language === 'uz' && "Yetkazib berish to'lovi"}
                      {language === 'ru' && 'Стоимость доставки'}:
                    </th>
                    <td className="fw-semi-bold text-800">
                      +{currency} {shipping_fee || 0}
                    </td>
                  </tr>
                  <tr className="border-top">
                    <th className="text-900 ">
                      {' '}
                      {language === 'en' && 'Total'}
                      {language === 'uz' && 'Jami'}
                      {language === 'ru' && 'Всего'}:
                    </th>
                    <td className="fw-semi-bold text-800">
                      {currency}{' '}
                      {parseFloat(total_cost).toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2
                      })}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </div>
      </Card.Body>
    </Card>
  );
};

OrderedProductsCustomer.propTypes = {
  total_cost: PropTypes.string,
  orderId: PropTypes.string,
  shipping_fee: PropTypes.string
};

export default OrderedProductsCustomer;
