/* eslint-disable react/prop-types */
import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { AiFillEye } from 'react-icons/ai';

const ImageModal = ({ children }) => {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <>
      <Button
        className="m-0"
        variant="primary"
        onClick={() => setModalShow(true)}
      >
        <AiFillEye className="m-0" size={20} />
      </Button>

      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Modal heading
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{children}</Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setModalShow(false)}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ImageModal;
