import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import ProfileSettings from './SupplierProfileSettings/SupplierProfileSettings';
import { useNavigate } from 'react-router-dom';
import decryptData from 'secret/decryptData';
import DevLog from 'helpers/DevLog';

const Settings = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState();
  DevLog(user);
  useEffect(() => {
    setUser(decryptData('data-user').user_type);
    if (!decryptData('data-user').user_type) {
      navigate('/');
    }
  }, []);

  return (
    <>
      <Row className="g-3">
        <Col lg={12}>
          <ProfileSettings />
        </Col>
      </Row>
    </>
  );
};

export default Settings;
