export const dashboardRoutesCustomerUz = {
  label: '',
  labelDisable: true,
  children: [
    {
      name: 'Boshqaruv paneli',
      to: '/customer/user/profile',
      icon: 'chart-pie',
      active: true
    }
  ]
};
export const dashboardRoutesCustomerRu = {
  label: 'Панель управления',
  labelDisable: true,
  children: [
    {
      name: 'Dashboard',
      to: '/customer/user/profile',
      icon: 'chart-pie',
      active: true
    }
  ]
};
export const dashboardRoutesCustomerEn = {
  label: 'Dashboard',
  labelDisable: true,
  children: [
    {
      name: 'Dashboard',
      to: '/customer/user/profile',
      icon: 'chart-pie',
      active: true
    }
  ]
};
export const appRoutesCustomerUz = {
  label: 'app',
  children: [
    {
      name: 'Elektron savdo',
      icon: 'shopping-cart',
      active: true,
      children: [
        {
          name: 'Sotib olish',
          active: true,
          children: [
            {
              name: 'Mobil qurilmalar',
              to: '/customer/e-commerce/purchase/mobile-device',
              active: true
            },
            {
              name: 'Noutbuklar',
              to: '/customer/e-commerce/purchase/laptops',
              active: true
            },
            {
              name: "O'yin konsollari",
              to: '/customer/e-commerce/purchase/gaming-consoles',
              active: true
            },
            {
              name: 'Gadjetlar',
              to: '/customer/e-commerce/purchase/wearables',
              active: true
            }
          ]
        },
        {
          name: 'Mening savatcham',
          to: '/customer/e-commerce/orders',
          active: true
        },
        {
          name: 'Mening buyurtmalarim',
          to: '/customer/e-commerce/get/orders',
          active: true
        }
      ]
    }
  ]
};
export const appRoutesCustomerEn = {
  label: 'app',
  children: [
    {
      name: 'E Commerce',
      icon: 'shopping-cart',
      active: true,
      children: [
        {
          name: 'Purchase',
          active: true,
          children: [
            {
              name: 'Mobile devices',
              to: '/customer/e-commerce/purchase/mobile-device',
              active: true
            },
            {
              name: 'Laptops',
              to: '/customer/e-commerce/purchase/laptops',
              active: true
            },
            {
              name: 'Gaming Consoles',
              to: '/customer/e-commerce/purchase/gaming-consoles',
              active: true
            },
            {
              name: 'Wearables',
              to: '/customer/e-commerce/purchase/wearables',
              active: true
            }
          ]
        },
        {
          name: 'My Cart',
          to: '/customer/e-commerce/orders',
          active: true
        },
        {
          name: 'My orders',
          to: '/customer/e-commerce/get/orders',
          active: true
        }
      ]
    }
  ]
};
export const appRoutesCustomerRu = {
  label: 'app',
  children: [
    {
      name: 'Электронная торговля',
      icon: 'shopping-cart',
      active: true,
      children: [
        {
          name: 'Покупка',
          active: true,
          children: [
            {
              name: 'Мобильные устройства',
              to: '/customer/e-commerce/purchase/mobile-device',
              active: true
            },
            {
              name: 'Ноутбуки',
              to: '/customer/e-commerce/purchase/laptops',
              active: true
            },
            {
              name: 'Игровые консоли',
              to: '/customer/e-commerce/purchase/gaming-consoles',
              active: true
            },
            {
              name: 'Носимые гаджеты',
              to: '/customer/e-commerce/purchase/wearables',
              active: true
            }
          ]
        },
        {
          name: 'Моя корзина',
          to: '/customer/e-commerce/orders',
          active: true
        },
        {
          name: 'Мои заказы',
          to: '/customer/e-commerce/get/orders',
          active: true
        }
      ]
    }
  ]
};
export const pagesRoutesCustomer = {
  label: 'pages',
  children: [
    {
      name: 'My Account',
      icon: 'user',
      active: true,
      children: [
        {
          name: 'Profile',
          to: '/customer/user/profile',
          active: true
        },
        {
          name: 'Settings',
          to: '/customer/user/settings',
          active: true
        }
      ]
    }
  ]
};

export default [appRoutesCustomerEn];
