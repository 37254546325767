import React, { useState } from 'react';
import PropTypes from 'prop-types';

import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import Lottie from 'lottie-react';
import infiniteLoop from '../../../assets/img/animated-icons/new/thanks.json';
import errorAnimation from '../../../assets/img/animated-icons/new/403.json';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { BASE_URL } from 'constants/url';
import DevLog from 'helpers/DevLog';

const ConfirmMailContent = ({ titleTag: TitleTag }) => {
  const params = useParams();
  const navigate = useNavigate();
  const [thankYou, setThankYou] = useState(false);
  const [error, setError] = useState(false);
  const [text, setText] = useState(true);
  DevLog(params);
  const { uuid, token } = params;
  DevLog(uuid, token);
  const verifyEmail = async () => {
    const data = {
      uid: uuid,
      token: token
    };

    const headers = {
      'Content-Type': 'application/json'
    };
    try {
      await axios
        .post(BASE_URL + '/auth/users/activation/', data, { headers })
        .then(response => {
          DevLog(response);
          response.status == 204 && setThankYou(true);
          if (response.status == 204) {
            setText(false);
          }
        })
        .catch(e => {
          DevLog(e);
          setError(true);
          setText(false);
        });
    } catch (error) {
      DevLog({ error });
    }
  };

  return (
    <>
      {thankYou && (
        <Lottie animationData={infiniteLoop} loop={true} width={100} />
      )}

      {error && (
        <Lottie animationData={errorAnimation} loop={true} width={100} />
      )}
      {error && (
        <>
          <TitleTag>You have already confirmed you email!</TitleTag>
          <br />
          <Button onClick={() => navigate('/')}>Go to Login page</Button>
        </>
      )}
      {text && (
        <>
          <TitleTag>
            Please confirm your email by clicking the button below!
          </TitleTag>
          <br />
          <Button onClick={() => verifyEmail()}>
            Please Confirm Your email
          </Button>
        </>
      )}

      {thankYou && (
        <>
          <TitleTag>Thank you for confirming your email!</TitleTag>
          <Link to="/" className="my-5">
            Go to login page
          </Link>
        </>
      )}
    </>
  );
};

ConfirmMailContent.propTypes = {
  layout: PropTypes.string,
  titleTag: PropTypes.string
};

ConfirmMailContent.defaultProps = { layout: 'simple', titleTag: 'h4' };

export default ConfirmMailContent;
