/* eslint-disable react/prop-types */
import axios from 'axios';
import { BASE_URL } from 'constants/url';
import DevLog from 'helpers/DevLog';
import useScreenSize from 'hooks/useScreenSize';
import { useLanguage } from 'provider/LanguageProvider';
import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import decryptData from 'secret/decryptData';

const UserInfoCard = ({ email, currency, user_type }) => {
  const [balance, setBalance] = useState(0);
  const screen = useScreenSize();
  const fetchBalance = async () => {
    await axios
      .get(BASE_URL + '/auth/users/me/', {
        headers: {
          Authorization: `JWT ${decryptData('token')}`
        }
      })
      .then(response => {
        setBalance(parseFloat(response.data.user_deposit.deposit_amount));
        localStorage.setItem(
          'balance',
          parseFloat(response.data.user_deposit.deposit_amount)
        );
      })
      .catch(err => {
        DevLog({ err });
      })
      .finally(() => {});
  };
  const { language } = useLanguage();
  useEffect(() => {
    fetchBalance();
  }, []);
  const userType = (language, type) => {
    if (language == 'uz' && type == 'customer') {
      return (
        <b className={screen == 'desktop' ? 'fs-1' : ''}>
          Foydalanuvchi: Haridor
        </b>
      );
    } else if (language == 'uz' && type == 'supplier') {
      return (
        <b className={screen == 'desktop' ? 'fs-1' : ''}>
          Foydalanuvchi: Ta'minotchi
        </b>
      );
    } else if (language == 'uz' && type == 'admin') {
      return (
        <b className={screen == 'desktop' ? 'fs-1' : ''}>
          Siz administratorsiz
        </b>
      );
    } else if (language == 'ru' && type == 'customer') {
      return (
        <b className={screen == 'desktop' ? 'fs-1' : ''}>
          Пользователь: клиент
        </b>
      );
    } else if (language == 'ru' && type == 'supplier') {
      return (
        <b className={screen == 'desktop' ? 'fs-1' : ''}>
          Пользователь: поставщик
        </b>
      );
    } else if (language == 'ru' && type == 'admin') {
      return (
        <b className={screen == 'desktop' ? 'fs-1' : ''}>
          Пользователь: администратор
        </b>
      );
    } else if (language == 'en' && type == 'admin') {
      return (
        <b className={screen == 'desktop' ? 'fs-1' : ''}>
          You are administrator
        </b>
      );
    } else if (language == 'en' && type == 'supplier') {
      return (
        <b className={screen == 'desktop' ? 'fs-1' : ''}>User: Supplier</b>
      );
    } else if (language == 'en' && type == 'customer') {
      return (
        <b className={screen == 'desktop' ? 'fs-1' : ''}>User: Customer</b>
      );
    }
  };
  const balanceTranslate = language => {
    if (language == 'uz') {
      return 'Balans';
    } else if (language == 'ru') {
      return 'Баланс';
    } else {
      return 'Balance';
    }
  };
  const emailTranslate = language => {
    if (language == 'uz') {
      return 'Elektron pochta';
    } else if (language == 'ru') {
      return 'Электронная почта';
    } else {
      return 'Email';
    }
  };
  return (
    <Card className="text-900 p-3 h-100" title="User Information">
      <p className={'text-900' + ' ' + `${screen == 'desktop' ? 'fs-1' : ''}`}>
        {userType(language, user_type)}
      </p>
      <p className={'text-900' + ' ' + `${screen == 'desktop' ? 'fs-1' : ''}`}>
        <strong
          className={'text-900' + ' ' + `${screen == 'desktop' ? 'fs-1' : ''}`}
        >
          {emailTranslate(language)}:
        </strong>{' '}
        {email}
      </p>
      <p className={'text-900' + ' ' + `${screen == 'desktop' ? 'fs-1' : ''}`}>
        <strong
          className={'text-900' + ' ' + `${screen == 'desktop' ? 'fs-1' : ''}`}
        >
          {balanceTranslate(language)}:
        </strong>{' '}
        {currency} {balance.toFixed(2)}
      </p>
    </Card>
  );
};

export default UserInfoCard;
