/* eslint-disable react/prop-types */
import React, { createContext, useContext, useState } from 'react';

const TimerContext = createContext();

export const useTimer = () => {
  return useContext(TimerContext);
};

export const TimerProvider = ({ children }) => {
  const [isTimerRunning, setIsTimerRunning] = useState(false);

  const startTimer = () => {
    setIsTimerRunning(true);
  };

  const timerValue = {
    isTimerRunning,
    startTimer
  };

  return (
    <TimerContext.Provider value={timerValue}>{children}</TimerContext.Provider>
  );
};
