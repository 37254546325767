/* eslint-disable react/prop-types */
import SoftBadge from 'components/common/SoftBadge';
import React, { useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import CustomFileUpload from './UploadFile';
import { AiOutlineCloudDownload } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { useLanguage } from 'provider/LanguageProvider';
import { BASE_URL_WITHOUT_API } from 'constants/url';
import DevLog from 'helpers/DevLog';

const OrderInfoCustomer = ({ x, payment_type, id, bank_receipt_file }) => {
  const [isUploaded, setIsUploaded] = useState(bank_receipt_file);
  const { language } = useLanguage();
  const {
    address_line_1,
    address_line_2,
    city,
    contact_name,
    contact_number,
    country,
    shipping_type
  } = x;
  DevLog({ xxx: x });
  return (
    <Card className="mb-3 text-700">
      <Card.Body>
        <Row>
          <Col md={6} lg={4} className="mb-4 mb-lg-0">
            <h5 className="mb-3 fs-0">
              {language == 'en' && 'Billing Information'}
              {language == 'uz' && "To'lov ma'lumotlari"}
              {language == 'ru' && 'Платежная информация'}
            </h5>
            <h6 className="mb-2">
              {language == 'en' && 'Contact name'}
              {language == 'uz' && 'Kontakt ismi'}
              {language == 'ru' && 'Имя контактного лица'}: {contact_name}
            </h6>
            <p className="mb-1 fs--1">{country}</p>
            <p className="mb-1 fs--1">{city}</p>
            <p className="mb-0 fs--1">
              <strong>
                {language == 'en' && 'Phone'}
                {language == 'uz' && 'Telefon'}
                {language == 'ru' && 'Телефон'}:{' '}
                <a href={`tel:${contact_number}`}>{contact_number}</a>
              </strong>
            </p>
          </Col>
          <Col md={6} lg={4} className="mb-4 mb-lg-0">
            <h5 className="mb-3 fs-0">
              {language == 'en' && 'Shipping type'}
              {language == 'uz' && 'Yetkazib berish turi'}
              {language == 'ru' && 'Тип доставки'}:{' '}
              <SoftBadge pill className="mx-2" variant="info">
                {shipping_type}
              </SoftBadge>
            </h5>
            <h5 className="mb-3 fs-0">
              {' '}
              {language == 'en' && 'Shipping address'}
              {language == 'uz' && 'Yetkazib berish manzili'}
              {language == 'ru' && 'Адрес доставки'}
            </h5>

            <h6 className="mb-3">
              {' '}
              {language == 'en' && 'Address line'}
              {language == 'uz' && 'Manzil liniyasi'}
              {language == 'ru' && 'Адресная линия'}: {address_line_1}
            </h6>
            <h6 className="mb-3">
              {' '}
              {language == 'en' && 'Address line'}
              {language == 'uz' && 'Manzil liniyasi'}
              {language == 'ru' && 'Адресная линия'}2: {address_line_2}
            </h6>
            {/* <div className="text-500 fs--1">(Free Shipping)</div> */}
          </Col>
          <Col md={6} lg={4}>
            <h5 className="mb-3 fs-0">
              {language == 'en' && 'Payment type'}
              {language == 'uz' && "To'lov turi"}
              {language == 'ru' && 'Способ оплаты'}:{' '}
              <SoftBadge pill className="mx-2" variant="info">
                {payment_type}
              </SoftBadge>
            </h5>
            {payment_type == 'BANK' && isUploaded == null ? (
              <CustomFileUpload setIsUploaded={setIsUploaded} id={id} />
            ) : payment_type == 'BANK' && isUploaded !== null ? (
              <>
                <SoftBadge pill className="p-3 px-4 fs--1" bg="info">
                  {language == 'en' &&
                    'You have successfully uploaded evidence for payment'}
                  {language == 'uz' &&
                    'Siz toʻlov uchun dalillarni muvaffaqiyatli yukladingiz'}
                  {language == 'ru' &&
                    'Вы успешно загрузили подтверждение платежа'}
                </SoftBadge>
                <br />
                <SoftBadge pill bg="success" className="my-2 fs-1 p-2 px-3">
                  <Link
                    target="_blank"
                    className="text-success"
                    to={BASE_URL_WITHOUT_API + bank_receipt_file}
                  >
                    {language == 'en' && 'Download'}
                    {language == 'uz' && 'Yuklab oling'}
                    {language == 'ru' && 'Скачать'} <AiOutlineCloudDownload />
                  </Link>
                </SoftBadge>
              </>
            ) : null}
            {/* <div className="d-flex">
              <div className="flex-1">
                <h6 className="mb-0">Mr Humble</h6>
                <p className="mb-0 fs--1">**** **** **** 9758</p>
              </div>
            </div> */}
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default OrderInfoCustomer;
