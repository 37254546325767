/* eslint-disable no-unused-vars */
import React, { useContext, useEffect } from 'react';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import AppContext from 'context/Context';
import classNames from 'classnames';
// import Footer from 'components/footer/Footer';
import NavbarVerticalAdmin from 'components/navbar/vertical/admin/NavbarVerticalAdmin';
import NavbarTopAdmin from 'components/navbar/top/admin/NavbarTopAdmin';
import { toast } from 'react-toastify';
import axios from 'axios';
import { BASE_URL } from 'constants/url';
import { AiFillHome } from 'react-icons/ai';
import { Button } from 'react-bootstrap';
import decryptData from 'secret/decryptData';
import DevLog from 'helpers/DevLog';
import NavigationButtons from './navigation_btn/NavigationButtons';
const MainLayoutAdmin = () => {
  const { hash, pathname } = useLocation();
  const isKanban = pathname.includes('kanban');
  const navigate = useNavigate();
  const {
    config: { isFluid, navbarPosition }
  } = useContext(AppContext);

  useEffect(() => {
    if (decryptData('data-user') !== null) {
      if (decryptData('data-user').user_type !== 'admin') {
        toast.error(`You are not ADMIN`, {
          theme: 'colored'
        });
        navigate('/');
      }
    } else {
      navigate('/');
    }

    setTimeout(() => {
      if (hash) {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ block: 'start', behavior: 'smooth' });
        }
      }
    }, 0);
  }, []);

  const fetchCategories = async () => {
    await axios
      .get(BASE_URL + '/dashboard/categories/', {
        headers: {
          Authorization: `JWT ${decryptData('token')}`,
          'Content-Type': 'application/json'
        }
      })
      .then(r => {
        try {
          r.data?.map(data => {
            if (data.category_name == 'Mobile') {
              localStorage.setItem(
                'mobileCategoryId',
                JSON.stringify(data.category_id)
              );
            } else if (data.category_name == 'Laptop') {
              localStorage.setItem(
                'laptopCategoryId',
                JSON.stringify(data.category_id)
              );
            } else if (data.category_name == 'Gaming') {
              localStorage.setItem(
                'gamingCategoryId',
                JSON.stringify(data.category_id)
              );
            } else if (data.category_name == 'Wearable') {
              localStorage.setItem(
                'wearableCategoryId',
                JSON.stringify(data.category_id)
              );
            }
          });
        } catch (error) {
          DevLog({ error });
        }
      })
      .catch(er => DevLog({ er }));
  };
  useEffect(() => {
    fetchCategories();
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className={'container-fluid position-relative'}>
      {(navbarPosition === 'vertical' || navbarPosition === 'combo') && (
        <NavbarVerticalAdmin />
      )}
      <div className={classNames('content', { 'pb-0': isKanban })}>
        <NavbarTopAdmin />
        <NavigationButtons />
        {/*------ Main Routes ------*/}
        <Outlet />
        {/* {!isKanban && <Footer />} */}
        <Link to={'/admin/dashboard'}>
          <Button
            style={{
              bottom: '20px',
              right: '20px'
            }}
            className="rounded-5 p-3 position-fixed z-3 "
          >
            <AiFillHome className="text-white" size={30} />
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default MainLayoutAdmin;
