import { countryOptions } from 'constants/country/country';
import React, { useState } from 'react';
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap';
import axios from 'axios';
import { BASE_URL } from 'constants/url';
import DevLog from 'helpers/DevLog';
import { FiLogOut } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import decryptData from 'secret/decryptData';
const BlankCustomerForm = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    business_name: '',
    business_address: '',
    contact_person: '',
    country: '',
    phone_number: '',
    bank_name: '',
    bank_address: '',
    account_number: '',
    vat_number: '',
    swift_code: '',
    iban: '',
    user_account: decryptData('data-user').id
  });
  const handleSubmit = async e => {
    e.preventDefault();
    DevLog({ formData });
    AddCustomerProfileInformation(e);
  };
  const handleAddChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleLicenseChange = event => {
    const license = event.target.files[0];
    setFormData(prevFormData => ({
      ...prevFormData,
      license
    }));
  };
  const handleVatCertChange = event => {
    const vat_certificate = event.target.files[0];
    setFormData(prevFormData => ({
      ...prevFormData,
      vat_certificate
    }));
  };

  const AddCustomerProfileInformation = async e => {
    setIsLoading(true);
    DevLog({ e });
    await axios
      .post(BASE_URL + '/customer/info/add/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `JWT ${decryptData('token')}`
        }
      })
      .then(res => {
        if (res.status === 200 || res.status == 201 || res.status === 204) {
          window.location.reload();
          return toast.success(
            `Congratulations🎉, Your have added necessary information to your account!`,
            {
              theme: 'colored',
              position: 'top-right',
              autoClose: true
            }
          );
        }
      })
      .catch(err => {
        DevLog({ err });
        return toast.error(
          `Something went wrong with your account, please try again`,
          {
            theme: 'colored',
            position: 'top-right',
            autoClose: 30000
          }
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div>
      <h1 className="text-center text-primary fs-3">
        You must fill the following fields in order to activate your account
      </h1>
      <Form onSubmit={handleSubmit} encType="multipart/form-data">
        <Row className="mb-3 g-3">
          <Form.Group as={Col} lg={6} controlId="business_name">
            <Form.Label> Business name </Form.Label>
            <Form.Control
              type="text"
              placeholder="Business Name"
              value={formData.business_name}
              name="business_name"
              onChange={handleAddChange}
            />
          </Form.Group>

          <Form.Group as={Col} lg={6} controlId="country">
            <Form.Label>Country</Form.Label>

            <select
              className="form-select"
              name="country"
              onChange={handleAddChange}
            >
              <option value={formData.country}></option>
              {countryOptions.map((country, i) => (
                <option value={country} key={i}>
                  {country}
                </option>
              ))}
            </select>
          </Form.Group>
        </Row>
        <Row className="mb-3 g-3">
          <Form.Group as={Col} lg={6} controlId="business_address">
            <Form.Label>Business address</Form.Label>
            <Form.Control
              type="text"
              placeholder="Business address"
              value={formData.business_address}
              name="business_address"
              onChange={handleAddChange}
            />
          </Form.Group>

          <Form.Group as={Col} lg={6} controlId="contact_person">
            <Form.Label>Contact person</Form.Label>
            <Form.Control
              type="text"
              placeholder="Contact person"
              value={formData.contact_person}
              name="contact_person"
              onChange={handleAddChange}
            />
          </Form.Group>

          <Form.Group as={Col} lg={6} className="mb-3" controlId="phone_number">
            <Form.Label>Phone number</Form.Label>
            <Form.Control
              type="text"
              placeholder="Phone number"
              value={formData.phone_number}
              name="phone_number"
              onChange={handleAddChange}
            />
          </Form.Group>

          <Form.Group as={Col} lg={6} className="mb-3" controlId="bank_name">
            <Form.Label>Bank name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Bank name"
              value={formData.bank_name}
              name="bank_name"
              onChange={handleAddChange}
            />
          </Form.Group>
          <Form.Group as={Col} lg={6} className="mb-3" controlId="bank_address">
            <Form.Label>Bank address</Form.Label>
            <Form.Control
              type="text"
              placeholder="Bank address"
              value={formData.bank_address}
              name="bank_address"
              onChange={handleAddChange}
            />
          </Form.Group>
          <Form.Group
            as={Col}
            lg={6}
            className="mb-3"
            controlId="account_number"
          >
            <Form.Label>Account number</Form.Label>
            <Form.Control
              type="text"
              placeholder="Account number"
              value={formData.account_number}
              name="account_number"
              onChange={handleAddChange}
            />
          </Form.Group>
          <Form.Group as={Col} lg={6} className="mb-3" controlId="vat_number">
            <Form.Label>VAT number</Form.Label>
            <Form.Control
              type="text"
              placeholder="VAT number"
              value={formData.vat_number}
              name="vat_number"
              onChange={handleAddChange}
            />
          </Form.Group>
          <Form.Group as={Col} lg={6} className="mb-3" controlId="swift_code">
            <Form.Label>SWIFT code</Form.Label>
            <Form.Control
              type="text"
              maxLength={15}
              placeholder="SWIFT code"
              value={formData.swift_code}
              name="swift_code"
              onChange={handleAddChange}
            />
          </Form.Group>
          <Form.Group as={Col} lg={6} className="mb-3" controlId="iban">
            <Form.Label>IBAN</Form.Label>
            <Form.Control
              type="text"
              placeholder="IBAN code"
              value={formData.iban}
              name="iban"
              onChange={handleAddChange}
            />
          </Form.Group>
        </Row>
        <Form.Group as={Col} lg={6} className="mb-3" controlId="file">
          <Form.Label>Upload Trade License</Form.Label>
          <Form.Control
            required
            type="file"
            accept=".pdf, .png, .jpg, .jpeg" // Accept only PDF and image file types
            name="licence"
            max="5242880"
            onChange={handleLicenseChange}
          />
        </Form.Group>
        <Form.Group as={Col} lg={6} className="mb-3" controlId="file">
          <Form.Label>Upload VAT certificate</Form.Label>
          <Form.Control
            type="file"
            required
            max="5242880"
            accept=".pdf, .png, .jpg, .jpeg" // Accept only PDF and image file types
            name="vat_certificate"
            onChange={handleVatCertChange}
          />
        </Form.Group>
        <Button
          variant={isLoading ? 'info' : 'primary'}
          className={`w-100 mt-3 w-100 d-flex fs-1 ${
            !isLoading ? 'px-3 py-2' : 'p-0'
          } justify-content-center align-items-center`}
          type="submit"
        >
          {isLoading ? (
            <>
              Saving your details, please wait...
              <Spinner className="m-2" animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </>
          ) : (
            'Complete account information'
          )}{' '}
        </Button>
        <Button
          onClick={() => {
            localStorage.clear();
            navigate('/');
          }}
          variant={'danger'}
          className={'px-3 py-2 w-100 my-2'}
        >
          <>
            Logout
            <FiLogOut className="mx-2" size={18} />
          </>
        </Button>
      </Form>
    </div>
  );
};

export default BlankCustomerForm;
