import axios from 'axios';
import { BASE_URL } from 'constants/url';
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Alert, Button, Col, Form, Row } from 'react-bootstrap';
import { countryOptions } from 'constants/country/country';
import decryptData from 'secret/decryptData';
import DevLog from 'helpers/DevLog';
const UpdateRequiredCustomer = ({ c }) => {
  const [customer, setCustomer] = useState(c);
  const [formData, setFormData] = useState({});
  const handleSubmit = e => {
    DevLog({ e });
    e.preventDefault();

    updateCommentsInSuppliersInfo();
  };
  const updateCommentsInSuppliersInfo = async () => {
    await axios
      .patch(
        BASE_URL + `/customer/info/update/${customer.customer_id}/ `,
        { ...formData },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `JWT ${decryptData('token')}`
          }
        }
      )
      .then(async r => {
        DevLog({ r });
        if (r.status === 200) {
          await axios
            .patch(
              BASE_URL + '/auth/users/me/',
              {
                user_status: 'UPDATED'
              },
              {
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `JWT ${decryptData('token')}`
                }
              }
            )
            .then(r => {
              DevLog({ r });
              window.location.reload();
            })
            .catch(err => {
              DevLog(err);

              // helperScrollToTop();
            });
        }
      })
      .catch(err => {
        DevLog({ outer: err });
      });
  };
  const handleChange = e => {
    setCustomer({ ...customer, [e.target.name]: e.target.value });
  };
  const handleFormChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <Row className="mb-3 g-3">
          <Form.Group as={Col} lg={12} controlId="business_name">
            <Form.Label> Business name </Form.Label>
            <Form.Control
              type="text"
              placeholder="Business Name"
              value={customer.business_name}
              name="business_name"
              disabled={customer.business_name_comment.length === 0}
              onChange={e => {
                handleChange(e);
                handleFormChange(e);
              }}
            />
          </Form.Group>
          {customer.business_name_comment.length > 0 && (
            <Col
              className="d-flex justify-content-center align-items-end"
              lg={12}
            >
              <Alert className="p-1 w-100 m-0 px-2" variant={'primary'}>
                {customer.business_name_comment}
              </Alert>
            </Col>
          )}
        </Row>
        <hr />
        <Row>
          <Form.Group
            as={Col}
            lg={customer.country_comment.length === 0 ? 12 : 6}
            controlId="country"
          >
            <Form.Label>Country</Form.Label>
            <select
              className="form-select"
              name="country"
              disabled={customer.country_comment.length === 0}
              onChange={e => {
                handleChange(e);
                handleFormChange(e);
              }}
            >
              <option value={customer.country}>{customer.country} </option>
              {countryOptions.map((country, i) => (
                <option value={country} key={i}>
                  {country}
                </option>
              ))}
            </select>
          </Form.Group>

          {customer.country_comment.length > 0 && (
            <Col
              className="d-flex justify-content-center align-items-end"
              lg={12}
            >
              <Alert className="p-1 w-100 m-0 px-2" variant={'primary'}>
                {customer.country_comment}
              </Alert>
            </Col>
          )}
        </Row>
        <hr />
        <Row className="mb-3 g-3">
          <Form.Group
            as={Col}
            lg={customer.business_address_comment.length === 0 ? 12 : 6}
            controlId="business_address"
          >
            <Form.Label>Business address</Form.Label>
            <Form.Control
              type="text"
              placeholder="Business address"
              value={customer.business_address}
              disabled={customer.business_address_comment.length === 0}
              name="business_address"
              onChange={e => {
                handleChange(e);
                handleFormChange(e);
              }}
            />
          </Form.Group>
          {customer.business_address_comment.length > 0 && (
            <Col
              className="d-flex justify-content-center align-items-end"
              lg={12}
            >
              <Alert className="p-1 w-100 m-0 px-2" variant={'primary'}>
                {customer.business_address_comment}
              </Alert>
            </Col>
          )}
        </Row>
        <hr />
        <Row className="mb-3 g-3">
          <Form.Group
            as={Col}
            lg={customer.contact_person_comment.length === 0 ? 12 : 6}
            controlId="contact_person"
          >
            <Form.Label>Contact person</Form.Label>
            <Form.Control
              type="text"
              placeholder="Contact person"
              value={customer.contact_person}
              disabled={customer.contact_person_comment.length === 0}
              name="contact_person"
              onChange={e => {
                handleChange(e);
                handleFormChange(e);
              }}
            />
          </Form.Group>
          {customer.contact_person_comment.length > 0 && (
            <Col
              className="d-flex justify-content-center align-items-end"
              lg={12}
            >
              <Alert className="p-1 w-100 m-0 px-2" variant={'primary'}>
                {customer.contact_person_comment}
              </Alert>
            </Col>
          )}
        </Row>
        <hr />
        <Row className="mb-3 g-3">
          <Form.Group
            as={Col}
            lg={customer.phone_number_comment.length === 0 ? 12 : 6}
            controlId="phone_number"
          >
            <Form.Label>Phone number</Form.Label>
            <Form.Control
              type="text"
              placeholder="Phone number"
              value={customer.phone_number}
              disabled={customer.phone_number_comment.length === 0}
              name="phone_number"
              onChange={e => {
                handleChange(e);
                handleFormChange(e);
              }}
            />
          </Form.Group>
          {customer.phone_number_comment.length > 0 && (
            <Col
              className="d-flex justify-content-center align-items-end"
              lg={12}
            >
              <Alert className="p-1 w-100 m-0 px-2" variant={'primary'}>
                {customer.contact_person_comment}
              </Alert>
            </Col>
          )}
        </Row>
        <hr />
        <Row className="mb-3 g-3">
          <Form.Group
            as={Col}
            lg={customer.bank_name_comment.length === 0 ? 12 : 6}
            controlId="bank_name"
          >
            <Form.Label>Bank name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Bank name"
              value={customer.bank_name}
              disabled={customer.bank_name_comment.length === 0}
              name="bank_name"
              onChange={e => {
                handleChange(e);
                handleFormChange(e);
              }}
            />
          </Form.Group>
          {customer.bank_name_comment.length > 0 && (
            <Col
              className="d-flex justify-content-center align-items-end"
              lg={12}
            >
              <Alert className="p-1 w-100 m-0 px-2" variant={'primary'}>
                {customer.bank_name_comment}
              </Alert>
            </Col>
          )}
        </Row>
        <hr />
        <Row className="mb-3 g-3">
          <Form.Group
            as={Col}
            lg={customer.bank_address_comment.length === 0 ? 12 : 6}
            controlId="bank_address"
          >
            <Form.Label>Bank address</Form.Label>
            <Form.Control
              type="text"
              placeholder="Bank address"
              value={customer.bank_address}
              disabled={customer.bank_address_comment.length === 0}
              name="bank_address"
              onChange={e => {
                handleChange(e);
                handleFormChange(e);
              }}
            />
          </Form.Group>
          {customer.bank_address_comment.length > 0 && (
            <Col
              className="d-flex justify-content-center align-items-end"
              lg={12}
            >
              <Alert className="p-1 w-100 m-0 px-2" variant={'primary'}>
                {customer.bank_address_comment}
              </Alert>
            </Col>
          )}
        </Row>
        <hr />
        <Row className="mb-3 g-3">
          <Form.Group
            as={Col}
            lg={customer.account_number_comment.length === 0 ? 12 : 6}
            controlId="account_number"
          >
            <Form.Label>Account number</Form.Label>
            <Form.Control
              type="text"
              placeholder="Account number"
              value={customer.account_number}
              disabled={customer.account_number_comment.length === 0}
              name="account_number"
              onChange={e => {
                handleChange(e);
                handleFormChange(e);
              }}
            />
          </Form.Group>
          {customer.account_number_comment.length > 0 && (
            <Col
              className="d-flex justify-content-center align-items-end"
              lg={12}
            >
              <Alert className="p-1 w-100 m-0 px-2" variant={'primary'}>
                {customer.account_number_comment}
              </Alert>
            </Col>
          )}
        </Row>
        <hr />
        <Row className="mb-3 g-3">
          <Form.Group
            as={Col}
            lg={customer.vat_number_comment.length === 0 ? 12 : 6}
            controlId="vat_number"
          >
            <Form.Label>VAT number</Form.Label>
            <Form.Control
              type="text"
              placeholder="VAT number"
              value={customer.vat_number}
              disabled={customer.vat_number_comment.length === 0}
              name="vat_number"
              onChange={e => {
                handleChange(e);
                handleFormChange(e);
              }}
            />
          </Form.Group>
          {customer.vat_number_comment.length > 0 && (
            <Col
              className="d-flex justify-content-center align-items-end"
              lg={12}
            >
              <Alert className="p-1 w-100 m-0 px-2" variant={'primary'}>
                {customer.vat_number_comment}
              </Alert>
            </Col>
          )}
        </Row>
        <hr />
        <Row className="mb-3 g-3">
          <Form.Group
            as={Col}
            lg={customer.swift_code_comment.length === 0 ? 12 : 6}
            controlId="swift_code"
          >
            <Form.Label>SWIFT code</Form.Label>
            <Form.Control
              type="text"
              maxLength={15}
              placeholder="SWIFT code"
              value={customer.swift_code}
              disabled={customer.swift_code_comment.length === 0}
              name="swift_code"
              onChange={e => {
                handleChange(e);
                handleFormChange(e);
              }}
            />
          </Form.Group>
          {customer.swift_code_comment.length > 0 && (
            <Col
              className="d-flex justify-content-center align-items-end"
              lg={12}
            >
              <Alert className="p-1 w-100 m-0 px-2" variant={'primary'}>
                {customer.swift_code_comment}
              </Alert>
            </Col>
          )}
        </Row>
        <hr />
        <Row className="mb-3 g-3">
          <Form.Group
            as={Col}
            lg={customer.iban_comment.length === 0 ? 12 : 6}
            controlId="iban"
          >
            <Form.Label>IBAN</Form.Label>
            <Form.Control
              type="text"
              placeholder="IBAN code"
              value={customer.iban}
              disabled={customer.iban_comment.length === 0}
              name="iban"
              onChange={e => {
                handleChange(e);
                handleFormChange(e);
              }}
            />
          </Form.Group>
          {DevLog(customer)}
          {customer.iban_comment.length > 0 && (
            <Col
              className="d-flex justify-content-center align-items-end"
              lg={12}
            >
              <Alert className="p-1 w-100 m-0 px-2" variant={'primary'}>
                {customer.iban_comment}
              </Alert>
            </Col>
          )}
        </Row>
        <Button type="submit">Submit</Button>
      </Form>
    </div>
  );
};
UpdateRequiredCustomer.propTypes = {
  c: PropTypes.object.isRequired
};

export default UpdateRequiredCustomer;
