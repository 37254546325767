import { create } from 'zustand';

const useCurrencyStore = create(set => ({
  currency: 'AED',
  setCurrencyState: newCurrency => {
    set({ currency: newCurrency });
    // Save to local storage
    localStorage.setItem('currency', newCurrency);
  },
  init: () => {
    // Initialize state from local storage
    const storedCurrency = localStorage.getItem('currency');
    if (storedCurrency) {
      set({ currency: storedCurrency });
    }
  }
}));

export default useCurrencyStore;
