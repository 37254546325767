import DevLog from 'helpers/DevLog';
import { create } from 'zustand';

const usePPK = create(set => ({
  PPK: {}, // Initial value for price_per_unit
  updatePPK: type => {
    set({ PPK: { ...type } });
    DevLog({ type });
  } // Function to update price_per_unit
}));

export default usePPK;
