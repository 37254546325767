/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { BsDatabaseFillUp } from 'react-icons/bs';
import { Form } from 'react-bootstrap';
import SoftBadge from 'components/common/SoftBadge';
import { toast } from 'react-toastify';
import axios from 'axios';
import { BASE_URL } from 'constants/url';
import decryptData from 'secret/decryptData';
import DevLog from 'helpers/DevLog';

const RemoveDepositComponent = ({ id, setData, data, order, minValue }) => {
  const [modalShow, setModalShow] = useState(false);
  const [formData, setFormData] = useState({
    amount: '',
    comment: ''
  });

  const handleInputChange = event => {
    const { name, value } = event.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = async event => {
    event.preventDefault();

    // Validate the form fields
    if (!formData.amount || !formData.comment) {
      alert('Please fill in all mandatory fields.');
      return;
    }
    if (minValue < formData.amount) {
      alert('Withdrawal amount must be less than or equal to deposit amount!');
      return;
    }
    if (formData.amount.length > 12) {
      alert('Ensure that amount may not exceed 12 digits!');
      return;
    }
    if (formData.amount < 0 || formData.amount == 0) {
      alert('Ensure that amount must be greater than 0');
      return;
    }

    // Perform form submission logic here
    await axios
      .post(BASE_URL + `/deposits/${id}/comments/withdrawal/`, formData, {
        headers: {
          Authorization: `JWT ${decryptData('token')}`,
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        DevLog({ response });
        if (response.status == 201) {
          setFormData({
            amount: '',
            comment: ''
          });
          const filteredData = data.filter(d => d.deposit_id !== id);

          setData([
            ...filteredData,
            {
              ...order.original,
              deposit_amount: Number(
                parseFloat(order.original.deposit_amount) -
                  parseFloat(formData.amount)
              ).toFixed(2)
            }
          ]);
          setModalShow(false);
          toast.success('Withdrawal was successful', {
            theme: 'colored',
            position: 'top-right',
            autoClose: true
          });
        }
      })
      .catch(err => {
        DevLog({ err });
      });

    // Reset form fields
  };
  return (
    <>
      <SoftBadge bg="primary" onClick={() => setModalShow(true)}>
        Remove deposit <BsDatabaseFillUp size={20} className="cursor-pointer" />
      </SoftBadge>

      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Remove deposit
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="amount">
              <Form.Label>Remove Deposit</Form.Label>
              <Form.Control
                type="number"
                name="amount"
                placeholder="Enter deposit amount"
                value={formData.amount}
                onChange={handleInputChange}
              />
            </Form.Group>

            <Form.Group controlId="comment">
              <Form.Label>Comment</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="comment"
                placeholder="Enter comment"
                value={formData.comment}
                onChange={handleInputChange}
              />
            </Form.Group>

            <Button variant="primary" className="my-3" type="submit">
              Remove deposit
            </Button>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setModalShow(false)}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default RemoveDepositComponent;
