/* eslint-disable react/react-in-jsx-scope */
import { FaGamepad } from 'react-icons/fa';
import { BiMobileAlt } from 'react-icons/bi';
import { BsFillBoxFill, BsFillLaptopFill, BsSmartwatch } from 'react-icons/bs';
import { useContext } from 'react';
import AppContext from 'context/Context';

import React from 'react';
import { AiFillSetting, AiOutlineCloudUpload } from 'react-icons/ai';
import { useLanguage } from 'provider/LanguageProvider';
import { CustomerProfilePageTranslate } from 'languages/Language';

const WidgetListSupplier = () => {
  const { language } = useLanguage();

  const {
    config: { isDark }
  } = useContext(AppContext);
  const widgetList = {
    products: [
      {
        gradientColors: isDark
          ? ['#111111', '#333333']
          : ['#00a8ff', '#55c2e5'],
        widgetName:
          CustomerProfilePageTranslate.widgets.products.mobile[language],
        widgetLink: '/supplier/e-commerce/product/Mobile',
        color: isDark ? '#00BFFF' : 'white',
        children: <BiMobileAlt color={isDark ? '#00BFFF' : 'white'} size={50} />
      },
      {
        gradientColors: isDark
          ? ['#000000', '#222222']
          : ['#34ace0', '#6bcbef'],
        widgetName:
          CustomerProfilePageTranslate.widgets.products.laptops[language],
        widgetLink: '/supplier/e-commerce/product/Laptop',
        color: isDark ? '#00BFFF' : 'white',
        children: (
          <BsFillLaptopFill color={isDark ? '#00BFFF' : 'white'} size={50} />
        )
      },
      {
        gradientColors: isDark
          ? ['#330033', '#550055']
          : ['#e84393', '#f984b6'],
        widgetName:
          CustomerProfilePageTranslate.widgets.products.gaming[language],
        widgetLink: '/supplier/e-commerce/product/Gaming',
        color: isDark ? '#00BFFF' : 'white',
        children: <FaGamepad color={isDark ? '#00BFFF' : 'white'} size={50} />
      },
      {
        gradientColors: isDark
          ? ['#660000', '#882222']
          : ['#ff6b6b', '#f9a7a7'],
        widgetName:
          CustomerProfilePageTranslate.widgets.products.wearable[language],
        widgetLink: '/supplier/e-commerce/product/Wearable',
        color: isDark ? '#00BFFF' : 'white',
        children: (
          <BsSmartwatch color={isDark ? '#00BFFF' : 'white'} size={50} />
        )
      }
    ],
    orders: [
      {
        gradientColors: isDark
          ? ['#111111', '#333333']
          : ['#00a8ff', '#55c2e5'],
        widgetName:
          CustomerProfilePageTranslate.widgets.products.mobile[language],
        widgetLink: '/supplier/e-commerce/product/upload-new-mobile',
        color: isDark ? '#00BFFF' : 'white',
        children: (
          <>
            {' '}
            <BiMobileAlt color={isDark ? '#00BFFF' : 'white'} size={50} />{' '}
            <AiOutlineCloudUpload
              color={isDark ? '#00BFFF' : 'white'}
              size={25}
            />
          </>
        )
      },
      {
        gradientColors: isDark
          ? ['#000000', '#222222']
          : ['#34ace0', '#6bcbef'],
        widgetName:
          CustomerProfilePageTranslate.widgets.products.laptops[language],
        widgetLink: '/supplier/e-commerce/product/upload-new-laptop',
        color: isDark ? '#00BFFF' : 'white',
        children: (
          <>
            <BsFillLaptopFill color={isDark ? '#00BFFF' : 'white'} size={50} />
            <AiOutlineCloudUpload
              color={isDark ? '#00BFFF' : 'white'}
              size={25}
            />
          </>
        )
      },
      {
        gradientColors: isDark
          ? ['#330033', '#550055']
          : ['#e84393', '#f984b6'],
        widgetName:
          CustomerProfilePageTranslate.widgets.products.gaming[language],
        widgetLink: '/supplier/e-commerce/product/upload-new-gaming-console',
        color: isDark ? '#00BFFF' : 'white',
        children: (
          <>
            <FaGamepad color={isDark ? '#00BFFF' : 'white'} size={50} />{' '}
            <AiOutlineCloudUpload
              color={isDark ? '#00BFFF' : 'white'}
              size={25}
            />
          </>
        )
      },
      {
        gradientColors: isDark
          ? ['#660000', '#882222']
          : ['#ff6b6b', '#f9a7a7'],
        widgetName:
          CustomerProfilePageTranslate.widgets.products.wearable[language],
        widgetLink: '/supplier/e-commerce/product/upload-new-wearable',
        color: isDark ? '#00BFFF' : 'white',
        children: (
          <>
            <BsSmartwatch color={isDark ? '#00BFFF' : 'white'} size={50} />
            <AiOutlineCloudUpload
              color={isDark ? '#00BFFF' : 'white'}
              size={25}
            />
          </>
        )
      }
    ],
    ord: [
      {
        gradientColors: isDark
          ? ['#004D4D', '#006666']
          : ['#A6E3E9', '#7BC6CC'],
        widgetName:
          CustomerProfilePageTranslate.widgets.orders.myOrders[language],
        widgetLink: '/supplier/e-commerce/orders/in-progress',
        color: isDark ? '#00BFFF' : 'white',
        children: (
          <BsFillBoxFill color={isDark ? '#00BFFF' : 'white'} size={50} />
        )
      }
    ],
    // http://localhost:3000/supplier/e-commerce/orders/in-progress
    account: [
      {
        gradientColors: isDark
          ? ['#004D4D', '#006666']
          : ['#A6E3E9', '#7BC6CC'],
        widgetName:
          CustomerProfilePageTranslate.widgets.accountSettings.settings[
            language
          ],
        widgetLink: '/supplier/user/settings',
        color: isDark ? '#00BFFF' : 'white',
        children: (
          <AiFillSetting color={isDark ? '#00BFFF' : 'white'} size={50} />
        )
      }
    ]
    // {
    //   gradientColors: isDark ? ['#663300', '#885500'] : ['#FAD961', '#F76B1C'],
    //   widgetName: 'Customers',
    //   widgetLink: '/admin/users/customers/all',
    //   color: isDark ? '#00BFFF' : 'white',
    //   children: <BsPersonCircle color={isDark ? '#00BFFF' : 'white'} size={screen == 'desktop' ? 50 : 30} />
    // },
    // {
    //   gradientColors: isDark ? ['#004D00', '#006600'] : ['#B4EC51', '#429321'],
    //   widgetName: 'Suppliers',
    //   widgetLink: '/admin/users/suppliers/all',
    //   color: isDark ? '#00BFFF' : 'white',
    //   children: <BsPersonCircle color={isDark ? '#00BFFF' : 'white'} size={screen == 'desktop' ? 50 : 30} />
    // }
  };
  return widgetList;
};

export default WidgetListSupplier;
