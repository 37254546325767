import React, { useState } from 'react';
import { Card, Form } from 'react-bootstrap';
import PerKg from './PerKg';
import PerUnit from './PerUnit';

const AdminCreateNewShippingAddress = () => {
  const [shippingType, setShippingType] = useState('Price per KG');
  const shippingTypeChangeHandler = e => {
    setShippingType(e.target.value);
  };
  return (
    <Card className="p-3 text-700">
      <h3 className="text-center my-2">Shipping option</h3>
      <center className="">
        <Form.Check
          inline
          type="radio"
          id="item2Radio"
          onChange={shippingTypeChangeHandler}
          label="Price per KG"
          value={'Price per KG'}
          defaultChecked
          name="inline-radio"
        />
        <Form.Check
          inline
          type="radio"
          onChange={shippingTypeChangeHandler}
          value={'Price per unit'}
          id="item1Radio"
          label="Price per unit"
          name="inline-radio"
        />
      </center>
      <h3 className="text-center my-2 fs-3">{shippingType}</h3>
      {shippingType == 'Price per unit' && <PerUnit />}
      {shippingType == 'Price per KG' && <PerKg />}
    </Card>
  );
};

export default AdminCreateNewShippingAddress;
