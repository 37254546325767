/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { Divider, List } from 'antd';

import { Card, Col, Row } from 'react-bootstrap';
import SoftBadge from 'components/common/SoftBadge';
import axios from 'axios';
import { BASE_URL } from 'constants/url';

import CommentSkeleton from 'components/common/skeletons/CommentSkeleton';
import decryptData from 'secret/decryptData';
import DevLog from 'helpers/DevLog';

const TransactionHistory = ({ id }) => {
  const [transactions, setTransactions] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  const groupedTransactions = transactions?.reduce((groups, item) => {
    const groupKey = item.action === 'DEPOSIT' ? 'deposits' : 'removals';
    groups[groupKey] = groups[groupKey] || [];
    groups[groupKey].push({
      ...item,
      color: item.action === 'DEPOSIT' ? true : false
    });
    return groups;
  }, {});
  const fetchComments = async () => {
    setLoading(true);
    await axios
      .get(BASE_URL + `/deposits/${id}/comments/`, {
        headers: {
          Authorization: `JWT ${decryptData('token')}`
        }
      })
      .then(res => {
        DevLog(res);
        setTransactions(res.data);
      })
      .catch(err => {
        DevLog(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    fetchComments();
  }, []);

  if (loading) {
    return (
      <>
        <div>
          <Divider>Transaction History</Divider>
          <Row>
            <Col sm={12} lg={6} md={6}>
              <h3>Deposits</h3>
              <CommentSkeleton color={'green'} />
              <CommentSkeleton color={'green'} />
              <CommentSkeleton color={'green'} />
            </Col>
            <Col sm={12} lg={6} md={6}>
              <h3>Removals</h3>
              <CommentSkeleton color={'red'} />
              <CommentSkeleton color={'red'} />
              <CommentSkeleton color={'red'} />
            </Col>
          </Row>
        </div>
      </>
    );
  }
  const renderItem = item => {
    return (
      <Card
        style={{
          borderLeft:
            item.action !== 'DEPOSIT' ? '4px solid red' : '4px solid lightGreen'
        }}
        className="p-3 my-4 mx-0"
      >
        <List.Item>
          <List.Item.Meta
            // avatar={<Avatar icon={<UserOutlined />} />}
            title={
              <b className="text-800">{`${item.action} by ${
                item.email || 'ADMIN'
              }`}</b>
            }
            description={<p className="text-800">{item.comment}</p>}
          />
          <SoftBadge
            bg={item.action !== 'DEPOSIT' ? 'danger' : 'success'}
            className={
              item.action !== 'DEPOSIT' ? 'text-danger' : 'text-success'
            }
          >
            {item.action !== 'DEPOSIT' ? '-' : '+'} AED {item.amount}
          </SoftBadge>
        </List.Item>
      </Card>
    );
  };

  return (
    <div>
      <Divider>Transaction History</Divider>
      <Row>
        <Col sm={12} lg={6} md={6}>
          <h3>Deposits</h3>
          <List
            itemLayout="horizontal"
            dataSource={groupedTransactions.deposits}
            renderItem={renderItem}
          />
        </Col>
        <Col sm={12} lg={6} md={6}>
          <h3>Removals</h3>
          <List
            className="text-800"
            itemLayout="horizontal"
            dataSource={groupedTransactions.removals}
            renderItem={renderItem}
          />
        </Col>
      </Row>
    </div>
  );
};

export default TransactionHistory;
