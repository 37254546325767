import React from 'react';
import Lottie from 'lottie-react';
import review from '../../assets/review.json';
import { Button, Card } from 'react-bootstrap';
import { FiLogOut } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
const UnderReview = () => {
  const navigate = useNavigate();
  return (
    <div className="p-5">
      <Card className="p-4 min-vh-100">
        <h1 className="text-center text-primary fs-6">
          Your account is under review, please wait for further instruction. We
          recommend you to check your email address regularly
        </h1>
        <div className="d-flex justify-content-center">
          <div className="w-50">
            <Lottie
              animationData={review}
              loop={true}
              // style={{ height: '45px', width: '45px', margin: 0 }}
            />
          </div>
        </div>
        <div className="d-flex justify-content-center">
          <Button
            onClick={() => {
              localStorage.clear();
              navigate('/');
            }}
            className="fs-4"
          >
            Log out <FiLogOut size={28} />
          </Button>
        </div>{' '}
      </Card>
    </div>
  );
};

export default UnderReview;
