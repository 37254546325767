import React, { useState } from 'react';
import { Form, Button, Row, Col, Card } from 'react-bootstrap';
import axios from 'axios';
import { BASE_URL } from 'constants/url';
import { topColors } from 'constants/colors';
import { wearableBrands } from 'constants/products';
import { toast } from 'react-toastify';
import { wearableWeightsInKg } from 'constants/wearableWeightsInKg';
import { Link } from 'react-router-dom';
import { Button as AntButton } from 'antd';
import decryptData from 'secret/decryptData';
const CreateWearable = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [wearable, setWearable] = useState({
    brand: '',
    model_name: '',
    model_number: '',
    color: '',
    code: '',
    weight: '',
    price_per_unit: '',
    category: JSON.parse(localStorage.getItem('wearableCategoryId'))
  });

  const handleChange = e => {
    const { name, value } = e.target;
    setWearable(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setIsLoading(true);
    await axios
      .post(`${BASE_URL}/dashboard/product/add/`, wearable, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `JWT ${decryptData('token')}`
        }
      })
      .then(response => {
        if (response.status == 200 || response.status == 201) {
          setWearable({
            brand: '',
            model_name: '',
            model_number: '',
            color: '',
            code: '',
            category: JSON.parse(localStorage.getItem('wearableCategoryId'))
          });
          const form = e.target;
          form.reset();
          return toast.success(
            `New wearable has been successfully created and uploaded to the system`,
            {
              theme: 'colored',
              position: 'top-right',
              autoClose: false
            }
          );
        }
      })
      .catch(error => {
        console.error(error);
        return toast.error(`Something went wrong! Error: ${error.message}`, {
          theme: 'colored',
          position: 'top-right',
          autoClose: false
        });
      })
      .finally(() => {
        setIsLoading(false);
        setWearable({
          brand: '',
          model_name: '',
          model_number: '',
          color: '',
          code: '',
          category: JSON.parse(localStorage.getItem('wearableCategoryId'))
        });
      });
  };

  return (
    <Card className="p-4 text-800">
      <span className="text-900 fs-4 mb-4">Upload wearable</span>
      <div className="d-flex justify-content-end">
        <Link to={'/admin/e-commerce/product/wearables'}>
          <AntButton type="primary">All wearables</AntButton>
        </Link>
      </div>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col md={6}>
            <Form.Group controlId="brand">
              <Form.Label>Brand:</Form.Label>
              <Form.Select
                onChange={handleChange}
                name="brand"
                aria-label="Default select example"
              >
                <option value=""></option>
                {wearableBrands.map((option, i) => (
                  <option key={i} value={option}>
                    {option}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="model_name">
              <Form.Label>Model Name:</Form.Label>
              <Form.Control
                className="mb-2"
                type="text"
                name="model_name"
                value={wearable.model_name}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <Form.Group controlId="model_number">
              <Form.Label>Model Number</Form.Label>
              <Form.Control
                className="mb-2"
                type="text"
                name="model_number"
                value={wearable.model_number}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="color">
              <Form.Label>Color</Form.Label>
              <Form.Select
                onChange={handleChange}
                name="color"
                aria-label="Default select example"
              >
                {topColors.map((option, i) => (
                  <option key={i} value={option}>
                    {option}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Form.Group controlId="code">
              <Form.Label>Code:</Form.Label>
              <Form.Control
                className="mb-2"
                type="text"
                name="code"
                value={wearable.code}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="weight">
              <Form.Label>Weight</Form.Label>
              <Form.Select
                onChange={handleChange}
                name="weight"
                aria-label="Default select example"
              >
                {wearableWeightsInKg.map((option, i) => (
                  <option key={i} value={option}>
                    {option} KG
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Form.Group className="mt-2" controlId="shippingCost">
              <Form.Label>Price per unit</Form.Label>
              <Form.Control
                onChange={handleChange}
                name="price_per_unit"
                type="number"
                required
              />
            </Form.Group>
          </Col>
        </Row>
        <br />

        <Button
          type="submit"
          variant="primary"
          disabled={isLoading}
          // onClick={!isLoading ? handleClick : null}
        >
          {isLoading ? 'Uploading...' : 'Upload a new wearable'}
        </Button>
      </Form>
    </Card>
  );
};

export default CreateWearable;
