// import Divider from 'components/common/Divider';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Alert, Button, Form, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Lottie from 'lottie-react';
import infiniteLoop from 'assets/img/animated-icons/infinite-loop.json';
import { BASE_URL } from 'constants/url';
import { useLanguage } from 'provider/LanguageProvider';
import encryptData from 'secret/encryptData';
import decryptData from 'secret/decryptData';
import useUserStore from 'state/userStore';
import DevLog from 'helpers/DevLog';

const LoginForm = ({ hasLabel }) => {
  const { setUserStatus } = useUserStore();
  const { language } = useLanguage();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  // State
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });

  const { email, password } = formData;
  // Handler
  const handleSubmit = async e => {
    e.preventDefault();
    setIsLoading(true);
    await axios
      .post(BASE_URL + '/auth/jwt/create/', {
        email,
        password
      })
      .then(async response => {
        if (response.status === 200) {
          encryptData('token', response.data.access);
        }
        const access_token = decryptData('token');
        await axios
          .get(BASE_URL + '/auth/users/me/', {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `JWT ${access_token}`
            }
          })
          .then(response => {
            DevLog(response.data);
            try {
              if (response.status === 200) {
                encryptData('data-user', response.data);
                setIsLoading(false);

                toast.success(`Logged in as ${formData.email}`, {
                  theme: 'colored'
                });
                setErrorMessage('');
                const { user_type, user_status } = response.data;
                setUserStatus(user_status);

                localStorage.setItem('user_status', user_status);
                localStorage.setItem('user_type', user_type);

                if (
                  user_status != 'BLANK' &&
                  user_status != 'UPDATE_REQUIRED'
                ) {
                  if (user_type === 'admin') {
                    navigate('/admin/dashboard');
                  } else if (user_type === 'supplier') {
                    navigate('/supplier/user/profile');
                  } else if (user_type === 'customer') {
                    navigate('/customer/user/profile');
                  }
                } else if (
                  user_status == 'BLANK' ||
                  user_status == 'UPDATE_REQUIRED'
                ) {
                  if (user_type === 'supplier') {
                    navigate('/supplier/user/settings');
                  } else if (user_type === 'customer') {
                    navigate('/customer/user/settings');
                  }
                }
              }
            } catch (error) {
              DevLog(error);
            }
          })
          .catch(error => {
            if (error.code === 'ECONNABORTED') {
              DevLog('No internet connection');
            }
          });
      })
      .catch(error => {
        try {
          setErrorMessage(error.response.data['detail']);
          setIsLoading(false);
        } catch (error) {
          setErrorMessage('Please check your internet connection');
          setIsLoading(false);
          DevLog({ outer: error });
        }
        toast.error(`Login failed ${formData.email}`, {
          theme: 'colored'
        });
      });
  };

  const handleFieldChange = e => {
    if (e.target.name == 'email') {
      setFormData({
        ...formData,
        [e.target.name]: String(e.target.value)
      });
    } else {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value
      });
    }
  };
  function getEmailLabel(language) {
    if (language === 'en') {
      return 'Email';
    } else if (language === 'uz') {
      return 'Elektron pochta manzili';
    } else if (language === 'ru') {
      return 'Адрес электронной почты';
    } else {
      // Handle other languages or a default value if needed
      return 'Unknown Language';
    }
  }
  function getPasswordLabel(language) {
    let passwordLabel = '';

    if (language === 'en') {
      passwordLabel = 'Password';
    } else if (language === 'uz') {
      passwordLabel = 'Parol';
    } else if (language === 'ru') {
      passwordLabel = 'Пароль';
    } else {
      // Handle other languages or a default value if needed
      passwordLabel = 'Unknown Language';
    }

    return passwordLabel;
  }
  return (
    <Form onSubmit={handleSubmit}>
      {errorMessage.length > 0 && (
        <Alert className="text-center" variant={'danger'}>
          {errorMessage.toLocaleUpperCase()}
        </Alert>
      )}
      <Form.Group className="mb-3">
        {hasLabel && (
          <Form.Label>
            {' '}
            {language == 'en' && 'Email'}
            {language == 'uz' && 'Elektron pochta manzili'}
            {language == 'ru' && 'Адрес электронной почты'}
          </Form.Label>
        )}
        <Form.Control
          placeholder={getEmailLabel(language)}
          value={formData.email}
          name="email"
          onChange={handleFieldChange}
          type="email"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        {hasLabel && (
          <Form.Label>
            {language == 'en' && 'Password'}
            {language == 'uz' && 'Parol'}
            {language == 'ru' && 'Пароль'}
          </Form.Label>
        )}
        <Form.Control
          placeholder={getPasswordLabel(language)}
          value={formData.password}
          name="password"
          onChange={handleFieldChange}
          type="password"
        />
      </Form.Group>

      <Row className="justify-content-between align-items-center">
        <Link className="fs--1 mb-0 text-center" to={`/forgot-password`}>
          {language == 'en' && 'Forgot Password?'}
          {language == 'uz' && 'Parolni unutdingizmi?'}
          {language == 'ru' && 'Забыли пароль?'}
        </Link>
      </Row>

      <Form.Group>
        <Button
          type="submit"
          variant={isLoading ? 'light' : 'primary'}
          className={`mt-3 w-100 d-flex ${
            !isLoading ? 'px-3 py-2' : 'p-0'
          } justify-content-center align-items-center`}
          disabled={!formData.email || !formData.password}
        >
          {isLoading ? (
            <Lottie
              animationData={infiniteLoop}
              loop={true}
              style={{ height: '45px', width: '45px', margin: 0 }}
            />
          ) : (
            <>
              {language == 'en' && 'Login'}
              {language == 'uz' && 'Kirish'}
              {language == 'ru' && 'Войти'}
            </>
          )}{' '}
        </Button>
      </Form.Group>
    </Form>
  );
};

LoginForm.propTypes = {
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
};

LoginForm.defaultProps = {
  layout: 'simple',
  hasLabel: false
};

export default LoginForm;
