// import { mobile } from 'constants/products';
import axios from 'axios';
import AccountApprovalMessage from 'components/AccountApprovalMessage';
import NoDataToDisplay from 'components/common/NoDataToDisplay';
import TableSkeleton from 'components/common/skeletons/table skleton/TableSkleton';
import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import decryptData from 'secret/decryptData';
import MobileTable from './MobileTable';

import DevLog from 'helpers/DevLog';
import useUserStore from 'state/userStore';

const SupplierUploadMobile = () => {
  const [mobile, setLaptops] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState(200);
  const fetchAllLaptops = async () => {
    setIsLoading(true);
    await axios
      .get(
        'https://tijarie-backend-76c0e453aad0.herokuapp.com/' +
          'api/v1/store/products/',
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `JWT ${decryptData('token')}`
          }
        }
      )
      .then(response => {
        const data = response.data?.filter(data => {
          const category = data.category.category_id;
          return (
            category == JSON.parse(localStorage.getItem('mobileCategoryId'))
          );
        });
        setStatus(response.status);
        setLaptops(data);
      })
      .catch(err => {
        DevLog(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const navigate = useNavigate();
  const { userStatus } = useUserStore();
  useEffect(() => {
    if (
      userStatus == 'BLANK' ||
      userStatus == '' ||
      userStatus == null ||
      userStatus == undefined
    ) {
      navigate('/supplier/user/settings');
    }
  }, [userStatus]);
  useEffect(() => {
    fetchAllLaptops();
  }, []);

  if (status == 403) {
    return (
      <Card className="p-3">
        <AccountApprovalMessage />
      </Card>
    );
  }
  if (isLoading) {
    return (
      <>
        <TableSkeleton lines={10} />;
      </>
    );
  }

  if (mobile.length === 0) {
    return (
      <>
        <h1 className="mb-5">Mobile Catalog</h1>
        <NoDataToDisplay />
      </>
    );
  }
  return (
    <div className="p-3">
      {mobile.length > 0 && <MobileTable mobile={mobile} />}
    </div>
  );
};

export default SupplierUploadMobile;
