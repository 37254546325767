export const mobilePhoneWeightsInKg = [
  '0',
  0.12, // 120 grams
  0.15, // 150 grams
  0.18, // 180 grams
  0.2, // 200 grams
  0.25, // 250 grams
  0.3, // 300 grams
  0.35, // 350 grams
  0.4, // 400 grams
  0.45, // 450 grams
  0.5, // 500 grams
  0.6, // 600 grams
  0.7, // 700 grams
  0.8, // 800 grams
  0.9, // 900 grams
  1.0 // 1000 grams (1 kg)
];
