/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button, Card, Col, Row } from 'react-bootstrap';
import Background from './Background';
import corner4 from 'assets/img/illustrations/corner-4.png';
import createMarkup from 'helpers/createMarkup';
import { AiFillCaretLeft, AiFillFilePdf } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import SoftBadge from './SoftBadge';
import { useLanguage } from 'provider/LanguageProvider';
import decryptData from 'secret/decryptData';
import { BASE_URL_WITHOUT_API } from 'constants/url';

const PageHeader = ({
  title,
  preTitle,
  titleTag: TitleTag,
  description,
  image,
  col,
  children,
  go_back,
  invoice_token,
  ...rest
}) => {
  const { language } = useLanguage();
  return (
    <Card {...rest}>
      <Background
        image={image}
        className="bg-card d-none d-sm-block"
        style={{
          borderTopRightRadius: '0.375rem',
          borderBottomRightRadius: '0.375rem'
        }}
      />
      <Card.Body className="position-relative">
        <Button onClick={go_back} variant="falcon-default" className="mb-5">
          <AiFillCaretLeft /> {language == 'en' && 'Back'}
          {language == 'ru' && 'Назад'}
          {language == 'uz' && 'Orqaga qaytish'}
        </Button>

        <Row>
          <Col {...col}>
            {preTitle && <h6 className="text-600">{preTitle}</h6>}
            <TitleTag className="mb-0">{title}</TitleTag>
            {description && (
              <p
                className={classNames('mt-2', { 'mb-0': !children })}
                dangerouslySetInnerHTML={createMarkup(description)}
              />
            )}
            {children}
          </Col>
          <Col className=" d-flex justify-content-end align-items-end">
            <Link
              target="blank"
              className="d-flex align-items-center cursor-pointer text-decoration-none"
              to={BASE_URL_WITHOUT_API + `/invoice/${invoice_token}/`}
            >
              {decryptData('data-user').user_type == 'customer' && (
                <SoftBadge pill bg="danger">
                  <AiFillFilePdf size={18} />{' '}
                  {language == 'en' && 'Generate invoice'}
                  {language == 'uz' && 'Hisob-fakturani yaratish'}
                  {language == 'ru' && 'Создать счет-фактуру'}
                </SoftBadge>
              )}
            </Link>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  preTitle: PropTypes.string,
  titleTag: PropTypes.string,
  description: PropTypes.string,
  col: PropTypes.shape(Col.propTypes),
  image: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node
};

PageHeader.defaultProps = { col: { lg: 8 }, image: corner4, titleTag: 'h3' };

export default PageHeader;
