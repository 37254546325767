import React, { useEffect, useState } from 'react';
import { Alert, Card, ListGroup, Tab, Tabs } from 'react-bootstrap';

import axios from 'axios';
import ProfileSkeleton from 'components/common/skeletons/Profile/ProfileSkeleton';
import { BASE_URL } from 'constants/url';
import DevLog from 'helpers/DevLog';
import decryptData from 'secret/decryptData';
import ApprovedCustomerForm from '../customer-status/ApprovedCustomerForm';
import BlankCustomerForm from '../customer-status/BlankCustomerForm';
import BlockedCustomerForm from '../customer-status/BlockedCustomerForm';
import PendingCustomerForm from '../customer-status/PendingCustomerForm';
import UpdateRequiredCustomer from '../customer-status/UpdateRequiredCustomer';
import UpdatedCustomerForm from '../customer-status/UpdatedCustomerForm';
import LanguageSelector from 'languages/LanguageSelector';
import CurrencySwitch from 'components/currency/CurrencySwitch';
const CustomerInnerSettings = () => {
  const [user, setUser] = useState({});
  const [status, setStatus] = useState('');
  const [customer, setCustomer] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const fetchUser = async () => {
    setIsLoading(true);
    await axios
      .get(BASE_URL + '/auth/users/me/', {
        headers: {
          Authorization: `JWT ${decryptData('token')}`,
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        setUser(response.data);
        setCustomer(response.data['customer']);
        setStatus(response.data['user_status']);
      })
      .catch(error => DevLog(error))
      .finally(() => {
        setIsLoading(false);
      });
  };
  useEffect(() => {
    fetchUser();
  }, []);

  const update_user_info_notifier = status => {
    if (status === 'PENDING') {
      return (
        <Alert variant={'info'}>
          Your Business details have been sent to our moderators. Please wait
          for further instructions
        </Alert>
      );
    } else if (status === 'verified') {
      return (
        <Alert className="mt-3" variant={'success'}>
          Your account has been updated and verified successfully
        </Alert>
      );
    }
  };

  if (isLoading) {
    return <ProfileSkeleton />;
  }

  return (
    <Card className="">
      <Tabs
        defaultActiveKey="profile"
        id="uncontrolled-tab-example"
        transition={false}
      >
        <Tab eventKey="home" title="Home" className="border-bottom border-x ">
          <Card className="text-700">
            {/* <FalconCardHeader title="Profile Settings" /> */}
            <Card.Body className="bg-light">
              {update_user_info_notifier(status)}
              {status.toLocaleUpperCase() === 'PENDING' && (
                <PendingCustomerForm user={user} />
              )}
              {status.toLocaleUpperCase() === 'BLANK' && <BlankCustomerForm />}
              {status.toLocaleUpperCase() === 'APPROVED' && (
                <ApprovedCustomerForm user={user} />
              )}
              {status.toLocaleUpperCase() === 'UPDATE_REQUIRED' && (
                <UpdateRequiredCustomer c={customer} />
              )}
              {status.toLocaleUpperCase() === 'UPDATED' && (
                <UpdatedCustomerForm />
              )}
              {status.toLocaleUpperCase() === 'BLOCKED' && (
                <BlockedCustomerForm />
              )}
            </Card.Body>
          </Card>
        </Tab>
        <Tab
          eventKey="profile"
          title={<p>User preferences</p>}
          className="border-bottom border-x p-3"
        >
          <ListGroup>
            <ListGroup.Item>
              Language: <LanguageSelector />
            </ListGroup.Item>
            <ListGroup.Item>
              <div className="d-flex">
                {' '}
                <p className="me-3">Currency:</p> <CurrencySwitch />
              </div>
            </ListGroup.Item>
          </ListGroup>
        </Tab>
      </Tabs>
    </Card>
  );
};

export default CustomerInnerSettings;
