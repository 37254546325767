import axios from 'axios';
import React, { useEffect, useState } from 'react';

import { Select } from 'antd';
import SoftBadge from 'components/common/SoftBadge';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import TableSkeleton from 'components/common/skeletons/table skleton/TableSkleton';
import { BASE_URL, BASE_URL_WITHOUT_API } from 'constants/url';
import { Card, Col, Row } from 'react-bootstrap';
import { AiFillEye } from 'react-icons/ai';
import { MdDone } from 'react-icons/md';
import { Link, useNavigate } from 'react-router-dom';
import decryptData from 'secret/decryptData';
import DevLog from 'helpers/DevLog';

const { Option } = Select;
const PendingSuppliers = () => {
  const userOptions = [
    { name: 'All', value: 'all=all' },
    { name: 'Approved', value: 'user_status=APPROVED' },
    { name: 'Pending', value: 'user_status=PENDING' },
    { name: 'Blocked', value: 'user_status=BLOCKED' },
    { name: 'Update required', value: 'user_status=UPDATE_REQUIRED' },
    { name: 'Updated', value: 'user_status=UPDATED' }
  ];
  const [allCustomers, setAllSuppliers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [supplierType, setCustomerType] = useState(userOptions[0].value);

  const columns = [
    {
      accessor: 'isNew',
      Header: 'New',
      Cell: rowData => {
        const { is_new } = rowData.row.original;
        DevLog(rowData.row.original);
        return (
          <center>
            {is_new ? (
              <SoftBadge pill bg="danger">
                NEW
              </SoftBadge>
            ) : (
              <SoftBadge pill>
                Read <MdDone />
              </SoftBadge>
            )}
          </center>
        );
      }
    },
    {
      accessor: 'business_name',
      Header: 'Business Name'
    },
    {
      accessor: 'email',
      Header: 'Email'
    },
    {
      accessor: 'country',
      Header: 'Country'
    },
    {
      accessor: 'contact_person',
      Header: 'Contact Person'
    },
    {
      accessor: 'user_status',
      Header: 'Status'
    },
    {
      accessor: 'bank_name',
      Header: 'Bank Name'
    },
    {
      accessor: 'license',
      Header: 'License',
      Cell: rowData => {
        const { license } = rowData.row.original;
        //vat_certificate

        return license == undefined || license == null || license == '' ? (
          <p>Not uploaded yet</p>
        ) : (
          <Link target="blank" to={BASE_URL_WITHOUT_API + license}>
            View License
          </Link>
        );
      }
    },
    {
      accessor: 'certificate',
      Header: 'Certificate',
      Cell: rowData => {
        const { vat_certificate } = rowData.row.original;
        //vat_certificate

        return vat_certificate == undefined ||
          vat_certificate == null ||
          vat_certificate == '' ? (
          <p>Not uploaded yet</p>
        ) : (
          <Link target="blank" to={BASE_URL_WITHOUT_API + vat_certificate}>
            View Certificate
          </Link>
        );
      }
    },
    {
      accessor: 'action',
      Header: 'Action',
      Cell: rowData => {
        const user = rowData.row.original;
        const { user_status } = user;
        DevLog({ user_status });
        ///admin/customer/customerPageWithComments
        return (
          <Link
            to={
              user_status !== 'UPDATE_REQUIRED'
                ? '/admin/supplier/supplierPage'
                : '/admin/supplier/supplierPageWithComments'
            }
            state={user}
            className="d-flex justify-content-center"
          >
            <SoftBadge
              className="mx-0 cursor-pointer hover-button px-1 py-1 bg-soft-primary text-primary"
              bg="info"
              pill
            >
              <AiFillEye size={22} />
            </SoftBadge>
          </Link>
        );
      }
    }
  ];
  const navigate = useNavigate();
  const fetchCustomers = async () => {
    setIsLoading(true);
    if (decryptData('token') !== null) {
      // ;  ; user_status=UPDATED
      await axios
        .get(BASE_URL + `/dashboard/suppliers/?${supplierType}`, {
          headers: {
            Authorization: `JWT ${decryptData('token')}`
          }
        })
        .then(res => {
          DevLog({ resC: res });
          const data = res.data?.map(e => {
            return { ...e, ...e.supplier };
          });
          setAllSuppliers(
            data.filter(
              supplier =>
                supplier.user_status !== 'BLANK' &&
                supplier.user_status !== 'blank'
            )
          );
        })
        .catch(err => {
          DevLog(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      navigate('/');
    }
  };
  const handleChange = value => {
    // Handle the selected value here
    DevLog(value);
    setCustomerType(value);
  };
  useEffect(() => {
    fetchCustomers();
  }, [supplierType]);

  if (isLoading) {
    return <TableSkeleton />;
  }

  return (
    <Card className="p-4 text-800">
      <AdvanceTableWrapper
        columns={columns}
        data={allCustomers}
        sortable
        pagination
        perPage={5}
      >
        <Row className="flex-end-center mb-3">
          <Col xs="auto" sm={4} lg={4}>
            <h3 className="text-primary fw-light">All suppliers</h3>
          </Col>
          <Col xs="auto" sm={4} lg={4}>
            <Select
              defaultValue={supplierType}
              style={{ width: '100%' }}
              onChange={handleChange}
            >
              {userOptions.map((o, i) => {
                return (
                  <Option key={i} value={o.value}>
                    {o.name}
                  </Option>
                );
              })}
            </Select>
          </Col>
          <Col xs="auto" sm={4} lg={4}>
            <AdvanceTableSearchBox table />
          </Col>
        </Row>
        <AdvanceTable
          table
          headerClassName="bg-200 text-900 text-nowrap align-middle"
          rowClassName="align-middle white-space-nowrap"
          tableProps={{
            bordered: true,
            striped: true,
            className: 'fs--1 mb-0 overflow-hidden'
          }}
        />
        <div className="mt-3">
          <AdvanceTableFooter
            rowCount={allCustomers.length}
            table
            rowInfo
            navButtons
            rowsPerPageSelection
          />
        </div>
      </AdvanceTableWrapper>
    </Card>
  );
};

export default PendingSuppliers;
