import React from 'react';
import NavbarDropdown from '../NavbarDropdown';
import {
  appRoutesCustomerEn,
  appRoutesCustomerRu,
  appRoutesCustomerUz,
  // dashboardRoutesCustomer,
  pagesRoutesCustomer
} from 'routes/siteMapForCustomer';
// import { Dropdown } from 'react-bootstrap';
// import { Link } from 'react-router-dom';
import NavbarDropdownApp from '../NavbarDropdownApp';
import NavbarDropdownPages from '../NavbarDropdownPages';
import { useLanguage } from 'provider/LanguageProvider';
import DevLog from 'helpers/DevLog';
// import AppContext from 'context/Context';

const NavbarTopDropDownMenusCustomer = () => {
  const app_routes = {
    en: appRoutesCustomerEn,
    ru: appRoutesCustomerRu,
    uz: appRoutesCustomerUz
  };
  const { language } = useLanguage();
  DevLog({ navLang: language });
  return (
    <>
      <NavbarDropdown title="dashboard"></NavbarDropdown>

      <NavbarDropdown title="app">
        <NavbarDropdownApp items={app_routes[language].children} />
      </NavbarDropdown>

      <NavbarDropdown title="pages">
        <NavbarDropdownPages items={pagesRoutesCustomer.children} />
      </NavbarDropdown>
    </>
  );
};

export default NavbarTopDropDownMenusCustomer;
