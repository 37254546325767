// LaptopCartContext.js
import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const WearableCartContext = createContext();

export const WearableCartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState([]);

  const addToCart = item => {
    setCartItems(prevItems => [...prevItems, item]);
  };

  const removeFromCart = item => {
    setCartItems(prevItems => prevItems.filter(prevItem => prevItem !== item));
  };

  return (
    <WearableCartContext.Provider
      value={{ cartItems, addToCart, removeFromCart }}
    >
      {children}
    </WearableCartContext.Provider>
  );
};
WearableCartProvider.propTypes = {
  children: PropTypes.node.isRequired
};
