export const laptopWeightsInKg = [
  '',
  1.0, // 1 kg (Ultra-light laptops)
  1.5, // 1.5 kg
  2.0, // 2 kg (Lightweight laptops)
  2.5, // 2.5 kg
  3.0, // 3 kg (Standard laptops)
  3.5, // 3.5 kg
  4.0, // 4 kg (Heavier laptops)
  4.5, // 4.5 kg
  5.0 // 5 kg (Desktop replacement laptops)
];
