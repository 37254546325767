/* eslint-disable no-unused-vars */
import axios from 'axios';
import IconAlert from 'components/common/IconAlert';
import SoftBadge from 'components/common/SoftBadge';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import ColorBox from 'components/common/color/ColorBox';
import GetMobileSkeleton from 'components/common/skeletons/get mobile customer/GetMobileSkeleton';
import TableSkeleton from 'components/common/skeletons/table skleton/TableSkleton';
import { BASE_URL } from 'constants/url';
import { useTimer } from 'context/TimerContext';
import useCurrencyStore from 'currency/useCurrencyStore';
import DevLog from 'helpers/DevLog';
import useScreenSize from 'hooks/useScreenSize';
import {
  CustomerShoppingCartTranslate,
  SearchPlaceHolderTranslations
} from 'languages/Language';
import {
  productQuantityUpdateFailedTranslation,
  productQuantityUpdatedSuccessfullyTranslation
} from 'languages/MessageTranslations';
import { useLanguage } from 'provider/LanguageProvider';
import React, { useEffect, useRef, useState } from 'react';
import {
  Accordion,
  Button,
  Card,
  Col,
  Form,
  FormControl,
  InputGroup,
  ListGroup,
  Row,
  Spinner
} from 'react-bootstrap';
import { BsFillPatchCheckFill, BsFillTrashFill } from 'react-icons/bs';
import { FaCartPlus, FaShoppingCart } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import decryptData from 'secret/decryptData';

const CustomerShoppingCartPage = () => {
  const { currency } = useCurrencyStore();
  const { language } = useLanguage();
  const qtyRefs = {};
  let btns = {};
  const [btnLoading, setBtnLoading] = useState(btns); // Object to store multiple refs
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [balance, setBalance] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);
  const [isReadyToGoToCheckout, setIsReadyToGoToCheckout] = useState(false);
  const screen = useScreenSize();
  const total = data.reduce((acc, item) => {
    if (typeof item.price === 'number') {
      return acc + item.price * item.order_item_quantity;
    }
    return acc;
  }, 0);
  const handleAdd = async (brand, order_item_id) => {
    const qty = qtyRefs[`${brand}_id_${order_item_id}`].current.value;
    setBtnLoading({ ...btnLoading, [brand]: true });
    await axios
      .patch(
        BASE_URL + `/store/cart/item/update/${order_item_id}/`,
        {
          order_item_quantity: qty
        },
        {
          headers: {
            Authorization: `JWT ${decryptData('token')}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then(response => {
        DevLog({ update: response });
      })
      .catch(err => {
        DevLog({ update: err });
      })
      .finally(() => {
        setBtnLoading({
          ...btnLoading,
          [`${brand}_id_${order_item_id}`]: false
        });
        window.location.reload();
      });
  };
  const columns = [
    {
      accessor: 'brand',
      Header: CustomerShoppingCartTranslate().table.brand[language]
    },
    {
      accessor: 'model_name',
      Header: CustomerShoppingCartTranslate().table['Model name'][language]
    },
    {
      accessor: 'ram',
      Header: CustomerShoppingCartTranslate().table.RAM[language]
    },
    {
      accessor: 'storage',
      Header: CustomerShoppingCartTranslate().table.Storage[language]
    },
    {
      accessor: 'color',
      Header: CustomerShoppingCartTranslate().table.Color[language]
    },
    {
      accessor: 'specs',
      Header: CustomerShoppingCartTranslate().table.Specs[language]
    },
    {
      accessor: 'price',
      Header: CustomerShoppingCartTranslate().table.Price[language]
    },
    {
      accessor: 'supplier_product_quantity',
      Header: CustomerShoppingCartTranslate().table.Stock[language]
    },
    {
      accessor: 'order_item_quantity',
      Header: CustomerShoppingCartTranslate().table.Quantity[language],
      Cell: rowData => {
        const { brand, order_item_quantity, order_item_id, status } =
          rowData.row.original;
        const ref = useRef(); // Create a ref for each input field
        qtyRefs[`${brand}_id_${order_item_id}`] = ref;
        btns = { ...btns, [`${brand}_id_${order_item_id}`]: false }; // Store the ref in qtyRefs object
        return (
          <div className="" style={{ minWidth: '200px' }}>
            <Form
              onKeyDown={e => {
                if (e.key == 'Enter') {
                  handleAdd(brand, order_item_id);
                }
              }}
            >
              <InputGroup className="">
                <FormControl
                  ref={ref}
                  disabled={status === 'CONFIRMED'}
                  type="number"
                  className=""
                  defaultValue={order_item_quantity}
                  min={0}
                  onChange={() => {}}
                  aria-describedby="basic-addon2"
                />
                {btnLoading[`${brand}_id_${order_item_id}`] ? (
                  <Button
                    variant="primary"
                    className="d-inline-flex flex-center"
                    disabled
                  >
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    <span className="visually-hidden">Loading...</span>
                  </Button>
                ) : (
                  <Button
                    disabled={status === 'CONFIRMED'}
                    onClick={() => {
                      handleAdd(brand, order_item_id);
                    }}
                    id="button-addon2"
                    className="bg-soft-primary text-primary"
                  >
                    <FaCartPlus />
                  </Button>
                )}
                {/* {' '} */}
              </InputGroup>
            </Form>
          </div>
        );
      }
    },
    {
      accessor: 'remove',
      Header: CustomerShoppingCartTranslate().table.Remove[language],
      Cell: rowData => {
        const { order_item_id, status } = rowData.row.original;
        DevLog({ status });
        if (status !== 'PENDING') {
          return (
            <div className=" d-flex justify-content-center">
              <BsFillPatchCheckFill
                style={{ cursor: 'not-allowed' }}
                size={25}
                className="m-0 p-0"
                color="#0A6EBD"
              />
            </div>
          );
        }
        return (
          <Button
            onClick={async () => {
              setIsDeleting(true);
              setData(
                data.filter(item => item.supplier_product_id !== order_item_id)
              );
              await axios
                .delete(
                  BASE_URL + `/store/cart/item/remove/${order_item_id}/`,
                  {
                    headers: {
                      Authorization: `JWT ${decryptData('token')}`,
                      'Content-Type': 'application/json'
                    }
                  }
                )
                .then(response => {
                  DevLog({ response });
                  setData(data.filter(d => d.order_item_id != order_item_id));
                })
                .catch(err => {
                  toast.error(
                    `Product did not added to your cart
        error: ${err.response.data.message}
        `,
                    {
                      theme: 'colored'
                    }
                  );
                })
                .finally(() => {
                  setIsDeleting(false);
                });
            }}
            variant="danger"
          >
            {isDeleting ? (
              <Spinner className="m-1" animation="grow" size="sm" />
            ) : (
              <BsFillTrashFill />
            )}
          </Button>
        );
      }
    },
    {
      accessor: 'status',
      Header: CustomerShoppingCartTranslate().table.Status[language],
      Cell: rowData => {
        const { status } = rowData.row.original;
        if (status === 'DECLINED') {
          return (
            <SoftBadge bg="danger" className="me-2">
              {status}
            </SoftBadge>
          );
        }

        if (status === 'PENDING') {
          return (
            <SoftBadge bg="warning" className="me-2">
              {status}
            </SoftBadge>
          );
        }
        if (status === 'CONFIRMED') {
          return (
            <SoftBadge bg="success" className="me-2">
              {status}
            </SoftBadge>
          );
        }
      }
    }
  ];
  const fetchMobilePhones = async () => {
    setIsLoading(true);
    await axios
      .get(BASE_URL + '/store/cart/items/', {
        headers: {
          Authorization: `JWT ${decryptData('token')}`,
          'Content-Type': 'application/json'
        }
      })
      .then(async response => {
        // const product = response.data;
        DevLog({ response });

        setData(
          response.data.data.map(p => {
            const { order_item_quantity, order_item_id, status } = p;
            let product = {
              ...p.supplier_product.product,
              order_item_quantity,
              supplier_product_quantity:
                p.supplier_product.supplier_product_quantity,
              price: p.supplier_product.price,
              order_item_id,
              status
            };
            return product;
          })
        );
      })
      .catch(err => DevLog({ err }))
      .finally(() => setIsLoading(false));
  };
  const handleClick = async () => {
    setIsConfirming(true);

    await axios
      .post(
        BASE_URL + '/store/cart/items/confirm/',
        {},
        {
          headers: {
            Authorization: `JWT ${decryptData('token')}`
          }
        }
      )
      .then(response => {
        if (response.status === 200) {
          fetchMobilePhones();
        }
      })
      .catch(err => {
        DevLog(err);
        const msg = err.response?.data?.message;

        toast.error(msg || err.message, {
          autoClose: true
        });
      })
      .finally(() => {
        setIsConfirming(false);
      });
  };
  useEffect(() => {
    fetchMobilePhones();
  }, []);

  if (isLoading) {
    if (screen == 'mobile') {
      return <GetMobileSkeleton />;
    } else {
      return <TableSkeleton />;
    }
  }

  function checkAllConfirmedStatus(array) {
    for (const item of array) {
      // If any item has a status other than "CONFIRMED", return false
      if (item.status !== 'CONFIRMED') {
        return false;
      }
    }

    // If all items have "status": "CONFIRMED", return true
    return true;
  }

  const result = checkAllConfirmedStatus(data);
  DevLog({ result });

  //
  if (screen == 'mobile') {
    return (
      <>
        <h1 className="p-1 mb-2">All wearables</h1>
        <b
          className="p-1 mb-2"
          onClick={() => {
            DevLog('clicked');
          }}
        >
          {CustomerShoppingCartTranslate().other.Total[language]}({data.length}{' '}
          {CustomerShoppingCartTranslate().other.Items[language]})
        </b>{' '}
        |
        <b className="p-1 mb-2">
          Total:{' '}
          {data
            .reduce((acc, item) => {
              DevLog({
                acc,
                price: item.price * item.order_item_quantity
              });
              if (typeof item.price === 'number') {
                return acc + item.price * item.order_item_quantity;
              }
              return acc;
            }, 0)
            .toLocaleString('en-AE', {
              style: 'currency',
              currency: currency,
              currencyDisplay: 'symbol'
            })}
        </b>
        <br />
        {isConfirming && (
          <Spinner
            animation="grow"
            size="sm"
            variant="primary"
            className="mx-2"
          />
        )}
        {!result && (
          <button
            onClick={handleClick}
            className=" d-inline-block btn btn-outline-primary me-3 mt-2"
          >
            Confirm <BsFillPatchCheckFill className="my-0 me-2" />{' '}
          </button>
        )}
        {!isReadyToGoToCheckout && data.length > 0 ? (
          <Link
            to="/customer/e-commerce/checkout"
            state={{
              total: data,
              total_items: data.length,
              total_price: data.reduce((acc, item) => {
                if (typeof item.price === 'number') {
                  return acc + item.price * item.order_item_quantity;
                }
                return acc;
              }, 0),
              total_sum: data
                .reduce((acc, item) => {
                  if (typeof item.price === 'number') {
                    return acc + item.price;
                  }
                  return acc;
                }, 0)
                .toLocaleString('en-AE', {
                  style: 'currency',
                  currency: currency,
                  currencyDisplay: 'symbol' // Include currency symbol
                })
            }}
            className="btn btn-outline-primary mt-2"
          >
            Checkout <FaShoppingCart className="my-0 mx-2" />
          </Link>
        ) : (
          <></>
        )}
        <div className="p-2 three-d-box-shadow">
          <Accordion defaultActiveKey="0" flush>
            {data.map((e, i) => {
              const {
                order_item_id,
                brand,
                model_name,
                color,
                ram,
                storage,
                price,
                supplier_product_quantity,
                order_item_quantity,
                supplier_product_id,
                status
              } = e;
              DevLog({ e });

              let changedQuantity;

              const addToStore = async () => {
                await axios
                  .patch(
                    BASE_URL + `/store/cart/item/update/${order_item_id}/`,
                    {
                      order_item_quantity: changedQuantity
                    },
                    {
                      headers: {
                        Authorization: `JWT ${decryptData('token')}`
                      }
                    }
                  )
                  .then(async response => {
                    DevLog({ response });
                    if (response.status == 201) {
                      document.getElementById(
                        model_name + supplier_product_id
                      ).value = 0;
                      toast.success(
                        productQuantityUpdatedSuccessfullyTranslation[language],
                        {
                          theme: 'colored'
                        }
                      );
                      response.status == 201 &&
                        setData(
                          data.filter(
                            product =>
                              product.supplier_product_id !=
                              response.data.supplier_product
                          )
                        );
                    }
                  })
                  .catch(err => {
                    DevLog({ err });
                    toast.error(
                      productQuantityUpdateFailedTranslation[language],
                      {
                        theme: 'colored'
                      }
                    );
                  });
              };
              return (
                <Accordion.Item className="mb-2" key={i} eventKey={i}>
                  <Accordion.Header className="outline-none">
                    <div className="d-flex w-100 justify-content-between">
                      <div className="">
                        {brand} {model_name} {color} {ram} {storage}{' '}
                      </div>
                      <div className="">
                        {status === 'DECLINED' && (
                          <SoftBadge bg="danger" className="me-2">
                            {status}
                          </SoftBadge>
                        )}
                        {status === 'PENDING' && (
                          <SoftBadge bg="warning" className="me-2">
                            {status}
                          </SoftBadge>
                        )}
                        {status === 'CONFIRMED' && (
                          <SoftBadge bg="success" className="me-2">
                            {status}
                          </SoftBadge>
                        )}
                      </div>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body className="py-2 px-1 ">
                    <ListGroup className="">
                      <ListGroup.Item className="">
                        {brand} {model_name}
                      </ListGroup.Item>
                      <ListGroup.Item className="">
                        Color: {color} <ColorBox color={color} />
                      </ListGroup.Item>
                      <ListGroup.Item className="">
                        Price ({currency}): {price}
                      </ListGroup.Item>
                      <ListGroup.Item className="">
                        Stock: {supplier_product_quantity}
                      </ListGroup.Item>
                      <ListGroup.Item className="">
                        Quantity chosen: {order_item_quantity}
                      </ListGroup.Item>

                      <ListGroup.Item className="">
                        <div className="d-flex justify-content-between w-100">
                          <div style={{ width: '85%' }}>
                            <Form
                              onKeyDown={e => {
                                if (e.key === 'Enter') {
                                  addToStore();
                                }
                              }}
                            >
                              <InputGroup className="text-700">
                                <FormControl
                                  id={model_name + supplier_product_id}
                                  type="number"
                                  className="text-700"
                                  min={0}
                                  onChange={e => {
                                    changedQuantity = e.target.value;
                                  }}
                                  aria-describedby="basic-addon2"
                                />
                                <Button
                                  onClick={addToStore}
                                  id="button-addon2"
                                  className="bg-soft-primary text-primary"
                                >
                                  <FaCartPlus />
                                </Button>
                                {/* {' '} */}
                              </InputGroup>
                            </Form>
                          </div>
                          <Button
                            onClick={async () => {
                              setIsDeleting(true);
                              setData(
                                data.filter(
                                  item =>
                                    item.supplier_product_id !== order_item_id
                                )
                              );
                              await axios
                                .delete(
                                  BASE_URL +
                                    `/store/cart/item/remove/${order_item_id}/`,
                                  {
                                    headers: {
                                      Authorization: `JWT ${
                                        JSON.parse(
                                          localStorage.getItem('token')
                                        ).access
                                      }`,
                                      'Content-Type': 'application/json'
                                    }
                                  }
                                )
                                .then(response => {
                                  DevLog({ response });
                                  setData(
                                    data.filter(
                                      d => d.order_item_id != order_item_id
                                    )
                                  );
                                })
                                .catch(err => {
                                  toast.error(
                                    `Product did not added to your cart
        error: ${err.response.data.message}
        `,
                                    {
                                      theme: 'colored'
                                    }
                                  );
                                })
                                .finally(() => {
                                  setIsDeleting(false);
                                });
                            }}
                            variant="danger"
                          >
                            {isDeleting ? (
                              <Spinner
                                className="m-1"
                                animation="grow"
                                size="sm"
                              />
                            ) : (
                              <BsFillTrashFill />
                            )}
                          </Button>
                        </div>
                      </ListGroup.Item>
                    </ListGroup>
                  </Accordion.Body>
                </Accordion.Item>
              );
            })}
          </Accordion>
        </div>
      </>
    );
  }

  return (
    <>
      <Card className="p-3">
        <Row>
          <Col
            sm={12}
            lg={2}
            className="d-flex justify-content-center align-items-center"
          >
            <h4
              onClick={() => {
                DevLog('clicked');
              }}
            >
              {CustomerShoppingCartTranslate().other.Total[language]}(
              {data.length}{' '}
              {CustomerShoppingCartTranslate().other.Items[language]})
            </h4>
          </Col>
          <Col className="">
            {Number(localStorage.getItem('balance')) <=
              Number(total) * 0.02 && (
              <IconAlert className={'px-2 py-1'} variant={'danger'}>
                In your deposit ({currency} {localStorage.getItem('balance')}),
                you DO NOT have sufficient balance for this transaction
              </IconAlert>
            )}
          </Col>
          <Col
            sm={12}
            lg={3}
            className="d-flex justify-content-end  align-items-center"
          >
            <h4 className="text-right my-0 mx-2">
              {CustomerShoppingCartTranslate().other.Total[language]}:{' '}
              {total.toLocaleString('en-AE', {
                style: 'currency',
                currency: currency,
                currencyDisplay: 'symbol' // Include currency symbol
              })}
            </h4>
            {isConfirming && (
              <Spinner
                animation="grow"
                size="sm"
                variant="primary"
                className="mx-2"
              />
            )}
            {!result && (
              <button
                onClick={handleClick}
                className=" d-inline-block btn btn-outline-primary me-3 "
              >
                Confirm <BsFillPatchCheckFill className="my-0 me-2" />{' '}
              </button>
            )}
            {result && data.length > 0 ? (
              <Link
                to="/customer/e-commerce/checkout"
                state={{
                  total: data,
                  total_items: data.length,
                  total_price: data.reduce((acc, item) => {
                    if (typeof item.price === 'number') {
                      return acc + item.price * item.order_item_quantity;
                    }
                    return acc;
                  }, 0),
                  total_sum: data
                    .reduce((acc, item) => {
                      if (typeof item.price === 'number') {
                        return acc + item.price;
                      }
                      return acc;
                    }, 0)
                    .toLocaleString('en-AE', {
                      style: 'currency',
                      currency: currency,
                      currencyDisplay: 'symbol' // Include currency symbol
                    })
                }}
                className="d-flex btn btn-primary justify-content-center align-items-center"
              >
                {CustomerShoppingCartTranslate().other.CheckOutButton[language]}{' '}
                <FaShoppingCart className="my-0 mx-2" />
              </Link>
            ) : (
              <></>
            )}
          </Col>
        </Row>
        <br />
        {/* <Divider>Orders</Divider> */}
        <AdvanceTableWrapper
          columns={columns}
          data={data}
          sortable
          pagination
          perPage={15}
        >
          <Row className="flex-end-center mb-3">
            <Col xs="auto" sm={6} lg={4}>
              <AdvanceTableSearchBox
                placeholder={SearchPlaceHolderTranslations[language]}
                table
              />
            </Col>
          </Row>
          <AdvanceTable
            table
            headerClassName="bg-200 text-900 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              bordered: true,
              striped: true,
              className: 'fs--1 mb-0 overflow-hidden'
            }}
          />
          <div className="mt-3">
            {/* <AdvanceTableFooter
              rowCount={data.length}
              table
              rowInfo
              navButtons
              rowsPerPageSelection
            /> */}
          </div>
        </AdvanceTableWrapper>
      </Card>
    </>
  );
};

export default CustomerShoppingCartPage;
