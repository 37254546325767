import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Alert } from 'react-bootstrap';

function BrowserRecommendationMessage() {
  return (
    <Container className="mt-1">
      <Alert variant="warning">
        <h4>Please do not use Microsoft Edge or lesser-known browsers.</h4>
        <p>
          For the best experience, we recommend using one of the following
          browsers:
        </p>
        <ul>
          <li>Google Chrome</li>
          <li>Apple Safari</li>
          <li>Brave</li>
        </ul>
      </Alert>
    </Container>
  );
}

export default BrowserRecommendationMessage;
