// import { laptops } from 'constants/products';
import axios from 'axios';
import NoDataToDisplay from 'components/common/NoDataToDisplay';
import TableSkeleton from 'components/common/skeletons/table skleton/TableSkleton';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import decryptData from 'secret/decryptData';
import LaptopTable from './LaptopTable';

import DevLog from 'helpers/DevLog';
import useUserStore from 'state/userStore';

const SupplierUploadLaptop = () => {
  const [laptops, setLaptops] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const fetchAllLaptops = async () => {
    setisLoading(true);
    await axios
      .get(
        'https://tijarie-backend-76c0e453aad0.herokuapp.com/' +
          'api/v1/store/products/',
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `JWT ${decryptData('token')}`
          }
        }
      )
      .then(response => {
        const data = response.data?.filter(data => {
          const category = data.category.category_id;
          return (
            category === JSON.parse(localStorage.getItem('laptopCategoryId'))
          );
        });
        setLaptops(data);

        DevLog(data);
      })
      .catch(err => {
        DevLog(err);
      })
      .finally(() => {
        setisLoading(false);
      });
  };
  const navigate = useNavigate();
  const { userStatus } = useUserStore();
  useEffect(() => {
    if (
      userStatus == 'BLANK' ||
      userStatus == '' ||
      userStatus == null ||
      userStatus == undefined
    ) {
      navigate('/supplier/user/settings');
    }
  }, [userStatus]);
  useEffect(() => {
    fetchAllLaptops();
  }, []);

  if (isLoading) {
    return <TableSkeleton lines={10} />;
  }

  if (laptops.length === 0) {
    return (
      <>
        <h1>Laptop Catalog</h1>
        <br />
        <NoDataToDisplay />
      </>
    );
  }
  return (
    <div className="p-3">
      {laptops.length > 0 && <LaptopTable laptops={laptops} />}
    </div>
  );
};

export default SupplierUploadLaptop;
