import React, { useContext, useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';

import axios from 'axios';
import Divider from 'components/common/Divider';
import { BASE_URL } from 'constants/url';
import ShippingContext from '../ShippingContext';

import DevLog from 'helpers/DevLog';
import {
  detailsAddFailedTranslation,
  detailsAddedSuccessfullyTranslation,
  orderCreatedSuccessfullyTranslation,
  orderCreationFailedTranslation
} from 'languages/MessageTranslations';
import { useLanguage } from 'provider/LanguageProvider';
import { FaPaperPlane } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import decryptData from 'secret/decryptData';
import usePriceType from './StorePPU';
import usePPK from './StorePPK';

const OtherRegionsForm = () => {
  const { updatePricePerUnit } = usePriceType();
  const { updatePPK } = usePPK();
  const navigate = useNavigate();
  const [addressLine1, setAddressLine1] = useState('');
  const [addressLine2, setAddressLine2] = useState('');
  const [city, setCity] = useState('');
  const [country, setCountry] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [contactNumber, setContactNumber] = useState('');
  const [contactName, setContactName] = useState('');
  const [shippingOptionsGlobal, setShippingOptionsGlobal] = useState([]);
  const { setData } = useContext(ShippingContext);
  const [shipping_detail_id, setShipping_detail_id] = useState('');
  const { language } = useLanguage();
  const handleSubmit = async e => {
    e.preventDefault();

    // Perform form validation here
    if (
      !addressLine1 ||
      !city ||
      !country ||
      !postalCode ||
      !contactNumber ||
      !contactName
    ) {
      alert('Please fill in all required fields.');
      return;
    }

    // Prepare the data object to be sent in the API request
    const data = {
      address_line_1: addressLine1,
      address_line_2: addressLine2,
      city,
      country,
      postal_code: postalCode,
      contact_number: contactNumber,
      contact_name: contactName,
      shipping_type: 'GLOBAL',
      shipping_detail: shipping_detail_id
    };

    try {
      DevLog(data);
      await axios
        .post(BASE_URL + '/store/order/shipping-address/add/', data, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `JWT ${decryptData('token')}`
          }
        })
        .then(async response => {
          DevLog(response);
          toast.success(detailsAddedSuccessfullyTranslation[language], {
            theme: 'colored',
            position: 'top-right'
          });
          if (response.status == 201) {
            await axios
              .patch(
                BASE_URL + '/store/order/update/',
                {
                  order_status: 'AWAITING_PAYMENT'
                },
                {
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: `JWT ${decryptData('token')}`
                  }
                }
              )
              .then(res => {
                DevLog({ res });
                if (res.status == 204) {
                  navigate('/customer/user/profile');
                  toast.success(orderCreatedSuccessfullyTranslation[language], {
                    theme: 'colored',
                    position: 'top-right'
                  });
                }
              })
              .catch(err => {
                DevLog(err);
                toast.error(
                  orderCreationFailedTranslation[language] +
                    `\nError: ${e.message}`,
                  {
                    theme: 'colored',
                    position: 'top-right'
                  }
                );
              });
          }
        })
        .catch(e => {
          DevLog(e);
          toast.error(
            detailsAddFailedTranslation[language] +
              `\nError: ${e.message}
        `,
            {
              theme: 'colored',
              position: 'top-right'
            }
          );
        })
        .finally(() => {});
    } catch (error) {
      console.error(error); // Handle any errors that occurred during the request
    }
  };

  const fetchShippingOptions = async () => {
    await axios
      .get(BASE_URL + '/dashboard/shipping-details/info/', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `JWT ${decryptData('token')}`
        }
      })
      .then(r => {
        DevLog({ shippingOptionsGlobal: r });
        const shippingOptionsGlobal = r.data.filter(o => {
          return o.region == 'OUTSIDE_UAE';
        });
        setShippingOptionsGlobal(shippingOptionsGlobal);
      })
      .catch(error => {
        DevLog({ ShippingOptionGlobal: error });
      });
  };

  useEffect(() => {
    fetchShippingOptions();
  }, []);
  return (
    <Card className="p-3 text-700">
      <Divider>
        {language == 'en' && 'Global shipping address'}
        {language == 'uz' && 'Global yetkazib berish manzili'}
        {language == 'ru' && 'Глобальный адрес доставки'}
      </Divider>

      <Form onSubmit={handleSubmit}>
        <Row>
          <Col lg={12}>
            <Form.Group className="mb-3" controlId="city">
              <Form.Label>
                {language == 'en' && 'Country'}
                {language == 'uz' && 'Mamlakat'}
                {language == 'ru' && 'Страна'}
              </Form.Label>

              <Form.Select
                onChange={e => {
                  DevLog({
                    value: JSON.parse(e.target.value)
                  });

                  const {
                    range_0_100,
                    range_101_500,
                    range_501_1000,
                    range_1001_more
                  } = JSON.parse(e.target.value);
                  updatePPK({
                    range_0_100: Number(range_0_100),
                    range_101_500: Number(range_101_500),
                    range_501_1000: Number(range_501_1000),
                    range_1001_more: Number(range_1001_more)
                  });

                  updatePricePerUnit(JSON.parse(e.target.value).price_per_unit);
                  setCountry(JSON.parse(e.target.value).name);
                  setShipping_detail_id(
                    JSON.parse(e.target.value).shipping_detail_id
                  );
                  setData({
                    name: '',
                    price: JSON.parse(e.target.value).documentation_fee,
                    vat: JSON.parse(e.target.value).vat_percentage
                  });
                }}
                required
                aria-label="Default select example"
              >
                <option
                  value={JSON.stringify({
                    name: 'Nothing is selected',
                    documentation_fee: 0,
                    vat_percentage: 0
                  })}
                >
                  Please select option
                </option>
                {shippingOptionsGlobal?.map((so, idx) => {
                  return (
                    <option key={idx} value={JSON.stringify(so)}>
                      {so.name}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>
          </Col>

          <Col lg={12}>
            <Form.Group className="mb-3" controlId="city">
              <Form.Label>
                {language == 'en' && 'City'}
                {language == 'uz' && 'Shahar'}
                {language == 'ru' && 'Город'}{' '}
                <span className="required">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                value={city}
                onChange={e => setCity(e.target.value)}
                required
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col lg={6}>
            <Form.Group className="mb-3" controlId="addressLine1">
              <Form.Label>
                {language == 'en' && 'Address Line 1'}
                {language == 'uz' && 'Manzil 1'}
                {language == 'ru' && 'Адресная линия 1'}{' '}
                <span className="required">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                value={addressLine1}
                onChange={e => setAddressLine1(e.target.value)}
                required
              />
            </Form.Group>
          </Col>
          <Col lg={6}>
            <Form.Group className="mb-3" controlId="addressLine2">
              <Form.Label>
                {' '}
                {language == 'en' && 'Address Line 2'}
                {language == 'uz' && 'Manzil 2'}
                {language == 'ru' && 'Адресная линия 2'}{' '}
              </Form.Label>
              <Form.Control
                type="text"
                value={addressLine2}
                onChange={e => setAddressLine2(e.target.value)}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <Form.Group className="mb-3" controlId="postalCode">
              <Form.Label>
                {language == 'en' && 'Postal Code'}
                {language == 'uz' && 'Pochta kodi'}
                {language == 'ru' && 'Почтовый индекс'}{' '}
                <span className="required">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                value={postalCode}
                onChange={e => setPostalCode(e.target.value)}
                required
              />
            </Form.Group>
          </Col>
        </Row>

        <br />
        <Divider>
          <b>
            {' '}
            {language == 'en' && 'Recipient'}
            {language == 'uz' && 'Qabul qiluvchi'}
            {language == 'ru' && 'Получатель'}{' '}
          </b>
        </Divider>
        <br />
        <Row>
          <Col lg={6}>
            <Form.Group className="mb-3" controlId="contactNumber">
              <Form.Label>
                {language == 'en' && 'Contact number'}
                {language == 'uz' && 'Aloqa raqami'}
                {language == 'ru' && 'Контактный номер'}{' '}
                <span className="required">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                value={contactNumber}
                onChange={e => setContactNumber(e.target.value)}
                required
              />
            </Form.Group>
          </Col>
          <Col lg={6}>
            <Form.Group className="mb-3" controlId="contactName">
              <Form.Label>
                {language == 'en' && 'Recipient'}
                {language == 'uz' && 'Qabul qiluvchi'}
                {language == 'ru' && 'Получатель'}{' '}
                <span className="required">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                value={contactName}
                onChange={e => setContactName(e.target.value)}
                required
              />
            </Form.Group>
          </Col>
        </Row>

        <Button type="submit" className="w-100" icon={'send'}>
          {language == 'en' && 'Complete order'}
          {language == 'uz' && 'Buyurtma berish'}
          {language == 'ru' && 'Завершить заказ'} <FaPaperPlane />{' '}
        </Button>

        <style>{`
          .required {
            color: red;
          }
        `}</style>
      </Form>
    </Card>
  );
};

export default OtherRegionsForm;
