import React, { useEffect, useState } from 'react';
import { Form, Button, Card } from 'react-bootstrap';
import axios from 'axios';
import Divider from 'components/common/Divider';
import { BASE_URL } from 'constants/url';
import { toast } from 'react-toastify';

import { useNavigate } from 'react-router-dom';
import { FaPaperPlane } from 'react-icons/fa';
import { useLanguage } from 'provider/LanguageProvider';
import {
  detailsAddFailedTranslation,
  detailsAddedSuccessfullyTranslation,
  orderCreatedSuccessfullyTranslation,
  orderCreationFailedTranslation
} from 'languages/MessageTranslations';
import decryptData from 'secret/decryptData';
import DevLog from 'helpers/DevLog';

const ShippingAddressForm = () => {
  const { language } = useLanguage();
  const navigate = useNavigate();
  const [addressLine1, setAddressLine1] = useState('');
  const [contactNumber, setContactNumber] = useState('');
  const [contactName, setContactName] = useState('');
  const [shippingOptionsPickup, setShippingOptionsPickup] = useState([]);
  const [shipping_detail_id, setShipping_detail_id] = useState('');
  const handleSubmit = async e => {
    e.preventDefault();

    // Perform form validation here
    if (!addressLine1 || !contactNumber || !contactName) {
      alert('Please fill in all required fields.');
      return;
    }

    // Prepare the data object to be sent in the API request
    const data = {
      address_line_1: addressLine1,
      contact_number: contactNumber,
      contact_name: contactName,
      shipping_type: 'FREE_ZONE',
      shipping_detail: shipping_detail_id
    };

    try {
      // Make the API request using Axios
      await axios
        .post(BASE_URL + '/store/order/shipping-address/add/', data, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `JWT ${decryptData('token')}`
          }
        })
        .then(async response => {
          toast.success(detailsAddedSuccessfullyTranslation[language], {
            theme: 'colored',
            position: 'top-right'
          });
          if (response.status == 201) {
            await axios
              .patch(
                BASE_URL + '/store/order/update/',
                {
                  order_status: 'AWAITING_PAYMENT'
                },
                {
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: `JWT ${decryptData('token')}`
                  }
                }
              )
              .then(res => {
                if (res.status == 204) {
                  navigate('/customer/user/profile');
                  toast.success(orderCreatedSuccessfullyTranslation[language], {
                    theme: 'colored',
                    position: 'top-right'
                  });
                }
              })
              .catch(e => {
                DevLog(e);
                toast.error(orderCreationFailedTranslation[language], {
                  theme: 'colored',
                  position: 'top-right'
                });
              });
          }
        })
        .catch(e => {
          DevLog(e);
          toast.error(detailsAddFailedTranslation[language], {
            theme: 'colored',
            position: 'top-right'
          });
        })
        .finally(() => {});
    } catch (error) {
      DevLog(error);
    }
  };

  const fetchShippingOptions = async () => {
    await axios
      .get(BASE_URL + `/dashboard/shipping-details/info/`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `JWT ${decryptData('token')}`
        }
      })
      .then(r => {
        DevLog({ shippingOptionsPickup: r });
        const free_zone = r.data.filter(r => r.region == 'FREE_ZONE');
        setShippingOptionsPickup(free_zone);
      })
      .catch(e => {
        DevLog({ shippingOptionError: e });
      });
  };
  useEffect(() => {
    fetchShippingOptions();
  }, []);

  return (
    <Card className="p-3 text-700">
      <Divider>Free Zone shipping address</Divider>
      <Form.Group className="mb-3" controlId="city">
        <Form.Label>
          {' '}
          {language == 'en' && 'Options'}
          {language == 'uz' && 'Variantlar'}
          {language == 'ru' && 'Опции'}{' '}
        </Form.Label>
        <Form.Select
          onChange={e => {
            DevLog({
              value: JSON.parse(e.target.value)
            });
            setShipping_detail_id(
              JSON.parse(e.target.value).shipping_detail_id
            );
          }}
          required
          aria-label="Default select example"
        >
          <option
            value={JSON.stringify({
              name: 'Nothing is selected',
              documentation_fee: 0,
              vat_percentage: 0
            })}
          >
            Please select option
          </option>
          {shippingOptionsPickup?.map((so, idx) => {
            return (
              <option key={idx} value={JSON.stringify(so)}>
                {so.name}
              </option>
            );
          })}
        </Form.Select>
      </Form.Group>
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="addressLine1">
          <Form.Label>
            {language == 'en' && 'Address'}
            {language == 'uz' && 'Manzil'}
            {language == 'ru' && 'Адрес'} <span className="required">*</span>
          </Form.Label>
          <Form.Control
            type="text"
            value={addressLine1}
            onChange={e => setAddressLine1(e.target.value)}
            required
          />
        </Form.Group>

        <br />
        <Divider>
          <b>
            {' '}
            {language == 'en' && 'Recipient'}
            {language == 'uz' && 'Qabul qiluvchi'}
            {language == 'ru' && 'Получатель'}{' '}
          </b>
        </Divider>
        <br />
        <Form.Group className="mb-3" controlId="contactNumber">
          <Form.Label>
            {language == 'en' && 'Contact number'}
            {language == 'uz' && 'Aloqa raqami'}
            {language == 'ru' && 'Контактный номер'}{' '}
            <span className="required">*</span>
          </Form.Label>
          <Form.Control
            type="text"
            value={contactNumber}
            onChange={e => setContactNumber(e.target.value)}
            required
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="contactName">
          <Form.Label>
            {language == 'en' && 'Recipient'}
            {language == 'uz' && 'Qabul qiluvchi'}
            {language == 'ru' && 'Получатель'}{' '}
            <span className="required">*</span>
          </Form.Label>
          <Form.Control
            type="text"
            value={contactName}
            onChange={e => setContactName(e.target.value)}
            required
          />
        </Form.Group>

        <Button type="submit" className="w-100" icon={'send'}>
          {language == 'en' && 'Complete order'}
          {language == 'uz' && 'Buyurtma berish'}
          {language == 'ru' && 'Завершить заказ'} <FaPaperPlane />{' '}
        </Button>

        <style>{`
          .required {
            color: red;
          }
        `}</style>
      </Form>
    </Card>
  );
};

export default ShippingAddressForm;
