import axios from 'axios';
import React, { useEffect, useState } from 'react';

import { Card, Col, Row } from 'react-bootstrap';
import SoftBadge from 'components/common/SoftBadge';
import { Link, useNavigate } from 'react-router-dom';
import { BASE_URL, BASE_URL_WITHOUT_API } from 'constants/url';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import TableSkeleton from 'components/common/skeletons/table skleton/TableSkleton';
import { AiFillEye } from 'react-icons/ai';
import decryptData from 'secret/decryptData';
import DevLog from 'helpers/DevLog';

const PendingCustomers = () => {
  const [pendingCustomers, setPendingCustomers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const columns = [
    {
      accessor: 'business_name',
      Header: 'Business Name'
    },
    {
      accessor: 'country',
      Header: 'Country'
    },
    {
      accessor: 'contact_person',
      Header: 'Contact Person'
    },
    {
      accessor: 'bank_name',
      Header: 'Bank Name'
    },
    {
      accessor: 'license',
      Header: 'License',
      Cell: rowData => {
        const { license } = rowData.row.original;
        return license == undefined || license == null || license == '' ? (
          <p>Not uploaded yet</p>
        ) : (
          <Link target="blank" to={BASE_URL_WITHOUT_API + license}>
            View License
          </Link>
        );
      }
    },
    {
      accessor: 'certificate',
      Header: 'Certificate',
      Cell: rowData => {
        const { vat_certificate } = rowData.row.original;
        return vat_certificate == undefined ||
          vat_certificate == null ||
          vat_certificate == '' ? (
          <p>Not uploaded yet</p>
        ) : (
          <Link target="blank" to={BASE_URL_WITHOUT_API + vat_certificate}>
            View Certificate
          </Link>
        );
      }
    },
    {
      accessor: 'action',
      Header: 'Action',
      Cell: rowData => {
        const user = rowData.row.original;
        return (
          <Link
            state={user}
            to={'/admin/customers/customersPage'}
            className="d-flex justify-content-center"
          >
            <SoftBadge
              className="mx-0 cursor-pointer hover-button px-1 py-1 bg-soft-primary text-primary"
              bg="info"
              pill
            >
              <AiFillEye size={22} />
            </SoftBadge>
          </Link>
        );
      }
    }
  ];
  const navigate = useNavigate();
  const fetchPendingCustomers = async () => {
    setIsLoading(true);
    if (decryptData('token') !== null) {
      await axios
        .get(BASE_URL + '/dashboard/customers/?user_status=PENDING', {
          headers: {
            Authorization: `JWT ${decryptData('token')}`
          }
        })
        .then(res => {
          DevLog({ resC: res });
          const data = res.data?.map(e => {
            return { ...e, ...e.customer };
          });
          setPendingCustomers(data);
        })
        .catch(err => {
          DevLog(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      navigate('/');
    }
  };

  useEffect(() => {
    fetchPendingCustomers();
  }, []);

  if (isLoading) {
    return <TableSkeleton />;
  }

  return (
    <Card className="p-4 text-800">
      <AdvanceTableWrapper
        columns={columns}
        data={pendingCustomers}
        sortable
        pagination
        perPage={5}
      >
        <Row className="flex-end-center mb-3">
          <Col xs="auto" sm={6} lg={6}>
            <h3 className="text-primary fw-light">List of pending customers</h3>
          </Col>
          <Col xs="auto" sm={6} lg={6}>
            <AdvanceTableSearchBox table />
          </Col>
        </Row>
        <AdvanceTable
          table
          headerClassName="bg-200 text-900 text-nowrap align-middle"
          rowClassName="align-middle white-space-nowrap"
          tableProps={{
            bordered: true,
            striped: true,
            className: 'fs--1 mb-0 overflow-hidden'
          }}
        />
        <div className="mt-3">
          <AdvanceTableFooter
            rowCount={pendingCustomers.length}
            table
            rowInfo
            navButtons
            rowsPerPageSelection
          />
        </div>
      </AdvanceTableWrapper>
    </Card>
    // <Card className="p-3">
    //   <h3 className="mx-3 text-800">Pending Customers</h3>

    //   <Row>
    //     <Col lg={6}>
    //       <input
    //         type="text"
    //         placeholder="Search..."
    //         value={searchQuery}
    //         className="my-2 mx-3 form-control"
    //         onChange={handleSearchQueryChange}
    //       />
    //     </Col>
    //   </Row>

    //   {pendingCustomers.length > 0 && filteredCustomers.length > 0 ? (
    //     <SimpleBarReact>
    //       <Table>
    //         <thead>
    //           <tr>
    //             <th scope="col">
    //               <p className="text-800">Business Name</p>
    //             </th>
    //             <th scope="col">
    //               <p className="text-800">Country</p>
    //             </th>
    //             <th scope="col">
    //               <p className="text-800">Contact Name</p>
    //             </th>
    //             <th scope="col">
    //               <p className="text-800">Bank Name</p>
    //             </th>
    //             <th scope="col">
    //               <p className="text-800">Status</p>
    //             </th>
    //             <th scope="col">
    //               <p className="text-800">Get more</p>
    //             </th>
    //           </tr>
    //         </thead>
    //         <tbody>
    //           {filteredCustomers.map((user, index) => (
    //             <tr key={index}>
    //               <td>
    //                 <p className="text-800">{user.customer.business_name}</p>
    //               </td>
    //               <td>
    //                 <p className="text-800">{user.customer.country}</p>
    //               </td>
    //               <td>
    //                 <p className="text-800">{user.customer.contact_person}</p>
    //               </td>
    //               <td>
    //                 <p className="text-800">{user.customer.bank_name}</p>
    //               </td>
    //               <td>
    //                 <SoftBadge pill bg="warning" className="me-2">
    //                   Pending
    //                 </SoftBadge>{' '}
    //               </td>
    //               <td className="">

    //               </td>
    //             </tr>
    //           ))}
    //         </tbody>
    //       </Table>
    //     </SimpleBarReact>
    //   ) : (
    //     <NothingFound />
    //   )}
    // </Card>
  );
};

export default PendingCustomers;
