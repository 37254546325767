import UserInfoCard from 'components/common/user info card/UserInfoCard';
import WidgetCard from 'components/common/widget/menu item card/WidgetCard';
import MarketShare from 'components/dashboards/default/MarketShare';
import WeeklySales from 'components/dashboards/default/WeeklySales';
import TotalOrder from 'components/dashboards/e-commerce admin/TotalOrder';
import WidgetListCustomer from 'constants/WidgetListCustomer';
import { weeklySalesData } from 'data/dashboard/default';
import { marketShare, totalOrder } from 'data/dashboard/ecom';
import useScreenSize from 'hooks/useScreenSize';
import { CustomerProfilePageTranslate } from 'languages/Language';
import { useLanguage } from 'provider/LanguageProvider';
import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Spinner } from 'react-bootstrap';

import axios from 'axios';
import { BASE_URL } from 'constants/url';
import DevLog from 'helpers/DevLog';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import decryptData from 'secret/decryptData';
import CustomerNotification from './CustomerNotification';
import useCurrencyStore from 'currency/useCurrencyStore';

const Profile = () => {
  const { language } = useLanguage();
  const screen = useScreenSize();
  const { currency } = useCurrencyStore();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [data, setData] = useState([]);
  const fetchPenalties = async () => {
    setIsLoading(true);
    await axios
      .get(BASE_URL + '/customer/deposit/notification/', {
        headers: {
          Authorization: `JWT ${decryptData('token')}`
        }
      })
      .then(r => {
        DevLog({ r });
        setData(r.data);
        setNotifications(r.data?.filter(d => d.is_new === true));
      })
      .catch(err => {
        DevLog({ err });
        return toast.error(err.message + ' ' + 'Failed to load notifications', {
          autoClose: true
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  useEffect(() => {
    fetchPenalties();
    const userStatus = localStorage.getItem('user_status');
    if (
      userStatus == 'PENDING' ||
      userStatus == '' ||
      userStatus == undefined ||
      userStatus == null
    ) {
      // localStorage.clear();
      navigate('/under-review');
    } else if (userStatus == 'BLANK') {
      navigate('/customer/user/settings');
    }
  }, []);
  return (
    <div>
      {isLoading && (
        <center className="my-3">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </center>
      )}
      {notifications?.map((n, i) => (
        <CustomerNotification
          key={i}
          user_type={'customer'}
          notifications={n}
          whole={data}
        />
      ))}
      <Row className="g-3 mb-3">
        <Col className="position-relative" md={3} xxl={3}>
          <WeeklySales
            width={screen == 'desktop' ? '250px' : '130px'}
            data={weeklySalesData}
          />
        </Col>
        <Col md={3} xxl={3}>
          <TotalOrder data={totalOrder} />
        </Col>
        <Col md={3} xxl={3}>
          <MarketShare data={marketShare} radius={['100%', '87%']} />
        </Col>
        <Col md={3} xxl={3}>
          <UserInfoCard
            currency={currency}
            email={decryptData('data-user').email}
            user_type={decryptData('data-user').user_type}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <Card className="p-3 ">
            <h4
              className={`m-0 text-primary ${
                screen == 'desktop' ? 'fs-3' : ''
              }`}
            >
              {CustomerProfilePageTranslate.products[language]}
            </h4>
            <Row className="">
              {WidgetListCustomer().products.map((widget, index) => {
                return (
                  <Col key={index} lg={2} sm={6}>
                    <WidgetCard
                      gradientColors={widget.gradientColors}
                      color={widget.color}
                      widgetName={widget.widgetName}
                      widgetLink={widget.widgetLink}
                      // Add any additional props you need
                    >
                      {widget.children}
                    </WidgetCard>
                  </Col>
                );
              })}
            </Row>
          </Card>
        </Col>
        <Col className="mt-3" lg={12}>
          <Card className="p-3 ">
            <h4
              className={`m-0 text-primary ${
                screen == 'desktop' ? 'fs-3' : ''
              }`}
            >
              {CustomerProfilePageTranslate.orders[language]}
            </h4>
            <Row className="">
              {WidgetListCustomer().orders.map((widget, index) => {
                return (
                  <Col key={index} lg={2} sm={6}>
                    <WidgetCard
                      gradientColors={widget.gradientColors}
                      color={widget.color}
                      widgetName={widget.widgetName}
                      widgetLink={widget.widgetLink}
                      // Add any additional props you need
                    >
                      {widget.children}
                    </WidgetCard>
                  </Col>
                );
              })}
            </Row>
          </Card>
        </Col>
        <Col className="mt-3 ">
          <Card className="p-3 ">
            <h4
              className={`m-0 text-primary ${
                screen == 'desktop' ? 'fs-3' : ''
              }`}
            >
              {CustomerProfilePageTranslate.settings[language]}
            </h4>
            <Row className="">
              {WidgetListCustomer().account.map((widget, index) => {
                return (
                  <Col key={index} lg={2} sm={6}>
                    <WidgetCard
                      gradientColors={widget.gradientColors}
                      color={widget.color}
                      widgetName={widget.widgetName}
                      widgetLink={widget.widgetLink}
                      // Add any additional props you need
                    >
                      {widget.children}
                    </WidgetCard>
                  </Col>
                );
              })}
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Profile;
