/* eslint-disable react/prop-types */
import React from 'react';

const BlurCoverage = ({ children }) => {
  return (
    <div className="position-relative h-md-100 ">
      <div className="blur-component rounded">
        <div className="d-flex h-100 justify-content-center align-items-center">
          <p className="m-0 fs-2">10 days left</p>
        </div>
      </div>
      {children}
    </div>
  );
};

export default BlurCoverage;
