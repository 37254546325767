/* eslint-disable react/prop-types */
import React from 'react';
import { List } from 'antd';
import './ChatBubble.css'; // Import your CSS file

const ChatBubble = ({ text, isOwnMessage }) => {
  return (
    <List.Item
      className={`chat-bubble ${
        isOwnMessage
          ? 'own-message bg-facebook   '
          : 'other-message bg-facebook   '
      }`}
    >
      <List.Item.Meta
        description={
          <h6
            className="text-white
"
          >
            Admin: {text}
          </h6>
        }
      />
      <div
        className={`bubble-tail ${isOwnMessage ? 'own-tail' : 'other-tail'}`}
      />
    </List.Item>
  );
};

export default ChatBubble;
