import React from 'react';
import './loader.css';
import { Card, Spinner } from 'react-bootstrap';

const Loader = () => {
  return (
    <Card
      style={{ minHeight: '100vh' }}
      className="d-flex  justify-content-center align-items-center"
    >
      <Spinner size="200px" animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </Card>
  );
};

export default Loader;
