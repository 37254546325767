import axios from 'axios';
import { BASE_URL } from 'constants/url';
import { helperScrollToTop } from 'helpers/scrollToTop';
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Alert, Button, Col, Form, Row } from 'react-bootstrap';
import { countryOptions } from 'constants/country/country';
import decryptData from 'secret/decryptData';
import DevLog from 'helpers/DevLog';
const UpdateRequiredSupplierForm = ({ sup }) => {
  const [supplier, setSupplier] = useState(sup);
  const [formData, setFormData] = useState({});
  const handleSubmit = e => {
    e.preventDefault();
    updateCommentsInSuppliersInfo();
  };
  // const updateCommentsInSuppliersInfo = async () => {
  //   await axios
  //     .patch(
  //       BASE_URL + `/supplier/info/update/${supplier.supplier_id}/ `,
  //       supplier,
  //       {
  //         headers: {
  //           'Content-Type': 'application/json',
  //           Authorization: `JWT ${
  //             decryptData('token')
  //           }`
  //         }
  //       }
  //     )
  //     .then(async r => {
  //       DevLog({ r });
  //       if (r.status === 200) {
  //         await axios
  //           .patch(
  //             BASE_URL + '/auth/users/me/',
  //             {
  //               user_status: 'UPDATED'
  //             },
  //             {
  //               headers: {
  //                 'Content-Type': 'application/json',
  //                 Authorization: `JWT ${
  //                   decryptData('token')
  //                 }`
  //               }
  //             }
  //           )
  //           .then(a => {
  //             DevLog({ a });
  //           })
  //           .catch(err => {
  //             DevLog(err);

  //             helperScrollToTop();
  //           });
  //       }
  //     })
  //     .catch(err => {
  //       DevLog({ outer: err });
  //     });
  // };
  const handleChange = e => {
    setSupplier({ ...supplier, [e.target.name]: e.target.value });
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const updateCommentsInSuppliersInfo = async () => {
    await axios
      .patch(
        BASE_URL + `/supplier/info/update/${supplier.supplier_id}/ `,
        { ...formData },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `JWT ${decryptData('token')}`
          }
        }
      )
      .then(async r => {
        DevLog({ manR: r });

        axios
          .patch(
            BASE_URL + '/auth/users/me/',
            {
              user_status: 'UPDATED'
            },
            {
              headers: {
                'Content-Type': 'application/json',
                Authorization: `JWT ${decryptData('token')}`
              }
            }
          )
          .then(r => {
            DevLog({ status_user: r });
            if (r.status === 200) {
              setSupplier(r.data.supplier);
              // window.location.reload();
            }
          })
          .catch(err => {
            DevLog(err);
            helperScrollToTop();
          });
      })
      .catch(async err => {
        DevLog(err);
        await axios
          .get(BASE_URL + '/auth/users/me/', {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `JWT ${decryptData('token')}`
            }
          })
          .then(r => {
            localStorage.removeItem('userData');
            localStorage.setItem('userData', JSON.stringify(r.data));
            setSupplier(localStorage.getItem('userData'));
            DevLog({
              test: decryptData('data-user')
            });
          })
          .catch(err => {
            DevLog({ err });
          });
      });
  };

  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <Row className="mb-3 g-3">
          <Form.Group as={Col} lg={12} controlId="business_name">
            <Form.Label> Business name </Form.Label>
            <Form.Control
              type="text"
              placeholder="Business Name"
              value={supplier.business_name}
              name="business_name"
              disabled={supplier.business_name_comment.length === 0}
              onChange={e => {
                handleChange(e);
              }}
            />
          </Form.Group>
          {supplier.business_name_comment.length > 0 && (
            <Col
              className="d-flex justify-content-center align-items-end"
              lg={12}
            >
              <Alert className="p-1 w-100 m-0 px-2" variant={'primary'}>
                {supplier.business_name_comment}
              </Alert>
            </Col>
          )}
        </Row>
        <hr />
        <Row>
          <Form.Group
            as={Col}
            lg={supplier.country_comment.length === 0 ? 12 : 6}
            controlId="country"
          >
            <Form.Label>Country</Form.Label>
            <select
              className="form-select"
              name="country"
              disabled={supplier.country_comment.length === 0}
              onChange={e => {
                handleChange(e);
              }}
            >
              <option value={supplier.country}>{supplier.country} </option>
              {countryOptions.map((country, i) => (
                <option value={country} key={i}>
                  {country}
                </option>
              ))}
            </select>
          </Form.Group>

          {supplier.country_comment.length > 0 && (
            <Col
              className="d-flex justify-content-center align-items-end"
              lg={12}
            >
              <Alert className="p-1 w-100 m-0 px-2" variant={'primary'}>
                {supplier.country_comment}
              </Alert>
            </Col>
          )}
        </Row>
        <hr />
        <Row className="mb-3 g-3">
          <Form.Group
            as={Col}
            lg={supplier.business_address_comment.length === 0 ? 12 : 6}
            controlId="business_address"
          >
            <Form.Label>Business address</Form.Label>
            <Form.Control
              type="text"
              placeholder="Business address"
              value={supplier.business_address}
              disabled={supplier.business_address_comment.length === 0}
              name="business_address"
              onChange={e => {
                handleChange(e);
              }}
            />
          </Form.Group>
          {supplier.business_address_comment.length > 0 && (
            <Col
              className="d-flex justify-content-center align-items-end"
              lg={12}
            >
              <Alert className="p-1 w-100 m-0 px-2" variant={'primary'}>
                {supplier.business_address_comment}
              </Alert>
            </Col>
          )}
        </Row>
        <hr />
        <Row className="mb-3 g-3">
          <Form.Group
            as={Col}
            lg={supplier.contact_person_comment.length === 0 ? 12 : 6}
            controlId="contact_person"
          >
            <Form.Label>Contact person</Form.Label>
            <Form.Control
              type="text"
              placeholder="Contact person"
              value={supplier.contact_person}
              disabled={supplier.contact_person_comment.length === 0}
              name="contact_person"
              onChange={e => {
                handleChange(e);
              }}
            />
          </Form.Group>
          {supplier.contact_person_comment.length > 0 && (
            <Col
              className="d-flex justify-content-center align-items-end"
              lg={12}
            >
              <Alert className="p-1 w-100 m-0 px-2" variant={'primary'}>
                {supplier.contact_person_comment}
              </Alert>
            </Col>
          )}
        </Row>
        <hr />
        <Row className="mb-3 g-3">
          <Form.Group
            as={Col}
            lg={supplier.phone_number_comment.length === 0 ? 12 : 6}
            controlId="phone_number"
          >
            <Form.Label>Phone number</Form.Label>
            <Form.Control
              type="text"
              placeholder="Phone number"
              value={supplier.phone_number}
              disabled={supplier.phone_number_comment.length === 0}
              name="phone_number"
              onChange={e => {
                handleChange(e);
              }}
            />
          </Form.Group>
          {supplier.phone_number_comment.length > 0 && (
            <Col
              className="d-flex justify-content-center align-items-end"
              lg={12}
            >
              <Alert className="p-1 w-100 m-0 px-2" variant={'primary'}>
                {supplier.contact_person_comment}
              </Alert>
            </Col>
          )}
        </Row>
        <hr />
        <Row className="mb-3 g-3">
          <Form.Group
            as={Col}
            lg={supplier.bank_name_comment.length === 0 ? 12 : 6}
            controlId="bank_name"
          >
            <Form.Label>Bank name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Bank name"
              value={supplier.bank_name}
              disabled={supplier.bank_name_comment.length === 0}
              name="bank_name"
              onChange={e => {
                handleChange(e);
              }}
            />
          </Form.Group>
          {supplier.bank_name_comment.length > 0 && (
            <Col
              className="d-flex justify-content-center align-items-end"
              lg={12}
            >
              <Alert className="p-1 w-100 m-0 px-2" variant={'primary'}>
                {supplier.bank_name_comment}
              </Alert>
            </Col>
          )}
        </Row>
        <hr />
        <Row className="mb-3 g-3">
          <Form.Group
            as={Col}
            lg={supplier.bank_address_comment.length === 0 ? 12 : 6}
            controlId="bank_address"
          >
            <Form.Label>Bank address</Form.Label>
            <Form.Control
              type="text"
              placeholder="Bank address"
              value={supplier.bank_address}
              disabled={supplier.bank_address_comment.length === 0}
              name="bank_address"
              onChange={e => {
                handleChange(e);
              }}
            />
          </Form.Group>
          {supplier.bank_address_comment.length > 0 && (
            <Col
              className="d-flex justify-content-center align-items-end"
              lg={12}
            >
              <Alert className="p-1 w-100 m-0 px-2" variant={'primary'}>
                {supplier.bank_address_comment}
              </Alert>
            </Col>
          )}
        </Row>
        <hr />
        <Row className="mb-3 g-3">
          <Form.Group
            as={Col}
            lg={supplier.account_number_comment.length === 0 ? 12 : 6}
            controlId="account_number"
          >
            <Form.Label>Account number</Form.Label>
            <Form.Control
              type="text"
              placeholder="Account number"
              value={supplier.account_number}
              disabled={supplier.account_number_comment.length === 0}
              name="account_number"
              onChange={e => {
                handleChange(e);
              }}
            />
          </Form.Group>
          {supplier.account_number_comment.length > 0 && (
            <Col
              className="d-flex justify-content-center align-items-end"
              lg={12}
            >
              <Alert className="p-1 w-100 m-0 px-2" variant={'primary'}>
                {supplier.account_number_comment}
              </Alert>
            </Col>
          )}
        </Row>
        <hr />
        <Row className="mb-3 g-3">
          <Form.Group
            as={Col}
            lg={supplier.vat_number_comment.length === 0 ? 12 : 6}
            controlId="vat_number"
          >
            <Form.Label>VAT number</Form.Label>
            <Form.Control
              type="text"
              placeholder="VAT number"
              value={supplier.vat_number}
              disabled={supplier.vat_number_comment.length === 0}
              name="vat_number"
              onChange={e => {
                handleChange(e);
              }}
            />
          </Form.Group>
          {supplier.vat_number_comment.length > 0 && (
            <Col
              className="d-flex justify-content-center align-items-end"
              lg={12}
            >
              <Alert className="p-1 w-100 m-0 px-2" variant={'primary'}>
                {supplier.vat_number_comment}
              </Alert>
            </Col>
          )}
        </Row>
        <hr />
        <Row className="mb-3 g-3">
          <Form.Group
            as={Col}
            lg={supplier.swift_code_comment.length === 0 ? 12 : 6}
            controlId="swift_code"
          >
            <Form.Label>SWIFT code</Form.Label>
            <Form.Control
              type="text"
              maxLength={15}
              placeholder="SWIFT code"
              value={supplier.swift_code}
              disabled={supplier.swift_code_comment.length === 0}
              name="swift_code"
              onChange={e => {
                handleChange(e);
              }}
            />
          </Form.Group>
          {supplier.swift_code_comment.length > 0 && (
            <Col
              className="d-flex justify-content-center align-items-end"
              lg={12}
            >
              <Alert className="p-1 w-100 m-0 px-2" variant={'primary'}>
                {supplier.swift_code_comment}
              </Alert>
            </Col>
          )}
        </Row>
        <hr />
        <Row className="mb-3 g-3">
          <Form.Group
            as={Col}
            lg={supplier.iban_comment.length === 0 ? 12 : 6}
            controlId="iban"
          >
            <Form.Label>IBAN</Form.Label>
            <Form.Control
              type="text"
              placeholder="IBAN code"
              value={supplier.iban}
              disabled={supplier.iban_comment.length === 0}
              name="iban"
              onChange={e => {
                handleChange(e);
              }}
            />
          </Form.Group>
          {supplier.iban_comment.length > 0 && (
            <Col
              className="d-flex justify-content-center align-items-end"
              lg={12}
            >
              <Alert className="p-1 w-100 m-0 px-2" variant={'primary'}>
                {supplier.iban_comment}
              </Alert>
            </Col>
          )}
        </Row>
        <Button type="submit">Submit</Button>
      </Form>
    </div>
  );
};
UpdateRequiredSupplierForm.propTypes = {
  sup: PropTypes.object.isRequired
};

export default UpdateRequiredSupplierForm;
