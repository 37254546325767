import axios from 'axios';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import ColorBox from 'components/common/color/ColorBox';
import GetMobileSkeleton from 'components/common/skeletons/get mobile customer/GetMobileSkeleton';
import TableSkeleton from 'components/common/skeletons/table skleton/TableSkleton';
import { BASE_URL } from 'constants/url';
import useScreenSize from 'hooks/useScreenSize';
import React, { useEffect, useRef, useState } from 'react';
import {
  Accordion,
  Button,
  Card,
  Col,
  Form,
  FormControl,
  InputGroup,
  ListGroup,
  Row,
  Spinner
} from 'react-bootstrap';
import { FaCartPlus } from 'react-icons/fa';
import { toast } from 'react-toastify';
import {
  CustomerPurchaseWearablesTranslate,
  SearchPlaceHolderTranslations
} from 'languages/Language';
import { useLanguage } from 'provider/LanguageProvider';
import {
  productAddToCartFailedTranslation,
  productAddedToCartSuccessfullyTranslation
} from 'languages/MessageTranslations';
import AccountApprovalMessage from 'components/AccountApprovalMessage';
import decryptData from 'secret/decryptData';
import DevLog from 'helpers/DevLog';
import useCurrencyStore from 'currency/useCurrencyStore';
const GetWearables = () => {
  const qtyRefs = {};
  let btns = {};
  const [btnLoading, setBtnLoading] = useState(btns); // Object to store multiple refs
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState('');
  const screen = useScreenSize();
  const { currency } = useCurrencyStore();
  const { language } = useLanguage();
  const handleAdd = async (supplier_product_id, id) => {
    const qty = qtyRefs[supplier_product_id].current.value;
    setBtnLoading({ ...btnLoading, [supplier_product_id]: true });
    await axios
      .post(
        BASE_URL + '/store/cart/add/',
        {
          order_item_quantity: qty,
          supplier_product: id
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `JWT ${decryptData('token')}`
          }
        }
      )
      .then(async response => {
        response.status == 201 &&
          toast.success(productAddedToCartSuccessfullyTranslation[language], {
            theme: 'colored'
          });
        response.status == 201 &&
          setData(
            data.filter(
              product =>
                product.supplier_product_id != response.data.supplier_product
            )
          );
      })
      .catch(err => {
        DevLog(err);
        toast.error(productAddToCartFailedTranslation[language], {
          theme: 'colored'
        });
      })
      .finally(() => {
        setBtnLoading({ ...btnLoading, [supplier_product_id]: false });
      });
  };
  const columns = [
    {
      accessor: 'brand',
      Header: CustomerPurchaseWearablesTranslate().table.brand[language]
    },
    {
      accessor: 'model_name',
      Header: CustomerPurchaseWearablesTranslate().table['Model name'][language]
    },

    {
      accessor: 'color',
      Header: CustomerPurchaseWearablesTranslate().table.Color[language]
    },
    {
      accessor: 'price',
      Header: CustomerPurchaseWearablesTranslate().table.Price[language]
    },
    {
      accessor: 'supplier_product_quantity',
      Header: CustomerPurchaseWearablesTranslate().table.Stock[language]
    },
    {
      accessor: 'myquantity',
      Header: CustomerPurchaseWearablesTranslate().table.Quantity[language],
      Cell: rowData => {
        const { supplier_product_id, supplier_product_quantity } =
          rowData.row.original;
        const ref = useRef(); // Create a ref for each input field
        qtyRefs[supplier_product_id] = ref;
        btns = { ...btns, [supplier_product_id]: false }; // Store the ref in qtyRefs object
        return (
          <div className="" style={{ minWidth: '200px' }}>
            <Form
              onKeyDown={e => {
                if (e.key == 'Enter') {
                  handleAdd(supplier_product_id, supplier_product_id);
                }
              }}
            >
              <InputGroup className="">
                <FormControl
                  ref={ref}
                  max={supplier_product_quantity}
                  type="number"
                  className=""
                  min={0}
                  onChange={() => {}}
                  aria-describedby="basic-addon2"
                />
                {btnLoading[supplier_product_id] ? (
                  <Button
                    variant="primary"
                    className="d-inline-flex flex-center"
                    disabled
                  >
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    <span className="visually-hidden">Loading...</span>
                  </Button>
                ) : (
                  <Button
                    onClick={() => {
                      handleAdd(supplier_product_id, supplier_product_id);
                    }}
                    id="button-addon2"
                    className="bg-soft-primary text-primary"
                  >
                    <FaCartPlus />
                  </Button>
                )}
                {/* {' '} */}
              </InputGroup>
            </Form>
          </div>
        );
      }
    }
  ];
  const fetchMobilePhones = async () => {
    setIsLoading(true);

    await axios
      .get(
        BASE_URL +
          '/store/shop/products/?product__category__category_name=Wearable',
        {
          headers: {
            Authorization: `JWT ${decryptData('token')}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then(response => {
        // const product = response.data;
        setStatus(response.status);
        setData(
          response.data
            .map(p => {
              const { price, supplier_product_quantity, supplier_product_id } =
                p;
              let product = {
                ...p.product,
                price,
                supplier_product_quantity,
                supplier_product_id
              };
              return product;
            })
            .filter(e => e.supplier_product_quantity !== 0)
        );
      })
      .catch(err => {
        setStatus(err.response.status);
      })
      .finally(() => setIsLoading(false));
  };
  useEffect(() => {
    fetchMobilePhones();
  }, []);

  if (status == 403) {
    return <AccountApprovalMessage />;
  }

  if (isLoading) {
    if (screen == 'mobile') {
      return <GetMobileSkeleton />;
    } else {
      return <TableSkeleton />;
    }
  }
  //
  if (screen == 'mobile') {
    return (
      <>
        <h1 className="p-1 mb-2">All wearables</h1>
        <div className="p-1">
          <Accordion defaultActiveKey="0" flush>
            {data.map((e, i) => {
              // DevLog(e);
              const {
                supplier_product_id,
                brand,
                model_name,
                color,
                ram,
                storage,
                price,
                supplier_product_quantity
              } = e;
              let order_item_quantity;

              const addToStore = async () => {
                await axios
                  .post(
                    BASE_URL + '/store/cart/add/',
                    {
                      order_item_quantity: order_item_quantity,
                      supplier_product: supplier_product_id
                    },
                    {
                      headers: {
                        Authorization: `JWT ${decryptData('token')}`
                      }
                    }
                  )
                  .then(async response => {
                    DevLog({
                      aaa:
                        document.getElementById(
                          model_name + supplier_product_id
                        ) || 'noo'
                    });
                    if (response.status == 201) {
                      document.getElementById(
                        model_name + supplier_product_id
                      ).value = 0;
                      toast.success(
                        productAddedToCartSuccessfullyTranslation[language],
                        {
                          theme: 'colored'
                        }
                      );
                      response.status == 201 &&
                        setData(
                          data.filter(
                            product =>
                              product.supplier_product_id !=
                              response.data.supplier_product
                          )
                        );
                    }
                  })
                  .catch(err => {
                    DevLog(err);
                    toast.error(productAddToCartFailedTranslation[language], {
                      theme: 'colored'
                    });
                  });
              };
              return (
                <Accordion.Item className="mb-2" key={i} eventKey={i}>
                  <Accordion.Header>
                    {brand} {model_name} {color} {ram} {storage}
                  </Accordion.Header>
                  <Accordion.Body className="py-2 px-1 ">
                    <ListGroup className="">
                      <ListGroup.Item className="">
                        {brand} {model_name}
                      </ListGroup.Item>
                      <ListGroup.Item className="">
                        Color: {color} <ColorBox color={color} />
                      </ListGroup.Item>
                      <ListGroup.Item className="">
                        Price ({currency}): {price}
                      </ListGroup.Item>
                      <ListGroup.Item className="">
                        Stock: {supplier_product_quantity}
                      </ListGroup.Item>
                      <ListGroup.Item className="">
                        <Form
                          onKeyDown={e => {
                            if (e.key === 'Enter') {
                              addToStore();
                            }
                          }}
                        >
                          <InputGroup className="text-700">
                            <FormControl
                              id={model_name + supplier_product_id}
                              type="number"
                              className="text-700"
                              min={0}
                              onChange={e => {
                                order_item_quantity = e.target.value;
                              }}
                              aria-describedby="basic-addon2"
                            />
                            <Button
                              onClick={addToStore}
                              id="button-addon2"
                              className="bg-soft-primary text-primary"
                            >
                              <FaCartPlus />
                            </Button>
                            {/* {' '} */}
                          </InputGroup>
                        </Form>
                      </ListGroup.Item>
                    </ListGroup>
                  </Accordion.Body>
                </Accordion.Item>
              );
            })}
          </Accordion>
        </div>
      </>
    );
  }

  return (
    <Card className="p-3">
      <AdvanceTableWrapper
        columns={columns}
        data={data}
        sortable
        pagination
        perPage={15}
      >
        <Row className="flex-end-center mb-3">
          <Col xs="auto" sm={6} lg={4}>
            <AdvanceTableSearchBox
              placeholder={SearchPlaceHolderTranslations[language]}
              table
            />
          </Col>
        </Row>
        <AdvanceTable
          table
          headerClassName="bg-200 text-900 text-nowrap align-middle"
          rowClassName="align-middle white-space-nowrap"
          tableProps={{
            bordered: true,
            striped: true,
            className: 'fs--1 mb-0 overflow-hidden'
          }}
        />
        <div className="mt-3">
          {/* <AdvanceTableFooter
            rowCount={data.length}
            table
            rowInfo
            navButtons
            rowsPerPageSelection
          /> */}
        </div>
      </AdvanceTableWrapper>
    </Card>
  );
};

export default GetWearables;
