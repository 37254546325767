import CryptoJS from 'crypto-js';
import { SECRET_KEY } from 'constants/secret_key';
const encryptData = (name, data) => {
  const encrypted = CryptoJS.AES.encrypt(
    JSON.stringify(data),
    SECRET_KEY
  ).toString();
  localStorage.setItem(name, encrypted);
};

export default encryptData;
