import axios from 'axios';
import classNames from 'classnames';
import Footer from 'components/footer/Footer';
import NavbarTop from 'components/navbar/top/NavbarTop';
import NavbarVerticalCustomer from 'components/navbar/vertical/customer/NavbarVerticalCustomer';
import { BASE_URL } from 'constants/url';
import AppContext from 'context/Context';
import DevLog from 'helpers/DevLog';
import useScreenSize from 'hooks/useScreenSize';
import React, { useContext, useEffect } from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { AiFillHome, AiTwotonePhone } from 'react-icons/ai';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import decryptData from 'secret/decryptData';
import NavigationButtons from './navigation_btn/NavigationButtons';

const MainLayoutCustomers = () => {
  const { hash, pathname } = useLocation();
  const navigate = useNavigate();
  const isKanban = pathname.includes('kanban');
  // const isChat = pathname.includes('chat');

  const {
    config: { navbarPosition }
  } = useContext(AppContext);

  useEffect(() => {
    if (decryptData('data-user') !== null) {
      if (
        localStorage.getItem('user_status') == 'PENDING' ||
        localStorage.getItem('user_status') == 'UPDATED'
      ) {
        navigate('/under-review');
      } else if (localStorage.getItem('user_status') == 'BLOCKED') {
        navigate('/blocked');
      }
      if (decryptData('data-user').user_type !== 'customer') {
        toast.error(`You have to login`, {
          theme: 'colored'
        });
        navigate('/');
      }
    } else {
      navigate('/');
    }
    setTimeout(() => {
      if (hash) {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ block: 'start', behavior: 'smooth' });
        }
      }
    }, 0);
  }, []);
  const fetchCategories = async () => {
    await axios
      .get(BASE_URL + '/store/categories/', {
        headers: {
          Authorization: `JWT ${decryptData('token')}`,
          'Content-Type': 'application/json'
        }
      })
      .then(r => {
        try {
          r.data?.map(data => {
            if (data.category_name == 'Mobile') {
              localStorage.setItem(
                'mobileCategoryId',
                JSON.stringify(data.category_id)
              );
              localStorage.setItem(
                'mobile',
                JSON.stringify(data.category_name)
              );
            } else if (data.category_name == 'Laptop') {
              localStorage.setItem(
                'laptopCategoryId',
                JSON.stringify(data.category_id)
              );
              localStorage.setItem(
                'laptop',
                JSON.stringify(data.category_name)
              );
            } else if (data.category_name == 'Gaming') {
              localStorage.setItem(
                'gamingCategoryId',
                JSON.stringify(data.category_id)
              );
              localStorage.setItem(
                'gaming',
                JSON.stringify(data.category_name)
              );
            } else if (data.category_name == 'Wearable') {
              localStorage.setItem(
                'wearableCategoryId',
                JSON.stringify(data.category_id)
              );
              localStorage.setItem(
                'wearable',
                JSON.stringify(data.category_name)
              );
            }
          });
        } catch (error) {
          DevLog({ error });
        }
      })
      .catch(er => DevLog({ er }));
  };
  useEffect(() => {
    fetchCategories();
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className={'container-fluid position-relative'}>
      {(navbarPosition === 'vertical' || navbarPosition === 'combo') && (
        <NavbarVerticalCustomer />
      )}
      <div className={classNames('content', { 'pb-0': isKanban })}>
        <NavbarTop />
        {/*------ Main Routes ------*/}
        <NavigationButtons />
        <Outlet />
        {!isKanban && <Footer />}
      </div>
      <Link to={'/customer/user/profile'}>
        <Button
          style={{
            bottom: '20px',
            right: '20px'
          }}
          className={`rounded-5 ${
            useScreenSize() == 'mobile' ? 'p-1' : 'px-2'
          } position-fixed z-3 `}
        >
          <AiFillHome
            className="text-white"
            size={useScreenSize() == 'mobile' ? 10 : 15}
          />
        </Button>
      </Link>
      <Link to={'tel:+998900174290'}>
        <OverlayTrigger
          placement={'left'}
          overlay={
            <Tooltip style={{ position: 'fixed' }} id={'tooltip-left'}>
              Call to customer service <br /> +998 90 017 42 90
            </Tooltip>
          }
        >
          <Button
            variant="success"
            style={{
              bottom: useScreenSize() == 'mobile' ? '70px' : '60px',
              right: '20px'
            }}
            className={`rounded-5 ${
              useScreenSize() == 'mobile' ? 'p-1' : 'px-2'
            } position-fixed z-3 `}
          >
            <AiTwotonePhone
              className="text-white"
              size={useScreenSize() == 'mobile' ? 10 : 15}
            />
          </Button>
        </OverlayTrigger>
      </Link>
    </div>
  );
};

export default MainLayoutCustomers;
