/* eslint-disable no-unused-vars */
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { GiCheckMark } from 'react-icons/gi';
import { BsClockHistory } from 'react-icons/bs';
import { TbTruckDelivery } from 'react-icons/tb';
import { GiReceiveMoney } from 'react-icons/gi';
import { MdPendingActions } from 'react-icons/md';
import { AiOutlineStop } from 'react-icons/ai';
import { customerOrderNumberGenerator } from 'helpers/customerOrderNumberGenerator';
import SoftBadge from 'components/common/SoftBadge';
import CommentOffcanvas from 'components/app/e-commerce customers/orders/comment/CommentOffcanvas';
import { BASE_URL } from 'constants/url';
import TableSkeleton from 'components/common/skeletons/table skleton/TableSkleton';
import { Divider } from 'antd';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import { useLanguage } from 'provider/LanguageProvider';
import {
  SearchPlaceHolderTranslations,
  SupplierOrdersPageTranslate
} from 'languages/Language';
import decryptData from 'secret/decryptData';
import useUserStore from 'state/userStore';
import DevLog from 'helpers/DevLog';

const OrderInProgressSupplier = () => {
  const { language } = useLanguage();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const options = {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric'
  };
  const columns = [
    {
      accessor: 'order_id',
      Header: SupplierOrdersPageTranslate.table.OrderNumber[language],
      Cell: rowData => {
        const {
          supplier_order_id,
          created_at,
          order_status,
          total_cost,
          tax_fee,
          total_product_cost,
          shipping_fee,
          x
        } = rowData.row.original;

        const date = new Date(created_at);
        const dispatched = {
          supplier_order_id,
          x,
          order_status,
          total_cost,
          tax_fee,
          total_product_cost,
          shipping_fee,
          date: date.toLocaleDateString('en-US', options)
        };

        return (
          <Link
            state={dispatched}
            to={`/supplier/e-commerce/order/${supplier_order_id}`}
          >
            #{customerOrderNumberGenerator(supplier_order_id)}
          </Link>
        );
      }
    },
    {
      accessor: 'order_date',
      Header: SupplierOrdersPageTranslate.table.OrderDate[language],
      Cell: rowData => {
        const { created_at } = rowData.row.original;
        const date = new Date(created_at);
        return date.toLocaleDateString('en-US', options);
      }
    },
    {
      accessor: 'total_cost',
      Header: SupplierOrdersPageTranslate.table.TotalCost[language]
    },
    {
      accessor: 'order_status',
      Header: SupplierOrdersPageTranslate.table.OrderStatus[language],
      Cell: rowData => {
        const { order_status } = rowData.row.original;
        if (String(order_status).toLowerCase() === 'awaiting_payment') {
          //BsClockHistory
          return (
            <SoftBadge pill bg="warning" className="me-2">
              Awaiting Payment {<BsClockHistory size={16} />}
            </SoftBadge>
          );
        }

        if (String(order_status).toLowerCase() === 'awaiting_shipment') {
          //BsClockHistory
          return (
            <SoftBadge pill bg="primary" className="me-2">
              Awaiting shipment {<TbTruckDelivery size={16} />}
            </SoftBadge>
          );
        }

        if (String(order_status).toLowerCase() === 'awaiting_pickup') {
          //BsClockHistory
          return (
            <SoftBadge pill bg="secondary" className="me-2">
              Awaiting payment {<MdPendingActions size={16} />}
            </SoftBadge>
          );
        }

        if (String(order_status).toLowerCase() === 'partially_paid') {
          //BsClockHistory
          return (
            <SoftBadge pill bg="info" className="me-2">
              Partially paid {<GiReceiveMoney size={16} />}
            </SoftBadge>
          );
        }

        if (String(order_status).toLowerCase() === 'declined') {
          //BsClockHistory
          return (
            <SoftBadge pill bg="danger" className="me-2">
              Declined {<AiOutlineStop size={16} />}
            </SoftBadge>
          );
        }
        return (
          <SoftBadge pill bg="success" className="me-2">
            Completed {<GiCheckMark size={16} />}
          </SoftBadge>
        );
      }
    }
    // {
    //   accessor: 'order_comment',
    //   Header: 'Comment',
    //   Cell: () => {
    //     // const { get_order_comments } = rowData.row.original;

    //     return (
    //       <CommentOffcanvas count={10}>
    //         {/* {get_order_comments.length > 0 ? (
    //           get_order_comments.map((comment, index) => {
    //             return (
    //               <ChatBubble
    //                 key={index}
    //                 author="Admin"
    //                 text={comment.comment}
    //                 isOwnMessage={false}
    //               />
    //             );
    //           })
    //         ) : (
    //           <p className="text-900 text-center">No comments...</p>
    //         )} */}
    //       </CommentOffcanvas>
    //     );
    //   }
    // }
  ];

  const fetchCustomerOrder = async () => {
    setIsLoading(true);
    await axios
      .get(BASE_URL + '/store/supplier/orders/', {
        headers: {
          Authorization: `JWT ${decryptData('token')}`,
          'Content-Type': 'application/json'
        }
      })
      .then(async response => {
        // const product = response.data;
        DevLog({ rrrr: response });

        const data = response.data?.map(d => {
          return { ...d, x: d.last_shipping_address };
        });
        setData(data);
      })
      .catch(err => DevLog({ err }))
      .finally(() => setIsLoading(false));
  };
  const navigate = useNavigate();
  const { userStatus } = useUserStore();
  useEffect(() => {
    if (
      userStatus == 'BLANK' ||
      userStatus == '' ||
      userStatus == null ||
      userStatus == undefined
    ) {
      navigate('/supplier/user/settings');
    }
  }, [userStatus]);
  useEffect(() => {
    fetchCustomerOrder();
  }, []);

  if (isLoading) {
    return <TableSkeleton />;
  }

  return (
    <Card className="p-3">
      <Divider>
        {language == 'en' && 'Orders '}
        {language == 'ru' && 'Заказы '}
        {language == 'uz' && 'Buyurtmalar '}
      </Divider>
      <AdvanceTableWrapper
        columns={columns}
        data={data}
        sortable
        pagination
        perPage={15}
      >
        <Row className="flex-end-center mb-3">
          <Col xs="auto" sm={6} lg={4}>
            <AdvanceTableSearchBox
              placeholder={SearchPlaceHolderTranslations[language]}
              table
            />
          </Col>
        </Row>
        <AdvanceTable
          table
          headerClassName="bg-200 text-900 text-nowrap align-middle"
          rowClassName="align-middle white-space-nowrap"
          tableProps={{
            bordered: false,
            striped: true,
            className: 'fs--1 mb-0 overflow-hidden '
          }}
        />
        <div className="mt-3">
          {/* <AdvanceTableFooter
            rowCount={data.length}
            table
            rowInfo
            navButtons
            rowsPerPageSelection
          /> */}
        </div>
      </AdvanceTableWrapper>
    </Card>
  );
};

export default OrderInProgressSupplier;
