import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'antd';
import CurrencySwitch from 'components/currency/CurrencySwitch';
import ProfileDropdown from 'components/navbar/top/ProfileDropdown';
import AppContext from 'context/Context';

import useHardReloadAndClearCache from 'hooks/useHardReloadAndClearCache';
import LanguageSelector from 'languages/LanguageSelector';
import React, { useContext } from 'react';
import { Dropdown, Nav, OverlayTrigger, Tooltip } from 'react-bootstrap';
import {
  AiFillSetting,
  AiOutlineReload,
  AiOutlineShoppingCart
} from 'react-icons/ai';
import { Link } from 'react-router-dom';
import useUserCart from 'state/cartStore';

const TopNavRightSideNavItem = () => {
  const { Cart } = useUserCart();
  const hardReloadAndEmptyCache = useHardReloadAndClearCache();
  const {
    config: { isDark, isRTL },
    setConfig
  } = useContext(AppContext);

  return (
    <Nav
      navbar
      className="navbar-nav-icons ms-auto flex-row align-items-center"
      as="ul"
    >
      {/* /customer/e-commerce/orders */}

      <Nav.Item as={'li'}>
        <Dropdown className="rounded-2">
          <Dropdown.Toggle variant="falcon-default">
            Options <AiFillSetting size={20} className="mr-3" />
          </Dropdown.Toggle>
          <Dropdown.Menu className="py-2">
            <Dropdown.Item>
              Hard reload:{' '}
              <AiOutlineReload
                onClick={hardReloadAndEmptyCache}
                className="cursor-pointer  text-primary"
                size={20}
              />
            </Dropdown.Item>
            <Dropdown.Item>
              <div className="d-flex">
                <p className="me-2">Currency:</p> <CurrencySwitch />
              </div>
            </Dropdown.Item>
            <Dropdown.Divider />
            <div className="p-3">
              <LanguageSelector />
            </div>
          </Dropdown.Menu>
        </Dropdown>
      </Nav.Item>
      <Nav.Item as={'li'}>
        {' '}
        <Link
          to={
            localStorage.getItem('user_type') == 'supplier'
              ? '/supplier/e-commerce/checkout-products'
              : '/customer/e-commerce/orders'
          }
        >
          <Button
            className="mx-2 rounded-5 d-flex justify-content-around align-items-center"
            type="primary"
          >
            <AiOutlineShoppingCart className="mx-1" size={18} />{' '}
            {localStorage.getItem('user_type') == 'supplier' && (
              <b>{Cart.length}</b>
            )}
          </Button>
        </Link>{' '}
      </Nav.Item>
      <Nav.Item as={'li'}>
        <Nav.Link
          className="px-2 theme-control-toggle"
          onClick={() => setConfig('isDark', !isDark)}
        >
          <OverlayTrigger
            key="left"
            placement={isRTL ? 'bottom' : 'left'}
            overlay={
              <Tooltip style={{ position: 'fixed' }} id="ThemeColor">
                {isDark ? 'Switch to light theme' : 'Switch to dark theme'}
              </Tooltip>
            }
          >
            <div className="theme-control-toggle-label">
              <FontAwesomeIcon
                icon={isDark ? 'sun' : 'moon'}
                className="fs-0"
              />
            </div>
          </OverlayTrigger>
        </Nav.Link>
      </Nav.Item>

      <ProfileDropdown />
    </Nav>
  );
};

export default TopNavRightSideNavItem;
