import CryptoJS from 'crypto-js';
import { SECRET_KEY } from 'constants/secret_key';

const decryptData = name => {
  const encrypted = localStorage.getItem(name);
  const decrypted = CryptoJS.AES.decrypt(encrypted, SECRET_KEY).toString(
    CryptoJS.enc.Utf8
  );
  return JSON.parse(decrypted);
};

export default decryptData;
