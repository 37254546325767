/* eslint-disable react/prop-types */
import SoftBadge from 'components/common/SoftBadge';
import React, { useState } from 'react';
import { Offcanvas } from 'react-bootstrap';
import { BsFillChatQuoteFill } from 'react-icons/bs';

const CommentOffcanvas = ({ children, count = 0 }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <SoftBadge
        onClick={handleShow}
        pill
        bg="info"
        className="me-2  cursor-pointer hover-700 d-inline-flex align-items-center justify-content-center"
      >
        <BsFillChatQuoteFill className="mx-1" size={20} />{' '}
        <h5 className="d-inline-block text-800 m-0">{count}</h5>
      </SoftBadge>
      <Offcanvas className="" placement="end" show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Comments by Administrator</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>{children}</Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default CommentOffcanvas;
