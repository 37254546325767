import { Divider } from 'antd';
import React from 'react';
import { Row, Col } from 'react-bootstrap';

const BankDetails = () => {
  return (
    <>
      <h5 className="text-center fs-2">Bank Details</h5>
      <br />

      <div className="p-3 mb-1">
        <Divider className="text-black">BANK AED</Divider>
        <Row>
          <Col>
            <h6>Account name:</h6>
            <p className="text-700">AMIR GROUP FZCO</p>
          </Col>
          <Col>
            <h6>Account Number:</h6>
            <p className="text-700">097520206588401</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <h6>Account SWIFT code:</h6>
            <p className="text-700">DUIBAEAD</p>
          </Col>
          <Col>
            <h6>IBAN:</h6>
            <p className="text-700">AE310240097520206588401</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <h6>Name of the bank:</h6>
            <p className="text-700">DUBAI ISLAMIC BANK</p>
          </Col>
        </Row>
      </div>
      <div className="p-3 ">
        <Divider className="text-black">BANK USD</Divider>
        <Row>
          <Col>
            <h6>Account name:</h6>
            <p className="text-700">AMIR GROUP FZCO</p>
          </Col>
          <Col>
            <h6>Account Number:</h6>
            <p className="text-700">097521206588401</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <h6>Account SWIFT code:</h6>
            <p className="text-700">DUIBAEAD</p>
          </Col>
          <Col>
            <h6>IBAN:</h6>
            <p className="text-700">AE830240097521206588401</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <h6>Name of the bank:</h6>
            <p className="text-700">DUBAI ISLAMIC BANK</p>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default BankDetails;
