// import DevLog from 'helpers/DevLog';
// export const BASE_URL =
//   process.env.NODE_ENV !== 'development'
//     ? 'http://localhost:8000/api/v1'
//     : 'http://localhost:8000/api/v1';
// export const BASE_URL_WITHOUT_API =
//   process.env.NODE_ENV !== 'development'
//     ? 'http://localhost:8000'
//     : 'http://localhost:8000';
// DevLog({ process: process.env.NODE_ENV });

// export const BASE_URL =
//   process.env.NODE_ENV !== 'development'
//     ? 'https://tijarie-backend-76c0e453aad0.herokuapp.com/api/v1'
//     : 'https://skhayrullayev.pythonanywhere.com/api/v1';
// export const BASE_URL_WITHOUT_API =
//   process.env.NODE_ENV !== 'development'
//     ? 'https://tijarie-backend-76c0e453aad0.herokuapp.com'
//     : 'https://skhayrullayev.pythonanywhere.com';
// DevLog({ process: process.env.NODE_ENV });

export const BASE_URL =
  'https://tijarie-backend-76c0e453aad0.herokuapp.com/api/v1';
export const BASE_URL_WITHOUT_API =
  'https://tijarie-backend-76c0e453aad0.herokuapp.com';
