/* eslint-disable react/react-in-jsx-scope */
import { FaGamepad, FaShippingFast } from 'react-icons/fa';
import { BiLocationPlus } from 'react-icons/bi';
import { BiMobileAlt } from 'react-icons/bi';
import {
  BsFillBoxSeamFill,
  BsFillLaptopFill,
  BsFillPersonFill,
  BsPersonCircle,
  BsSmartwatch
} from 'react-icons/bs';
import { useContext } from 'react';
import AppContext from 'context/Context';
import React from 'react';

const WidgetList = () => {
  const {
    config: { isDark }
  } = useContext(AppContext);
  const widgetList = {
    shipping: [
      {
        gradientColors: isDark
          ? ['#333333', '#555555']
          : ['#4a69bd', '#b4c5e4'],
        widgetName: 'Shipping Address',

        widgetLink: '/admin/get/shippingAddress/',
        color: isDark ? '#00CCFF' : 'white',
        children: (
          <FaShippingFast color={isDark ? '#00CCFF' : 'white'} size={50} />
        )
      },
      {
        gradientColors: isDark
          ? ['#222222', '#444444']
          : ['#2980b9', '#6bb9f0'],
        widgetName: 'Create Shipping Address',
        widgetLink: '/admin/shippingAddress/create',
        color: isDark ? '#00CCFF' : 'white',
        children: (
          <BiLocationPlus color={isDark ? '#00CCFF' : 'white'} size={50} />
        )
      }
    ],
    products: [
      {
        gradientColors: isDark
          ? ['#111111', '#333333']
          : ['#00a8ff', '#55c2e5'],
        widgetName: 'Mobile phones',
        name: 'mobile_phones',
        widgetLink: '/admin/e-commerce/product/mobile_phones',
        color: isDark ? '#00CCFF' : 'white',
        children: <BiMobileAlt color={isDark ? '#00CCFF' : 'white'} size={50} />
      },
      {
        gradientColors: isDark
          ? ['#000000', '#222222']
          : ['#34ace0', '#6bcbef'],
        widgetName: 'Laptops',
        name: 'laptop',
        widgetLink: '/admin/e-commerce/product/laptops',
        color: isDark ? '#00CCFF' : 'white',
        children: (
          <BsFillLaptopFill color={isDark ? '#00CCFF' : 'white'} size={50} />
        )
      },
      {
        gradientColors: isDark
          ? ['#330033', '#550055']
          : ['#e84393', '#f984b6'],
        widgetName: 'Gaming consoles',
        name: 'gaming',
        widgetLink: '/admin/e-commerce/product/gaming_consoles',
        color: isDark ? '#00CCFF' : 'white',
        children: <FaGamepad color={isDark ? '#00CCFF' : 'white'} size={50} />
      },
      {
        gradientColors: isDark
          ? ['#660000', '#882222']
          : ['#ff6b6b', '#f9a7a7'],
        widgetName: 'Wearables',
        name: 'wearable',
        widgetLink: '/admin/e-commerce/product/wearables',
        color: isDark ? '#00CCFF' : 'white',
        children: (
          <BsSmartwatch color={isDark ? '#00CCFF' : 'white'} size={50} />
        )
      }
    ],
    order: [
      {
        gradientColors: isDark
          ? ['#1A1A1A', '#2C2C2C']
          : ['#45B1E8', '#76ABDF'],
        widgetName: 'Customer Orders',
        name_old: 'total_customer_orders',
        name_new: 'new_customer_orders',
        widgetLink: '/admin/e-commerce/orders/all-orders',
        color: isDark ? '#00CCFF' : 'white',
        children: (
          <BsFillBoxSeamFill color={isDark ? '#00CCFF' : 'white'} size={50} />
        )
      },
      {
        gradientColors: isDark
          ? ['#1A1A1A', '#2C2C2C']
          : ['#e84393', '#f984b6'],
        widgetName: 'Supplier Orders',
        name_old: 'total_supplier_orders',
        name_new: 'new_supplier_orders',
        widgetLink: '/admin/e-commerce/supplier/orders/all-orders',
        color: isDark ? '#00CCFF' : 'white',
        children: (
          <BsFillBoxSeamFill color={isDark ? '#00CCFF' : 'white'} size={50} />
        )
      }
    ],
    deposit: [
      {
        gradientColors: isDark
          ? ['#2B2B2B', '#4D4D4D']
          : ['#6A88EF', '#9564E2'],
        widgetName: 'Customers Deposits',
        widgetLink: '/admin/users/customers/deposits',
        color: isDark ? '#00CCFF' : 'white',
        children: (
          <BsFillPersonFill color={isDark ? '#00CCFF' : 'white'} size={50} />
        )
      },
      {
        gradientColors: isDark
          ? ['#004D4D', '#006666']
          : ['#A6E3E9', '#7BC6CC'],
        widgetName: 'Supplier Deposits',
        widgetLink: '/admin/users/suppliers/deposits',
        color: isDark ? '#00CCFF' : 'white',
        children: (
          <BsPersonCircle color={isDark ? '#00CCFF' : 'white'} size={50} />
        )
      }
    ],
    users: [
      {
        gradientColors: isDark
          ? ['#663300', '#885500']
          : ['#FAD961', '#F76B1C'],
        widgetName: 'Customers',
        name_old: 'customers',
        name_new: 'new_customers',

        widgetLink: '/admin/users/customers/all',
        color: isDark ? '#00CCFF' : 'white',
        children: (
          <BsPersonCircle color={isDark ? '#00CCFF' : 'white'} size={50} />
        )
      },
      {
        gradientColors: isDark
          ? ['#004D00', '#006600']
          : ['#B4EC51', '#429321'],
        widgetName: 'Suppliers',
        name_old: 'supplier',
        name_new: 'new_suppliers',
        widgetLink: '/admin/users/suppliers/all',
        color: isDark ? '#00CCFF' : 'white',
        children: (
          <BsPersonCircle color={isDark ? '#00CCFF' : 'white'} size={50} />
        )
      }
    ]
  };
  return widgetList;
};

export default WidgetList;
