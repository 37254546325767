/* eslint-disable react/prop-types */
import axios from 'axios';
import SoftBadge from 'components/common/SoftBadge';
import { BASE_URL } from 'constants/url';
import DevLog from 'helpers/DevLog';
import React, { useState } from 'react';
import { BsArrowDownShort, BsArrowUpShort } from 'react-icons/bs';
import decryptData from 'secret/decryptData';

const DropdownWithCheckbox = ({ setFilteredItems, setIsLoading }) => {
  const [options, setOptions] = useState([
    'awaiting payment',
    'awaiting pickup',
    'awaiting shipment',
    'declined',
    'completed',
    'partially paid'
  ]);
  const [selected, setSelected] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const handleRemoveChip = async id => {
    const filtered = selected.filter(o => {
      return o != id;
    });
    setSelected(filtered);

    setOptions([...options, id]);
    DevLog({ filtered });
    await axios
      .get(
        filtered.length > 0
          ? `${BASE_URL}/dashboard/orders/?order_status=[${filtered.map(x => {
              return String(x).toUpperCase().split(' ').join('_');
            })}]`
          : `${BASE_URL}/dashboard/orders/?all`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `JWT ${decryptData('token')}`
          }
        }
      )
      .then(response => {
        DevLog({ response });
        const data = response.data.results?.map(a => {
          const {
            order_id,
            created_at,
            total_cost,
            customer,
            last_shipping_address,
            order_status
          } = a;
          const { shipping_type } = last_shipping_address;
          const { business_name } = customer;

          return {
            order_id,
            created_at,
            total_cost,
            shipping_type,
            business_name,
            order_status
          };
        });

        setFilteredItems(data);
      })
      .catch(err => DevLog(err))
      .finally(() => setIsLoading(false));
  };

  const handleAddChip = async id => {
    setSelected([...selected, id]);

    setOptions(options.filter(i => i !== id));
    await axios
      .get(
        BASE_URL +
          `/dashboard/orders/?order_status=[${[...selected, id].map(i => {
            return String(i).toUpperCase().split(' ').join('_');
          })}]`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `JWT ${decryptData('token')}`
          }
        }
      )
      .then(response => {
        DevLog({ response });
        const data = response.data.results?.map(a => {
          const {
            order_id,
            created_at,
            total_cost,
            customer,
            last_shipping_address,
            order_status
          } = a;
          const { shipping_type } = last_shipping_address;
          const { business_name } = customer;

          return {
            order_id,
            created_at,
            total_cost,
            shipping_type,
            business_name,
            order_status
          };
        });

        setFilteredItems(data);
      })
      .catch(err => DevLog(err))
      .finally(() => setIsLoading(false));
  };
  return (
    <div>
      <div className="d-flex flex-column">
        <div
          onClick={() => setIsOpen(!isOpen)}
          className="rounded bg-200 mb-1  py-1 d-inline-block px-2"
        >
          <p className="text-800 m-0 text-center">
            {' '}
            Select order status{' '}
            {isOpen ? <BsArrowUpShort /> : <BsArrowDownShort />}
          </p>
        </div>
        {isOpen ? (
          <div className="bg-100 px-2 rounded mb-2 py-1 d-inline-block">
            {options?.map((option, idx) => {
              return (
                <div
                  className="cursor-pointer hover-1000"
                  onClick={() => handleAddChip(option)}
                  key={idx}
                >
                  <p className="m-0 text-600 hover-400">{option}</p>
                </div>
              );
            })}
          </div>
        ) : (
          <></>
        )}
      </div>
      {selected?.map((option, idx) => {
        return (
          <SoftBadge
            className="d-inline-flex mb-1 me-1 justify-content-between align-items-center py-2"
            key={idx}
            variant="success"
          >
            {option}
            <SoftBadge
              onClick={() => handleRemoveChip(option)}
              className="cursor-pointer m-0"
              variant="danger"
            >
              ❌
            </SoftBadge>
          </SoftBadge>
        );
      })}
    </div>
  );
};

export default DropdownWithCheckbox;
