/* eslint-disable react/prop-types */
import bgNavbar from 'assets/img/generic/bg-navbar.png';
import classNames from 'classnames';
import Flex from 'components/common/Flex';
import { navbarBreakPoint, topNavbarBreakpoint } from 'config';
import AppContext from 'context/Context';
import { capitalize } from 'helpers/utils';
import useScreenSize from 'hooks/useScreenSize';
import React, { Fragment, useContext, useEffect } from 'react';
import { Col, Nav, Navbar, Row } from 'react-bootstrap';
import routes from 'routes/siteMapForAdmin';
import NavbarTopDropDownMenusAdmin from '../../top/admin/NavbarToDropDownMenusAdmin';
import ToggleButton from '../ToggleButton';
import NavbarVerticalMenuAdmin from './NavbarVerticalMenuAdmin';

const NavbarVerticalAdmin = () => {
  const {
    config: {
      navbarPosition,
      navbarStyle,
      isNavbarVerticalCollapsed,
      showBurgerMenu
    }
  } = useContext(AppContext);
  const screen = useScreenSize();
  const HTMLClassList = document.getElementsByTagName('html')[0].classList;

  useEffect(() => {
    if (isNavbarVerticalCollapsed) {
      HTMLClassList.add('navbar-vertical-collapsed');
    } else {
      HTMLClassList.remove('navbar-vertical-collapsed');
    }
    return () => {
      HTMLClassList.remove('navbar-vertical-collapsed-hover');
    };
  }, [isNavbarVerticalCollapsed, HTMLClassList]);

  //Control mouseEnter event
  let time = null;
  const handleMouseEnter = () => {
    if (isNavbarVerticalCollapsed) {
      time = setTimeout(() => {
        HTMLClassList.add('navbar-vertical-collapsed-hover');
      }, 100);
    }
  };
  const handleMouseLeave = () => {
    clearTimeout(time);
    HTMLClassList.remove('navbar-vertical-collapsed-hover');
  };

  const NavbarLabel = ({ label }) => (
    <Nav.Item as="li">
      <Row className="mt-3 mb-2 navbar-vertical-label-wrapper">
        <Col xs="auto" className="navbar-vertical-label navbar-vertical-label">
          {label}
        </Col>
        <Col className="ps-0">
          <hr className="mb-0 navbar-vertical-divider"></hr>
        </Col>
      </Row>
    </Nav.Item>
  );

  return (
    <Navbar
      expand={navbarBreakPoint}
      className={classNames('navbar-vertical ', {
        [`navbar-${navbarStyle}`]: navbarStyle !== 'transparent'
      })}
      variant="light"
    >
      <>
        <Flex
          className={screen !== 'mobile' && 'mt-2'}
          alignItems="center"
          justifyContent={'start'}
        >
          <ToggleButton />
        </Flex>
        {screen !== 'mobile' && <br />}
        <Navbar.Collapse
          in={showBurgerMenu}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          style={{
            backgroundImage:
              navbarStyle === 'vibrant'
                ? `linear-gradient(-45deg, rgba(0, 160, 255, 0.86), #0048a2),url(${bgNavbar})`
                : 'none'
          }}
        >
          <div className="navbar-vertical-content scrollbar">
            <Nav className="flex-column" as="ul">
              {routes.map(route => (
                <Fragment key={route.label}>
                  {!route.labelDisable && (
                    <NavbarLabel label={capitalize(route.label)} />
                  )}
                  <NavbarVerticalMenuAdmin routes={route.children} />
                </Fragment>
              ))}
            </Nav>

            <>
              {navbarPosition === 'combo' && (
                <div className={`d-${topNavbarBreakpoint}-none`}>
                  <div className="navbar-vertical-divider">
                    <hr className="navbar-vertical-hr my-2" />
                  </div>
                  <Nav navbar>
                    <NavbarTopDropDownMenusAdmin />
                  </Nav>
                </div>
              )}
            </>
          </div>
        </Navbar.Collapse>
      </>
    </Navbar>
  );
};

export default NavbarVerticalAdmin;
