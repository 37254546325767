import React, { useContext, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Nav, Navbar, Row, Col } from 'react-bootstrap';
import { navbarBreakPoint, topNavbarBreakpoint } from 'config';
import AppContext from 'context/Context';
import Flex from 'components/common/Flex';
import NavbarVerticalMenu from '../NavbarVerticalMenu';
import ToggleButton from '../ToggleButton';
import {
  appRoutesEN,
  appRoutesUZ,
  appRoutesRU,
  dashboardRoutesEN,
  dashboardRoutesRU,
  dashboardRoutesUZ
} from 'routes/siteMapForSuppliers';
import { capitalize } from 'helpers/utils';
import NavbarTopDropDownMenus from 'components/navbar/top/NavbarTopDropDownMenus';
import bgNavbar from 'assets/img/generic/bg-navbar.png';
import useScreenSize from 'hooks/useScreenSize';
import { useLanguage } from 'provider/LanguageProvider';

const NavbarVerticalSuppliers = () => {
  const { language } = useLanguage();
  const app_routes = {
    en: appRoutesEN,
    uz: appRoutesUZ,
    ru: appRoutesRU
  };
  const app_routes_dashboard = {
    en: dashboardRoutesEN,
    uz: dashboardRoutesUZ,
    ru: dashboardRoutesRU
  };
  const {
    config: {
      navbarPosition,
      navbarStyle,
      isNavbarVerticalCollapsed,
      showBurgerMenu
    }
  } = useContext(AppContext);
  const screen = useScreenSize();

  const HTMLClassList = document.getElementsByTagName('html')[0].classList;

  useEffect(() => {
    if (isNavbarVerticalCollapsed) {
      HTMLClassList.add('navbar-vertical-collapsed');
    } else {
      HTMLClassList.remove('navbar-vertical-collapsed');
    }
    return () => {
      HTMLClassList.remove('navbar-vertical-collapsed-hover');
    };
  }, [isNavbarVerticalCollapsed, HTMLClassList]);

  //Control mouseEnter event
  let time = null;
  const handleMouseEnter = () => {
    if (isNavbarVerticalCollapsed) {
      time = setTimeout(() => {
        HTMLClassList.add('navbar-vertical-collapsed-hover');
      }, 100);
    }
  };
  const handleMouseLeave = () => {
    clearTimeout(time);
    HTMLClassList.remove('navbar-vertical-collapsed-hover');
  };

  const NavbarLabel = ({ label }) => (
    <Nav.Item as="li">
      <Row className="mt-3 mb-2 navbar-vertical-label-wrapper">
        <Col xs="auto" className="navbar-vertical-label navbar-vertical-label">
          {label}
        </Col>
        <Col className="ps-0">
          <hr className="mb-0 navbar-vertical-divider"></hr>
        </Col>
      </Row>
    </Nav.Item>
  );

  return (
    <Navbar
      expand={navbarBreakPoint}
      className={classNames('navbar-vertical ', {
        [`navbar-${navbarStyle}`]: navbarStyle !== 'transparent'
      })}
      variant="light"
    >
      <>
        <Flex
          className={screen !== 'mobile' ? 'mt-2' : 'm-0'}
          alignItems="center"
          justifyContent={'start'}
        >
          <ToggleButton />
        </Flex>
        {screen !== 'mobile' && <br />}
        <Navbar.Collapse
          in={showBurgerMenu}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          style={{
            backgroundImage:
              navbarStyle === 'vibrant'
                ? `linear-gradient(-45deg, rgba(0, 160, 255, 0.86), #0048a2),url(${bgNavbar})`
                : 'none'
          }}
        >
          {/* TODO: conditionally render links */}
          <div className="navbar-vertical-content scrollbar">
            <Nav className="flex-column" as="ul">
              {/* app_routes */}

              <Fragment key={app_routes_dashboard[language].label}>
                {!app_routes_dashboard[language].labelDisable && (
                  <NavbarLabel
                    label={capitalize(app_routes_dashboard[language].label)}
                  />
                )}
                <NavbarVerticalMenu
                  routes={app_routes_dashboard[language].children}
                />
              </Fragment>
              <Fragment key={app_routes[language].label}>
                {!app_routes[language].labelDisable && (
                  <NavbarLabel label={capitalize(app_routes[language].label)} />
                )}
                <NavbarVerticalMenu routes={app_routes[language].children} />
              </Fragment>
            </Nav>

            <>
              {navbarPosition === 'combo' && (
                <div className={`d-${topNavbarBreakpoint}-none`}>
                  <div className="navbar-vertical-divider">
                    <hr className="navbar-vertical-hr my-2" />
                  </div>
                  <Nav navbar>
                    <NavbarTopDropDownMenus />
                  </Nav>
                </div>
              )}
            </>
          </div>
        </Navbar.Collapse>
      </>
    </Navbar>
  );
};

NavbarVerticalSuppliers.propTypes = {
  label: PropTypes.string
};

export default NavbarVerticalSuppliers;
