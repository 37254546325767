import React from 'react';
import { Button, Card } from 'react-bootstrap';
import {
  BsFillArrowLeftCircleFill,
  BsFillArrowRightCircleFill
} from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';

function NavigationButtons() {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1); // Navigate back in the history stack
  };

  const goForward = () => {
    navigate(1); // Navigate forward in the history stack
  };

  return (
    <Card className=" rounded-2 mb-2 p-1 ">
      <div className="">
        <Button onClick={goBack} className="mx-2 rounded-5">
          <BsFillArrowLeftCircleFill size={19} />
        </Button>
        <Button onClick={goForward} className="rounded-5 ">
          <BsFillArrowRightCircleFill size={19} />
        </Button>
      </div>
    </Card>
  );
}

export default NavigationButtons;
