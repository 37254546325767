// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';

import { BiSolidUserCircle } from 'react-icons/bi';
import { useLanguage } from 'provider/LanguageProvider';
import decryptData from 'secret/decryptData';

const ProfileDropdown = () => {
  const { language } = useLanguage();
  const [link, setLink] = useState('');

  useEffect(() => {
    setLink(decryptData('data-user')?.user_type || null);
  }, []);
  return (
    <Dropdown navbar={true} as="li">
      <Dropdown.Toggle
        bsPrefix="toggle"
        as={'div'}
        to="#"
        className="pe-0 ps-2 nav-link"
      >
        <BiSolidUserCircle size={35} className="text-primary" />
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-caret dropdown-menu-card  dropdown-menu-end">
        <div className="bg-white rounded-2 py-2 dark__bg-1000">
          <Dropdown.Item
            as={Link}
            to={link == 'admin' ? '/admin/dashboard' : `/${link}/user/profile`}
          >
            {language == 'en' && 'Home'}
            {language == 'uz' && 'Bosh sahifa'}
            {language == 'ru' && 'Главная страница'}
          </Dropdown.Item>
          {link !== 'admin' && (
            <Dropdown.Item as={Link} to={`/${link}/user/top-up-balance`}>
              {language == 'en' && 'Top-up balance'}
              {language == 'uz' && "Balansni to'ldirish"}
              {language == 'ru' && 'Пополнение баланса'}
            </Dropdown.Item>
          )}
          {link !== 'admin' && (
            <Dropdown.Item as={Link} to={`/${link}/user/settings`}>
              {language == 'en' && 'Account'}
              {language == 'uz' && 'Hisob'}
              {language == 'ru' && 'Аккаунт'}
            </Dropdown.Item>
          )}
          <Dropdown.Item
            onClick={() => {
              localStorage.removeItem('data-user');
              localStorage.removeItem('token');
            }}
            as={Link}
            to="/"
          >
            {language == 'en' && 'Logout'}
            {language == 'uz' && 'Chiqish'}
            {language == 'ru' && 'Выйти'}
          </Dropdown.Item>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ProfileDropdown;
