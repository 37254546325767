import axios from 'axios';
import TableSkeleton from 'components/common/skeletons/table skleton/TableSkleton';
import { BASE_URL } from 'constants/url';
import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import ShippingOfCanvas from '../components/ShippingOfCanvas';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import EditShippingInformation from '../components/EditShippingInformation';
import SoftBadge from 'components/common/SoftBadge';
import decryptData from 'secret/decryptData';
import DevLog from 'helpers/DevLog';

const GetAllShippingAddress = () => {
  const [options, setOptions] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const columns = [
    {
      accessor: 'name',
      Header: 'Name'
    },
    {
      accessor: 'documentation_fee',
      Header: 'Documentation Fee'
    },
    {
      accessor: 'Price per unit',
      Header: 'Type',
      Cell: rowData => {
        const { price_per_unit } = rowData.row.original;

        return <>{price_per_unit ? 'Price per unit' : 'Price per KG'}</>;
      }
    },
    {
      accessor: 'range_0_100',
      Header: '0-100'
    },
    {
      accessor: 'range_101_500',
      Header: '101-500'
    },
    {
      accessor: 'range_501_1000',
      Header: '501-100'
    },
    {
      accessor: 'range_1001_more',
      Header: '1001+'
    },
    {
      accessor: 'vat_percentage',
      Header: 'VAT percentage',
      Cell: rowData => {
        const { vat_percentage } = rowData.row.original;

        return vat_percentage == undefined ? 'Not available' : vat_percentage;
      }
    },
    {
      accessor: 'is_active',
      Header: 'Status',
      Cell: rowData => {
        const { is_active } = rowData.row.original;

        return is_active ? (
          <SoftBadge pill bg="primary" className="me-2">
            ACTIVE
          </SoftBadge>
        ) : (
          <SoftBadge pill bg="danger" className="me-2">
            NOT ACTIVE
          </SoftBadge>
        );
      }
    },
    {
      accessor: 'more',
      Header: 'More',
      Cell: rowData => {
        // DevLog({ row: rowData.row.original });
        const { shipping_detail_id } = rowData.row.original;
        return (
          <ShippingOfCanvas>
            <EditShippingInformation
              setOptions={setOptions}
              id={shipping_detail_id}
              options={options}
              data={rowData.row.original}
            />
          </ShippingOfCanvas>
        );
      }
    }
  ];
  const FetchAllShippingAddress = async () => {
    setLoading(true);

    await axios
      .get(BASE_URL + '/dashboard/shipping-details/info/', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `JWT ${decryptData('token')}`
        }
      })
      .then(response => {
        setOptions(response.data);
      })
      .catch(error => {
        DevLog(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    FetchAllShippingAddress();
  }, []);

  if (isLoading) {
    return <TableSkeleton />;
  }
  return (
    <Card className="p-3">
      <AdvanceTableWrapper
        columns={columns}
        data={options}
        sortable
        pagination
        perPage={15}
      >
        <Row className="flex-end-center mb-3">
          <Col xs="auto" sm={6} lg={4}>
            <AdvanceTableSearchBox table />
          </Col>
        </Row>
        <AdvanceTable
          table
          headerClassName="bg-200 text-900 text-nowrap align-middle"
          rowClassName="align-middle white-space-nowrap"
          tableProps={{
            bordered: true,
            striped: true,
            className: 'fs--1 mb-0 overflow-hidden'
          }}
        />
        <div className="mt-3">
          <AdvanceTableFooter
            rowCount={options.length}
            table
            rowInfo
            navButtons
            rowsPerPageSelection
          />
        </div>
      </AdvanceTableWrapper>
    </Card>
  );
};

export default GetAllShippingAddress;
