import useCurrencyStore from 'currency/useCurrencyStore';

export const CustomerPurchaseMobilePhoneTranslate = () => {
  const { currency } = useCurrencyStore();
  return {
    table: {
      brand: {
        en: 'Brand',
        ru: 'Бренд',
        uz: 'Brend'
      },
      'Model name': {
        en: 'Model Name',
        ru: 'модели',
        uz: 'Model'
      },
      RAM: {
        en: 'RAM',
        ru: 'ОЗУ',
        uz: 'RAM'
      },
      Storage: {
        en: 'Storage',
        ru: 'Память',
        uz: 'Xotira'
      },
      Color: {
        en: 'Color',
        ru: 'Цвет',
        uz: 'Rang'
      },
      Specs: {
        en: 'Specs',
        ru: 'Specs',
        uz: 'Specs'
      },

      Price: {
        en: `Price (${currency})`,
        ru: `Цена ${currency}`,
        uz: `Narxi (${currency})`
      },
      Stock: {
        en: 'Quantity in stock',
        ru: 'Количество на складе',
        uz: 'Ombordagi miqdor'
      },
      Quantity: {
        en: 'Quantity',
        ru: 'Количество',
        uz: 'Miqdori'
      }
    }
  };
};

export const CustomerPurchaseGamingConsoleTranslate = () => {
  const { currency } = useCurrencyStore();

  return {
    table: {
      brand: {
        en: 'Brand',
        ru: 'Бренд',
        uz: 'Brend'
      },
      'Model name': {
        en: 'Model Name',
        ru: 'Модель',
        uz: 'Model'
      },
      RAM: {
        en: 'RAM',
        ru: 'ОЗУ',
        uz: 'RAM'
      },
      Storage: {
        en: 'Storage',
        ru: 'Память',
        uz: 'Xotira'
      },
      Processor: {
        en: 'Processor',
        ru: 'Процессор',
        uz: 'Protsessor'
      },
      VideoCard: {
        en: 'Video Card',
        ru: 'Видеокарта',
        uz: 'Video karta'
      },
      Color: {
        en: 'Color',
        ru: 'Цвет',
        uz: 'Rang'
      },
      Specs: {
        en: 'Specs',
        ru: 'Specs',
        uz: 'Specs'
      },

      Price: {
        en: `Price (${currency})`,
        ru: `Цена ${currency}`,
        uz: `Narxi (${currency})`
      },
      Stock: {
        en: 'Quantity in stock',
        ru: 'Количество на складе',
        uz: 'Ombordagi miqdor'
      },
      Quantity: {
        en: 'Quantity',
        ru: 'Количество',
        uz: 'Miqdori'
      }
    }
  };
};

export const CustomerPurchaseWearablesTranslate = () => {
  const { currency } = useCurrencyStore();
  return {
    table: {
      brand: {
        en: 'Brand',
        ru: 'Бренд',
        uz: 'Brend'
      },
      'Model name': {
        en: 'Model Name',
        ru: 'Модель',
        uz: 'Model'
      },
      RAM: {
        en: 'RAM',
        ru: 'ОЗУ',
        uz: 'RAM'
      },
      Storage: {
        en: 'Storage',
        ru: 'Память',
        uz: 'Xotira'
      },
      Processor: {
        en: 'Processor',
        ru: 'Процессор',
        uz: 'Protsessor'
      },
      VideoCard: {
        en: 'Video Card',
        ru: 'Видеокарта',
        uz: 'Video karta'
      },
      Color: {
        en: 'Color',
        ru: 'Цвет',
        uz: 'Rang'
      },
      Specs: {
        en: 'Specs',
        ru: 'Specs',
        uz: 'Specs'
      },

      Price: {
        en: `Price (${currency})`,
        ru: `Цена ${currency}`,
        uz: `Narxi (${currency})`
      },
      Stock: {
        en: 'Quantity in stock',
        ru: 'Количество на складе',
        uz: 'Ombordagi miqdor'
      },
      Quantity: {
        en: 'Quantity',
        ru: 'Количество',
        uz: 'Miqdori'
      }
    }
  };
};

export const CustomerShoppingCartTranslate = () => {
  const { currency } = useCurrencyStore();
  return {
    table: {
      brand: {
        en: 'Brand',
        ru: 'Бренд',
        uz: 'Brend'
      },
      'Model name': {
        en: 'Model Name',
        ru: 'Модель',
        uz: 'Model'
      },
      RAM: {
        en: 'RAM',
        ru: 'ОЗУ',
        uz: 'RAM'
      },
      Storage: {
        en: 'Storage',
        ru: 'Память',
        uz: 'Xotira'
      },
      Processor: {
        en: 'Processor',
        ru: 'Процессор',
        uz: 'Protsessor'
      },
      VideoCard: {
        en: 'Video Card',
        ru: 'Видеокарта',
        uz: 'Video karta'
      },
      Color: {
        en: 'Color',
        ru: 'Цвет',
        uz: 'Rang'
      },
      Specs: {
        en: 'Specs',
        ru: 'Specs',
        uz: 'Specs'
      },

      Price: {
        en: `Price (${currency})`,
        ru: `Цена ${currency}`,
        uz: `Narxi (${currency})`
      },
      Stock: {
        en: 'Quantity in stock',
        ru: 'Количество на складе',
        uz: 'Ombordagi miqdor'
      },
      Quantity: {
        en: 'Quantity',
        ru: 'Количество',
        uz: 'Miqdori'
      },
      Remove: {
        en: 'Remove',
        ru: 'Удалить',
        uz: 'Oʻchirish'
      },
      Status: {
        en: 'Status',
        ru: 'Статус',
        uz: 'Holat'
      }
    },
    other: {
      Total: {
        en: 'Total ',
        ru: 'Итого ',
        uz: 'Jami '
      },
      Items: {
        en: ' Items',
        ru: ' Товары',
        uz: ' Mahsulotlar'
      },
      ConfirmButton: {
        en: ' Confirm',
        ru: ' Подтвердить',
        uz: ' Tasdiqlash'
      },
      // Оформить заказ
      CheckOutButton: {
        en: ' Check Out',
        ru: ' Оформить заказ',
        uz: ' Rasmiylashtirish'
      }
    }
  };
};
export const CustomerOtherTranslations = {
  Orders: {
    allOrdersDivider: {
      en: 'All orders',
      ru: 'Все заказы',
      uz: 'Barcha buyurtmalar'
    },
    CompletedOrders: {
      en: 'Completed orders',
      ru: 'Завершенные заказы',
      uz: 'Tugallangan buyurtmalar'
    }
  }
};

export const CustomerSiteMapTranslate = {
  mainPage: {
    en: 'Dashboard',
    ru: 'Панель управления',
    uz: 'Boshqaruv paneli'
  }
};
export const CustomerProfilePageTranslate = {
  products: {
    en: 'Products',
    ru: 'Товары',
    uz: 'Mahsulotlar'
  },
  orders: {
    en: 'Orders',
    ru: 'Заказы',
    uz: 'Buyurtmalar'
  },
  settings: {
    en: 'Settings',
    ru: 'Настройки',
    uz: 'Sozlamalar'
  },
  widgets: {
    products: {
      mobile: {
        en: 'Mobile phones',
        ru: 'Мобильные телефоны',
        uz: 'Mobil telefonlar'
      },
      laptops: {
        en: 'Laptops',
        ru: 'Ноутбуки',
        uz: 'Noutbuklar'
      },
      wearable: {
        en: 'Wearables',
        ru: 'Носимые гаджеты',
        uz: 'Taqiladigan buyumlar'
      },
      gaming: {
        en: 'Gaming consoles',
        ru: 'Игровые консоли',
        uz: "O'yin konsollari"
      }
    },
    orders: {
      // My Shopping Cart
      // My Orders
      // My Completed Orders
      myShoppingCart: {
        en: 'My Shopping Cart',
        ru: 'Моя Корзина',
        uz: 'Savatim'
      },
      myOrders: {
        en: 'My orders',
        ru: 'мои заказы',
        uz: 'Mening Buyurtmalarim'
      },
      myCompletedOrders: {
        en: 'My Completed Orders',
        ru: 'Мои завершенные заказы',
        uz: 'Tugallangan buyurtmalarim'
      }
    },
    accountSettings: {
      settings: {
        en: 'Account settings',
        ru: 'Настройки аккаунта',
        uz: 'Hisob sozlamalari'
      }
    }
  }
};
export const CustomerPurchaseLaptopsTranslate = () => {
  const { currency } = useCurrencyStore();
  return {
    table: {
      brand: {
        en: 'Brand',
        ru: 'Бренд',
        uz: 'Brend'
      },
      'Model name': {
        en: 'Model Name',
        ru: 'Модель',
        uz: 'Model'
      },
      'Model number': {
        en: 'Model number',
        ru: 'Номер модели',
        uz: 'Model raqami'
      },
      RAM: {
        en: 'RAM',
        ru: 'ОЗУ',
        uz: 'RAM'
      },
      Storage: {
        en: 'Storage',
        ru: 'Память',
        uz: 'Xotira'
      },
      Processor: {
        en: 'Processor',
        ru: 'Процессор',
        uz: 'Protsessor'
      },
      VideoCard: {
        en: 'Video Card',
        ru: 'Видеокарта',
        uz: 'Video karta'
      },
      Color: {
        en: 'Color',
        ru: 'Цвет',
        uz: 'Rang'
      },
      Specs: {
        en: 'Specs',
        ru: 'Specs',
        uz: 'Specs'
      },

      Price: {
        en: `Price (${currency})`,
        ru: `Цена ${currency}`,
        uz: `Narxi (${currency})`
      },
      Stock: {
        en: 'Quantity in stock',
        ru: 'Количество на складе',
        uz: 'Ombordagi miqdor'
      },
      Quantity: {
        en: 'Quantity',
        ru: 'Количество',
        uz: 'Miqdori'
      }
    }
  };
};
export const CustomerOrdersPageTranslate = () => {
  const { currency } = useCurrencyStore();
  return {
    table: {
      OrderId: {
        en: 'Order Number',
        ru: 'Номер заказа',
        uz: 'Buyurtma raqami'
      },
      OrderDate: {
        en: 'Order date',
        ru: 'Дата заказа',
        uz: 'Buyurtma sanasi'
      },
      ShippingType: {
        en: 'Shipping Type',
        ru: 'Тип доставки',
        uz: 'Yetkazib berish turi'
      },
      TotalCost: {
        en: `Total Cost (${currency})`,
        ru: `Общая стоимость (${currency})`,
        uz: `Umumiy narx (${currency})`
      },
      PaidAmount: {
        en: `Paid amount (${currency})`,
        ru: `Оплаченная сумма (${currency})`,
        uz: `Toʻlangan summa (${currency})`
      },
      RemainingAmount: {
        en: `Remaining amount (${currency})`,
        ru: `Остаток суммы (${currency})`,
        uz: `Qolgan summa (${currency})`
      },
      OrderStatus: {
        en: 'Order status',
        ru: 'Статус заказа',
        uz: 'Buyurtma holati'
      },
      Comment: {
        en: 'Comment',
        ru: 'Комментарий',
        uz: 'Izoh'
      }
    },
    search: {
      en: 'Search...',
      ru: 'Поиск...',
      uz: 'Qidirish...'
    }
  };
};
export const SearchPlaceHolderTranslations = {
  en: 'Search...',
  ru: 'Поиск...',
  uz: 'Qidirish...'
};
export const SupplierPurchaseMobilePhoneTranslate = () => {
  const { currency } = useCurrencyStore();
  return {
    table: {
      brand: {
        en: 'Brand',
        ru: 'Бренд',
        uz: 'Brend'
      },
      Code: {
        en: 'Code',
        ru: 'Code',
        uz: 'Code'
      },
      'Model name': {
        en: 'Model Name',
        ru: 'модели',
        uz: 'Model'
      },
      'Model number': {
        en: 'Model number',
        ru: 'Номер модели',
        uz: 'Model raqami'
      },
      RAM: {
        en: 'RAM',
        ru: 'ОЗУ',
        uz: 'RAM'
      },
      Storage: {
        en: 'Storage',
        ru: 'Память',
        uz: 'Xotira'
      },
      Color: {
        en: 'Color',
        ru: 'Цвет',
        uz: 'Rang'
      },
      Specs: {
        en: 'Specs',
        ru: 'Specs',
        uz: 'Specs'
      },

      Price: {
        en: `Price (${currency})`,
        ru: `Цена ${currency}`,
        uz: `Narxi (${currency})`
      },
      Stock: {
        en: 'Quantity in stock',
        ru: 'Количество на складе',
        uz: 'Ombordagi miqdor'
      },
      Quantity: {
        en: 'Quantity',
        ru: 'Количество',
        uz: 'Miqdori'
      }
    },
    title: {
      mobile: {
        en: 'My Mobile Phones',
        ru: 'Мои мобильные телефоны',
        uz: 'Mening mobil telefonlarim'
      },
      laptops: {
        en: 'My Laptops',
        ru: 'Мои ноутбуки',
        uz: 'Mening Noutbuklarim'
      },
      gaming: {
        en: 'My Gaming consoles',
        ru: 'Мои игровые консоли',
        uz: "Mening o'yin konsollarim"
      },
      wearables: {
        en: 'My Wearables',
        ru: 'Мои носимые устройства',
        uz: 'Mening taqiladigan gadjetlarim'
      }
    }
  };
};

export const SupplierPurchaseGamingConsoleTranslate = () => {
  const { currency } = useCurrencyStore();
  return {
    table: {
      brand: {
        en: 'Brand',
        ru: 'Бренд',
        uz: 'Brend'
      },
      'Model name': {
        en: 'Model Name',
        ru: 'Модель',
        uz: 'Model'
      },
      RAM: {
        en: 'RAM',
        ru: 'ОЗУ',
        uz: 'RAM'
      },
      Storage: {
        en: 'Storage',
        ru: 'Память',
        uz: 'Xotira'
      },
      Processor: {
        en: 'Processor',
        ru: 'Процессор',
        uz: 'Protsessor'
      },
      VideoCard: {
        en: 'Video Card',
        ru: 'Видеокарта',
        uz: 'Video karta'
      },
      Color: {
        en: 'Color',
        ru: 'Цвет',
        uz: 'Rang'
      },
      Specs: {
        en: 'Specs',
        ru: 'Specs',
        uz: 'Specs'
      },

      Price: {
        en: `Price (${currency})`,
        ru: `Цена ${currency}`,
        uz: `Narxi (${currency})`
      },
      Stock: {
        en: 'Quantity in stock',
        ru: 'Количество на складе',
        uz: 'Ombordagi miqdor'
      },
      Quantity: {
        en: 'Quantity',
        ru: 'Количество',
        uz: 'Miqdori'
      }
    }
  };
};

export const SupplierPurchaseWearablesTranslate = () => {
  const { currency } = useCurrencyStore();
  return {
    table: {
      brand: {
        en: 'Brand',
        ru: 'Бренд',
        uz: 'Brend'
      },
      'Model name': {
        en: 'Model Name',
        ru: 'Модель',
        uz: 'Model'
      },
      RAM: {
        en: 'RAM',
        ru: 'ОЗУ',
        uz: 'RAM'
      },
      Storage: {
        en: 'Storage',
        ru: 'Память',
        uz: 'Xotira'
      },
      Processor: {
        en: 'Processor',
        ru: 'Процессор',
        uz: 'Protsessor'
      },
      VideoCard: {
        en: 'Video Card',
        ru: 'Видеокарта',
        uz: 'Video karta'
      },
      Color: {
        en: 'Color',
        ru: 'Цвет',
        uz: 'Rang'
      },
      Specs: {
        en: 'Specs',
        ru: 'Specs',
        uz: 'Specs'
      },

      Price: {
        en: `Price (${currency})`,
        ru: `Цена ${currency}`,
        uz: `Narxi (${currency})`
      },
      Stock: {
        en: 'Quantity in stock',
        ru: 'Количество на складе',
        uz: 'Ombordagi miqdor'
      },
      Quantity: {
        en: 'Quantity',
        ru: 'Количество',
        uz: 'Miqdori'
      }
    }
  };
};

export const SupplierOrdersPageTranslate = {
  table: {
    OrderNumber: {
      en: 'Order Number',
      ru: 'Номер заказа',
      uz: 'Buyurtma raqami'
    },
    OrderDate: {
      en: 'Order Date',
      ru: 'Дата заказа',
      uz: 'Buyurtma sanasi'
    },
    TotalCost: {
      en: 'Total cost',
      ru: 'Общая стоимость',
      uz: 'Umumiy qiymati'
    },
    OrderStatus: {
      en: 'Order Status',
      ru: 'Статус заказа',
      uz: 'Buyurtma holati'
    }
  }
};
export const SupplierOrdersDetailsPageTranslate = {
  table: {
    Products: {
      en: 'Products',
      ru: 'Продукты',
      uz: 'Mahsulotlar'
    },
    RequiredQty: {
      en: 'Required quantity',
      ru: 'Требуемое количество',
      uz: 'Kerakli miqdor'
    },
    DeliveredQty: {
      en: 'Delivered quantity',
      ru: 'Доставленное количество',
      uz: 'Yetkazib berilgan miqdor'
    },
    Status: {
      en: 'Status',
      ru: 'Статус',
      uz: 'Holat'
    },
    Price: {
      en: 'Price per',
      ru: 'Цена',
      uz: 'Narxi'
    },
    subtotal: {
      en: 'Subtotal',
      ru: 'Итого',
      uz: 'Jami'
    }
  }
};
// let template = {
// en: '',
// ru: '',
// uz: ''
// };
