import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import React, { useEffect, useState } from 'react';
import { Card, Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';

import TransactionHistory from './TransactionHistory';
import CreateComment from '../../orders/all orders customer/comment/CreateComment';
import AddDepositComponent from './deposit form/AddDepositeForm';
import RemoveDepositComponent from './deposit form/RemoveDepositeForm';
import axios from 'axios';
import { BASE_URL } from 'constants/url';
import { AiOutlineMessage } from 'react-icons/ai';
import { toast } from 'react-toastify';
import decryptData from 'secret/decryptData';
import DevLog from 'helpers/DevLog';

const CustomerDepositsList = () => {
  const [data, setData] = useState([]);
  const columns = [
    {
      accessor: 'customer_name',
      Header: 'Customer Name',

      Cell: rowData => {
        const { business_name, phone_number } = rowData.row.original.customer;
        const handleCopy = textToCopy => {
          const textarea = document.createElement('textarea');
          textarea.value = textToCopy;
          document.body.appendChild(textarea);
          textarea.select();
          document.execCommand('copy');
          document.body.removeChild(textarea);
          return toast.success(
            `${textToCopy} copied to clipboard successfully`
          );
        };
        return (
          <div className="">
            {business_name} <br />{' '}
            <OverlayTrigger
              placement={'bottom'}
              overlay={
                <Tooltip
                  style={{ position: 'fixed' }}
                  id={'tooltip-' + 'bottom'}
                >
                  Copy to clipboard
                </Tooltip>
              }
            >
              <b
                onClick={() =>
                  handleCopy(
                    phone_number.split('')[0] !== '+'
                      ? `+${phone_number}`
                      : phone_number
                  )
                }
                style={{ fontSize: '12px', cursor: 'copy' }}
              >
                {phone_number.split('')[0] !== '+'
                  ? `+${phone_number}`
                  : phone_number}
              </b>
            </OverlayTrigger>
          </div>
        );
      }

      // Update header
    },
    {
      accessor: 'deposit_amount', // Update accessor
      Header: 'Deposit' // Update header
    },
    {
      accessor: 'created_at', // Update accessor
      Header: 'Date/Time',
      Cell: rowData => {
        const { created_at } = rowData.row.original;
        return new Date(created_at).toLocaleDateString();
      }
      // Update header
    },
    {
      accessor: 'add_amount', // Update accessor
      Header: 'Add Amount',
      Cell: rowData => {
        const { deposit_id } = rowData.row.original;
        const order = rowData.row;
        return (
          <AddDepositComponent
            order={order}
            data={data}
            setData={setData}
            id={deposit_id}
          />
        );
      }
      // Update header
    },
    {
      accessor: 'remove_amount', // Update accessor
      Header: 'Remove Amount',
      Cell: rowData => {
        const { deposit_id, deposit_amount } = rowData.row.original;
        const order = rowData.row;

        return (
          <RemoveDepositComponent
            order={order}
            data={data}
            setData={setData}
            minValue={parseFloat(deposit_amount)}
            id={deposit_id}
          />
        );
      }
    },
    {
      accessor: 'add_comment', // Update accessor
      Header: 'View Comment',
      Cell: rowData => {
        const { deposit_id } = rowData.row.original;

        return (
          <CreateComment
            icon={
              <span>
                <AiOutlineMessage className="cursor-pointer" size={25} />
              </span>
            }
            title={'Transactions'}
          >
            <TransactionHistory id={deposit_id} />
          </CreateComment>
        );
      }
      // Update header
    }
  ];
  const fetchDeposits = async () => {
    await axios
      .get(BASE_URL + '/deposits/?user_type=customer', {
        headers: {
          Authorization: `JWT ${decryptData('token')}`
        }
      })
      .then(r => {
        DevLog({ r });
        setData(r.data);
      })
      .catch(err => {
        DevLog({ err });
      });
  };

  useEffect(() => {
    fetchDeposits();
    return () => {};
  }, []);
  return (
    <Card className="p-4">
      <h3>Customer deposits</h3>
      <div className="w-lg-90">
        <AdvanceTableWrapper
          columns={columns}
          data={data}
          sortable
          pagination
          perPage={5}
        >
          <Row className="flex-end-center mb-3">
            <Col xs="auto" sm={6} lg={4}>
              <AdvanceTableSearchBox table />
            </Col>
          </Row>
          <AdvanceTable
            table
            headerClassName="bg-200 text-900 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              bordered: true,
              striped: true,
              className: 'fs--1 mb-0 overflow-hidden',
              style: {
                tableLayout: 'auto'
                // Add this line
              }
            }}
          />
          <div className="mt-3">
            <AdvanceTableFooter
              rowCount={data.length}
              table
              rowInfo
              navButtons
              rowsPerPageSelection
            />
          </div>
        </AdvanceTableWrapper>
      </div>
    </Card>
  );
};

export default CustomerDepositsList;
