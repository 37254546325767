import React, { useState } from 'react';
import ShippingContext from './ShippingContext';
import PropTypes from 'prop-types';

const ShippingProvider = ({ children }) => {
  const [data, setData] = useState({ name: '', price: 0, vat: 0 });
  return (
    <ShippingContext.Provider value={{ data: JSON.stringify(data), setData }}>
      {children}
    </ShippingContext.Provider>
  );
};

ShippingProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default ShippingProvider;
