import React, { useState } from 'react';
import { Form, Button, Row, Col, Card } from 'react-bootstrap';
import axios from 'axios';
import { BASE_URL } from 'constants/url';
import { storageOptions, topColors } from 'constants/colors';
import { GamingConsoleExamples, regions } from 'constants/products';
import { toast } from 'react-toastify';
import { gamingConsoleWeightsInKg } from 'constants/gamingConsoleWeightsInKg';
import { Link } from 'react-router-dom';
import { Button as AntButton } from 'antd';
import decryptData from 'secret/decryptData';
import DevLog from 'helpers/DevLog';
const CreateGamingConsole = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [gamingConsole, setGamingConsole] = useState({
    brand: '',
    model_name: '',
    model_number: '',
    storage: '',
    color: '',
    specs: '',
    code: '',
    weight: '',
    category: JSON.parse(localStorage.getItem('gamingCategoryId'))
  });

  const handleChange = e => {
    const { name, value } = e.target;
    setGamingConsole(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setIsLoading(true);

    await axios
      .post(`${BASE_URL}/dashboard/product/add/`, gamingConsole, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `JWT ${decryptData('token')}`
        }
      })
      .then(response => {
        DevLog(response);
        if (response.status === 201 && response.status == 201) {
          setGamingConsole({
            brand: '',
            model_name: '',
            model_number: '',
            storage: '',
            color: '',
            specs: '',
            code: '',
            category: JSON.parse(localStorage.getItem('gamingCategoryId'))
          });
          const form = e.target;
          form.reset();
          setIsLoading(false);
          return toast.success(
            `New Gaming Console has been successfully created and uploaded to the server`,
            {
              theme: 'colored',
              position: 'top-right',
              autoClose: false
            }
          );
        }
      })
      .catch(err => {
        DevLog(err);
        setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Card className="p-4 text-800">
      <span className="text-900 fs-4 mb-4">Upload Gaming Console</span>
      <div className="d-flex justify-content-end">
        <Link to={'/admin/e-commerce/product/gaming_consoles'}>
          <AntButton type="primary">All Gaming Consoles</AntButton>
        </Link>
      </div>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col md={6}>
            <Form.Group controlId="brand">
              <Form.Label>Brand:</Form.Label>
              <Form.Select
                onChange={handleChange}
                name="brand"
                aria-label="Default select example"
              >
                {GamingConsoleExamples.map((option, i) => (
                  <option key={i} value={option}>
                    {option}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="model_name">
              <Form.Label>Model Name:</Form.Label>
              <Form.Control
                className="mb-2"
                type="text"
                name="model_name"
                value={gamingConsole.model_name}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Form.Group controlId="model_number">
              <Form.Label>Model Number</Form.Label>
              <Form.Control
                className="mb-2"
                type="text"
                name="model_number"
                value={gamingConsole.model_number}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="storage">
              <Form.Label>Storage</Form.Label>
              <Form.Select
                onChange={handleChange}
                name="storage"
                aria-label="Default select example"
              >
                {storageOptions.map((option, i) => (
                  <option key={i} value={option}>
                    {option}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Form.Group controlId="color">
              <Form.Label>Color</Form.Label>
              <Form.Select
                onChange={handleChange}
                name="color"
                aria-label="Default select example"
              >
                {topColors.map((option, i) => (
                  <option key={i} value={option}>
                    {option}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="specs">
              <Form.Label>Specs</Form.Label>
              <Form.Select
                onChange={handleChange}
                name="specs"
                aria-label="Default select example"
              >
                {regions.map((option, i) => (
                  <option key={i} value={option}>
                    {option}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Form.Group controlId="code">
              <Form.Label>Code:</Form.Label>
              <Form.Control
                className="mb-2"
                type="text"
                name="code"
                value={gamingConsole.code}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="weight">
              <Form.Label>Weight</Form.Label>
              <Form.Select
                onChange={handleChange}
                name="weight"
                aria-label="Default select example"
              >
                {gamingConsoleWeightsInKg.map((option, i) => (
                  <option key={i} value={option}>
                    {option} KG
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Form.Group className="mt-2" controlId="shippingCost">
              <Form.Label>Price per unit</Form.Label>
              <Form.Control
                onChange={handleChange}
                name="price_per_unit"
                type="number"
                required
              />
            </Form.Group>
          </Col>
        </Row>
        <Button
          className="my-3"
          type="submit"
          variant="primary"
          disabled={isLoading}
        >
          {isLoading ? 'Uploading...' : 'Upload gaming console'}
        </Button>
      </Form>
    </Card>
  );
};

export default CreateGamingConsole;
