/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { TbTruckDelivery } from 'react-icons/tb';
import { GiCheckMark, GiReceiveMoney } from 'react-icons/gi';
import { MdPendingActions } from 'react-icons/md';
import { AiOutlineStop } from 'react-icons/ai';
import { BsClockHistory } from 'react-icons/bs';
import SoftBadge from 'components/common/SoftBadge';
import PageHeader from 'components/common/PageHeader';
import { customerOrderNumberGenerator } from 'helpers/customerOrderNumberGenerator';

import { useNavigate } from 'react-router-dom';
import OrderedProductsAdminSupplier from './Order detail/OrderedProductsAdminSupplier';
import OrderInfoAdminSupplier from './Order detail/OrderInfoAdminSupplier';
import OrderInfoCustomer from 'components/app/e-commerce customers/orders/Order detail/OrderInfoCustomer';
import { Card, Col, Row } from 'react-bootstrap';
import { copyToClipBoard } from 'helpers/utils';
import axios from 'axios';
import { BASE_URL } from 'constants/url';
import { toast } from 'react-toastify';
import decryptData from 'secret/decryptData';
import DevLog from 'helpers/DevLog';
const AdminOrderDetailsSupplier = () => {
  const navigate = useNavigate();
  const location = useLocation().state;
  const { orderId } = useParams();
  const {
    supplier_order_status,
    total_cost,
    payment_type,
    bank_receipt_file,
    shipping_fee,
    date,
    supplier_order_id,
    x
  } = location;
  DevLog({
    location
  });
  const changeOrderStatusHandler = async () => {
    await axios
      .patch(
        BASE_URL + `/dashboard/supplier/order/update/${orderId}/`,
        { is_new: false },
        {
          headers: {
            Authorization: `JWT ${decryptData('token')}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then(res => {
        return toast.success(`This order marked as read, it is no longer new`, {
          theme: 'colored',
          position: 'top-right',
          autoClose: true
        });
      })
      .catch(err => DevLog(err));
  };

  useEffect(() => {
    changeOrderStatusHandler();
  }, []);
  return (
    <>
      <PageHeader
        title={`Order Number: #${customerOrderNumberGenerator(
          supplier_order_id
        )}`}
        titleTag="h5"
        go_back={() => {
          navigate('/admin/e-commerce/supplier/orders/all-orders');
        }}
        className="mb-3 text-700 text-primary"
      >
        <p className="fs--1 mt-1">{String(date)}</p>
        <div>
          <strong className="me-2 text-capitalize">
            Status: {supplier_order_status.replace(/_/g, ' ')}{' '}
          </strong>
        </div>
      </PageHeader>
      <Card className="mb-3 text-700">
        <Card.Body>
          <Row>
            <Col md={6} lg={4} className="mb-4 mb-lg-0">
              <p className="mb-1 fs--1">Business Name: {x.business_name}</p>
              <p className="mb-1 fs--1">Phone Number: {x.phone_number}</p>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <OrderedProductsAdminSupplier
        shipping_fee={shipping_fee}
        supplier_order_id={supplier_order_id}
        total_cost={total_cost}
      />
    </>
  );
};

export default AdminOrderDetailsSupplier;
