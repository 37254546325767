/* eslint-disable react/prop-types */
import React, { useContext } from 'react';
import { Select } from 'antd';
import { useLanguage } from 'provider/LanguageProvider';
import AppContext from 'context/Context';

const { Option } = Select;

const LanguageSelector = () => {
  const { changeLanguage, language } = useLanguage();
  const handleLanguageChange = value => {
    // Call the onChange callback with the selected language
    // onChange(value);
    changeLanguage(value);
  };
  const {
    config: { isDark }
  } = useContext(AppContext);
  return (
    <>
      <Select
        defaultValue={language}
        style={{}}
        className="rounded-5"
        onChange={handleLanguageChange}
      >
        <Option value="uz">UZ</Option>
        <Option value="en">EN</Option>
        <Option value="ru">RU</Option>
        {/* Add more language options as needed */}
      </Select>
      <style>
        {`.ant-select-selector {
                background-color: ${isDark ? '#36454F' : 'white'} !important;
                color: ${isDark ? 'white' : 'black'} !important;
            }`}
      </style>
    </>
  );
};

export default LanguageSelector;
