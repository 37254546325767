/* eslint-disable no-unused-vars */
import axios from 'axios';
import { BASE_URL } from 'constants/url';
import DevLog from 'helpers/DevLog';
import React, { useState } from 'react';
import {
  Card,
  Form,
  FormControl,
  Button,
  Row,
  Col,
  Spinner
} from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import decryptData from 'secret/decryptData';

const CustomersPageWithComments = props => {
  const { state } = useLocation();
  const { customer_id, id } = state;
  const userData = { ...state, ...state.customer };
  const [formData, setFormData] = useState(userData);
  const [editing, setEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const changeUserStatus = async status => {
    const response = confirm("Are you sure you want to change user's status");
    if (response) {
      setIsLoading(true);
      await axios
        .patch(BASE_URL + `/dashboard/customers/${id}/`, status, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `JWT ${decryptData('token')}`
          }
        })
        .then(r => {
          DevLog({ r });
          if (r.status === 200) {
            window.history.back();
            return toast.success(
              `User's status  has been successfully updated `,
              {
                theme: 'colored',
                position: 'top-right',
                autoClose: true
              }
            );
          }
        })
        .catch(err => {
          DevLog(err);
          toast.error(`Something went wrong! ${err.message} `, {
            theme: 'colored',
            position: 'top-right',
            autoClose: false
          });
          setIsLoading(false);
        })
        .finally(() => setIsLoading(false));
    } else {
      setIsLoading(false);
    }
  };
  const updateCommentsInSuppliersInfo = async () => {
    await axios
      .patch(
        BASE_URL + `/dashboard/customers/info/update/${customer_id}/ `,
        { ...submittedValue },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `JWT ${decryptData('token')}`
          }
        }
      )
      .then(r => {
        DevLog({ a: r });
        if (r.status === 200) {
          return toast.success(`Commented successfully `, {
            theme: 'colored',
            position: 'top-right',
            autoClose: false
          });
        }
      })
      .catch(err => {
        DevLog(err);
      });
  };
  const {
    account_number_comment,
    bank_address_comment,
    bank_name_comment,
    business_address_comment,
    business_name_comment,
    contact_person_comment,
    country_comment,
    email,
    iban_comment,
    phone_number_comment,
    swift_code_comment,
    vat_number_comment
  } = state;
  const [submittedValue, setSubmittedValue] = useState({
    account_number_comment,
    bank_address_comment,
    bank_name_comment,
    business_address_comment,
    business_name_comment,
    contact_person_comment,
    country_comment,
    email,
    iban_comment,
    phone_number_comment,
    swift_code_comment,
    vat_number_comment
  });
  const handleSubmit = e => {
    e.preventDefault();
    DevLog({ formData });
    DevLog('Submitted values:', { ...submittedValue });
    changeUserStatus(formData);
    updateCommentsInSuppliersInfo();
  };

  DevLog({ state });
  return (
    <div style={{ padding: '20px' }}>
      <Card>
        <Card.Header as="h5">Customer Information</Card.Header>
        <Card.Body>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col sm={12} md={12} lg={12} xxl={12}>
                <Form.Group controlId="userStatus">
                  <Form.Label className="text-900 mt-3">User Status</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={e => setFormData({ user_status: e.target.value })}
                    disabled={!editing}
                  >
                    <option value="APPROVED">Approve</option>
                    <option value="PENDING">Pend</option>
                    <option value="UPDATE_REQUIRED">Update required</option>
                    <option value="UPDATED">Updated</option>
                    <option value="BLOCKED">Block</option>
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col sm={12} md={12} lg={12} xxl={12}>
                <Row>
                  <Col sm={12} md={6} lg={6} xxl={6}>
                    <Form.Group controlId="businessName">
                      <Form.Label className="text-900 mt-3">
                        Business Name
                      </Form.Label>
                      <FormControl
                        type="text"
                        value={formData.business_name}
                        disabled={true}
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={12} md={6} lg={6} xxl={6}>
                    <Form.Group controlId="businessName">
                      <Form.Label className="text-900 mt-3">
                        Business Name Comment
                      </Form.Label>
                      <FormControl
                        type="text"
                        onChange={e =>
                          setSubmittedValue({
                            ...submittedValue,
                            business_name_comment: e.target.value
                          })
                        }
                        value={submittedValue.business_name_comment || ''}
                        disabled={!editing}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
              <Col sm={12} md={12} lg={12} xxl={12}>
                <Row>
                  <Col sm={12} md={6} lg={6} xxl={6}>
                    <Form.Group controlId="businessName">
                      <Form.Label className="text-900 mt-3">
                        Bank Name
                      </Form.Label>
                      <FormControl
                        type="text"
                        value={formData.bank_name}
                        disabled={true}
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={12} md={6} lg={6} xxl={6}>
                    <Form.Group controlId="businessName">
                      <Form.Label className="text-900 mt-3">
                        Bank Name Comment
                      </Form.Label>
                      <FormControl
                        type="text"
                        onChange={e =>
                          setSubmittedValue({
                            ...submittedValue,
                            bank_name_comment: e.target.value
                          })
                        }
                        value={submittedValue.bank_name_comment || ''}
                        disabled={!editing}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={12} lg={12} xxl={12}>
                <Row>
                  <Col sm={12}>
                    <Form.Group controlId="businessAddress">
                      <Form.Label className="text-900 mt-3">Email</Form.Label>
                      <FormControl
                        type="text"
                        value={formData.email}
                        disabled={true}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} md={6} lg={6} xxl={12}>
                    <Form.Group controlId="businessAddress">
                      <Form.Label className="text-900 mt-3">
                        Business Address
                      </Form.Label>
                      <FormControl
                        type="text"
                        value={formData.business_address}
                        disabled={true}
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={12} md={6} lg={6} xxl={12}>
                    <Form.Group controlId="businessAddress">
                      <Form.Label className="text-900 mt-3">
                        Business Address Comment
                      </Form.Label>
                      <FormControl
                        type="text"
                        onChange={e =>
                          setSubmittedValue({
                            ...submittedValue,
                            business_address_comment: e.target.value
                          })
                        }
                        value={submittedValue.business_address_comment || ''}
                        disabled={!editing}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
              <Col sm={12} md={12} lg={12} xxl={12}>
                <Row>
                  <Col sm={12} md={6} lg={6} xxl={12}>
                    <Form.Group controlId="contactPerson">
                      <Form.Label className="text-900 mt-3">
                        Contact Person
                      </Form.Label>
                      <FormControl
                        type="text"
                        value={formData.contact_person}
                        disabled={true}
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={12} md={6} lg={6} xxl={12}>
                    <Form.Group controlId="contactPerson">
                      <Form.Label className="text-900 mt-3">
                        Contact Person Comment
                      </Form.Label>
                      <FormControl
                        type="text"
                        onChange={e =>
                          setSubmittedValue({
                            ...submittedValue,
                            contact_person_comment: e.target.value
                          })
                        }
                        disabled={!editing}
                        value={submittedValue.contact_person_comment || ''}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={12} lg={12} xxl={12}>
                <Row>
                  <Col sm={12} md={6} lg={6} xxl={12}>
                    <Form.Group controlId="country">
                      <Form.Label className="text-900 mt-3">Country</Form.Label>
                      <FormControl
                        type="text"
                        value={formData.country}
                        disabled={true}
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={12} md={6} lg={6} xxl={12}>
                    <Form.Group controlId="country">
                      <Form.Label className="text-900 mt-3">
                        Country Comment
                      </Form.Label>
                      <FormControl
                        onChange={e =>
                          setSubmittedValue({
                            ...submittedValue,
                            country_comment: e.target.value
                          })
                        }
                        type="text"
                        value={submittedValue.country_comment || ''}
                        disabled={!editing}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
              <Col sm={12} md={12} lg={12} xxl={12}>
                <Row>
                  <Col sm={12} md={6} lg={6} xxl={12}>
                    <Form.Group controlId="phoneNumber">
                      <Form.Label className="text-900 mt-3">
                        Phone Number
                      </Form.Label>
                      <FormControl
                        type="text"
                        value={formData.phone_number}
                        disabled={true}
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={12} md={6} lg={6} xxl={12}>
                    <Form.Group controlId="phoneNumber">
                      <Form.Label className="text-900 mt-3">
                        Phone Number Comment
                      </Form.Label>
                      <FormControl
                        type="text"
                        onChange={e =>
                          setSubmittedValue({
                            ...submittedValue,
                            phone_number_comment: e.target.value
                          })
                        }
                        value={submittedValue.phone_number_comment || ''}
                        disabled={!editing}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={12} lg={12} xxl={12}>
                <Row>
                  <Col sm={12} md={6} lg={6} xxl={12}>
                    <Form.Group controlId="vatNumber">
                      <Form.Label className="text-900 mt-3">
                        VAT Number
                      </Form.Label>
                      <FormControl
                        type="text"
                        value={formData.vat_number}
                        disabled={true}
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={12} md={6} lg={6} xxl={12}>
                    <Form.Group controlId="vatNumber">
                      <Form.Label className="text-900 mt-3">
                        VAT Number Comment
                      </Form.Label>
                      <FormControl
                        type="text"
                        onChange={e =>
                          setSubmittedValue({
                            ...submittedValue,
                            vat_number_comment: e.target.value
                          })
                        }
                        value={submittedValue.vat_number_comment || ''}
                        disabled={!editing}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
              <Col sm={12} md={12} lg={12} xxl={12}>
                <Row>
                  <Col sm={12} md={6} lg={6} xxl={12}>
                    <Form.Group controlId="bankAddress">
                      <Form.Label className="text-900 mt-3">
                        Bank Address
                      </Form.Label>
                      <FormControl
                        type="text"
                        value={formData.bank_address}
                        disabled={true}
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={12} md={6} lg={6} xxl={12}>
                    <Form.Group controlId="bankAddress">
                      <Form.Label className="text-900 mt-3">
                        Bank Address Comment
                      </Form.Label>
                      <FormControl
                        type="text"
                        onChange={e =>
                          setSubmittedValue({
                            ...submittedValue,
                            bank_address_comment: e.target.value
                          })
                        }
                        value={submittedValue.bank_address_comment || ''}
                        disabled={!editing}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={12} lg={12} xxl={12}>
                <Row>
                  <Col sm={12} md={6} lg={6} xxl={12}>
                    <Form.Group controlId="bankAccountNumber">
                      <Form.Label className="text-900 mt-3">
                        Bank Account Number
                      </Form.Label>
                      <FormControl
                        type="text"
                        value={formData.account_number}
                        disabled={true}
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={12} md={6} lg={6} xxl={12}>
                    <Form.Group controlId="bankAccountNumber">
                      <Form.Label className="text-900 mt-3">
                        Bank Account Number Comment
                      </Form.Label>
                      <FormControl
                        type="text"
                        onChange={e =>
                          setSubmittedValue({
                            ...submittedValue,
                            account_number_comment: e.target.value
                          })
                        }
                        value={submittedValue.account_number_comment || ''}
                        disabled={!editing}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
              <Col sm={12} md={12} lg={12} xxl={12}>
                <Row>
                  <Col sm={12} md={6} lg={6} xxl={12}>
                    <Form.Group controlId="swiftCode">
                      <Form.Label className="text-900 mt-3">
                        SWIFT Code
                      </Form.Label>
                      <FormControl
                        type="text"
                        value={formData.swift_code}
                        disabled={true}
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={12} md={6} lg={6} xxl={12}>
                    <Form.Group controlId="swiftCode">
                      <Form.Label className="text-900 mt-3">
                        SWIFT Code Comment
                      </Form.Label>
                      <FormControl
                        type="text"
                        onChange={e =>
                          setSubmittedValue({
                            ...submittedValue,
                            swift_code_comment: e.target.value
                          })
                        }
                        value={submittedValue.swift_code_comment || ''}
                        disabled={!editing}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col>
                <Row>
                  <Col sm={12} md={6} lg={6} xxl={12}>
                    {' '}
                    <Form.Group controlId="ibanNumber">
                      <Form.Label className="text-900 mt-3">
                        IBAN Number
                      </Form.Label>
                      <FormControl
                        type="text"
                        value={formData.iban}
                        onChange={e =>
                          setFormData({
                            ...formData,
                            iban_number: e.target.value
                          })
                        }
                        disabled={true}
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={12} md={6} lg={6} xxl={12}>
                    {' '}
                    <Form.Group controlId="ibanNumber">
                      <Form.Label className="text-900 mt-3">
                        IBAN Number Comment
                      </Form.Label>
                      <FormControl
                        type="text"
                        onChange={e =>
                          setSubmittedValue({
                            ...submittedValue,
                            iban_comment: e.target.value
                          })
                        }
                        value={submittedValue.iban_comment || ''}
                        disabled={!editing}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </Row>

            <br />
            <Button
              variant="primary"
              type="submit"
              className="me-2"
              disabled={!editing}
            >
              {isLoading ? (
                <>
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />{' '}
                  Saving...
                </>
              ) : (
                'Save'
              )}
            </Button>
            {editing ? (
              <Button variant="secondary" onClick={() => setEditing(false)}>
                Cancel
              </Button>
            ) : (
              <Button onClick={() => setEditing(true)}>Edit</Button>
            )}
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
};

export default CustomersPageWithComments;
