import classNames from 'classnames';
import Footer from 'components/footer/Footer';
import NavbarTop from 'components/navbar/top/NavbarTop';
import AppContext from 'context/Context';
import React, { useContext, useEffect } from 'react';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
// import ProductProvider from 'components/app/e-commerce admin/ProductProvider';
import axios from 'axios';
import { GamingCartProvider } from 'components/app/e-commerce suppliers/product/upload/Gaming/GamingCartContext';
import { CartProvider } from 'components/app/e-commerce suppliers/product/upload/laptop/LaptopCartContext';
import { MobileCartProvider } from 'components/app/e-commerce suppliers/product/upload/mobile/MobileCartContext';
import { WearableCartProvider } from 'components/app/e-commerce suppliers/product/upload/wearable/WearableCartContext';
import ScrollToTopButton from 'components/common/scroll-to-top/ScrollToTopButton';
import NavbarVerticalSuppliers from 'components/navbar/vertical/supplier/NavbarVerticalSupplier';
import { BASE_URL } from 'constants/url';
import useScreenSize from 'hooks/useScreenSize';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { AiFillHome, AiTwotonePhone } from 'react-icons/ai';
import { toast } from 'react-toastify';
import decryptData from 'secret/decryptData';
import useUserStore from 'state/userStore';
import CheckUserStatus from 'helpers/CheckUser';
import DevLog from 'helpers/DevLog';
import NavigationButtons from './navigation_btn/NavigationButtons';

const MainLayoutSuppliers = () => {
  const { hash, pathname } = useLocation();
  const navigate = useNavigate();
  const { userStatus } = useUserStore();
  DevLog({ stoorree: userStatus });
  const isKanban = pathname.includes('kanban');

  const {
    config: { navbarPosition }
  } = useContext(AppContext);

  useEffect(() => {
    CheckUserStatus();
    if (
      decryptData('data-user') !== null ||
      decryptData('data-user') !== '' ||
      decryptData('data-user') !== undefined
    ) {
      if (userStatus == 'BLANK' || userStatus == 'UPDATE_REQUIRED') {
        navigate('/supplier/user/settings');
      } else if (userStatus == 'PENDING') {
        navigate('/under-review');
      } else if (userStatus == 'BLOCKED') {
        navigate('/blocked');
      } else if (userStatus == 'UPDATED') {
        navigate('/updated');
      }
      if (decryptData('data-user').user_type !== 'supplier') {
        toast.error(`You have to login`, {
          theme: 'colored'
        });
        navigate('/');
      }
    } else {
      navigate('/');
    }
    setTimeout(() => {
      if (hash) {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ block: 'start', behavior: 'smooth' });
        }
      }
    }, 0);
  }, []);
  const fetchCategories = async () => {
    await axios
      .get(BASE_URL + '/store/categories/', {
        headers: {
          Authorization: `JWT ${decryptData('token')}`,
          'Content-Type': 'application/json'
        }
      })
      .then(r => {
        try {
          r.data?.map(data => {
            if (data.category_name == 'Mobile') {
              DevLog({ data, id: data.category_id });
              localStorage.setItem(
                'mobileCategoryId',
                JSON.stringify(data.category_id)
              );
            } else if (data.category_name == 'Laptop') {
              DevLog({ data, id: data.category_id });

              localStorage.setItem(
                'laptopCategoryId',
                JSON.stringify(data.category_id)
              );
            } else if (data.category_name == 'Gaming') {
              DevLog({ data, id: data.category_id });

              localStorage.setItem(
                'gamingCategoryId',
                JSON.stringify(data.category_id)
              );
            } else if (data.category_name == 'Wearable') {
              DevLog({ data, id: data.category_id });

              localStorage.setItem(
                'wearableCategoryId',
                JSON.stringify(data.category_id)
              );
            }
          });
        } catch (error) {
          DevLog({ error });
        }
      })
      .catch(er => DevLog({ er }));
  };
  useEffect(() => {
    fetchCategories();
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className={'container-fluid position-relative'}>
      {(navbarPosition === 'vertical' || navbarPosition === 'combo') && (
        <NavbarVerticalSuppliers />
      )}
      <WearableCartProvider>
        <MobileCartProvider>
          <GamingCartProvider>
            <CartProvider>
              <div className={classNames('content', { 'pb-0': isKanban })}>
                <NavbarTop />
                {/*------ Main Routes ------*/}
                <NavigationButtons />
                <Outlet />
                <ScrollToTopButton />
                {!isKanban && <Footer />}
              </div>
            </CartProvider>
          </GamingCartProvider>
        </MobileCartProvider>
      </WearableCartProvider>
      <Link to={'/supplier/user/profile'}>
        <Button
          style={{
            bottom: '20px',
            right: '20px'
          }}
          className={`rounded-5 ${
            useScreenSize() == 'mobile' ? 'p-2' : 'p-3'
          } position-fixed z-3 `}
        >
          <AiFillHome
            className="text-white"
            size={useScreenSize() == 'mobile' ? 20 : 30}
          />
        </Button>
      </Link>
      <Link to={'tel:+998900174290'}>
        <OverlayTrigger
          placement={'left'}
          overlay={
            <Tooltip style={{ position: 'fixed' }} id={'tooltip-left'}>
              Call to customer service <br /> +998 90 017 42 90
            </Tooltip>
          }
        >
          <Button
            variant="success"
            style={{
              bottom: useScreenSize() == 'mobile' ? '70px' : '90px',
              right: '20px'
            }}
            className={`rounded-5 ${
              useScreenSize() == 'mobile' ? 'p-2' : 'p-3'
            } position-fixed z-3 `}
          >
            <AiTwotonePhone
              className="text-white"
              size={useScreenSize() == 'mobile' ? 20 : 30}
            />
          </Button>
        </OverlayTrigger>
      </Link>
    </div>
  );
};

export default MainLayoutSuppliers;
