import React, { useEffect, useState } from 'react';
import WeeklySales from './WeeklySales';
import { Row, Col, Card } from 'react-bootstrap';
import {
  marketShare,
  totalOrder,
  weeklySalesData
} from 'data/dashboard/default';

import TotalOrder from './TotalOrder';
import MarketShare from './MarketShare';
import WidgetCard from 'components/common/widget/menu item card/WidgetCard';
import WidgetList from 'constants/widgetList';
import axios from 'axios';
import { BASE_URL } from 'constants/url';
import decryptData from 'secret/decryptData';
import DevLog from 'helpers/DevLog';

const Dashboard = () => {
  const [counts, setCounts] = useState({
    mobile_phones: 0,
    laptop: 0,
    gaming: 0,
    wearable: 0,
    total_customer_orders: 0,
    new_customer_orders: 0,
    total_supplier_orders: 0,
    new_supplier_orders: 0,
    customers: 0,
    supplier: 6
  });
  const url = '/dashboard/counts/';
  const fetchCount = async () => {
    await axios
      .get(BASE_URL + url, {
        headers: {
          Authorization: `JWT ${decryptData('token')}`
        }
      })
      .then(res => {
        DevLog(res);
        setCounts(res.data);
      })
      .catch(err => {
        DevLog(err);
      })
      .finally(() => {});
  };
  useEffect(() => {
    fetchCount();
  }, []);
  return (
    <>
      <Row className="g-3 mb-3">
        <Col md={4} xxl={4}>
          <WeeklySales data={weeklySalesData} />
        </Col>
        <Col md={4} xxl={4}>
          <TotalOrder data={totalOrder} />
        </Col>
        <Col md={4} xxl={4}>
          <MarketShare data={marketShare} radius={['100%', '87%']} />
        </Col>
      </Row>
      <Card className="p-3 my-2">
        <h5 className="mx-2">Shipping</h5>
        <Row>
          {WidgetList().shipping.map((widget, index) => {
            return (
              <Col key={index} lg={2} xl={2} sm={6}>
                <WidgetCard
                  oldCount={'Not available'}
                  isCountNeeded={false}
                  gradientColors={widget.gradientColors}
                  color={widget.color}
                  widgetName={widget.widgetName}
                  widgetLink={widget.widgetLink}
                  // Add any additional props you need
                >
                  {widget.children}
                </WidgetCard>
              </Col>
            );
          })}
        </Row>
      </Card>
      <Card className="p-3 my-2">
        <h5 className="mx-2">Products</h5>
        <Row>
          {WidgetList().products.map((widget, index) => {
            return (
              <Col key={index} xl={2} lg={2} sm={6}>
                <WidgetCard
                  isCountNeeded
                  oldCount={counts[widget.name]}
                  gradientColors={widget.gradientColors}
                  color={widget.color}
                  widgetName={widget.widgetName}
                  widgetLink={widget.widgetLink}
                  // Add any additional props you need
                >
                  {widget.children}
                </WidgetCard>
              </Col>
            );
          })}
        </Row>
      </Card>

      <Card className="p-3 my-2">
        <h5 className="mx-2">Orders</h5>
        <Row>
          {WidgetList().order.map((widget, index) => {
            return (
              <Col xxl={2} key={index} lg={2} sm={6}>
                <WidgetCard
                  isCountNeeded
                  isNew={true}
                  oldCount={counts[widget.name_old]}
                  newCount={counts[widget.name_new]}
                  gradientColors={widget.gradientColors}
                  color={widget.color}
                  widgetName={widget.widgetName}
                  widgetLink={widget.widgetLink}
                  // Add any additional props you need
                >
                  {widget.children}
                </WidgetCard>
              </Col>
            );
          })}
        </Row>
      </Card>
      <Card className="p-3 my-2">
        <h5 className="mx-2">Deposit</h5>
        <Row>
          {WidgetList().deposit.map((widget, index) => {
            return (
              <Col xl={2} key={index} lg={2} sm={6}>
                <WidgetCard
                  gradientColors={widget.gradientColors}
                  color={widget.color}
                  widgetName={widget.widgetName}
                  widgetLink={widget.widgetLink}
                  // Add any additional props you need
                >
                  {widget.children}
                </WidgetCard>
              </Col>
            );
          })}
        </Row>
      </Card>
      <Card className="p-3 my-2">
        <h5 className="mx-2">Users</h5>
        <Row>
          {WidgetList().users.map((widget, index) => {
            return (
              <Col xl={2} key={index} lg={2} sm={6}>
                <WidgetCard
                  isCountNeeded
                  newCount={counts[widget.name_new]}
                  oldCount={counts[widget.name_old]}
                  gradientColors={widget.gradientColors}
                  color={widget.color}
                  widgetName={widget.widgetName}
                  widgetLink={widget.widgetLink}
                  // Add any additional props you need
                >
                  {widget.children}
                </WidgetCard>
              </Col>
            );
          })}
        </Row>
      </Card>
    </>
  );
};

export default Dashboard;
