// src/components/PrivacyPolicy.js
import React from 'react';
import Container from 'react-bootstrap/Container';

const PrivacyPolicy = () => {
  return (
    <Container>
      <h1>Privacy Policy</h1>
      <p>
        At ATG, we take your privacy seriously. This Privacy Policy describes
        the information we collect, how it is used, and your choices regarding
        your personal data. By using our services, you consent to the practices
        described in this policy.
      </p>
      <h2>Information We Collect</h2>
      <p>
        We collect information that you provide directly to us, such as when you
        create an account, fill out a form, or contact us.
      </p>
      <h2>How We Use Your Information</h2>
      <p>
        We may use the information we collect for various purposes, including to
        provide and maintain our services, improve and personalize your
        experience, and send you important notices.
      </p>
      {/* Add more privacy policy content as needed */}
    </Container>
  );
};

export default PrivacyPolicy;
