export const mobileBrands = [
  'Apple',
  'Samsung',
  'Huawei',
  'Xiaomi',
  'Oppo',
  'Vivo',
  'OnePlus',
  'Google',
  'LG',
  'Sony',
  'Motorola',
  'Nokia',
  'HTC',
  'Lenovo',
  'ASUS',
  'BlackBerry',
  'Alcatel',
  'ZTE',
  'TCL',
  'Realme',
  'Honor',
  'Infinix',
  'Micromax',
  'Meizu',
  'Lava',
  'Gionee',
  'Panasonic',
  'LeEco',
  'Coolpad',
  'Sharp'
];

export const laptopBrands = [
  '',
  'Apple',
  'HP',
  'Dell',
  'Lenovo',
  'Acer',
  'Asus',
  'Microsoft',
  'MSI',
  'Razer',
  'Samsung',
  'LG',
  'Toshiba',
  'Sony',
  'Fujitsu',
  'Panasonic',
  'Alienware',
  'Huawei',
  'Xiaomi',
  'Gigabyte',
  'Medion',
  'Gateway',
  'MSI',
  'Vaio',
  'Chromebook',
  'Compaq',
  'HCL',
  'IBM',
  'NEC',
  'Sager',
  'Toshiba'
];

export const GamingConsoleExamples = [
  '',
  'PlayStation',
  'Xbox',
  'Nintendo Switch',
  'PC',
  'PlayStation 5',
  'Xbox Series X',
  'Nintendo 3DS',
  'PlayStation 4',
  'Xbox One',
  'Nintendo Wii U',
  'Nintendo Wii',
  'PlayStation 3',
  'Xbox 360',
  'PlayStation 2',
  'Nintendo DS',
  'Game Boy Advance',
  'Sega Genesis',
  'Super Nintendo Entertainment System (SNES)',
  'Atari 2600',
  'Sega Dreamcast',
  'Sega Saturn',
  'Neo Geo',
  'TurboGrafx-16',
  'Intellivision',
  'ColecoVision',
  'Magnavox Odyssey',
  'Amiga CD32',
  'Philips CD-i',
  '3DO Interactive Multiplayer',
  'Jaguar'
];

export const wearableBrands = [
  'Apple',
  'Samsung',
  'Fitbit',
  'Xiaomi',
  'Garmin',
  'Huawei',
  'Sony',
  'Bose',
  'Jabra',
  'Beats',
  'Fossil',
  'Amazfit',
  'Polar',
  'Skullcandy',
  'Jaybird',
  'Sennheiser',
  'Bowers & Wilkins',
  'Plantronics',
  'JBL',
  'Bang & Olufsen',
  'Anker',
  'Motorola',
  'Withings',
  'TicWatch',
  'Ultimate Ears',
  'Razer',
  'Aftershokz',
  'Shure',
  'Libratone',
  'JBL'
];

export const regions = [
  '',
  'Global',
  'European',
  'Arabic',
  'African',
  'Malasian',
  'Russian',
  'Thai'
];

// export const laptops = [
//   {
//     brand: 'Apple',
//     model: 'MacBook Pro',
//     specs: {
//       processor: 'Intel Core i9',
//       ram: '64 GB',
//       storage: 'SSD',
//       graphics: 'AMD Radeon Pro 5600M',
//       display: '16-inch'
//     }
//   },
//   {
//     brand: 'HP',
//     model: 'HP Spectre x360',
//     specs: {
//       processor: 'Intel Core i7',
//       ram: '32 GB',
//       storage: 'SSD',
//       graphics: 'NVIDIA GeForce MX330',
//       display: '15.6-inch'
//     }
//   },
//   {
//     brand: 'Dell',
//     model: 'Dell XPS 13',
//     specs: {
//       processor: 'Intel Core i7',
//       ram: '32 GB',
//       storage: 'SSD',
//       graphics: 'Intel Iris Xe Graphics',
//       display: '13.4-inch'
//     }
//   },
//   {
//     brand: 'Lenovo',
//     model: 'ThinkPad X1 Carbon',
//     specs: {
//       processor: 'Intel Core i7',
//       ram: '32 GB',
//       storage: 'SSD',
//       graphics: 'Intel UHD Graphics',
//       display: '14-inch'
//     }
//   },
//   {
//     brand: 'Acer',
//     model: 'Acer Predator Helios 300',
//     specs: {
//       processor: 'Intel Core i7',
//       ram: '32 GB',
//       storage: 'SSD',
//       graphics: 'NVIDIA GeForce RTX 3070',
//       display: '15.6-inch'
//     }
//   },
//   {
//     brand: 'Asus',
//     model: 'Asus ZenBook 14',
//     specs: {
//       processor: 'Intel Core i7',
//       ram: '16 GB',
//       storage: 'SSD',
//       graphics: 'NVIDIA GeForce MX450',
//       display: '14-inch'
//     }
//   },
//   {
//     brand: 'Microsoft',
//     model: 'Surface Laptop 4',
//     specs: {
//       processor: 'AMD Ryzen 7',
//       ram: '16 GB',
//       storage: 'SSD',
//       graphics: 'AMD Radeon Graphics',
//       display: '15-inch'
//     }
//   },
//   {
//     brand: 'MSI',
//     model: 'MSI GS66 Stealth',
//     specs: {
//       processor: 'Intel Core i7',
//       ram: '32 GB',
//       storage: 'SSD',
//       graphics: 'NVIDIA GeForce RTX 3080',
//       display: '15.6-inch'
//     }
//   },
//   {
//     brand: 'Razer',
//     model: 'Razer Blade 15',
//     specs: {
//       processor: 'Intel Core i7',
//       ram: '32 GB',
//       storage: 'SSD',
//       graphics: 'NVIDIA GeForce RTX 3060',
//       display: '15.6-inch'
//     }
//   },
//   {
//     brand: 'Lenovo',
//     model: 'Lenovo Legion 5',
//     specs: {
//       processor: 'AMD Ryzen 9',
//       ram: '32 GB',
//       storage: 'SSD',
//       graphics: 'NVIDIA GeForce RTX 3070',
//       display: '15.6-inch'
//     }
//   },
//   {
//     brand: 'Apple',
//     model: 'MacBook Pro',
//     specs: {
//       processor: 'Intel Core i9',
//       ram: '64 GB',
//       storage: 'SSD',
//       graphics: 'AMD Radeon Pro 5600M',
//       display: '16-inch'
//     }
//   },
//   {
//     brand: 'HP',
//     model: 'HP Spectre x360',
//     specs: {
//       processor: 'Intel Core i7',
//       ram: '32 GB',
//       storage: 'SSD',
//       graphics: 'NVIDIA GeForce MX330',
//       display: '15.6-inch'
//     }
//   },
//   {
//     brand: 'Dell',
//     model: 'Dell XPS 13',
//     specs: {
//       processor: 'Intel Core i7',
//       ram: '32 GB',
//       storage: 'SSD',
//       graphics: 'Intel Iris Xe Graphics',
//       display: '13.4-inch'
//     }
//   },
//   {
//     brand: 'Lenovo',
//     model: 'ThinkPad X1 Carbon',
//     specs: {
//       processor: 'Intel Core i7',
//       ram: '32 GB',
//       storage: 'SSD',
//       graphics: 'Intel UHD Graphics',
//       display: '14-inch'
//     }
//   },
//   {
//     brand: 'Acer',
//     model: 'Acer Predator Helios 300',
//     specs: {
//       processor: 'Intel Core i7',
//       ram: '32 GB',
//       storage: 'SSD',
//       graphics: 'NVIDIA GeForce RTX 3070',
//       display: '15.6-inch'
//     }
//   },
//   {
//     brand: 'Asus',
//     model: 'Asus ZenBook 14',
//     specs: {
//       processor: 'Intel Core i7',
//       ram: '16 GB',
//       storage: 'SSD',
//       graphics: 'NVIDIA GeForce MX450',
//       display: '14-inch'
//     }
//   },
//   {
//     brand: 'Microsoft',
//     model: 'Surface Laptop 4',
//     specs: {
//       processor: 'AMD Ryzen 7',
//       ram: '16 GB',
//       storage: 'SSD',
//       graphics: 'AMD Radeon Graphics',
//       display: '15-inch'
//     }
//   },
//   {
//     brand: 'MSI',
//     model: 'MSI GS66 Stealth',
//     specs: {
//       processor: 'Intel Core i7',
//       ram: '32 GB',
//       storage: 'SSD',
//       graphics: 'NVIDIA GeForce RTX 3080',
//       display: '15.6-inch'
//     }
//   },
//   {
//     brand: 'Razer',
//     model: 'Razer Blade 15',
//     specs: {
//       processor: 'Intel Core i7',
//       ram: '32 GB',
//       storage: 'SSD',
//       graphics: 'NVIDIA GeForce RTX 3060',
//       display: '15.6-inch'
//     }
//   },
//   {
//     brand: 'Lenovo',
//     model: 'Lenovo Legion 5',
//     specs: {
//       processor: 'AMD Ryzen 9',
//       ram: '32 GB',
//       storage: 'SSD',
//       graphics: 'NVIDIA GeForce RTX 3070',
//       display: '15.6-inch'
//     }
//   },
//   {
//     brand: 'Apple',
//     model: 'MacBook Pro',
//     specs: {
//       processor: 'Intel Core i9',
//       ram: '64 GB',
//       storage: 'SSD',
//       graphics: 'AMD Radeon Pro 5600M',
//       display: '16-inch'
//     }
//   },
//   {
//     brand: 'HP',
//     model: 'HP Spectre x360',
//     specs: {
//       processor: 'Intel Core i7',
//       ram: '32 GB',
//       storage: 'SSD',
//       graphics: 'NVIDIA GeForce MX330',
//       display: '15.6-inch'
//     }
//   },
//   {
//     brand: 'Dell',
//     model: 'Dell XPS 13',
//     specs: {
//       processor: 'Intel Core i7',
//       ram: '32 GB',
//       storage: 'SSD',
//       graphics: 'Intel Iris Xe Graphics',
//       display: '13.4-inch'
//     }
//   },
//   {
//     brand: 'Lenovo',
//     model: 'ThinkPad X1 Carbon',
//     specs: {
//       processor: 'Intel Core i7',
//       ram: '32 GB',
//       storage: 'SSD',
//       graphics: 'Intel UHD Graphics',
//       display: '14-inch'
//     }
//   },
//   {
//     brand: 'Acer',
//     model: 'Acer Predator Helios 300',
//     specs: {
//       processor: 'Intel Core i7',
//       ram: '32 GB',
//       storage: 'SSD',
//       graphics: 'NVIDIA GeForce RTX 3070',
//       display: '15.6-inch'
//     }
//   },
//   {
//     brand: 'Asus',
//     model: 'Asus ZenBook 14',
//     specs: {
//       processor: 'Intel Core i7',
//       ram: '16 GB',
//       storage: 'SSD',
//       graphics: 'NVIDIA GeForce MX450',
//       display: '14-inch'
//     }
//   },
//   {
//     brand: 'Microsoft',
//     model: 'Surface Laptop 4',
//     specs: {
//       processor: 'AMD Ryzen 7',
//       ram: '16 GB',
//       storage: 'SSD',
//       graphics: 'AMD Radeon Graphics',
//       display: '15-inch'
//     }
//   },
//   {
//     brand: 'MSI',
//     model: 'MSI GS66 Stealth',
//     specs: {
//       processor: 'Intel Core i7',
//       ram: '32 GB',
//       storage: 'SSD',
//       graphics: 'NVIDIA GeForce RTX 3080',
//       display: '15.6-inch'
//     }
//   },
//   {
//     brand: 'Razer',
//     model: 'Razer Blade 15',
//     specs: {
//       processor: 'Intel Core i7',
//       ram: '32 GB',
//       storage: 'SSD',
//       graphics: 'NVIDIA GeForce RTX 3060',
//       display: '15.6-inch'
//     }
//   },
//   {
//     brand: 'Lenovo',
//     model: 'Lenovo Legion 5',
//     specs: {
//       processor: 'AMD Ryzen 9',
//       ram: '32 GB',
//       storage: 'SSD',
//       graphics: 'NVIDIA GeForce RTX 3070',
//       display: '15.6-inch'
//     }
//   }
// ];
