/* eslint-disable no-unused-vars */
import { Switch } from 'antd';
import axios from 'axios';
import { BASE_URL } from 'constants/url';
import useCurrencyStore from 'currency/useCurrencyStore';
import DevLog from 'helpers/DevLog';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import decryptData from 'secret/decryptData';

function CurrencySwitch() {
  const [currency, setCurrency] = useState(false); // Default currency is USD
  const [conf, setConf] = useState('');
  const { setCurrencyState } = useCurrencyStore();
  const url = BASE_URL + `/auth/preference/update/`;
  const handleCurrencyChange = async checked => {
    const newCurrency = checked ? 'USD' : 'AED';
    setCurrency(!currency);
    DevLog({ newCurrency });
    await axios
      .patch(
        url,
        { currency: newCurrency },
        {
          headers: {
            Authorization: `JWT ${decryptData('token')}`
          }
        }
      )
      .then(r => {
        DevLog({ r });
        if (r.status === 200) {
          setCurrencyState(newCurrency);
          window.location.reload();
          return toast.success('Currency updated successfully');
        }
      })
      .catch(err => {
        DevLog(err);
      });
  };
  let bool = currency == 'USD' ? true : false;
  const fetchCurrency = async () => {
    await axios
      .get(BASE_URL + `/auth/preference/`, {
        headers: {
          Authorization: `JWT ${decryptData('token')}`
        }
      })
      .then(r => {
        DevLog(r);
        if (r.status === 200) {
          let val = r.data.currency === 'USD' ? true : false;
          setCurrency(val);
          setCurrencyState(r.data.currency);
        }
      })
      .catch(err => {
        DevLog(err);
      });
  };
  useEffect(() => {
    fetchCurrency();
  }, []);
  return (
    <div onClick={() => {}}>
      <Switch
        onClick={() => {
          setCurrencyState(conf);
        }}
        className=""
        checkedChildren="USD"
        unCheckedChildren="AED"
        checked={currency}
        onChange={handleCurrencyChange}
      />
    </div>
  );
}

export default CurrencySwitch;
// currency == 'USD' ? true : false
