import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Card } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import { getColor, rgbaColor } from 'helpers/utils';
import * as echarts from 'echarts/core';
import { LineChart } from 'echarts/charts';
import {
  GridComponent,
  TooltipComponent,
  TitleComponent
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import BasicECharts from 'components/common/BasicEChart';
import { useLanguage } from 'provider/LanguageProvider';
import useScreenSize from 'hooks/useScreenSize';
import BlurCoverage from 'components/blur/BlurCoverage';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LineChart,
  CanvasRenderer
]);

const getOptions = data => ({
  series: [
    {
      type: 'line',
      data,
      areaStyle: {
        color: {
          type: 'linear',
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: rgbaColor(getColor('primary'), 0.25)
            },
            {
              offset: 1,
              color: rgbaColor(getColor('primary'), 0)
            }
          ]
        }
      }
    }
  ],
  grid: { bottom: '-10px' }
});

const TotalOrder = ({ data }) => {
  const { language } = useLanguage();
  const screen = useScreenSize();
  return (
    <BlurCoverage>
      <Card className="h-md-100 text-700">
        <Card.Header className="pb-0">
          <h6
            className={
              'mb-0 mt-2' + ' ' + ` ${screen == 'desktop' ? 'fs-1' : ''}`
            }
          >
            {language == 'en' && 'Total orders'}
            {language == 'uz' && 'Jami buyurtmalar'}
            {language == 'ru' && 'Общее количество заказов'}
          </h6>
        </Card.Header>

        <Card.Body
          as={Flex}
          alignItems="end"
          justifyContent="between"
          className="pt-0 text-700"
        >
          <div>
            <h2
              className={
                'fw-normal text-700 mb-1 lh-1 fs-1' +
                ' ' +
                ` ${screen == 'desktop' ? 'fs-2' : ''}`
              }
            >
              58.4K
            </h2>
            <Badge
              pill
              bg="200"
              className={
                'text-primary fs--2 text-700' +
                ' ' +
                ` ${screen == 'desktop' ? 'px-3 fs-1' : ''}`
              }
            >
              <FontAwesomeIcon icon="caret-up" className={'me-1 text-700'} />
              13.6%
            </Badge>
          </div>
          <div className="ps-0">
            <BasicECharts
              echarts={echarts}
              options={getOptions(data)}
              style={{
                width: screen == 'desktop' ? '230px' : '100px',
                height: screen == 'desktop' ? '120px' : '80px'
              }}
            />
          </div>
        </Card.Body>
      </Card>
    </BlurCoverage>
  );
};

TotalOrder.propTypes = { data: PropTypes.array.isRequired };

export default TotalOrder;
