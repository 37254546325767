// cartStore.js
import { create } from 'zustand';

const useUserCart = create(set => ({
  Cart: JSON.parse(localStorage.getItem('user-cart')) || [],

  addToCart: product =>
    set(state => {
      const updatedCart = [...state.Cart, product];
      localStorage.setItem('user-cart', JSON.stringify(updatedCart));
      return { Cart: updatedCart };
    }),

  removeFromCart: productId =>
    set(state => {
      const updatedCart = state.Cart.filter(
        product => product.product_id !== productId
      );
      localStorage.setItem('user-cart', JSON.stringify(updatedCart));
      return { Cart: updatedCart };
    }),
  clearCart: () =>
    set(() => {
      localStorage.removeItem('user-cart');

      return { Cart: [] };
    })
}));

export default useUserCart;
