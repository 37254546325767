/* eslint-disable react/react-in-jsx-scope */
import Flex from 'components/common/Flex';
import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import LoginForm from '../login forms/LoginForm';
import { useLanguage } from 'provider/LanguageProvider';
import { Divider } from 'antd';
import decryptData from 'secret/decryptData';
import DevLog from 'helpers/DevLog';

const Login = () => {
  const navigate = useNavigate();
  const { language, changeLanguage } = useLanguage();
  DevLog({ language, changeLanguage });
  useEffect(() => {
    let isUserLoggedInTo;
    try {
      isUserLoggedInTo = decryptData('data-user').user_type;
    } catch (error) {
      isUserLoggedInTo = null;
    }
    if (isUserLoggedInTo == 'supplier') {
      navigate('/supplier/user/profile');
    } else if (isUserLoggedInTo == 'customer') {
      navigate('/customer/user/profile');
    } else if (isUserLoggedInTo == 'admin') {
      navigate('/admin/dashboard');
    } else {
      navigate('/');
    }
  }, []);
  return (
    <>
      <h3 className="text-center my-3 text-primary fs-5">AGT</h3>

      <Flex justifyContent="between" alignItems="center" className="mb-2">
        <h5>
          {' '}
          {language == 'en' && 'Login'}
          {language == 'uz' && 'Kirish'}
          {language == 'ru' && 'Войти'}
        </h5>
        <p className="fs--1 text-600 mb-0">
          {language == 'en' && 'or'}
          {language == 'uz' && 'yoki'}
          {language == 'ru' && 'или'}{' '}
          <Link to="/registration">
            {language == 'en' && 'Create an account'}
            {language == 'uz' && 'Yangi hisob ochish'}
            {language == 'ru' && 'Создать учетную запись'}
          </Link>
        </p>
      </Flex>
      <LoginForm />
      <br />
      <Divider className="text-primary">
        {language == 'en' && 'Please, select a Language'}
        {language == 'uz' && 'Iltimos, tilni tanlang'}
        {language == 'ru' && 'Пожалуйста, выберите язык'}
      </Divider>
      <div className="w-100 d-flex justify-content-center">
        <div className="d-flex w-50 justify-content-around pt-3  ">
          <p
            onClick={() => {
              changeLanguage('uz');
            }}
            className={
              language == 'uz'
                ? 'mx-3 bg-soft-info p-2 text-primary rounded px-3  cursor-pointer hover-1000'
                : 'mx-3  p-2 rounded px-3 text-primary  cursor-pointer hover-1000'
            }
          >
            UZ
          </p>
          <p
            onClick={() => {
              changeLanguage('en');
            }}
            className={
              language == 'en'
                ? 'mx-3 bg-soft-info p-2 text-primary rounded px-3  cursor-pointer hover-1000'
                : 'mx-3  p-2 rounded px-3  text-primary cursor-pointer hover-1000'
            }
          >
            EN
          </p>
          <p
            onClick={() => {
              changeLanguage('ru');
            }}
            className={
              language == 'ru'
                ? 'mx-3 bg-soft-info p-2 rounded px-3 text-primary   cursor-pointer hover-1000'
                : 'mx-3  p-2 rounded px-3  text-primary cursor-pointer hover-1000'
            }
          >
            RU
          </p>
        </div>
      </div>
      <p className="text-center text-info">
        {language == 'en' && 'Well done! You selected English language.'}
        {language == 'uz' && 'Yaxshi! Siz oʻzbek tilini tanladingiz.'}
        {language == 'ru' && 'Отлично! Вы выбрали русский язык.'}
      </p>
    </>
  );
};

export default Login;
