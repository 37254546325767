import AppContext from 'context/Context';
import React, { useContext } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

const CustomerOrderedDataSkeleton = () => {
  const {
    config: { isDark }
  } = useContext(AppContext);
  return (
    <Card className="mb-3 text-800">
      <Card.Body>
        <div className="table-responsive fs--1">
          <table className="table">
            <thead className="bg-200 text-900">
              <tr>
                <th className="border-0 text-800">
                  <SkeletonTheme
                    baseColor={isDark ? '#333' : '#fafafa'}
                    highlightColor={isDark ? '#444' : '#e5e5e5'}
                  >
                    <Skeleton width={200} />
                  </SkeletonTheme>
                </th>
                <th className="border-0 text-800 text-center">
                  <SkeletonTheme
                    baseColor={isDark ? '#333' : '#fafafa'}
                    highlightColor={isDark ? '#444' : '#e5e5e5'}
                  >
                    <SkeletonTheme
                      baseColor={isDark ? '#333' : '#fafafa'}
                      highlightColor={isDark ? '#444' : '#e5e5e5'}
                    >
                      <Skeleton width={100} />
                    </SkeletonTheme>
                  </SkeletonTheme>
                </th>
                <th className="border-0 text-800 text-end">
                  <SkeletonTheme
                    baseColor={isDark ? '#333' : '#fafafa'}
                    highlightColor={isDark ? '#444' : '#e5e5e5'}
                  >
                    <Skeleton width={100} />
                  </SkeletonTheme>
                </th>
                <th className="border-0 text-800 text-end">
                  <SkeletonTheme
                    baseColor={isDark ? '#333' : '#fafafa'}
                    highlightColor={isDark ? '#444' : '#e5e5e5'}
                  >
                    <Skeleton width={100} />
                  </SkeletonTheme>
                </th>
              </tr>
            </thead>
            <tbody>
              {Array.from({ length: 2 }).map((_, index) => (
                <tr key={index} className="border-200">
                  <td className="align-middle text-800">
                    <h6 className="mb-0 text-nowrap">
                      <SkeletonTheme
                        baseColor={isDark ? '#333' : '#fafafa'}
                        highlightColor={isDark ? '#444' : '#e5e5e5'}
                      >
                        <Skeleton width={150} />
                      </SkeletonTheme>
                    </h6>
                    <p className="mb-0">
                      <SkeletonTheme
                        baseColor={isDark ? '#333' : '#fafafa'}
                        highlightColor={isDark ? '#444' : '#e5e5e5'}
                      >
                        <Skeleton count={1} />
                      </SkeletonTheme>
                    </p>
                  </td>
                  <td className="align-middle text-800 text-center">
                    <SkeletonTheme
                      baseColor={isDark ? '#333' : '#fafafa'}
                      highlightColor={isDark ? '#444' : '#e5e5e5'}
                    >
                      <Skeleton width={50} />
                    </SkeletonTheme>
                  </td>
                  <td className="align-middle text-800 text-end">
                    <SkeletonTheme
                      baseColor={isDark ? '#333' : '#fafafa'}
                      highlightColor={isDark ? '#444' : '#e5e5e5'}
                    >
                      <Skeleton width={80} />
                    </SkeletonTheme>
                  </td>
                  <td className="align-middle text-800 text-end">
                    <SkeletonTheme
                      baseColor={isDark ? '#333' : '#fafafa'}
                      highlightColor={isDark ? '#444' : '#e5e5e5'}
                    >
                      <Skeleton width={80} />
                    </SkeletonTheme>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <Row className="g-0 justify-content-end">
            <Col xs="auto">
              <table className="table borderless size-sm fs--1 text-800 text-end">
                <tbody>
                  <tr>
                    <th className="text-900">
                      <SkeletonTheme
                        baseColor={isDark ? '#333' : '#fafafa'}
                        highlightColor={isDark ? '#444' : '#e5e5e5'}
                      >
                        <SkeletonTheme
                          baseColor={isDark ? '#333' : '#fafafa'}
                          highlightColor={isDark ? '#444' : '#e5e5e5'}
                        >
                          <Skeleton width={80} />
                        </SkeletonTheme>
                      </SkeletonTheme>
                    </th>
                    <td className="fw-semi-bold text-800">
                      <SkeletonTheme
                        baseColor={isDark ? '#333' : '#fafafa'}
                        highlightColor={isDark ? '#444' : '#e5e5e5'}
                      >
                        <Skeleton width={80} />
                      </SkeletonTheme>
                    </td>
                  </tr>
                  <tr>
                    <th className="text-900">
                      <SkeletonTheme
                        baseColor={isDark ? '#333' : '#fafafa'}
                        highlightColor={isDark ? '#444' : '#e5e5e5'}
                      >
                        <Skeleton width={80} />
                      </SkeletonTheme>
                    </th>
                    <td className="fw-semi-bold text-800">
                      <SkeletonTheme
                        baseColor={isDark ? '#333' : '#fafafa'}
                        highlightColor={isDark ? '#444' : '#e5e5e5'}
                      >
                        <Skeleton width={80} />
                      </SkeletonTheme>
                    </td>
                  </tr>
                  <tr>
                    <th className="text-900">
                      <SkeletonTheme
                        baseColor={isDark ? '#333' : '#fafafa'}
                        highlightColor={isDark ? '#444' : '#e5e5e5'}
                      >
                        <Skeleton width={120} />
                      </SkeletonTheme>
                    </th>
                    <td className="fw-semi-bold text-800">
                      <SkeletonTheme
                        baseColor={isDark ? '#333' : '#fafafa'}
                        highlightColor={isDark ? '#444' : '#e5e5e5'}
                      >
                        <Skeleton width={80} />
                      </SkeletonTheme>
                    </td>
                  </tr>
                  <tr className="border-top">
                    <th className="text-900">
                      <Skeleton width={80} />
                    </th>
                    <td className="fw-semi-bold text-800">
                      <Skeleton width={80} />
                    </td>
                  </tr>
                </tbody>
              </table>
            </Col>
          </Row>
        </div>
      </Card.Body>
    </Card>
  );
};

export default CustomerOrderedDataSkeleton;
