/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Offcanvas } from 'react-bootstrap';
import { BiSolidEdit } from 'react-icons/bi';

const RequiredQtyCanvas = ({ title, children, name, placement, icon }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div onClick={handleShow} className="">
        {icon} {name} {<BiSolidEdit size={20} className="mx-2" />}
      </div>
      <Offcanvas placement={placement} show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{title}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>{children}</Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default RequiredQtyCanvas;
