export const mobilePhoneColors = [
  '',
  'Black',
  'White',
  'Silver',
  'Gold',
  'Rose Gold',
  'Space Gray',
  'Midnight Blue',
  'Coral',
  'Red',
  'Blue',
  'Green',
  'Yellow',
  'Pink',
  'Purple',
  'Graphite',
  'Lavender',
  'Mint Green',
  'Amber',
  'Emerald Green',
  'Sapphire Blue',
  'Ruby Red',
  'Pearl White',
  'Champagne Gold',
  'Titanium Gray',
  'Arctic Blue',
  'Topaz Blue',
  'Platinum Silver',
  'Indigo Blue',
  'Opal Black',
  'Ruby Black',
  'Silver Gray',
  'Matte Black',
  'Copper',
  'Ocean Blue',
  'Sunset Orange',
  'Lime Green',
  'Crimson Red',
  'Frost White',
  'Azure Blue',
  'Gunmetal Gray',
  'Rose Pink',
  'Emerald',
  'Violet',
  'Electric Blue',
  'Mocha Brown',
  'Obsidian Black',
  'Sunrise Gold',
  'Peach',
  'Forest Green',
  'Cloud White',
  'Ruby',
  'Platinum Gold',
  'Cobalt Blue',
  'Golden Rose',
  'Deep Red',
  'Silver White',
  'Charcoal Black',
  'Pale Pink',
  'Metallic Blue',
  'Orchid Purple',
  'Shadow Gray',
  'Sky Blue',
  'Matte Blue',
  'Iron Gray',
  'Slate Gray',
  'Coral Blue',
  'Sandstone',
  'Turquoise',
  'Mystic Green',
  'Blush Pink',
  'Bronze',
  'Pearl Blue',
  'Emerald Blue',
  'Ruby Pink',
  'Ocean Green',
  'Silk White',
  'Ceramic White',
  'Space Silver',
  'Frozen Blue',
  'Caramel Brown'
];

export const ramOptions = [
  '',
  '2GB',
  '4GB',
  '6GB',
  '8GB',
  '12GB',
  '16GB',
  '32GB',
  '64GB',
  '128GB',
  '256GB',
  '512GB',
  '1TB',
  '2TB',
  '4TB',
  '8TB'
];

export const storageOptions = [
  '',
  '4GB',
  '8GB',
  '16GB',
  '32GB',
  '64GB',
  '128GB',
  '256GB',
  '512GB',
  '1TB',
  '2TB',
  '4TB',
  '8TB',
  '16TB',
  '32TB',
  '64TB'
];

export const laptopColors = [
  '',
  'Silver',
  'Space Gray',
  'Black',
  'Rose Gold',
  'White',
  'Blue',
  'Gold',
  'Red',
  'Pink',
  'Aqua Blue',
  'Purple',
  'Green',
  'Yellow',
  'Orange',
  'Matte Black',
  'Titanium Gray',
  'Burgundy',
  'Navy Blue'
  // Add more colors here
];
export const topColors = [
  '',
  'Red',
  'Green',
  'Blue',
  'Yellow',
  'Purple',
  'Pink',
  'Orange',
  'Black',
  'White',
  'Gray',
  'Brown',
  'Cyan',
  'Magenta',
  'Teal',
  'Lime'
  // Add more colors here
];
