// src/components/TermsAndConditions.js
import React from 'react';
import Container from 'react-bootstrap/Container';

const TermsAndConditions = () => {
  return (
    <Container>
      <h1>Terms and Conditions</h1>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla facilisi.
        Sed congue augue id libero euismod, et ultricies quam dictum. Vivamus
        sit amet congue odio. Vivamus vel justo in justo volutpat vulputate.
        Quisque tincidunt mi at tellus eleifend viverra.
      </p>
      <p>
        Nullam nec sollicitudin libero, eu tincidunt elit. Sed non ex tellus.
        Nulla facilisi. Nulla facilisi. Nulla facilisi. Duis eleifend, elit non
        egestas interdum, justo ex rhoncus nisl, vel auctor metus purus id
        purus.
      </p>
      {/* Add more terms and conditions content as needed */}
    </Container>
  );
};

export default TermsAndConditions;
