import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card, Col, Row } from 'react-bootstrap';
import { BASE_URL } from 'constants/url';
import { BsPencilSquare, BsTrashFill } from 'react-icons/bs';
import { Link } from 'react-router-dom';

import TableSkeleton from 'components/common/skeletons/table skleton/TableSkleton';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import SoftBadge from 'components/common/SoftBadge';
import { toast } from 'react-toastify';
import { Button } from 'antd';
import decryptData from 'secret/decryptData';
import DevLog from 'helpers/DevLog';
const AdminWearables = () => {
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const columns = [
    {
      accessor: 'model_name',
      Header: 'Model Name'
    },
    {
      accessor: 'model_number',
      Header: 'Number'
    },
    {
      accessor: 'color',
      Header: 'Color'
    },
    {
      accessor: 'code',
      Header: 'Code'
    },
    {
      accessor: 'action',
      Header: 'Action',
      Cell: rowData => {
        const { model_name, product_id } = rowData.row.original;
        return (
          <>
            <Link
              className="badge text-info bg-soft-info hover-button rounded-5  mx-3 p-2"
              state={rowData.row.original}
              variant="primary"
              to={`/admin/e-commerce/product/wearables/edit/${model_name}`}
            >
              <BsPencilSquare size={20} />
            </Link>
            <SoftBadge
              className="badge text-danger bg-soft-danger hover-button rounded-5  mx-3 p-2"
              onClick={() => {
                if (window.confirm('Are you sure you want to delete?')) {
                  handleDelete(product_id);
                }
              }}
            >
              <BsTrashFill size={20} />
            </SoftBadge>
          </>
        );
      }
    }
  ];
  useEffect(() => {
    setIsLoading(true);
    // Fetch data using Axios
    axios
      .get(BASE_URL + '/dashboard/products/', {
        headers: {
          Authorization: `JWT ${decryptData('token')}`,
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        const wearables = response.data?.filter(item => {
          const { category_id } = item.category;
          return (
            category_id ==
            JSON.parse(localStorage.getItem('wearableCategoryId'))
          );
        });
        DevLog({ wearables });
        setProducts(wearables);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const handleDelete = async productId => {
    // Handle delete logic for the product with the given productId
    DevLog('Delete product with ID:', productId);
    await axios
      .patch(
        BASE_URL + '/dashboard/product/update/' + productId + '/',
        { is_active: false },
        {
          headers: {
            Authorization: `JWT ${decryptData('token')}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then(response => {
        DevLog({ response });
        if (response.status === 200) {
          setProducts(
            products.filter(
              p => p.product_id !== productId && p.is_active === true
            )
          );
          return toast.success(
            `Product successfully deleted, Product ID: ${productId}`,
            {
              theme: 'colored',
              position: 'top-right',
              autoClose: true
            }
          );
        }
      })
      .catch(err => {
        DevLog(err);
      })
      .finally(() => {});
  };

  if (isLoading) {
    return <TableSkeleton />;
  }

  return (
    <Card className="p-4 text-800">
      <AdvanceTableWrapper
        columns={columns}
        data={products}
        sortable
        pagination
        perPage={5}
      >
        <Row className="flex-end-center mb-3">
          <Col xs="auto" sm={6} lg={6}>
            <h3 className="text-primary fw-light">List of wearables</h3>
          </Col>
          <Col xs="auto" sm={6} lg={6}>
            <AdvanceTableSearchBox table />
          </Col>
          <Col xs="auto" sm={6} lg={6}>
            <div className="d-flex justify-content-end">
              <Link to={'/admin/e-commerce admin/product/upload_wearable'}>
                <Button className="mt-3" type="primary">
                  Create new wearable +
                </Button>
              </Link>
            </div>
          </Col>
        </Row>
        <AdvanceTable
          table
          headerClassName="bg-200 text-900 text-nowrap align-middle"
          rowClassName="align-middle white-space-nowrap"
          tableProps={{
            bordered: true,
            striped: true,
            className: 'fs--1 mb-0 overflow-hidden'
          }}
        />
        <div className="mt-3">
          <AdvanceTableFooter
            rowCount={products.length}
            table
            rowInfo
            navButtons
            rowsPerPageSelection
          />
        </div>
      </AdvanceTableWrapper>
    </Card>
    // <Card className="p-4 text-800">
    //   <Form.Group controlId="searchForm">
    //     <Form.Control
    //       type="text"
    //       placeholder="Search"
    //       value={searchTerm}
    //       onChange={handleSearch}
    //     />
    //   </Form.Group>
    //   <Table hover>
    //     <thead>
    //       <tr>
    //         <th className="text-800">Brand</th>
    //         <th className="text-800">Model</th>
    //         <th className="text-800">Number</th>
    //         <th className="text-800">Color</th>
    //         <th className="text-800">Code</th>
    //         <th className="text-800">Actions</th>
    //       </tr>
    //     </thead>
    //     <tbody>
    //       {filteredProducts.map(product => (
    //         <tr key={product.id}>
    //           <td className="text-800">{product.brand}</td>
    //           <td className="text-800">{product.model_name}</td>
    //           <td className="text-800">{product.model_number}</td>
    //           <td className="text-800">{product.color}</td>
    //           <td className="text-800">{product.code}</td>
    //           <td className="text-800">

    //           </td>
    //         </tr>
    //       ))}
    //     </tbody>
    //   </Table>
    // </Card>
  );
};

export default AdminWearables;
