import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Card, Col, Row } from 'react-bootstrap';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
// import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import TableSkeleton from 'components/common/skeletons/table skleton/TableSkleton';
import { formatPrice } from 'components/utilities/formatPrice';
import { useLanguage } from 'provider/LanguageProvider';
import { SupplierPurchaseMobilePhoneTranslate } from 'languages/Language';
import decryptData from 'secret/decryptData';
import { useNavigate } from 'react-router-dom';

import { BASE_URL_WITHOUT_API } from 'constants/url';
import useUserStore from 'state/userStore';

const GamingProducts = () => {
  const [data, setData] = useState([]);
  const { language } = useLanguage();
  const [isLoading, setIsLoading] = useState(false);

  const fetchSupplierProducts = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        BASE_URL_WITHOUT_API + '/api/v1/store/supplier/products/',
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `JWT ${decryptData('token')}`
          }
        }
      );
      let list = [];
      response.data?.map(e => {
        let p = e.product;
        let full = {
          price: formatPrice(e.price),
          supplier_product_quantity: e.supplier_product_quantity,
          ...p
        };
        list.push(full);
      });

      setData(
        list?.filter(product => {
          const { category_id } = product.category;
          return (
            category_id == JSON.parse(localStorage.getItem('gamingCategoryId'))
          );
        })
      );

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };
  const navigate = useNavigate();
  const { userStatus } = useUserStore();
  useEffect(() => {
    if (
      userStatus == 'BLANK' ||
      userStatus == '' ||
      userStatus == null ||
      userStatus == undefined
    ) {
      navigate('/supplier/user/settings');
    }
  }, [userStatus]);
  useEffect(() => {
    fetchSupplierProducts();
  }, []);

  const columns = [
    {
      accessor: 'brand',
      Header: SupplierPurchaseMobilePhoneTranslate().table.brand[language]
    },
    {
      accessor: 'model_name',
      Header:
        SupplierPurchaseMobilePhoneTranslate().table['Model name'][language]
    },
    {
      accessor: 'model_number',
      Header:
        SupplierPurchaseMobilePhoneTranslate().table['Model number'][language]
    },
    {
      accessor: 'storage',
      Header: SupplierPurchaseMobilePhoneTranslate().table.Storage[language]
    },

    {
      accessor: 'specs',
      Header: SupplierPurchaseMobilePhoneTranslate().table.Specs[language]
    },
    {
      accessor: 'supplier_product_quantity',
      Header: SupplierPurchaseMobilePhoneTranslate().table.Quantity[language]
    },
    {
      accessor: 'price',
      Header: SupplierPurchaseMobilePhoneTranslate().table.Price[language]
    }
  ];

  return isLoading ? (
    <TableSkeleton />
  ) : (
    <Card className="p-2">
      <AdvanceTableWrapper
        columns={columns}
        data={data}
        sortable
        pagination
        perPage={15}
      >
        <Row className="flex-end-center mb-3 pt-2">
          <Col>
            <h4 className="text-primary m-2">
              {SupplierPurchaseMobilePhoneTranslate().title.gaming[language]}
            </h4>
          </Col>
          <Col xs="auto" sm={6} lg={4}>
            <AdvanceTableSearchBox table />
          </Col>
        </Row>
        <AdvanceTable
          table
          headerClassName="bg-200 text-900 text-nowrap align-middle"
          rowClassName="align-middle white-space-nowrap"
          tableProps={{
            bordered: true,
            striped: true,
            className: 'fs--1 mb-0 overflow-hidden'
          }}
          data={data}
        />
        <div className="mt-3">
          {/* <AdvanceTableFooter
            rowCount={data.length}
            table
            rowInfo
            navButtons
            rowsPerPageSelection
          /> */}
        </div>
      </AdvanceTableWrapper>
    </Card>
  );
};

export default GamingProducts;
