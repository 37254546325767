import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Row, Table } from 'react-bootstrap';
import CustomerOrderedDataSkeleton from 'components/common/skeletons/CustomerOrderedDataSkeleton/CustomerOrderedDataSkeleton';
import axios from 'axios';
import { BASE_URL } from 'constants/url';
import { useLocation } from 'react-router-dom';
import { useLanguage } from 'provider/LanguageProvider';
import { SupplierOrdersDetailsPageTranslate } from 'languages/Language';
import decryptData from 'secret/decryptData';
import DevLog from 'helpers/DevLog';
import useCurrencyStore from 'currency/useCurrencyStore';

const OrderedProductsSupplier = () => {
  const { language } = useLanguage();
  const { currency } = useCurrencyStore();
  const [isLoading, setIsLoading] = useState(false);
  const [totalCost, setTotalCost] = useState(0);
  const [orderItems, setOrderItems] = useState([]);
  const { supplier_order_id } = useLocation().state;
  const fetchOrderItems = async () => {
    setIsLoading(true);
    await axios
      .get(BASE_URL + `/store/supplier/order/${supplier_order_id}/items/`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `JWT ${decryptData('token')}`
        }
      })
      .then(response => {
        DevLog({ response });
        setTotalCost(response.data?.supplier_order_total_cost);
        const filtered = response.data?.data?.map(i => {
          const {
            supplier_order_item_id,
            required_quantity,
            delivered_quantity,
            supplier_order_item_status,
            subtotal,
            created_at
          } = i;
          const { price } = i.supplier_product;
          const { product_full_name } = i.supplier_product.product;
          const fullData = {
            supplier_order_item_id,
            required_quantity,
            delivered_quantity,
            supplier_order_item_status,
            subtotal,
            created_at,
            price,
            product_full_name
          };

          return fullData;
        });

        DevLog({ filtered });
        setOrderItems(filtered);
      })
      .catch(error => {
        DevLog({ error });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchOrderItems();
  }, []);

  if (isLoading) {
    return <CustomerOrderedDataSkeleton />;
  }

  return (
    <Card className="mb-3 text-800">
      <Card.Body>
        <div className="table-responsive fs--1">
          <Table striped className="border-bottom">
            <thead className="bg-200 text-900">
              <tr>
                <th className="border-0 text-800 ">
                  {SupplierOrdersDetailsPageTranslate.table.Products[language]}
                </th>
                <th className="border-0 text-800  text-center">
                  {' '}
                  {
                    SupplierOrdersDetailsPageTranslate.table.RequiredQty[
                      language
                    ]
                  }
                </th>
                <th className="border-0 text-800  text-center">
                  {
                    SupplierOrdersDetailsPageTranslate.table.DeliveredQty[
                      language
                    ]
                  }
                </th>
                {/* <th className="border-0 text-800  text-center">Comments</th> */}
                <th className="border-0 text-800  ">
                  {' '}
                  {SupplierOrdersDetailsPageTranslate.table.Status[language]}
                </th>

                <th className="border-0 text-800  ">
                  {' '}
                  {SupplierOrdersDetailsPageTranslate.table.Price[language]}
                </th>
                <th className="border-0 text-800  ">
                  {' '}
                  {SupplierOrdersDetailsPageTranslate.table.subtotal[language]}
                </th>
              </tr>
            </thead>
            <tbody>
              {orderItems.map(product => (
                <tr key={product.product_full_name} className="border-200">
                  <td className="align-middle text-800">
                    <h6 className="mb-0 text-nowrap">
                      {product.product_full_name}
                    </h6>
                  </td>
                  <td className="align-middle text-800 text-center">
                    {product.required_quantity}
                  </td>
                  <td className="align-middle text-800 text-center">
                    {product.delivered_quantity}
                  </td>
                  {/* <td className="align-middle cursor-pointer text-800 text-center">
                    <RequiredQtyCanvas
                      icon={<FaCommentDots color="blue" />}
                      placement={'end'}
                      title={'Comments received quantity'}
                      name={product.quantity}
                    >
                      <QuantityHistory
                        data={[
                          {
                            key: '1',
                            receivedQty: 100,
                            comment: 'Initial stock'
                          },
                          {
                            key: '2',
                            receivedQty: 50,
                            comment: 'Restock'
                          },
                          {
                            key: '3',
                            receivedQty: 75,
                            comment: 'Additional order'
                          }
                        ]}
                      />
                    </RequiredQtyCanvas>
                  </td> */}
                  <td className="align-middle cursor-pointer text-800 ">
                    {product.supplier_order_item_status}
                  </td>
                  <td className="align-middle text-800 ">
                    {currency}{' '}
                    {parseFloat(product.price).toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2
                    })}
                  </td>
                  <td className="align-middle text-800 ">
                    {currency}{' '}
                    {parseFloat(product.subtotal).toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2
                    })}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Row className="g-0 justify-content-end">
            <Col xs="auto">
              <Table borderless size="sm" className="fs--1 text-800 text-end">
                <tbody>
                  <tr className="border-top">
                    <th className="text-900 ">
                      {language == 'en' && 'Total'}
                      {language == 'uz' && 'Jami'}
                      {language == 'ru' && 'Итого'}:
                    </th>
                    <td className="fw-semi-bold text-800">
                      {currency}{' '}
                      {parseFloat(totalCost).toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2
                      })}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </div>
      </Card.Body>
    </Card>
  );
};

OrderedProductsSupplier.propTypes = {
  total_cost: PropTypes.any,
  supplier_order_id: PropTypes.any,
  shipping_fee: PropTypes.any
};

export default OrderedProductsSupplier;
