import { useLanguage } from 'provider/LanguageProvider';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function useHardReloadAndClearCache() {
  const { language } = useLanguage();
  const msg = () => {
    if (language === 'en') {
      return 'Hard refresh and clear cache';
    } else if (language === 'uz') {
      return 'Yangilash va keshni tozalash';
    } else if (language === 'ru') {
      return 'Жесткое обновление и очистка кэша';
    }
  };
  const navigate = useNavigate();
  useEffect(() => {
    const handleReload = () => {
      const result = window.confirm(msg());
      if (result) {
        localStorage.clear();
        navigate('/');
        window.location.reload(true);
      }
    };

    window.addEventListener('reloadAndClearCache', handleReload);

    return () => {
      // Remove the event listener to clean up when the component unmounts.
      window.removeEventListener('reloadAndClearCache', handleReload);
    };
  }, []);

  // Function to trigger the hard reload and cache clearing.
  const reloadAndClearCache = () => {
    window.dispatchEvent(new Event('reloadAndClearCache'));
  };

  return reloadAndClearCache;
}

export default useHardReloadAndClearCache;
