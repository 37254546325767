/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Offcanvas } from 'react-bootstrap';
import { AiOutlineRight } from 'react-icons/ai';

const PenaltyCanvas = ({ title, children, ...props }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div
        onClick={handleShow}
        className="bg-blur px-2 py-1 cursor-pointer hover-1100 rounded-3"
      >
        See all <AiOutlineRight />
      </div>
      <Offcanvas show={show} onHide={handleClose} {...props}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{title}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>{children}</Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default PenaltyCanvas;
