/* eslint-disable react/prop-types */
import axios from 'axios';
import { BASE_URL } from 'constants/url';
import React, { useState } from 'react';
import {
  Button,
  Col,
  FormControl,
  InputGroup,
  Row,
  Spinner,
  Form
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import decryptData from 'secret/decryptData';
import DevLog from 'helpers/DevLog';
import { FaInfinity } from 'react-icons/fa';

const EditShippingInformation = ({ data, setOptions, options, id }) => {
  const [formData, setFormData] = useState(data);
  const [isLoading, setIsLoading] = useState(false);
  DevLog({ data });

  const handleChange = e => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? checked : value;

    setFormData(prevData => ({
      ...prevData,
      [name]: newValue
    }));
  };
  DevLog({ setOptions, options });
  const handleSubmit = async e => {
    setIsLoading(true);

    e.preventDefault();

    try {
      await axios
        .patch(
          `${BASE_URL}/dashboard/shipping-detail/update/${data.shipping_detail_id}/`,
          formData,
          {
            headers: {
              Authorization: `JWT ${decryptData('token')}`,
              'Content-Type': 'application/json'
            }
          }
        )
        .then(response => {
          if (response.status === 201 || response.status === 204) {
            const filtered = options?.filter(o => o.shipping_detail_id !== id);
            setOptions([...filtered, formData]);
            toast.success(`New shipping option has been successfully created`, {
              theme: 'colored',
              position: 'top-right',
              autoClose: false
            });
            setFormData({
              name: '',
              documentation_fee: '',
              price_per_kg: '',
              vat_percentage: ''
            });
            // window.location.reload();
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    } catch (error) {
      toast.error(`New shipping option has not been created`, {
        theme: 'colored',
        position: 'top-right'
      });
    }
  };
  return (
    <div className="p-3">
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col className="my-2" sm={12} lg={12} md={12}>
            <Form.Group controlId="formName">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                required
                value={formData.name}
                onChange={handleChange}
                placeholder="Enter name"
              />
            </Form.Group>
          </Col>
          <Col className="my-2" sm={12} lg={12} md={12}>
            <Form.Group controlId="formDocumentationFee">
              <Form.Label>Documentation Fee</Form.Label>
              <Form.Control
                type="number"
                name="documentation_fee"
                value={formData.documentation_fee}
                onChange={handleChange}
                placeholder="Enter documentation fee"
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col className="my-2" sm={12} lg={12} md={12}>
            <Form.Label>VAT Percentage</Form.Label>

            <InputGroup className="mb-2">
              <FormControl
                name="vat_percentage"
                aria-label="VAT Percentage"
                type="number"
                value={formData.vat_percentage}
                onChange={handleChange}
                placeholder="Enter VAT percentage"
                aria-describedby="basic-addon2"
              />
              <InputGroup.Text id="basic-addon2">
                <b>%</b>
              </InputGroup.Text>
            </InputGroup>
          </Col>

          {/* Range of prices based on KGs */}
          {!formData.price_per_unit && (
            <>
              <Col className="my-2" sm={12} lg={12} md={12}>
                <Form.Group controlId="formRange0-100">
                  <Form.Label>From 0 to 100</Form.Label>
                  <Form.Control
                    type="number"
                    name="range_0_100"
                    value={formData.range_0_100}
                    onChange={handleChange}
                    placeholder="Enter price per kg"
                  />
                </Form.Group>
              </Col>
              <Col className="my-2" sm={12} lg={12} md={12}>
                <Form.Group controlId="formRange101-500">
                  <Form.Label>From 101 to 500</Form.Label>
                  <Form.Control
                    type="number"
                    name="range_101_500"
                    value={formData.range_101_500}
                    onChange={handleChange}
                    placeholder="Enter price per kg"
                  />
                </Form.Group>
              </Col>
              <Col className="my-2" sm={12} lg={12} md={12}>
                <Form.Group controlId="formRange501-1000">
                  <Form.Label>From 501 to 1000</Form.Label>
                  <Form.Control
                    type="number"
                    name="range_501_1000"
                    value={formData.range_501_1000}
                    onChange={handleChange}
                    placeholder="Enter price per kg"
                  />
                </Form.Group>
              </Col>
              <Col className="my-2" sm={12} lg={12} md={12}>
                <Form.Group controlId="formRange501-1000">
                  <Form.Label>
                    From 1001 to <FaInfinity />
                  </Form.Label>
                  <Form.Control
                    type="number"
                    name="range_1001_more"
                    value={formData.range_1001_more}
                    onChange={handleChange}
                    placeholder="Enter price per kg"
                  />
                </Form.Group>
              </Col>
            </>
          )}
          <Col className="my-2" sm={12} lg={12} md={12}>
            <Form.Group controlId="formIsActive">
              <Form.Check
                type="checkbox"
                name="is_active"
                checked={formData.is_active}
                onChange={handleChange}
                label="Is Active"
                style={{ margin: '10px 0' }}
              />
            </Form.Group>
          </Col>
          <Col className="my-2" sm={12} lg={12} md={12}>
            <Form.Select
              name="region"
              onChange={handleChange}
              aria-label="Default select example"
            >
              <option defaultValue={formData.region}>{formData.region}</option>
              <option value="INSIDE_UAE">Inside UAE</option>
              <option value="OUTSIDE_UAE">Outside UAE</option>
            </Form.Select>
          </Col>
        </Row>

        {isLoading ? (
          <Button
            variant="primary"
            className="d-inline-flex my-2 flex-center gap-1"
            disabled
          >
            <Spinner
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            />
            Loading...
          </Button>
        ) : (
          <Button className="my-2" type="submit" variant="primary">
            Edit ✍🏻
          </Button>
        )}
      </Form>
    </div>
  );
};

EditShippingInformation.propTypes = {
  data: PropTypes.any
};
export default EditShippingInformation;
