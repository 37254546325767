/* eslint-disable no-unused-vars */
// import PageHeader from 'components/common/PageHeader';
import SoftBadge from 'components/common/SoftBadge';
import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
// import OrderInfoCustomer from './OrderInfoSupplier';
import OrderedProductsCustomer from './OrderedProductsSuppler';

import { TbTruckDelivery } from 'react-icons/tb';
import { GiCheckMark, GiReceiveMoney } from 'react-icons/gi';
import { MdPendingActions } from 'react-icons/md';
import { AiOutlineStop } from 'react-icons/ai';
import { BsClockHistory } from 'react-icons/bs';

// import { customerOrderNumberGenerator } from 'helpers/customerOrderNumberGenerator';
const OrderDetailsSupplier = () => {
  const location = useLocation().state;
  const { orderId } = useParams();
  const { order_status, total_cost } = location;
  const status = status => {
    if (String(status).toLowerCase() === 'awaiting_shipment') {
      //BsClockHistory
      return (
        <SoftBadge pill bg="primary" className="me-2">
          Awaiting shipment {<TbTruckDelivery size={16} />}
        </SoftBadge>
      );
    }
    if (String(order_status).toLowerCase() === 'awaiting_payment') {
      //BsClockHistory
      return (
        <SoftBadge pill bg="warning" className="me-2">
          Awaiting Payment {<BsClockHistory size={16} />}
        </SoftBadge>
      );
    }
    if (String(status).toLowerCase() === 'awaiting_pickup') {
      //BsClockHistory
      return (
        <SoftBadge pill bg="secondary" className="me-2">
          Awaiting payment {<MdPendingActions size={16} />}
        </SoftBadge>
      );
    }

    if (String(status).toLowerCase() === 'partially_paid') {
      //BsClockHistory
      return (
        <SoftBadge pill bg="info" className="me-2">
          Partially paid {<GiReceiveMoney size={16} />}
        </SoftBadge>
      );
    }

    if (String(status).toLowerCase() === 'declined') {
      //BsClockHistory
      return (
        <SoftBadge pill bg="danger" className="me-2">
          Declined {<AiOutlineStop size={16} />}
        </SoftBadge>
      );
    }

    return (
      <SoftBadge pill bg="success" className="me-2">
        Completed {<GiCheckMark size={16} />}
      </SoftBadge>
    );
  };
  const navigate = useNavigate();
  return (
    <>
      {/* <PageHeader
        go_back={() => {
          navigate('/customer/e-commerce/get/orders');
        }}
        // invoice_token={invoice_token}
        title={`Order Number: #${customerOrderNumberGenerator(order_id)}`}
        titleTag="h5"
        className="mb-3 text-700 text-primary"
      >
        <p className="fs--1 mt-1">{String(date)}</p>
        <div>
          <strong className="me-2">Status: </strong>
          {status(order_status)}
        </div>
      </PageHeader>
      <OrderInfoCustomer
        // bank_receipt_file={bank_receipt_file}
        // payment_type={payment_type}
        x={x}
        id={order_id}
      /> */}
      <OrderedProductsCustomer
        shipping_fee={100}
        orderId={orderId}
        total_cost={total_cost}
      />
    </>
  );
};

export default OrderDetailsSupplier;
