import React, { useState, useEffect } from 'react';
import './ScrollToTopButton.css';
import { FaArrowUp } from 'react-icons/fa';
const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    setIsVisible(scrollTop > 300);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div
      className={`scroll-to-top-button bg-primary ${
        isVisible ? 'visible' : ''
      }`}
      onClick={scrollToTop}
    >
      <FaArrowUp color="white" size={20} aria-hidden="true"></FaArrowUp>
    </div>
  );
};

export default ScrollToTopButton;
