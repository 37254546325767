/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import PageHeader from 'components/common/PageHeader';
import { customerOrderNumberGenerator } from 'helpers/customerOrderNumberGenerator';
import OrderedProductsAdmin from './Order detail/OrderedProductsAdmin';
import OrderInfoAdmin from './Order detail/OrderInfoAdmin';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import { BASE_URL } from 'constants/url';
import decryptData from 'secret/decryptData';
import DevLog from 'helpers/DevLog';
const AdminOrderDetails = () => {
  const navigate = useNavigate();
  const location = useLocation().state;
  const { orderId } = useParams();
  const {
    order_status,
    total_cost,
    payment_type,
    bank_receipt_file,
    date,
    order_id,
    x,
    tax_fee,
    shipping_fee
  } = location;
  DevLog({
    location
  });
  const changeOrderStatusHandler = async () => {
    await axios
      .patch(
        // {{base_url)}dashboard/order/update/4/
        BASE_URL + `/dashboard/order/update/${orderId}/`,
        { is_new: false },
        {
          headers: {
            Authorization: `JWT ${decryptData('token')}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then(res => {
        return toast.success(`This order marked as read, it is no longer new`, {
          theme: 'colored',
          position: 'top-right',
          autoClose: true
        });
      })
      .catch(err => DevLog(err));
  };
  useEffect(() => {
    changeOrderStatusHandler();
  }, []);
  return (
    <>
      <PageHeader
        title={`Order Number: #${customerOrderNumberGenerator(order_id)}`}
        titleTag="h5"
        go_back={() => {
          navigate('/admin/e-commerce/orders/all-orders');
        }}
        className="mb-3 text-700 text-primary"
      >
        <p className="fs--1 mt-1">{String(date)}</p>
        <div>
          <strong className="me-2">Status: </strong>
          {order_status.replace('_', ' ')}
        </div>
      </PageHeader>
      {/* <OrderInfoCustomer payment_type={payment_type} x={x} /> */}
      <OrderInfoAdmin
        bank_receipt_file={bank_receipt_file}
        payment_type={payment_type}
        x={x}
        id={order_id}
      />
      <OrderedProductsAdmin
        shipping_fee={shipping_fee}
        tax_fee={tax_fee}
        orderId={orderId}
        total_cost={total_cost}
      />
    </>
  );
};

export default AdminOrderDetails;
