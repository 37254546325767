import React from 'react';
import { Button, Card } from 'react-bootstrap';
import { FiLogOut } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

const AccountUnderModerationPage = () => {
  const navigate = useNavigate();

  return (
    <div className="p-5">
      <Card className="p-4 min-vh-100">
        <h1 className="text-center text-primary fs-6">
          Your account has been sent to our moderators for review.
        </h1>
        <p className="text-center">
          We appreciate your patience as we ensure the highest quality
          experience for all users.
        </p>

        <div className="d-flex justify-content-center">
          <Button
            onClick={() => {
              localStorage.clear();
              navigate('/');
            }}
            className="fs-4"
          >
            Log out <FiLogOut size={28} />
          </Button>
        </div>
      </Card>
    </div>
  );
};

export default AccountUnderModerationPage;
