import React from 'react';
import { Route, Routes } from 'react-router-dom';
import AuthSimpleLayout from '../layouts/AuthSimpleLayout';
import Profile from 'components/pages/user/profile/Profile';
import Settings from 'components/pages/user/settings/Settings';
import Ecommerce from 'components/dashboards/e-commerce admin';
import SimpleLogin from 'components/authentication/simple login layout/Login';
import SimpleRegistration from 'components/authentication/simple login layout/Registration';
import SimpleForgetPassword from 'components/authentication/simple login layout/ForgetPassword';
import SimpleConfirmMail from 'components/authentication/simple login layout/ConfirmMail';
import MainLayoutSuppliers from 'layouts/MainLayoutSuppliers';
import PasswordResetForm from 'components/authentication/login forms/PasswordResetForm';
import MainLayoutAdmin from 'layouts/MainLayoutAdmin';
import Analytics from 'components/dashboards/analytics';
import MainLayout from 'layouts/MainLayout';
import Crm from 'components/dashboards/crm';
import Dashboard from 'components/dashboards/default';
import Saas from 'components/dashboards/saas';
import ApprovedSuppliers from 'components/pages/admin/suppliers/ApprovedSuppliers';
import UpdateRequiredSuppliers from 'components/pages/admin/suppliers/UpdateRequiredSuppliers';
import PendingSuppliers from 'components/pages/admin/suppliers/PendingSuppliers';
import UpdateRequiredCustomers from 'components/pages/admin/customers/UpdateRequiredCustomers';
import PendingCustomers from 'components/pages/admin/customers/PendingCustomers';
import BlockedCustomers from 'components/pages/admin/customers/BlacklistCustomers';
import MainLayoutCustomers from 'layouts/MainLayoutCustomers';
import CustomerProfileSettings from 'components/pages/user/settings/CustomerProfileSettings/CustomerProfileSettings';
import UpdatedCustomers from 'components/pages/admin/customers/UpdatedCustomers';
import UpdatedSuppliers from 'components/pages/admin/suppliers/UpdatedSuppliers';
// import SupplierProducts from 'components/app/e-commerce suppliers/product/upload/laptop/LaptopProducts';
// import CustomerUploadNewProduct from 'components/app/e-commerce suppliers/product/upload/laptop/SupplierUploadLaptop';
import SupplierUploadLaptop from 'components/app/e-commerce suppliers/product/upload/laptop/SupplierUploadLaptop';
import LaptopCheckoutPage from 'components/app/e-commerce suppliers/product/upload/laptop/LaptopCheckoutPage';
import CreateMobilePhone from 'components/app/e-commerce admin/product/create new products froms/CreateMobilePhone';
import CreateLaptop from 'components/app/e-commerce admin/product/create new products froms/CreateLaptop';
import CreateGamingConsole from 'components/app/e-commerce admin/product/create new products froms/CreateGamingConsole';
import CreateWearable from 'components/app/e-commerce admin/product/create new products froms/CreateWearable';
import BlockedSuppliers from 'components/pages/admin/suppliers/BlackListedSuppliers';
import AdminMobilePhones from 'components/app/e-commerce admin/product/view and edit products/mobile phone/AdminMobilePhones';
import AdminLaptops from 'components/app/e-commerce admin/product/view and edit products/laptops/AdminLaptops';
import AdminGaminConsole from 'components/app/e-commerce admin/product/view and edit products/gaming console/AdminGaminConsole';
import AdminWearables from 'components/app/e-commerce admin/product/view and edit products/wearables/AdminWearables';
import EditAdminMobilePhone from 'components/app/e-commerce admin/product/view and edit products/mobile phone/EditAdminMobilePhone';
import EditAdminLaptops from 'components/app/e-commerce admin/product/view and edit products/laptops/EditAdminLaptops';
import EditAdminGamingConsole from 'components/app/e-commerce admin/product/view and edit products/gaming console/EditAdminGamingConsole';
import EditAdminWearables from 'components/app/e-commerce admin/product/view and edit products/wearables/EditAdminWearables';
import ApprovedCustomers from 'components/pages/admin/customers/ApprovedCustomers';
import SupplierUploadMobile from 'components/app/e-commerce suppliers/product/upload/mobile/SupplierUploadMobile';
import SupplierUploadWearable from 'components/app/e-commerce suppliers/product/upload/wearable/SupplierUploadWearable';
import SupplierUploadGaming from 'components/app/e-commerce suppliers/product/upload/Gaming/SupplierUploadGaming';
import GamingCheckoutPage from 'components/app/e-commerce suppliers/product/upload/Gaming/GamingCheckoutPage';
import GamingProducts from 'components/app/e-commerce suppliers/product/upload/Gaming/GamingProducts';

import MobileProducts from 'components/app/e-commerce suppliers/product/upload/mobile/MobileProducts';
import WearableProducts from 'components/app/e-commerce suppliers/product/upload/wearable/WearableProducts';
import WearableCheckoutPage from 'components/app/e-commerce suppliers/product/upload/wearable/WearableCheckoutPage';
import LaptopProducts from 'components/app/e-commerce suppliers/product/upload/laptop/LaptopProducts';
// import TestDataTable from 'constants/TestDataTable';
import GetMobile from 'components/app/e-commerce customers/product/categories/mobile devices/GetMobile';
import GetLaptops from 'components/app/e-commerce customers/product/categories/laptops/GetLaptops';
import GetGamingConsoles from 'components/app/e-commerce customers/product/categories/gaming consoles/GetGamingConsoles';
import GetWearables from 'components/app/e-commerce customers/product/categories/wearables/GetWearables';
import CustomerShoppingCartPage from 'components/app/e-commerce customers/product/Customer shopping cart/CustomerShoppingCart';
import CheckoutCustomer from 'components/app/e-commerce customers/product/cart/CheckoutCustomers';
import ShippingProvider from 'components/app/e-commerce customers/product/cart/ShippingProvider';
import CustomerOrdersPage from 'components/app/e-commerce customers/orders/CustomerOrdersPage';
import PaymentProvider from 'components/app/e-commerce customers/product/cart/payment context/PaymentProvider';
import AdminCreateNewShippingAddress from 'components/app/e-commerce admin/shipping/create new shipping/AdminCreateNewShippingAddress';
import GetAllShippingAddress from 'components/app/e-commerce admin/shipping/view shipping address/GetAllShippingAddress';
import ErrorLayout from 'layouts/ErrorLayout';
import Error404 from 'components/errors/Error404';
import Error500 from 'components/errors/Error500';
import OrderDetailsCustomer from 'components/app/e-commerce customers/orders/Order detail/OrderDetailsCustomer';
import AdminAllOrders from 'components/app/e-commerce admin/orders/all orders customer/AdminAllOrders';
import AdminCompletedOrders from 'components/app/e-commerce admin/orders/completed orders/AdminCompletedOrders';
import AdminOrderDetails from 'components/app/e-commerce admin/orders/all orders customer/AdminOrderDetails';
import CompletedOrdersCustomer from 'components/app/e-commerce customers/orders/complted orders/CompletedOrdersCustomer';
import TopUpBalance from 'components/pages/user/profile/TopUpBalance';
import CustomerDepositsList from 'components/app/e-commerce admin/deposits/customer/CustomerDepositsList';
import SupplierDepositsList from 'components/app/e-commerce admin/deposits/supplier/SupplierDepositsList';
import AllSuppliers from 'components/pages/admin/suppliers/AllSuppliers';
import AllCustomers from 'components/pages/admin/customers/AllCustomers';
import ProfileSupplier from 'components/pages/user/profile/ProfileSupplier';
import OrderInProgressSupplier from 'components/app/e-commerce suppliers/orders/OrderInProgressSupplier';
import OrderCompletedSupplier from 'components/app/e-commerce suppliers/orders/OrderCompletedSupplier';
import OrderArchivedSupplier from 'components/app/e-commerce suppliers/orders/OrderArchivedSupplier';
import AdminAllOrdersSupplier from 'components/app/e-commerce admin/orders/all orders supplier/AdminAllOrdersSupplier';
import AdminOrderDetailsSupplier from 'components/app/e-commerce admin/orders/all orders supplier/AdminOrderDetailsSupplier';
import OrderDetailsSupplier from 'components/app/e-commerce suppliers/orders/Order detail/OrderDetailsSupplier';
import TestComponent from 'components/test/TestComponent';
import { TimerProvider } from 'context/TimerContext';
import SupplierPage from 'components/app/e-commerce admin/suppliers/SupplierPage';
import SupplierPageWithComments from 'components/app/e-commerce admin/suppliers/SupplierPageWithComments';
import CustomersPage from 'components/app/e-commerce admin/Customers/CustomersPage';
import CustomersPageWithComments from 'components/app/e-commerce admin/Customers/CustomersPageWithComments';
import { LanguageProvider } from 'provider/LanguageProvider';
import PrivacyPolicy from 'components/pages/terms & condition & privacy & policy/PrivacyPolicy';
import TermsAndConditions from 'components/pages/terms & condition & privacy & policy/TermsAndConditions';
import useBrowserDetection from 'hooks/useBrowserDetection';
import BrowserRecommendationMessage from 'components/BrowserRecommendationMessage';
import SupplierCheckoutPage from 'components/app/e-commerce suppliers/product/upload/mobile/SupplierCheckoutPage';
import UnderReview from 'components/pages/UnderReview';
import CustomerInnerSettings from 'components/pages/user/settings/CustomerProfileSettings/profile/CustomerInnerSettings';
import AccountBlockedPage from 'components/pages/AccountBlockedPage';
import AccountUnderModerationPage from 'components/pages/AccountUnderModerationPage';

const FalconRoutes = () => {
  const browser = useBrowserDetection();
  const detectInvalidBrowser = browser => {
    if (
      browser == 'Microsoft Edge' ||
      browser == 'Mozilla Firefox' ||
      browser == 'Unknown'
    ) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <LanguageProvider>
      <Routes>
        <Route element={<ErrorLayout />}>
          <Route path="errors/404" element={<Error404 />} />
          <Route path="errors/500" element={<Error500 />} />
        </Route>
        <Route path="/" element={<AuthSimpleLayout />}>
          <Route
            path="/"
            element={
              detectInvalidBrowser(browser) ? (
                <BrowserRecommendationMessage />
              ) : (
                <SimpleLogin />
              )
            }
          />
          <Route path="/agt/privacy-and-policy" element={<PrivacyPolicy />} />
          <Route
            path="/agt/terms-and-conditions"
            element={<TermsAndConditions />}
          />

          {/* /forgot-password */}
          <Route
            path="/activate/:uuid/:token"
            element={<SimpleConfirmMail />}
          />
          <Route
            path="/forgot-password"
            element={
              detectInvalidBrowser(browser) ? (
                <BrowserRecommendationMessage />
              ) : (
                <SimpleForgetPassword />
              )
            }
          />
          <Route
            path="/registration"
            element={
              detectInvalidBrowser(browser) ? (
                <BrowserRecommendationMessage />
              ) : (
                <SimpleRegistration />
              )
            }
          />
          <Route
            path="/password/reset/confirm/:uid/:token"
            element={
              detectInvalidBrowser(browser) ? (
                <BrowserRecommendationMessage />
              ) : (
                <PasswordResetForm />
              )
            }
          />
        </Route>
        <Route path="/supplier/user/settings" element={<Settings />} />
        {/* //--- MainLayout Supplier Starts  */}
        <Route
          element={
            <LanguageProvider>
              <TimerProvider>
                <MainLayoutSuppliers />
              </TimerProvider>
            </LanguageProvider>
          }
        >
          <Route path="/supplier/user/profile" element={<ProfileSupplier />} />
          <Route path="/supplier/user/inner-settings" element={<Settings />} />
          <Route
            path="/supplier/dashboard/e-commerce"
            element={<Ecommerce />}
          />

          <Route
            path="/supplier/e-commerce/shopping-cart-laptop"
            element={<LaptopCheckoutPage />}
          />
          <Route
            path="/supplier/e-commerce/shopping-cart-gaming"
            element={<GamingCheckoutPage />}
          />
          <Route
            path="/supplier/e-commerce/checkout-products"
            element={<SupplierCheckoutPage />}
          />
          <Route
            path="/supplier/e-commerce/shopping-wearable"
            element={<WearableCheckoutPage />}
          />

          <Route
            path="/supplier/e-commerce/product/Laptop"
            element={<LaptopProducts />}
          />
          <Route
            path="/supplier/e-commerce/product/Gaming"
            element={<GamingProducts />}
          />
          <Route
            path="/supplier/e-commerce/product/Mobile"
            element={<MobileProducts />}
          />
          <Route
            path="/supplier/e-commerce/product/Wearable"
            element={<WearableProducts />}
          />
          <Route
            path="/supplier/e-commerce/product/upload-new-laptop"
            element={<SupplierUploadLaptop />}
          />
          <Route
            path="/supplier/e-commerce/product/upload-new-mobile"
            element={<SupplierUploadMobile />}
          />
          <Route
            path="/supplier/e-commerce/product/upload-new-wearable"
            element={<SupplierUploadWearable />}
          />
          <Route
            path="/supplier/e-commerce/product/upload-new-gaming-console"
            element={<SupplierUploadGaming />}
          />

          {/* orders */}
          <Route
            path="/supplier/e-commerce/order/:orderId"
            element={<OrderDetailsSupplier />}
          />
          <Route
            path="/supplier/e-commerce/orders/in-progress"
            element={<OrderInProgressSupplier />}
          />
          <Route
            path="/supplier/e-commerce/orders/completed"
            element={<OrderCompletedSupplier />}
          />
          <Route
            path="/supplier/e-commerce/orders/archived"
            element={<OrderArchivedSupplier />}
          />

          {/* end order */}
        </Route>

        {/* Customers */}
        <Route path="/under-review" element={<UnderReview />} />
        <Route path="/blocked" element={<AccountBlockedPage />} />
        <Route path="/updated" element={<AccountUnderModerationPage />} />
        <Route
          path="/customer/user/settings"
          element={<CustomerProfileSettings />}
        />
        <Route
          element={
            <TimerProvider>
              <MainLayoutCustomers />
            </TimerProvider>
          }
        >
          <Route
            path="/customer/user/inner-settings"
            element={<CustomerInnerSettings />}
          />
          <Route path="/customer/user/profile" element={<Profile />} />
          <Route
            path="/customer/dashboard/drag-drop"
            element={<TestComponent />}
          />
          <Route
            path="/customer/user/top-up-balance"
            element={<TopUpBalance />}
          />
          <Route
            path="/customer/dashboard/e-commerce"
            element={<Ecommerce />}
          />
          <Route
            path="/customer/e-commerce/purchase/mobile-device"
            element={<GetMobile />}
          />
          <Route
            path="/customer/e-commerce/purchase/laptops"
            element={<GetLaptops />}
          />
          <Route
            path="/customer/e-commerce/purchase/gaming-consoles"
            element={<GetGamingConsoles />}
          />
          <Route
            path="/customer/e-commerce/purchase/wearables"
            element={<GetWearables />}
          />
          <Route
            path="/customer/e-commerce/orders"
            element={<CustomerShoppingCartPage />}
          />
          <Route
            path="/customer/e-commerce/order/:orderId"
            element={<OrderDetailsCustomer />}
          />
          <Route
            path="/customer/e-commerce/get/orders"
            element={<CustomerOrdersPage />}
          />
          <Route
            path="/customer/e-commerce/get/orders/completed"
            element={<CompletedOrdersCustomer />}
          />
          <Route
            path="/customer/e-commerce/checkout"
            element={
              <PaymentProvider>
                <ShippingProvider>
                  <CheckoutCustomer />
                </ShippingProvider>
              </PaymentProvider>
            }
          />
        </Route>

        <Route element={<MainLayoutAdmin />}>
          <Route path="/admin/dashboard" element={<Dashboard />} />
          <Route path="admin/dashboard/analytics" element={<Analytics />} />
          <Route path="admin/dashboard/crm" element={<Crm />} />
          <Route path="admin/dashboard/saas" element={<Saas />} />
          <Route path="admin/dashboard/e-commerce" element={<Ecommerce />} />
          <Route
            path="admin/users/customers/deposits"
            element={<CustomerDepositsList />}
          />
          <Route
            path="admin/users/suppliers/deposits"
            element={<SupplierDepositsList />}
          />
          {/* --------------------- Admin shipping address create {start} --------------------- */}
          <Route
            path="/admin/shippingAddress/create"
            element={<AdminCreateNewShippingAddress />}
          />
          <Route
            path="/admin/get/shippingAddress/"
            element={<GetAllShippingAddress />}
          />
          {/* --------------------- Admin shipping address create {end} --------------------- */}
          {/* admin/shippingAddress/create */}
          {/* --------------------- Admin product create {start} --------------------- */}
          <Route
            path="/admin/e-commerce admin/product/upload_mobile"
            element={<CreateMobilePhone />}
          />
          <Route
            path="/admin/e-commerce admin/product/upload_laptop"
            element={<CreateLaptop />}
          />
          <Route
            path="/admin/e-commerce admin/product/upload_gaming_console"
            element={<CreateGamingConsole />}
          />
          <Route
            path="/admin/e-commerce admin/product/upload_wearable"
            element={<CreateWearable />}
          />
          {/* --------------------- Admin product create {end} --------------------- */}
          {/* ----------------- Admin get products start ------------------- */}
          <Route
            path="/admin/e-commerce/product/mobile_phones"
            element={<AdminMobilePhones />}
          />
          <Route
            path="/admin/e-commerce/product/mobile_phones/edit/:id"
            element={<EditAdminMobilePhone />}
          />
          {/* -------- */}
          <Route
            path="/admin/e-commerce/product/laptops"
            element={<AdminLaptops />}
          />
          <Route
            path="/admin/e-commerce/product/laptops/edit/:id"
            element={<EditAdminLaptops />}
          />
          {/* -------- */}
          {/* ----------------Orders start---------------------- */}
          <Route
            path="/admin/e-commerce/orders/all-orders"
            element={<AdminAllOrders />}
          />
          <Route
            path="/admin/e-commerce/orders/:orderId/detail"
            element={<AdminOrderDetails />}
          />
          <Route
            path="/admin/e-commerce/orders/completed-orders"
            element={<AdminCompletedOrders />}
          />
          <Route
            path="/admin/e-commerce/supplier/orders/all-orders"
            element={<AdminAllOrdersSupplier />}
          />
          <Route
            path="/admin/e-commerce/supplier/orders/:orderId/detail"
            element={<AdminOrderDetailsSupplier />}
          />
          <Route
            path="/admin/e-commerce/supplier/orders/completed-orders"
            element={<AdminCompletedOrders />}
          />
          /supplier
          {/* ----------------Orders end---------------------- */}
          {/* -------- */}
          <Route
            path="/admin/e-commerce/product/gaming_consoles"
            element={<AdminGaminConsole />}
          />
          <Route
            path="/admin/e-commerce/product/gaming_consoles/edit/:id"
            element={<EditAdminGamingConsole />}
          />
          {/* -------- */}
          <Route
            path="/admin/e-commerce/product/wearables"
            element={<AdminWearables />}
          />
          <Route
            path="/admin/e-commerce/product/wearables/edit/:id"
            element={<EditAdminWearables />}
          />
          {/* -------- */}
          {/* ----------------- Admin get products end ------------------- */}
          {/* --------------------- Suppliers start --------------------- */}
          <Route path="admin/users/suppliers/all" element={<AllSuppliers />} />
          <Route path="admin/users/customers/all" element={<AllCustomers />} />
          <Route
            path="admin/users/suppliers/approved"
            element={<ApprovedSuppliers />}
          />
          <Route
            path="/admin/supplier/supplierPage"
            element={<SupplierPage />}
          />
          <Route
            path="/admin/supplier/supplierPageWithComments"
            element={<SupplierPageWithComments />}
          />
          <Route
            path="/admin/customers/customersPage"
            element={<CustomersPage />}
          />
          <Route
            path="/admin/customer/customerPageWithComments"
            element={<CustomersPageWithComments />}
          />
          <Route
            path="admin/users/suppliers/rejected"
            element={<UpdateRequiredSuppliers />}
          />
          <Route
            path="admin/users/suppliers/pending"
            element={<PendingSuppliers />}
          />
          <Route
            path="admin/users/suppliers/blacklist"
            element={<BlockedSuppliers />}
          />
          <Route
            path="admin/users/suppliers/updated"
            element={<UpdatedSuppliers />}
          />
          {/* --------------------- Suppliers end --------------------- */}
          {/* --------------------- Customers start --------------------- */}
          <Route
            path="admin/users/customers/approved"
            element={<ApprovedCustomers />}
          />
          <Route
            path="admin/users/customers/rejected"
            element={<UpdateRequiredCustomers />}
          />
          <Route
            path="admin/users/customers/pending"
            element={<PendingCustomers />}
          />
          <Route
            path="admin/users/customers/blacklist"
            element={<BlockedCustomers />}
          />
          <Route
            path="admin/users/customers/updated"
            element={<UpdatedCustomers />}
          />
          {/* --------------------- Customers end --------------------- */}
        </Route>

        <Route element={<MainLayout />}></Route>

        {/* //--- MainLayout end  */}

        {/* <Navigate to="/errors/404" /> */}
        {/* <Route path="*" element={<Navigate to="/errors/404" replace />} /> */}
      </Routes>
    </LanguageProvider>
  );
};

export default FalconRoutes;
