import React, { useState } from 'react';
import PaymentContext from './PaymentContext';
import PropTypes from 'prop-types';

const PaymentProvider = ({ children }) => {
  const [paymentMethod, setPaymentMethod] = useState('CASH');

  return (
    <PaymentContext.Provider value={{ paymentMethod, setPaymentMethod }}>
      {children}
    </PaymentContext.Provider>
  );
};

PaymentProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default PaymentProvider;
