/* eslint-disable no-unused-vars */
import axios from 'axios';
import { BASE_URL } from 'constants/url';
import DevLog from 'helpers/DevLog';
import React, { useState } from 'react';
import {
  Card,
  Form,
  FormControl,
  Button,
  Row,
  Col,
  Spinner
} from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import decryptData from 'secret/decryptData';

const SupplierPage = props => {
  const { state } = useLocation();
  const { id } = state;
  const userData = { ...state, ...state.supplier };
  const [formData, setFormData] = useState(userData);
  const [editing, setEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const changeUserStatus = async status => {
    const response = confirm("Are you sure you want to change user's status");
    if (response) {
      setIsLoading(true);
      await axios
        .patch(BASE_URL + `/dashboard/suppliers/${id}/`, status, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `JWT ${decryptData('token')}`
          }
        })
        .then(r => {
          DevLog({ r });
          if (r.status === 200) {
            return toast.success(
              `User's status  has been successfully updated `,
              {
                theme: 'colored',
                position: 'top-right',
                autoClose: false
              }
            );
          }
        })
        .catch(err => {
          DevLog(err);
          toast.error(`Something went wrong! ${err.message} `, {
            theme: 'colored',
            position: 'top-right',
            autoClose: false
          });
          setIsLoading(false);
        })
        .finally(() => setIsLoading(false));
    } else {
      setIsLoading(false);
    }
  };

  const handleSubmit = e => {
    e.preventDefault();
    DevLog(state);
    DevLog('Submitted values:', formData);
    changeUserStatus(formData);
    // You can handle the submission logic here, e.g., update the backend
  };

  return (
    <div style={{ padding: '20px' }}>
      <Card>
        <Card.Header as="h5">Customer Information</Card.Header>
        <Card.Body>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col sm={12} md={6} lg={6} xxl={6}>
                <Form.Group controlId="userStatus">
                  <Form.Label className="text-900 mt-3">User Status</Form.Label>
                  <Form.Control
                    as="select"
                    defaultValue={formData.user_status}
                    onChange={e => setFormData({ user_status: e.target.value })}
                    disabled={!editing}
                  >
                    <option value="APPROVED">Approve</option>
                    <option value="PENDING">Pend</option>
                    <option value="UPDATE_REQUIRED">Update required</option>
                    <option value="UPDATED">Updated</option>
                    <option value="BLOCKED">Block</option>
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col sm={12} md={6} lg={6} xxl={6}>
                <Form.Group controlId="businessName">
                  <Form.Label className="text-900 mt-3">
                    Business Name
                  </Form.Label>
                  <FormControl
                    type="text"
                    value={formData.business_name}
                    disabled={true}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={6} lg={6} xxl={6}>
                <Form.Group controlId="businessAddress">
                  <Form.Label className="text-900 mt-3">
                    Business Address
                  </Form.Label>
                  <FormControl
                    type="text"
                    value={formData.business_address}
                    disabled={true}
                  />
                </Form.Group>
              </Col>
              <Col sm={12} md={6} lg={6} xxl={6}>
                <Form.Group controlId="contactPerson">
                  <Form.Label className="text-900 mt-3">
                    Contact Person
                  </Form.Label>
                  <FormControl
                    type="text"
                    value={formData.contact_person}
                    disabled={true}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={6} lg={6} xxl={6}>
                <Form.Group controlId="country">
                  <Form.Label className="text-900 mt-3">Country</Form.Label>
                  <FormControl
                    type="text"
                    value={formData.country}
                    disabled={true}
                  />
                </Form.Group>
              </Col>
              <Col sm={12} md={6} lg={6} xxl={6}>
                <Form.Group controlId="phoneNumber">
                  <Form.Label className="text-900 mt-3">
                    Phone Number
                  </Form.Label>
                  <FormControl
                    type="text"
                    value={formData.phone_number}
                    disabled={true}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={6} lg={6} xxl={6}>
                <Form.Group controlId="vatNumber">
                  <Form.Label className="text-900 mt-3">VAT Number</Form.Label>
                  <FormControl
                    type="text"
                    value={formData.vat_number}
                    disabled={true}
                  />
                </Form.Group>
              </Col>
              <Col sm={12} md={6} lg={6} xxl={6}>
                <Form.Group controlId="bankAddress">
                  <Form.Label className="text-900 mt-3">
                    Bank Address
                  </Form.Label>
                  <FormControl
                    type="text"
                    value={formData.bank_address}
                    disabled={true}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={6} lg={6} xxl={6}>
                <Form.Group controlId="bankAccountNumber">
                  <Form.Label className="text-900 mt-3">
                    Bank Account Number
                  </Form.Label>
                  <FormControl
                    type="text"
                    value={formData.account_number}
                    disabled={true}
                  />
                </Form.Group>
              </Col>
              <Col sm={12} md={6} lg={6} xxl={6}>
                <Form.Group controlId="swiftCode">
                  <Form.Label className="text-900 mt-3">SWIFT Code</Form.Label>
                  <FormControl
                    type="text"
                    value={formData.swift_code}
                    disabled={true}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group controlId="ibanNumber">
                  <Form.Label className="text-900 mt-3">IBAN Number</Form.Label>
                  <FormControl
                    type="text"
                    value={formData.iban}
                    onChange={e =>
                      setFormData({ ...formData, iban_number: e.target.value })
                    }
                    disabled={true}
                  />
                </Form.Group>
              </Col>
            </Row>

            <br />
            <Button
              variant="primary"
              type="submit"
              className="me-2"
              disabled={!editing}
            >
              {isLoading ? (
                <>
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />{' '}
                  Saving...
                </>
              ) : (
                'Save'
              )}
            </Button>
            {editing ? (
              <Button variant="secondary" onClick={() => setEditing(false)}>
                Cancel
              </Button>
            ) : (
              <Button onClick={() => setEditing(true)}>Edit</Button>
            )}
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
};

export default SupplierPage;
