import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import RegistrationForm from '../login forms/RegistrationForm';
import { useLanguage } from 'provider/LanguageProvider';
import { Divider } from 'antd';

const Registration = () => {
  const { language, changeLanguage } = useLanguage();

  return (
    <div className="">
      <h3 className="text-center my-3 text-primary fs-5">AGT</h3>
      <Row className="align-items-center mb-2">
        <Col>
          <h5 id="modalLabel">
            {language == 'en' && 'Register'}
            {language == 'uz' && 'Roʻyxatdan oʻtish'}
            {language == 'ru' && 'Зарегистрироваться'}
          </h5>
        </Col>
        <Col xs="auto">
          <p className="fs--1 text-600 mb-0">
            {language == 'en' && 'Have an account? '}
            {language == 'uz' && 'Hisob qaydnomangiz bormi? '}
            {language == 'ru' && 'У вас есть учетная запись? '}
            <Link to="/">
              {language == 'en' && 'Login'}
              {language == 'uz' && 'Kirish'}
              {language == 'ru' && 'Войти'}
            </Link>
          </p>
        </Col>
      </Row>
      <RegistrationForm />

      <Divider className="text-primary">
        {language == 'en' && 'Please, select a Language'}
        {language == 'uz' && 'Iltimos, tilni tanlang'}
        {language == 'ru' && 'Пожалуйста, выберите язык'}
      </Divider>

      <div className="w-100 d-flex justify-content-center">
        <div className="d-flex w-50 justify-content-around pt-3  ">
          <p
            onClick={() => {
              changeLanguage('uz');
            }}
            className={
              language == 'uz'
                ? 'mx-3 bg-soft-info p-2 text-primary rounded px-3  cursor-pointer hover-1000'
                : 'mx-3  p-2 rounded px-3 text-primary  cursor-pointer hover-1000'
            }
          >
            UZ
          </p>
          <p
            onClick={() => {
              changeLanguage('en');
            }}
            className={
              language == 'en'
                ? 'mx-3 bg-soft-info p-2 text-primary rounded px-3  cursor-pointer hover-1000'
                : 'mx-3  p-2 rounded px-3  text-primary cursor-pointer hover-1000'
            }
          >
            EN
          </p>
          <p
            onClick={() => {
              changeLanguage('ru');
            }}
            className={
              language == 'ru'
                ? 'mx-3 bg-soft-info p-2 rounded px-3 text-primary   cursor-pointer hover-1000'
                : 'mx-3  p-2 rounded px-3  text-primary cursor-pointer hover-1000'
            }
          >
            RU
          </p>
        </div>
      </div>
      <p className="text-center text-info">
        {language == 'en' && 'Well done! You selected English language.'}
        {language == 'uz' && 'Yaxshi! Siz oʻzbek tilini tanladingiz.'}
        {language == 'ru' && 'Отлично! Вы выбрали русский язык.'}
      </p>
    </div>
  );
};

export default Registration;
