/* eslint-disable react/react-in-jsx-scope */
import { FaGamepad } from 'react-icons/fa';
import { BiMobileAlt } from 'react-icons/bi';
import {
  BsCardChecklist,
  BsFillBoxFill,
  BsFillCartCheckFill,
  BsFillLaptopFill,
  BsSmartwatch
} from 'react-icons/bs';
import { useContext } from 'react';
import AppContext from 'context/Context';
import React from 'react';
import { AiFillSetting } from 'react-icons/ai';
import { useLanguage } from 'provider/LanguageProvider';
import { CustomerProfilePageTranslate } from 'languages/Language';

const WidgetListCustomer = () => {
  const { language } = useLanguage();

  const {
    config: { isDark }
  } = useContext(AppContext);
  const widgetList = {
    products: [
      {
        gradientColors: isDark
          ? ['#111111', '#333333']
          : ['#00a8ff', '#55c2e5'],
        widgetName:
          CustomerProfilePageTranslate.widgets.products.mobile[language],
        widgetLink: '/customer/e-commerce/purchase/mobile-device',
        color: isDark ? '#00CCFF' : 'white',
        children: <BiMobileAlt color={isDark ? '#00CCFF' : 'white'} size={50} />
      },
      {
        gradientColors: isDark
          ? ['#000000', '#222222']
          : ['#34ace0', '#6bcbef'],
        widgetName:
          CustomerProfilePageTranslate.widgets.products.laptops[language],
        widgetLink: '/customer/e-commerce/purchase/laptops',
        color: isDark ? '#00CCFF' : 'white',
        children: (
          <BsFillLaptopFill color={isDark ? '#00CCFF' : 'white'} size={50} />
        )
      },
      {
        gradientColors: isDark
          ? ['#330033', '#550055']
          : ['#e84393', '#f984b6'],
        widgetName:
          CustomerProfilePageTranslate.widgets.products.gaming[language],
        widgetLink: '/customer/e-commerce/purchase/gaming-consoles',
        color: isDark ? '#00CCFF' : 'white',
        children: <FaGamepad color={isDark ? '#00CCFF' : 'white'} size={50} />
      },
      {
        gradientColors: isDark
          ? ['#660000', '#882222']
          : ['#ff6b6b', '#f9a7a7'],
        widgetName:
          CustomerProfilePageTranslate.widgets.products.wearable[language],
        widgetLink: '/customer/e-commerce/purchase/wearables',
        color: isDark ? '#00CCFF' : 'white',
        children: (
          <BsSmartwatch color={isDark ? '#00CCFF' : 'white'} size={50} />
        )
      }
    ],
    orders: [
      {
        gradientColors: isDark
          ? ['#1A1A1A', '#2C2C2C']
          : ['#45B1E8', '#76ABDF'],
        widgetName:
          CustomerProfilePageTranslate.widgets.orders.myShoppingCart[language],
        widgetLink: '/customer/e-commerce/orders',
        color: isDark ? '#00CCFF' : 'white',
        children: (
          <BsFillCartCheckFill color={isDark ? '#00CCFF' : 'white'} size={50} />
        )
      },
      {
        gradientColors: isDark
          ? ['#350035', '#550055']
          : ['#F2C1D1', '#C8A2C8'],
        widgetName:
          CustomerProfilePageTranslate.widgets.orders.myOrders[language],
        widgetLink: '/customer/e-commerce/get/orders',
        color: isDark ? '#00CCFF' : 'white',
        children: (
          <BsFillBoxFill color={isDark ? '#00CCFF' : 'white'} size={50} />
        )
      },
      {
        gradientColors: isDark
          ? ['#2B2B2B', '#4D4D4D']
          : ['#6A88EF', '#9564E2'],
        widgetName:
          CustomerProfilePageTranslate.widgets.orders.myCompletedOrders[
            language
          ],
        widgetLink: '/customer/e-commerce/get/orders/completed',
        color: isDark ? '#00CCFF' : 'white',
        children: (
          <BsCardChecklist color={isDark ? '#00CCFF' : 'white'} size={50} />
        )
      }
    ],
    account: [
      {
        gradientColors: isDark
          ? ['#004D4D', '#006666']
          : ['#A6E3E9', '#7BC6CC'],
        widgetName:
          CustomerProfilePageTranslate.widgets.accountSettings.settings[
            language
          ],

        widgetLink: '/customer/user/inner-settings',
        color: isDark ? '#00CCFF' : 'white',
        children: (
          <AiFillSetting color={isDark ? '#00CCFF' : 'white'} size={50} />
        )
      }
    ]
  };
  return widgetList;
};

export default WidgetListCustomer;
