import React from 'react';
import ConfirmMailContent from '../login forms/ConfirmMailContent';

const ConfirmMail = () => (
  <div className="text-center">
    <ConfirmMailContent />
  </div>
);

export default ConfirmMail;
