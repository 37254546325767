export const customerOrderNumberGenerator = id => {
  if (String(id).split('').length === 1) {
    return `000000${id}`;
  } else if (String(id).split('').length === 2) {
    return `00000${id}`;
  } else if (String(id).split('').length === 3) {
    return `0000${id}`;
  } else if (String(id).split('').length === 4) {
    return `000${id}`;
  } else if (String(id).split('').length === 5) {
    return `00${id}`;
  } else if (String(id).split('').length === 6) {
    return `0${id}`;
  } else if (String(id).split('').length === 7) {
    return id;
  } else {
    return id;
  }
};
