/* eslint-disable react/prop-types */
import axios from 'axios';
import { BASE_URL } from 'constants/url';
import DevLog from 'helpers/DevLog';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Spinner, Table } from 'react-bootstrap';
import decryptData from 'secret/decryptData';

const OrderedProductsAdmin = ({ orderId, shipping_fee, tax_fee }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [orderItems, setOrderItems] = useState([]);
  const subtotal = orderItems.reduce(
    (acc, curr) => curr.quantity * curr.rate + acc,
    0
  );

  const fetchOrderItems = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        BASE_URL + `/dashboard/order/${orderId}/`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `JWT ${decryptData('token')}`
          }
        }
      );

      const data = response.data?.map(item => {
        const { order_item_id, order_item_quantity } = item;
        const { price } = item.supplier_product;
        const { specs, product_full_name } = item.supplier_product.product;
        return {
          id: order_item_id,
          name: product_full_name,
          desc: specs == null ? '-' : specs,
          quantity: order_item_quantity,
          rate: price
        };
      });
      setOrderItems(data);
    } catch (err) {
      DevLog(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchOrderItems();
  }, []);

  if (isLoading) {
    return (
      <Card className="p-4">
        <center>
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </center>
      </Card>
    );
  }

  return (
    <Card className="mb-3 text-800">
      <Card.Body>
        <div className="table-responsive fs--1">
          <Table striped className="border-bottom">
            <thead className="bg-200 text-900">
              <tr>
                <th className="border-0 text-800 ">Products</th>
                <th className="border-0 text-800  text-center">Quantity</th>
                <th className="border-0 text-800  text-end">Rate</th>
                <th className="border-0 text-800  text-end">Amount</th>
              </tr>
            </thead>
            <tbody>
              {orderItems.map(product => (
                <tr key={product.id} className="border-200">
                  <td className="align-middle text-800">
                    <h6 className="mb-0 text-nowrap">{product.name}</h6>
                    <p className="mb-0">{product.desc}</p>
                  </td>
                  <td className="align-middle text-800 text-center">
                    {product.quantity}
                  </td>
                  <td className="align-middle text-800 text-end">
                    AED{' '}
                    {parseFloat(product.rate).toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2
                    })}
                  </td>
                  <td className="align-middle text-800 text-end">
                    AED{' '}
                    {parseFloat(product.quantity * product.rate).toLocaleString(
                      undefined,
                      { maximumFractionDigits: 2, minimumFractionDigits: 2 }
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Row className="g-0 justify-content-end">
            <Col xs="auto">
              <Table borderless size="sm" className="fs--1 text-800 text-end">
                <tbody>
                  <tr>
                    <th className="text-900">Subtotal:</th>
                    <td className="fw-semi-bold text-800">
                      AED{' '}
                      {parseFloat(subtotal).toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2
                      })}
                    </td>
                  </tr>
                  <tr>
                    <th className="text-900">Tax:</th>
                    <td className="fw-semi-bold text-800">
                      +AED {Number(tax_fee).toFixed(2)}
                    </td>
                  </tr>
                  <tr>
                    <th className="text-900">Shipping Fee:</th>
                    <td className="fw-semi-bold text-800">
                      +AED {shipping_fee || 0}
                    </td>
                  </tr>
                  <tr className="border-top">
                    <th className="text-900 ">Total:</th>
                    <td className="fw-semi-bold text-800">
                      AED{' '}
                      {parseFloat(
                        Number(subtotal) +
                          (Number(shipping_fee) || 0) +
                          (Number(tax_fee) || 0)
                      ).toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2
                      })}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </div>
      </Card.Body>
    </Card>
  );
};

OrderedProductsAdmin.propTypes = {
  total_cost: PropTypes.string,
  orderId: PropTypes.string,
  shipping_fee: PropTypes.string
};

export default OrderedProductsAdmin;
