import React from 'react';
import PropTypes from 'prop-types';

import { Alert, Col, Form, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { AiOutlineCloudDownload } from 'react-icons/ai';
import DevLog from 'helpers/DevLog';
const ApprovedSupplierForm = ({ user }) => {
  DevLog({ user });
  return (
    <div>
      <>
        <Alert className="my-3 text-center fs-4" variant={'info'}>
          Congratulation 🎉
          <br />
          <b>Your account has been approved</b>
        </Alert>
        <Form onSubmit={() => {}}>
          <Row className="mb-3 g-3">
            <Form.Group as={Col} lg={6} controlId="business_name">
              <Form.Label> Business name </Form.Label>
              <Form.Control
                type="text"
                placeholder="Business Name"
                disabled
                defaultValue={user.supplier.business_name}
                name="business_name"
              />
            </Form.Group>

            <Form.Group as={Col} lg={6} controlId="country">
              <Form.Label>Country</Form.Label>
              <Form.Control
                type="text"
                placeholder="Country"
                disabled
                defaultValue={user.supplier.country}
                name="country"
              />
            </Form.Group>
          </Row>
          <Row className="mb-3 g-3">
            <Form.Group as={Col} lg={6} controlId="business_address">
              <Form.Label>Business address</Form.Label>
              <Form.Control
                type="text"
                placeholder="Business address"
                disabled
                defaultValue={user.supplier.business_address}
                name="business_address"
              />
            </Form.Group>

            <Form.Group as={Col} lg={6} controlId="contact_person">
              <Form.Label>Contact person</Form.Label>
              <Form.Control
                type="text"
                placeholder="Contact person"
                disabled
                defaultValue={user.supplier.contact_person}
                name="contact_person"
              />
            </Form.Group>

            <Form.Group
              as={Col}
              lg={6}
              className="mb-3"
              controlId="phone_number"
            >
              <Form.Label>Phone number</Form.Label>
              <Form.Control
                type="text"
                placeholder="Phone number"
                disabled
                defaultValue={user.supplier.phone_number}
                name="phone_number"
              />
            </Form.Group>

            <Form.Group as={Col} lg={6} className="mb-3" controlId="bank_name">
              <Form.Label>Bank name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Bank name"
                disabled
                defaultValue={user.supplier.bank_name}
                name="bank_name"
              />
            </Form.Group>
            <Form.Group
              as={Col}
              lg={6}
              className="mb-3"
              controlId="bank_address"
            >
              <Form.Label>Bank address</Form.Label>
              <Form.Control
                type="text"
                placeholder="Bank address"
                disabled
                defaultValue={user.supplier.bank_address}
                name="bank_address"
              />
            </Form.Group>
            <Form.Group
              as={Col}
              lg={6}
              className="mb-3"
              controlId="account_number"
            >
              <Form.Label>Account number</Form.Label>
              <Form.Control
                type="text"
                placeholder="Account number"
                disabled
                defaultValue={user.supplier.account_number}
                name="account_number"
              />
            </Form.Group>
            <Form.Group as={Col} lg={6} className="mb-3" controlId="vat_number">
              <Form.Label>VAT number</Form.Label>
              <Form.Control
                type="text"
                placeholder="VAT number"
                disabled
                defaultValue={user.supplier.vat_number}
                name="vat_number"
              />
            </Form.Group>
            <Form.Group as={Col} lg={6} className="mb-3" controlId="swift_code">
              <Form.Label>SWIFT code</Form.Label>
              <Form.Control
                type="text"
                maxLength={15}
                placeholder="SWIFT code"
                disabled
                defaultValue={user.supplier.swift_code}
                name="swift_code"
              />
            </Form.Group>
            <Form.Group as={Col} lg={12} className="mb-3" controlId="iban">
              <Form.Label>IBAN</Form.Label>
              <Form.Control
                type="text"
                placeholder="IBAN code"
                disabled
                defaultValue={user.supplier.iban}
                name="iban"
              />
            </Form.Group>
          </Row>
          <Row>
            {user.supplier.license ? (
              <Col>
                <Link to={user.supplier.license}>
                  Download License{' '}
                  <AiOutlineCloudDownload size={27} className="mx-2" />
                </Link>
              </Col>
            ) : (
              <Col>
                <Alert variant="danger">
                  You have not uploaded your license! Please contact with
                  Administrator
                </Alert>
              </Col>
            )}
            {user.supplier.vat_certificate ? (
              <Col>
                <Link to={user.supplier.vat_certificate}>
                  Download VAT certificate{' '}
                  <AiOutlineCloudDownload size={27} className="mx-2" />
                </Link>
              </Col>
            ) : (
              <Col>
                <Alert variant="danger">
                  You have not uploaded your VAT License! Please contact with
                  Administrator
                </Alert>
              </Col>
            )}
          </Row>
        </Form>
      </>
    </div>
  );
};
ApprovedSupplierForm.propTypes = {
  user: PropTypes.object.isRequired
};

export default ApprovedSupplierForm;
