// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AdminAdvanceTableFooter from 'components/common/advance-table/AdminAdvancedTableFooter';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import moment from 'moment';

import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import React, { useEffect, useState } from 'react';
import {
  // Button,
  Card,
  Col,
  // FormControl,
  // InputGroup,
  Pagination,
  Row
} from 'react-bootstrap';
// import AdminAdvancedDateRangePicker from '../../components/Date picker with range/AdminAdvancedDateRangePicker';
import Divider from 'components/common/Divider';
import { customerOrderNumberGenerator } from 'helpers/customerOrderNumberGenerator';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { BASE_URL } from 'constants/url';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import TableSkeleton from 'components/common/skeletons/table skleton/TableSkleton';
// import DropdownWithCheckbox from '../DropdownWithCheckbox';
import { generateArray } from 'constants/array';
import decryptData from 'secret/decryptData';
import DevLog from 'helpers/DevLog';
const options = {
  month: 'long',
  day: 'numeric',
  year: 'numeric',
  hour: 'numeric',
  minute: 'numeric'
};
const columns = [
  {
    accessor: 'order_number',
    Header: 'Order number',
    Cell: rowData => {
      const {
        order_id,
        created_at,
        order_status,
        total_cost,
        payment_type,
        tax_fee,
        total_product_cost,
        shipping_fee
      } = rowData.row.original;
      const date = new Date(created_at);
      const dispatched = {
        order_id,
        x: rowData.row.original,
        order_status,
        total_cost,
        payment_type,
        tax_fee,
        total_product_cost,
        shipping_fee,
        date: date.toLocaleDateString('en-US', options)
      };
      // /admin/e-commerce/orders/${order_id}/detail
      return (
        <Link
          state={dispatched}
          to={`/admin/e-commerce/orders/${order_id}/detail`}
        >
          #{customerOrderNumberGenerator(order_id)}
        </Link>
      );
    }
  },
  {
    accessor: 'business_name',
    Header: 'Business name'
  },
  {
    accessor: 'created_at',
    Header: 'Order date',
    Cell: rowData => {
      const { created_at } = rowData.row.original;
      return moment(created_at).format('MMMM Do, YYYY');
    }
  },
  {
    accessor: 'order_status',
    Header: 'Order Status',
    Cell: rowData => {
      const { order_status } = rowData.row.original;
      return String(
        order_status.charAt(0).toUpperCase() +
          order_status.slice(1).toLowerCase()
      )
        .split('_')
        .join(' ');
    }
  },
  {
    accessor: 'shipping_type',
    Header: 'Shipping type'
  },
  {
    accessor: 'total_cost',
    Header: 'Total cost'
  },
  {
    accessor: 'paid_amount',
    Header: 'Paid',
    Cell: rowData => {
      const { paid_amount } = rowData.row.original;
      return paid_amount == null ? '-' : paid_amount;
    }
  },
  {
    accessor: 'remaining_amount',
    Header: 'Remaining',
    Cell: rowData => {
      const { remaining_amount } = rowData.row.original;
      return remaining_amount == null ? '-' : remaining_amount;
    }
  }
  // {
  //   accessor: 'action',
  //   Header: 'More',
  //   Cell: rowData => {
  //     const { order_status } = rowData.row.original;
  //     return (
  //       <div style={{ minWidth: '220px' }}>
  //         <Form.Select value={order_status} size="sm">
  //           {orderStatuses.map((status, index) => {
  //             return (
  //               <option key={index} value={status.value}>
  //                 {status.name}
  //               </option>
  //             );
  //           })}
  //         </Form.Select>
  //       </div>
  //     );
  //   }
  // }
];

const AdminCompletedOrders = () => {
  // const [searchQuery, setSearchQuery] = useState('');
  const [filteredItems, setFilteredItems] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [requestNumber, setRequestNumber] = useState(1);
  // const [dateRange, setDateRange] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [pageSize, setPageSize] = useState(15);
  const [count, setCount] = useState(0);
  // const [options, setOptions] = useState([]);
  // const handleSearch = event => {
  //   const query = event.target.value;
  //   setSearchQuery(query);
  // };
  // const handleSearchButton = async () => {
  //   await axios
  //     .get(
  //       BASE_URL +
  //         `/dashboard/orders/?customer__business_name__contains=${searchQuery}`,
  //       {
  //         headers: {
  //           'Content-Type': 'application/json',
  //           Authorization: `JWT ${
  //             decryptData('token')
  //           }`
  //         }
  //       }
  //     )
  //     .then(response => {
  //       DevLog({ response });
  //       const data = response.data.results?.map(a => {
  //         const {
  //           order_id,
  //           created_at,
  //           total_cost,
  //           customer,
  //           last_shipping_address,
  //           order_status
  //         } = a;
  //         const { shipping_type } = last_shipping_address;
  //         const { business_name } = customer;

  //         return {
  //           order_id,
  //           created_at,
  //           total_cost,
  //           shipping_type,
  //           business_name,
  //           order_status
  //         };
  //       });

  //       setFilteredItems(data);
  //     })
  //     .catch(err => DevLog(err));
  // };

  // const handleFilterByStatus = async () => {
  //   setIsLoading(true);
  //   DevLog({ options });
  // };
  const fetchOrders = async () => {
    setIsLoading(true);
    await axios
      .get(BASE_URL + `/dashboard/orders/`, {
        headers: {
          Authorization: `JWT ${decryptData('token')}`
        }
      })
      .then(response => {
        DevLog({ response });
        const count = response.data.results.length;
        setCount(count);
        setPageNumber(Math.ceil(Number(count / pageSize)));
        const data = response.data.results?.filter(
          result => result.order_status == 'COMPLETED'
        );
        data?.map(a => {
          const {
            order_id,
            created_at,
            total_cost,
            customer,
            last_shipping_address,
            order_status
          } = a;
          const { shipping_type } = last_shipping_address;
          const { business_name } = customer;
          return {
            order_id,
            created_at,
            total_cost,
            shipping_type,
            business_name,
            order_status
          };
        });

        setFilteredItems(data);
      })
      .catch(err => {
        DevLog(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const changePageByNumber = async n => {
    setIsLoading(true);
    setRequestNumber(n);
    await axios
      .get(BASE_URL + `/dashboard/orders/?page=${n}`, {
        headers: {
          Authorization: `JWT ${decryptData('token')}`
        }
      })
      .then(response => {
        DevLog({ response });
        const { count } = response.data;
        setPageNumber(Math.ceil(Number(count / pageSize)));
        const data = response.data.results?.map(a => {
          const {
            order_id,
            created_at,
            total_cost,
            customer,
            last_shipping_address,
            order_status
          } = a;
          const { shipping_type } = last_shipping_address;
          const { business_name } = customer;
          return {
            order_id,
            created_at,
            total_cost,
            shipping_type,
            business_name,
            order_status
          };
        });

        setFilteredItems(data);
      })
      .catch(err => {
        DevLog(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const changeNext = async n => {
    setIsLoading(true);
    setRequestNumber(n + 1);
    await axios
      .get(BASE_URL + `/dashboard/orders/?page=${n + 1}`, {
        headers: {
          Authorization: `JWT ${decryptData('token')}`
        }
      })
      .then(response => {
        DevLog({ response });
        const { count } = response.data;
        setPageNumber(Math.ceil(Number(count / pageSize)));
        const data = response.data.results?.map(a => {
          const {
            order_id,
            created_at,
            total_cost,
            customer,
            last_shipping_address,
            order_status
          } = a;
          const { shipping_type } = last_shipping_address;
          const { business_name } = customer;
          return {
            order_id,
            created_at,
            total_cost,
            shipping_type,
            business_name,
            order_status
          };
        });

        setFilteredItems(data);
      })
      .catch(err => {
        DevLog(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const changePrevious = async n => {
    setIsLoading(true);
    setRequestNumber(n);
    if (n !== 1) {
      setRequestNumber(n - 1);
    }
    await axios
      .get(BASE_URL + `/dashboard/orders/?page=${n - 1}`, {
        headers: {
          Authorization: `JWT ${decryptData('token')}`
        }
      })
      .then(response => {
        DevLog({ response });
        const { count } = response.data;
        setPageNumber(Math.ceil(Number(count / pageSize)));
        const data = response.data.results?.map(a => {
          const {
            order_id,
            created_at,
            total_cost,
            customer,
            last_shipping_address,
            order_status
          } = a;
          const { shipping_type } = last_shipping_address;
          const { business_name } = customer;
          return {
            order_id,
            created_at,
            total_cost,
            shipping_type,
            business_name,
            order_status
          };
        });

        setFilteredItems(data);
      })
      .catch(err => {
        DevLog(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    setPageNumber(0);
    fetchOrders();
  }, [pageSize]);

  // if (isLoading) {
  //   return <TableSkeleton />;
  // }
  return (
    <Card className="p-3 text-800">
      {/* <Divider>Advanced Filtering</Divider> */}
      <Row>
        {/* <Col xs="auto" sm={12} lg={4}>
          <InputGroup className={'position-relative'}>
            <FormControl
              size="sm"
              id="search_by_business_name"
              placeholder={'search by business name'}
              type="search"
              value={searchQuery}
              onChange={handleSearch}
              className="shadow-none"
            />
            <Button
              size="sm"
              onClick={handleSearchButton}
              variant="outline-secondary"
              className="border-300 hover-border-secondary"
            >
              <FontAwesomeIcon icon="search" className="fs--1" />
            </Button>
          </InputGroup>
        </Col> */}
        {/* <Col xs="auto" sm={12} lg={4}>
          <DropdownWithCheckbox
            // setOptionsParent={setOptions}
            setFilteredItems={setFilteredItems}
            setIsLoading={setIsLoading}
            updateStatusGlobally={handleFilterByStatus}
          />
        </Col>
        <Col xs="auto" sm={12} lg={4}>
          <AdminAdvancedDateRangePicker
            setFilterItems={setFilteredItems}
            filteredItems={filteredItems}
            setDateRange={setDateRange}
          />
        </Col> */}
      </Row>
      <Divider>Completed Orders page</Divider>
      <AdvanceTableWrapper
        columns={columns}
        data={filteredItems}
        sortable
        pagination
        perPage={15}
      >
        <Row className="flex-end-center mb-3">
          <Col xs="auto" sm={6} lg={3}>
            <AdvanceTableSearchBox table />
          </Col>
        </Row>
        {isLoading ? (
          <TableSkeleton />
        ) : (
          <AdvanceTable
            table
            headerClassName="bg-200 text-900 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              bordered: true,
              striped: true,
              className: 'fs--1 mb-0 overflow-hidden'
            }}
          />
        )}

        <div className="mt-3">
          <AdminAdvanceTableFooter
            rowCount={count}
            table
            rowInfo
            navButtons
            requestNumber={requestNumber}
            sendPageSize={setPageSize}
            rowsPerPageSelection
          />
        </div>
        <div className="mt-3 ">
          <div className=" d-flex justify-content-center align-items-center">
            {filteredItems.length > 0 && (
              <Pagination>
                <Pagination.Prev
                  disabled={requestNumber == 1}
                  onClick={() => changePrevious(requestNumber)}
                />
                {generateArray(pageNumber).map((e, i) => {
                  DevLog({ e, pageNumber, i });
                  return (
                    <Pagination.Item
                      active={requestNumber == e}
                      onClick={() => changePageByNumber(e)}
                      key={i}
                    >
                      {e}
                    </Pagination.Item>
                  );
                })}
                <Pagination.Next
                  disabled={requestNumber == generateArray(pageNumber).length}
                  onClick={() => changeNext(requestNumber)}
                />
              </Pagination>
            )}
          </div>
        </div>
      </AdvanceTableWrapper>
    </Card>
  );
};

export default AdminCompletedOrders;
