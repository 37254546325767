/* eslint-disable no-unused-vars */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import moment from 'moment';

import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import React, { useEffect, useState } from 'react';
import {
  Badge,
  Button,
  Card,
  Col,
  FormControl,
  InputGroup,
  Pagination,
  Row
} from 'react-bootstrap';
import AdminAdvancedDateRangePicker from '../../components/Date picker with range/AdminAdvancedDateRangePicker';
import Divider from 'components/common/Divider';
import { customerOrderNumberGenerator } from 'helpers/customerOrderNumberGenerator';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { BASE_URL } from 'constants/url';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import TableSkeleton from 'components/common/skeletons/table skleton/TableSkleton';
import DropdownWithCheckbox from '../DropdownWithCheckbox';
import { generateArray } from 'constants/array';
import { toast } from 'react-toastify';
import SoftBadge from 'components/common/SoftBadge';
import { TbTruckDelivery } from 'react-icons/tb';
import {
  BsCheck2Circle,
  BsClockHistory,
  BsDatabaseFillAdd
} from 'react-icons/bs';
import { MdDone, MdPendingActions } from 'react-icons/md';
import { GiCheckMark, GiReceiveMoney } from 'react-icons/gi';
import {
  AiOutlineCloseCircle,
  AiOutlineMessage,
  AiOutlineStop
} from 'react-icons/ai';
import LeaveComment from './comment/LeaveCommentForSupplier';
import CreateCommentForSupplier from './comment/CreateCommentForSupplier';
import decryptData from 'secret/decryptData';
import DevLog from 'helpers/DevLog';
const options = {
  month: 'long',
  day: 'numeric',
  year: 'numeric',
  hour: 'numeric',
  minute: 'numeric'
};
const statusBadges = {
  AWAITING_SHIPMENT: {
    badgeVariant: 'primary',
    icon: <TbTruckDelivery size={16} />
  },
  AWAITING_PAYMENT: {
    badgeVariant: 'warning',
    icon: <BsClockHistory size={16} />
  },
  AWAITING_PICKUP: {
    badgeVariant: 'secondary',
    icon: <MdPendingActions size={16} />
  },
  PARTIALLY_PAID: {
    badgeVariant: 'info',
    icon: <GiReceiveMoney size={16} />
  },
  DECLINED: {
    badgeVariant: 'danger',
    icon: <AiOutlineStop size={16} />
  },
  COMPLETED: {
    badgeVariant: 'success',
    icon: <GiCheckMark size={16} />
  }
};
const AdminAllOrdersSupplier = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredItems, setFilteredItems] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [requestNumber, setRequestNumber] = useState(1);
  const [dateRange, setDateRange] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [pageSize, setPageSize] = useState(15);
  const [count, setCount] = useState(0);

  const columns = [
    {
      accessor: 'read',
      Header: 'New',
      Cell: rowData => {
        const { is_new } = rowData.row.original;
        DevLog('data', rowData.row.original);
        return (
          <>
            {is_new ? (
              <SoftBadge bg="danger" pill>
                NEW
              </SoftBadge>
            ) : (
              <SoftBadge pill>
                Read <MdDone />
              </SoftBadge>
            )}
          </>
        );
      }
    },
    {
      accessor: 'order_number',
      Header: 'Order number',
      Cell: rowData => {
        const {
          supplier_order_id,
          created_at,
          supplier_order_status,
          total_cost,
          payment_type,
          tax_fee,
          total_product_cost,
          shipping_fee,
          city,
          contact_name,
          contact_number,
          country,
          postal_code,
          bank_receipt_file
        } = rowData.row.original;
        const date = new Date(created_at);
        const dispatched = {
          supplier_order_id,
          x: rowData.row.original,
          supplier_order_status,
          total_cost,
          payment_type,
          tax_fee,
          total_product_cost,
          shipping_fee,
          city,
          contact_name,
          contact_number,
          country,
          postal_code,
          bank_receipt_file,
          date: date.toLocaleDateString('en-US', options)
        };
        DevLog({ link: rowData.row.original });
        return (
          <Link
            state={dispatched}
            to={`/admin/e-commerce/supplier/orders/${supplier_order_id}/detail`}
          >
            #{customerOrderNumberGenerator(supplier_order_id)}
          </Link>
        );
      }
    },
    {
      accessor: 'business_name',
      Header: 'Business name'
    },
    {
      accessor: 'phone_number',
      Header: 'Phone number'
    },
    {
      accessor: 'created_at',
      Header: 'Order date',
      Cell: rowData => {
        const { created_at } = rowData.row.original;
        return moment(created_at).format('DD/MM/YYYY');
      }
    },
    {
      accessor: 'supplier_order_status',
      Header: 'Order Status',
      Cell: rowData => {
        const { supplier_order_status } = rowData.row.original;

        return supplier_order_status.replace(/_/g, ' ');
      }
    },

    {
      accessor: 'total_cost',
      Header: 'Total cost AED'
    }
  ];

  const fetchOrders = async () => {
    setIsLoading(true);
    await axios
      .get(BASE_URL + `/dashboard/suppliers/orders/`, {
        headers: {
          Authorization: `JWT ${decryptData('token')}`
        }
      })
      .then(response => {
        DevLog({ orders: response });
        const { count } = response.data;
        setCount(count);
        setPageNumber(Math.ceil(Number(count / pageSize)));

        const filtered = response.data?.map(p => {
          DevLog({ p });
          const {
            supplier_order_id,
            total_cost,
            supplier_order_status,
            created_at,
            is_new
          } = p;

          const { business_name, phone_number } = p.supplier;
          return {
            business_name,
            supplier_order_id,
            total_cost,
            supplier_order_status,
            created_at,
            phone_number,
            is_new
          };
        });
        setFilteredItems(filtered);
      })
      .catch(err => {
        DevLog(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    document.documentElement.style.zoom = '0.8';

    setPageNumber(0);
    fetchOrders();
    return () => {
      document.documentElement.style.zoom = '1';
    };
  }, [pageSize]);

  // if (isLoading) {
  //   return <TableSkeleton />;
  // }
  return (
    <Card className="p-3 text-800">
      {/* <Divider>Advanced Filtering</Divider> */}
      {/* <Row>
        <Col xs="auto" sm={12} lg={4}>
          <InputGroup className={'position-relative'}>
            <FormControl
              size="sm"
              id="search_by_business_name"
              placeholder={'search by business name'}
              type="search"
              value={searchQuery}
              onChange={handleSearch}
              className="shadow-none"
            />
            <Button
              size="sm"
              onClick={handleSearchButton}
              variant="outline-secondary"
              className="border-300 hover-border-secondary"
            >
              <FontAwesomeIcon icon="search" className="fs--1" />
            </Button>
          </InputGroup>
        </Col>
        <Col xs="auto" sm={12} lg={4}>
          <DropdownWithCheckbox
            // setOptionsParent={setOptions}
            setFilteredItems={setFilteredItems}
            setIsLoading={setIsLoading}
            updateStatusGlobally={handleFilterByStatus}
          />
        </Col>
        <Col xs="auto" sm={12} lg={4}>
          <AdminAdvancedDateRangePicker
            filter={setFilteredItems}
            filteredItems={filteredItems}
            setDateRange={setDateRange}
          />
        </Col>
      </Row> */}
      <Divider>Orders page</Divider>
      <AdvanceTableWrapper
        columns={columns}
        data={filteredItems}
        sortable
        pagination
        perPage={15}
      >
        <Row className="flex-end-center mb-3">
          <Col xs="auto" sm={6} lg={3}>
            <AdvanceTableSearchBox table />
          </Col>
        </Row>
        {isLoading ? (
          <TableSkeleton />
        ) : (
          <AdvanceTable
            table
            headerClassName="bg-200 text-900 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              bordered: true,
              striped: true,
              className: 'fs--1 mb-0 overflow-hidden'
            }}
          />
        )}

        {/* <div className="mt-3">
          <AdminAdvanceTableFooter
            rowCount={count}
            table
            rowInfo
            navButtons
            requestNumber={requestNumber}
            sendPageSize={setPageSize}
            rowsPerPageSelection
          />
        </div> */}
        {/* <div className="mt-3 ">
          <div className=" d-flex justify-content-center align-items-center">
            {filteredItems.length > 0 && (
              <Pagination>
                <Pagination.Prev
                  disabled={requestNumber == 1}
                  onClick={() => changePrevious(requestNumber)}
                />
                {generateArray(pageNumber).map((e, i) => {
                  DevLog({ e, pageNumber, i });
                  return (
                    <Pagination.Item
                      active={requestNumber == e}
                      onClick={() => changePageByNumber(e)}
                      key={i}
                    >
                      {e}
                    </Pagination.Item>
                  );
                })}
                <Pagination.Next
                  disabled={requestNumber == generateArray(pageNumber).length}
                  onClick={() => changeNext(requestNumber)}
                />
              </Pagination>
            )}
          </div>
        </div> */}
      </AdvanceTableWrapper>
    </Card>
  );
};

export default AdminAllOrdersSupplier;
