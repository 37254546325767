// LaptopCartContext.js
import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';
import DevLog from 'helpers/DevLog';

export const MobileCartContext = createContext();

export const MobileCartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState([]);

  const addToCart = item => {
    setCartItems(prevItems => [...prevItems, item]);
  };

  const removeFromCart = item => {
    DevLog({ item });
    setCartItems(prevItems => prevItems.filter(prevItem => prevItem !== item));
  };

  return (
    <MobileCartContext.Provider
      value={{ cartItems, addToCart, removeFromCart }}
    >
      {children}
    </MobileCartContext.Provider>
  );
};

MobileCartProvider.propTypes = {
  children: PropTypes.node.isRequired
};
