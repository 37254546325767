// import { wearable } from 'constants/products';
import axios from 'axios';
import NoDataToDisplay from 'components/common/NoDataToDisplay';
import TableSkeleton from 'components/common/skeletons/table skleton/TableSkleton';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import decryptData from 'secret/decryptData';
import WearableTable from './WearableTable';

import { BASE_URL_WITHOUT_API } from 'constants/url';
import DevLog from 'helpers/DevLog';
import useUserStore from 'state/userStore';

const SupplierUploadWearable = () => {
  const [wearable, setWearable] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const fetchAllWearable = async () => {
    setIsLoading(true);
    await axios
      .get(BASE_URL_WITHOUT_API + 'api/v1/store/products/', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `JWT ${decryptData('token')}`
        }
      })
      .then(response => {
        DevLog({ response });
        setWearable(
          response.data?.filter(data => {
            const { category_id } = data.category;

            return (
              category_id ==
              JSON.parse(localStorage.getItem('wearableCategoryId'))
            );
          })
        );
      })
      .catch(err => {
        DevLog(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const navigate = useNavigate();
  const { userStatus } = useUserStore();
  useEffect(() => {
    if (
      userStatus == 'BLANK' ||
      userStatus == '' ||
      userStatus == null ||
      userStatus == undefined
    ) {
      navigate('/supplier/user/settings');
    }
  }, [userStatus]);
  useEffect(() => {
    fetchAllWearable();
  }, []);

  if (isLoading) {
    return <TableSkeleton lines={10} />;
  }

  if (wearable.length === 0) {
    return (
      <>
        <h1>Wearable Catalog</h1>
        <br />
        <NoDataToDisplay />
      </>
    );
  }
  return (
    <div className="p-3">
      {wearable.length > 0 && <WearableTable wearable={wearable} />}
    </div>
  );
};

export default SupplierUploadWearable;
