import React, { useState } from 'react';
import {
  Offcanvas,
  OffcanvasHeader,
  OffcanvasTitle,
  OffcanvasBody
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { RiEditBoxLine } from 'react-icons/ri';

const ShippingOffCanvas = ({ children }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <RiEditBoxLine
        onClick={handleShow}
        size={35}
        className="hover-button bg-soft-primary rounded-5 p-2 text-primary"
      />
      <Offcanvas show={show} onHide={handleClose}>
        <OffcanvasHeader closeButton>
          <OffcanvasTitle>Edit Shipping</OffcanvasTitle>
        </OffcanvasHeader>
        <OffcanvasBody>{children}</OffcanvasBody>
      </Offcanvas>
    </>
  );
};

ShippingOffCanvas.propTypes = {
  children: PropTypes.node,
  name: PropTypes.string
};

export default ShippingOffCanvas;
