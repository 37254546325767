import React from 'react';
import { Accordion } from 'react-bootstrap';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

const GetMobileSkeleton = () => {
  return (
    <Accordion>
      <Accordion.Item eventKey="0">
        <Accordion.Header>
          <SkeletonTheme color="#caf0f8" highlightColor="#bde0fe">
            <Skeleton height={20} width={250} />
          </SkeletonTheme>
        </Accordion.Header>
        <Accordion.Body></Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>
          <SkeletonTheme color="#caf0f8" highlightColor="#bde0fe">
            <Skeleton height={20} width={250} />
          </SkeletonTheme>
        </Accordion.Header>
        <Accordion.Body></Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>
          <SkeletonTheme color="#caf0f8" highlightColor="#bde0fe">
            <Skeleton height={20} width={250} />
          </SkeletonTheme>
        </Accordion.Header>
        <Accordion.Body></Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
        <Accordion.Header>
          <SkeletonTheme color="#caf0f8" highlightColor="#bde0fe">
            <Skeleton height={20} width={250} />
          </SkeletonTheme>
        </Accordion.Header>
        <Accordion.Body></Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="4">
        <Accordion.Header>
          <SkeletonTheme color="#caf0f8" highlightColor="#bde0fe">
            <Skeleton height={20} width={250} />
          </SkeletonTheme>
        </Accordion.Header>
        <Accordion.Body></Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="4">
        <Accordion.Header>
          <SkeletonTheme color="#caf0f8" highlightColor="#bde0fe">
            <Skeleton height={20} width={250} />
          </SkeletonTheme>
        </Accordion.Header>
        <Accordion.Body></Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="4">
        <Accordion.Header>
          <SkeletonTheme color="#caf0f8" highlightColor="#bde0fe">
            <Skeleton height={20} width={250} />
          </SkeletonTheme>
        </Accordion.Header>
        <Accordion.Body></Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="4">
        <Accordion.Header>
          <SkeletonTheme color="#caf0f8" highlightColor="#bde0fe">
            <Skeleton height={20} width={250} />
          </SkeletonTheme>
        </Accordion.Header>
        <Accordion.Body></Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default GetMobileSkeleton;

{
  /* <SkeletonTheme color="#caf0f8" highlightColor="#bde0fe">
  <Skeleton height={20} width={120} />

  <Skeleton height={16} width={16} />
</SkeletonTheme>; */
}
