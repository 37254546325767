import { useState, useEffect } from 'react';

function useBrowserDetection() {
  const [browserName, setBrowserName] = useState(null);

  useEffect(() => {
    const userAgent = window.navigator.userAgent;

    if (userAgent.includes('MSIE') || userAgent.includes('Trident/')) {
      setBrowserName('Internet Explorer');
    } else if (userAgent.includes('Edge')) {
      setBrowserName('Microsoft Edge');
    } else if (userAgent.includes('Firefox')) {
      setBrowserName('Mozilla Firefox');
    } else if (userAgent.includes('Chrome')) {
      setBrowserName('Google Chrome');
    } else if (userAgent.includes('Safari') && !userAgent.includes('Chrome')) {
      setBrowserName('Apple Safari');
    } else {
      setBrowserName('Unknown');
    }
  }, []);

  return browserName;
}

export default useBrowserDetection;
