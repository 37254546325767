import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Form, Button, Card } from 'react-bootstrap';
import {
  mobilePhoneColors,
  ramOptions,
  storageOptions
} from 'constants/colors';
import axios from 'axios';
import { BASE_URL } from 'constants/url';
import Loader from 'components/common/loader/Loader';
import decryptData from 'secret/decryptData';
import DevLog from 'helpers/DevLog';

const EditAdminLaptops = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate(false);
  const location = useLocation();
  const { state } = location;
  const {
    brand,
    model_name,
    model_number,
    ram,
    storage,
    processor,
    video_card,
    color,
    specs,
    code,
    product_id
  } = state;

  const [formData, setFormData] = useState({
    brand,
    model_name,
    model_number,
    ram,
    storage,
    processor,
    video_card,
    color,
    specs,
    code
  });

  const handleInputChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setIsLoading(true);
    // Perform your submit logic here
    await axios
      .patch(
        BASE_URL + '/dashboard/product/update/' + product_id + '/',
        formData,
        {
          headers: {
            Authorization: `JWT ${decryptData('token')}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then(response => {
        DevLog(response);
        response.statusCode = 200 && setIsLoading(false);
        navigate('/admin/e-commerce/product/laptops');
      })
      .catch(err => {
        DevLog(err);
        setIsLoading(false);
      });
  };

  return (
    <Card className="p-4 text-800">
      {isLoading && <Loader />}
      <h1>Edit Laptop</h1>
      <hr />
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="brand">
          <Form.Label>Brand</Form.Label>
          <Form.Control
            type="text"
            name="brand"
            value={formData.brand}
            onChange={handleInputChange}
          />
        </Form.Group>
        <Form.Group controlId="model_name">
          <Form.Label>Model Name</Form.Label>
          <Form.Control
            type="text"
            name="model_name"
            value={formData.model_name}
            onChange={handleInputChange}
          />
        </Form.Group>

        <Form.Group controlId="model_number">
          <Form.Label>Model Number</Form.Label>
          <Form.Control
            type="text"
            name="model_number"
            value={formData.model_number}
            onChange={handleInputChange}
          />
        </Form.Group>

        <Form.Group controlId="ram">
          <Form.Label>RAM</Form.Label>
          <Form.Control
            as="select"
            name="ram"
            value={formData.ram}
            onChange={handleInputChange}
          >
            {ramOptions.map(s => (
              <option key={s} value={s}>
                {s}
              </option>
            ))}
          </Form.Control>
        </Form.Group>

        <Form.Group controlId="storage">
          <Form.Label>Storage</Form.Label>
          <Form.Control
            as="select"
            name="storage"
            value={formData.storage}
            onChange={handleInputChange}
          >
            {storageOptions.map(s => (
              <option key={s} value={s}>
                {s}
              </option>
            ))}
          </Form.Control>
        </Form.Group>

        <Form.Group controlId="processor">
          <Form.Label>Processor</Form.Label>
          <Form.Control
            type="text"
            name="processor"
            value={formData.processor}
            onChange={handleInputChange}
          />
        </Form.Group>

        <Form.Group controlId="video_card">
          <Form.Label>Video Card</Form.Label>
          <Form.Control
            type="text"
            name="video_card"
            value={formData.video_card}
            onChange={handleInputChange}
          />
        </Form.Group>

        <Form.Group controlId="color">
          <Form.Label>Color</Form.Label>
          <Form.Control
            as="select"
            name="color"
            value={formData.color}
            onChange={handleInputChange}
          >
            {mobilePhoneColors.map(color => (
              <option key={color} value={color}>
                {color}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
        <Form.Group controlId="specs">
          <Form.Label>Specs</Form.Label>
          <Form.Control
            type="text"
            name="specs"
            value={formData.specs}
            onChange={handleInputChange}
          />
        </Form.Group>
        <Form.Group controlId="code">
          <Form.Label>Code</Form.Label>
          <Form.Control
            type="text"
            name="code"
            value={formData.code}
            onChange={handleInputChange}
          />
        </Form.Group>

        <Button className="my-3" variant="primary" type="submit">
          Save Changes
        </Button>
      </Form>
    </Card>
  );
};

export default EditAdminLaptops;
