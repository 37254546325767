/* eslint-disable react/prop-types */
import React from 'react';
import { Card } from 'antd';

const ColorBox = ({ color }) => {
  const cardStyle = {
    width: '15px',
    height: '15px',
    margin: '0',
    backgroundColor: color,
    borderRadius: '10px',
    border:
      color === 'White' || color == 'Burgundy' ? `1px solid black` : 'none',
    display: 'inline-block'
  };

  return <Card style={cardStyle}></Card>;
};

export default ColorBox;
