import React from 'react';
import { Container } from 'react-bootstrap';

const NoDataToDisplay = () => {
  return (
    <Container>
      <h4 className="mb-4 text-center">Data is empty</h4>
      <p className="text-center">There is no data to display at the moment.</p>
    </Container>
  );
};

export default NoDataToDisplay;
