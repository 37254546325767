import axios from 'axios';
import Divider from 'components/common/Divider';
import SoftBadge from 'components/common/SoftBadge';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import TableSkeleton from 'components/common/skeletons/table skleton/TableSkleton';
import { BASE_URL } from 'constants/url';
import React, { useEffect, useState } from 'react';
import { Accordion, Card, Col, ListGroup, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { GiCheckMark } from 'react-icons/gi';
import { BsClockHistory } from 'react-icons/bs';
import { TbTruckDelivery } from 'react-icons/tb';
import { GiReceiveMoney } from 'react-icons/gi';
import { MdPendingActions } from 'react-icons/md';
import { AiOutlineStop } from 'react-icons/ai';
import { customerOrderNumberGenerator } from 'helpers/customerOrderNumberGenerator';
import CommentOffcanvas from './comment/CommentOffcanvas';
import ChatBubble from './comment/Chat/ChatBubble';
import GetMobileSkeleton from 'components/common/skeletons/get mobile customer/GetMobileSkeleton';
import useScreenSize from 'hooks/useScreenSize';

import moment from 'moment';
import { useLanguage } from 'provider/LanguageProvider';
import {
  CustomerOrdersPageTranslate,
  CustomerOtherTranslations
} from 'languages/Language';
import decryptData from 'secret/decryptData';
import DevLog from 'helpers/DevLog';
import useCurrencyStore from 'currency/useCurrencyStore';

const CustomerOrdersPage = () => {
  const [data, setData] = useState([]);
  const { language } = useLanguage();
  const { currency } = useCurrencyStore();
  const [isLoading, setIsLoading] = useState(false);
  const screen = useScreenSize();
  const orders_status_badge = order_status => {
    if (String(order_status).toLowerCase() === 'awaiting_payment') {
      //BsClockHistory
      return (
        <SoftBadge pill bg="warning" className="me-2">
          Awaiting Payment {<BsClockHistory size={16} />}
        </SoftBadge>
      );
    }

    if (String(order_status).toLowerCase() === 'awaiting_shipment') {
      //BsClockHistory
      return (
        <SoftBadge pill bg="primary" className="me-2">
          Awaiting shipment {<TbTruckDelivery size={16} />}
        </SoftBadge>
      );
    }

    if (String(order_status).toLowerCase() === 'awaiting_pickup') {
      //BsClockHistory
      return (
        <SoftBadge pill bg="secondary" className="me-2">
          Awaiting Pickup {<MdPendingActions size={16} />}
        </SoftBadge>
      );
    }

    if (String(order_status).toLowerCase() === 'partially_paid') {
      //BsClockHistory
      return (
        <SoftBadge pill bg="info" className="me-2">
          Partially paid {<GiReceiveMoney size={16} />}
        </SoftBadge>
      );
    }

    if (String(order_status).toLowerCase() === 'declined') {
      //BsClockHistory
      return (
        <SoftBadge pill bg="danger" className="me-2">
          Declined {<AiOutlineStop size={16} />}
        </SoftBadge>
      );
    }
    return (
      <SoftBadge pill bg="success" className="me-2">
        Completed {<GiCheckMark size={16} />}
      </SoftBadge>
    );
  };
  const options = {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric'
  };
  const columns = [
    {
      accessor: 'order_id',
      Header: CustomerOrdersPageTranslate().table.OrderId[language],
      Cell: rowData => {
        const {
          order_id,
          created_at,
          order_status,
          total_cost,
          payment_type,
          tax_fee,
          total_product_cost,
          shipping_fee,
          bank_receipt_file,
          invoice_token,
          x
        } = rowData.row.original;

        const date = new Date(created_at);
        const dispatched = {
          order_id,
          x,
          order_status,
          total_cost,
          payment_type,
          tax_fee,
          total_product_cost,
          shipping_fee,
          bank_receipt_file,
          invoice_token,
          date: date.toLocaleDateString('en-US', options)
        };

        return (
          <Link
            state={dispatched}
            to={`/customer/e-commerce/order/${order_id}`}
          >
            #{customerOrderNumberGenerator(order_id)}
          </Link>
        );
      }
    },
    {
      accessor: 'order_date',
      Header: CustomerOrdersPageTranslate().table.OrderDate[language],
      Cell: rowData => {
        const { created_at } = rowData.row.original;
        const date = new Date(created_at);
        return date.toLocaleDateString('en-US', options);
      }
    },
    {
      accessor: 'shipping_type',
      Header: CustomerOrdersPageTranslate().table.ShippingType[language],
      Cell: rowData => {
        const { shipping_type } = rowData.row.original.x;
        return <>{shipping_type}</>;
      }
    },
    {
      accessor: 'total_cost',
      Header: CustomerOrdersPageTranslate().table.TotalCost[language]
    },
    {
      accessor: 'paid_amount',
      Header: CustomerOrdersPageTranslate().table.PaidAmount[language],
      Cell: rowData => {
        const { paid_amount } = rowData.row.original;
        return paid_amount == null ? '-' : paid_amount;
      }
    },
    {
      accessor: 'remaining_amount',
      Header: CustomerOrdersPageTranslate().table.RemainingAmount[language],
      Cell: rowData => {
        const { remaining_amount } = rowData.row.original;
        return remaining_amount == null ? '-' : remaining_amount;
      }
    },
    {
      accessor: 'order_status',
      Header: CustomerOrdersPageTranslate().table.OrderStatus[language],
      Cell: rowData => {
        const { order_status } = rowData.row.original;
        return orders_status_badge(order_status);
      }
    },
    {
      accessor: 'order_comment',
      Header: CustomerOrdersPageTranslate().table.Comment[language],
      Cell: rowData => {
        const { get_order_comments } = rowData.row.original;

        return (
          <CommentOffcanvas count={get_order_comments.length}>
            {get_order_comments.length > 0 ? (
              get_order_comments.map((comment, index) => {
                return (
                  <ChatBubble
                    key={index}
                    author="Admin"
                    text={comment.comment}
                    isOwnMessage={false}
                  />
                );
              })
            ) : (
              <p className="text-900 text-center">No comments...</p>
            )}
          </CommentOffcanvas>
        );
      }
    }
  ];

  const fetchCustomerOrder = async () => {
    setIsLoading(true);
    await axios
      .get(BASE_URL + '/store/orders/', {
        headers: {
          Authorization: `JWT ${decryptData('token')}`,
          'Content-Type': 'application/json'
        }
      })
      .then(async response => {
        // const product = response.data;
        DevLog({ rrrr: response });

        const data = response.data?.map(d => {
          return { ...d, x: d.last_shipping_address };
        });
        setData(data);
      })
      .catch(err => DevLog({ err }))
      .finally(() => setIsLoading(false));
  };
  useEffect(() => {
    fetchCustomerOrder();
  }, []);

  if (isLoading) {
    if (screen == 'mobile') {
      return <GetMobileSkeleton />;
    } else {
      return <TableSkeleton />;
    }
  }

  if (screen == 'mobile') {
    return (
      <>
        <h1 className="p-1 mb-2">All wearables </h1>

        <div className="p-2 three-d-box-shadow">
          <Accordion defaultActiveKey="0" flush>
            {data.map((e, i) => {
              const { created_at, order_id, total_cost, order_status } = e;
              DevLog({ e });

              return (
                <Accordion.Item className="mb-2" key={i} eventKey={i}>
                  <Accordion.Header className="outline-none">
                    <div className="d-flex w-100 justify-content-between">
                      <div className="">
                        <b className="text-primary">
                          #{customerOrderNumberGenerator(order_id)}
                        </b>{' '}
                        <br />
                        {currency} {total_cost} <br />
                      </div>
                      <div className="">
                        {orders_status_badge(order_status)}
                      </div>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body className="py-2 px-1 ">
                    <ListGroup className="">
                      <ListGroup.Item className="">
                        <Divider>Order date</Divider>
                        {moment(created_at).format('llll')}
                      </ListGroup.Item>
                      <ListGroup.Item className=""></ListGroup.Item>
                      <ListGroup.Item className=""></ListGroup.Item>
                      <ListGroup.Item className=""></ListGroup.Item>
                      <ListGroup.Item className=""></ListGroup.Item>

                      <ListGroup.Item className=""></ListGroup.Item>
                    </ListGroup>
                  </Accordion.Body>
                </Accordion.Item>
              );
            })}
          </Accordion>
        </div>
      </>
    );
  }

  return (
    <Card className="p-3">
      <Divider>
        {CustomerOtherTranslations.Orders.allOrdersDivider[language]}
      </Divider>
      <AdvanceTableWrapper
        columns={columns}
        data={data}
        sortable
        pagination
        perPage={15}
      >
        <Row className="flex-end-center mb-3">
          <Col xs="auto" sm={6} lg={4}>
            <AdvanceTableSearchBox
              placeholder={CustomerOrdersPageTranslate().search[language]}
              table
            />
          </Col>
        </Row>
        <AdvanceTable
          table
          headerClassName="bg-200 text-900 text-nowrap align-middle"
          rowClassName="align-middle white-space-nowrap"
          tableProps={{
            bordered: false,
            striped: true,
            className: 'fs--1 mb-0 overflow-hidden '
          }}
        />
        <div className="mt-3">
          {/* <AdvanceTableFooter
            rowCount={data.length}
            table
            rowInfo
            navButtons
            rowsPerPageSelection
          /> */}
        </div>
      </AdvanceTableWrapper>
    </Card>
  );
};

export default CustomerOrdersPage;
