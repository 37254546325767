import React from 'react';
import { Alert } from 'react-bootstrap';

const AccountApprovalMessage = () => {
  return (
    <Alert variant="info" className="mt-2">
      <b>⚠️ Your Account Status </b>
      <p>
        Your account has not been approved yet. Please wait for some time and
        check it again.
      </p>
    </Alert>
  );
};

export default AccountApprovalMessage;
