/* eslint-disable no-unused-vars */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import moment from 'moment';

import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import React, { useEffect, useState } from 'react';
import {
  Badge,
  Button,
  Card,
  Col,
  FormControl,
  InputGroup,
  Pagination,
  Row
} from 'react-bootstrap';
import AdminAdvancedDateRangePicker from '../../components/Date picker with range/AdminAdvancedDateRangePicker';
import Divider from 'components/common/Divider';
import { customerOrderNumberGenerator } from 'helpers/customerOrderNumberGenerator';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { BASE_URL } from 'constants/url';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import TableSkeleton from 'components/common/skeletons/table skleton/TableSkleton';
import DropdownWithCheckbox from '../DropdownWithCheckbox';
import { generateArray } from 'constants/array';
import { toast } from 'react-toastify';
import SoftBadge from 'components/common/SoftBadge';
import { TbTruckDelivery } from 'react-icons/tb';
import {
  BsCheck2Circle,
  BsClockHistory,
  BsDatabaseFillAdd
} from 'react-icons/bs';
import { MdDone, MdPendingActions } from 'react-icons/md';
import { GiCheckMark, GiReceiveMoney } from 'react-icons/gi';
import { AiOutlineCloseCircle, AiOutlineStop } from 'react-icons/ai';
import LeaveComment from './comment/LeaveComment';
import CreateComment from './comment/CreateComment';
import decryptData from 'secret/decryptData';
import DevLog from 'helpers/DevLog';
const options = {
  month: 'long',
  day: 'numeric',
  year: 'numeric',
  hour: 'numeric',
  minute: 'numeric'
};

const AdminAllOrders = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredItems, setFilteredItems] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [requestNumber, setRequestNumber] = useState(1);
  const [dateRange, setDateRange] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [pageSize, setPageSize] = useState(15);
  const [count, setCount] = useState(0);
  // const [options, setOptions] = useState([]);
  const changeOrderStatusHandler = async (order_id, data) => {
    DevLog([data, dateRange]);
    await axios
      .patch(BASE_URL + `/dashboard/order/update/${order_id}/`, data, {
        headers: {
          Authorization: `JWT ${decryptData('token')}`,
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        DevLog({
          response: filteredItems.map(item => {
            if (item.order_id == order_id) {
              return { ...item, order_status: data.order_status };
            } else {
              return item;
            }
          })
        });
        if (response.status === 204) {
          setFilteredItems(
            filteredItems.map(item => {
              if (item.order_id == order_id) {
                return { ...item, order_status: data.order_status };
              } else {
                return item;
              }
            })
          );
          return toast.success(
            `Congratulations🎉, Order status has been successfully changed to ${String(
              data.order_status
            )
              .split('_')
              .join(' ')}`,
            {
              theme: 'colored',
              position: 'top-right',
              autoClose: true
            }
          );
        }
      })
      .catch(err => {
        const { status } = err.response;
        DevLog(err);
        return toast.error(
          'total cost is not fully covered, so order may not be completed!',
          {
            autoClose: true
          }
        );
      });
  };

  const paidHandler = async (order_id, data, total, shipping_type) => {
    if (Number(total) - Number(data.paid_amount) < 0) {
      alert('Paid amount can not be greater than total amount');
    } else {
      await axios
        .patch(BASE_URL + `/dashboard/order/update/${order_id}/`, data, {
          headers: {
            Authorization: `JWT ${decryptData('token')}`,
            'Content-Type': 'application/json'
          }
        })
        .then(response => {
          DevLog({
            response: filteredItems.map(item => {
              if (item.order_id == order_id) {
                return { ...item, paid_amount: data.paid_amount };
              } else {
                return item;
              }
            })
          });
          if (response.status === 204) {
            setFilteredItems(
              filteredItems.map(item => {
                if (item.order_id == order_id) {
                  const { paid_amount } = item;
                  let paid = Number(paid_amount) + Number(data.paid_amount);
                  return {
                    ...item,
                    paid_amount: Number(paid).toFixed(2),
                    remaining_amount: Number(Number(total) - paid).toFixed(2),
                    order_status:
                      Number(total) - paid > 0
                        ? 'PARTIALLY_PAID'
                        : shipping_type == 'PICKUP'
                        ? 'AWAITING_PICKUP'
                        : 'AWAITING_SHIPMENT'
                  };
                } else {
                  return item;
                }
              })
            );
            return toast.success(`Paid AED ${data.paid_amount}`, {
              theme: 'colored',
              position: 'top-right',
              autoClose: true
            });
          }
        })
        .catch(err => {
          const { status } = err.response;
          if (status == 400) {
            return toast.error(
              `Ensure that there are no more than 2 decimal places.`,
              {
                autoClose: true
              }
            );
          }
        });
    }
  };
  const columns = [
    {
      accessor: 'isNew',
      Header: 'New',
      Cell: rowData => {
        const { is_new } = rowData.row.original;
        DevLog(rowData.row.original);
        return (
          <center>
            {is_new ? (
              <SoftBadge pill bg="danger">
                NEW
              </SoftBadge>
            ) : (
              <SoftBadge pill>
                Read <MdDone />
              </SoftBadge>
            )}
          </center>
        );
      }
    },
    {
      accessor: 'order_number',
      Header: 'Order number',
      Cell: rowData => {
        const date = new Date(created_at);
        const {
          order_id,
          created_at,
          order_status,
          total_cost,
          payment_type,
          total_product_cost,
          shipping_fee,
          city,
          contact_name,
          contact_number,
          country,
          postal_code,
          tax_fee,
          bank_receipt_file
        } = rowData.row.original;
        const dispatched = {
          order_id,
          x: rowData.row.original,
          order_status,
          total_cost,
          payment_type,
          tax_fee,
          total_product_cost,
          shipping_fee,
          city,
          contact_name,
          contact_number,
          country,
          postal_code,
          bank_receipt_file,
          date: date.toLocaleDateString('en-US', options)
        };
        return (
          <Link
            state={dispatched}
            to={`/admin/e-commerce/orders/${order_id}/detail`}
          >
            <div>#{customerOrderNumberGenerator(order_id)}</div>
          </Link>
        );
      }
    },
    {
      accessor: 'created_at',
      Header: 'Order date',
      Cell: rowData => {
        const { created_at } = rowData.row.original;
        return moment(created_at).format('MM/DD/YYYY');
      }
    },
    {
      accessor: 'order_status',
      Header: 'Order Status',
      Cell: rowData => {
        const { order_status } = rowData.row.original;
        const status = status => {
          if (String(status).toLowerCase() === 'awaiting_shipment') {
            //BsClockHistory
            return (
              <SoftBadge pill bg="primary" className="me-2">
                Awaiting shipment {<TbTruckDelivery size={16} />}
              </SoftBadge>
            );
          }
          if (String(order_status).toLowerCase() === 'awaiting_payment') {
            //BsClockHistory
            return (
              <SoftBadge pill bg="warning" className="me-2">
                Awaiting Payment {<BsClockHistory size={16} />}
              </SoftBadge>
            );
          }
          if (String(status).toLowerCase() === 'awaiting_pickup') {
            //BsClockHistory
            return (
              <SoftBadge pill bg="secondary" className="me-2">
                Awaiting pickup {<MdPendingActions size={16} />}
              </SoftBadge>
            );
          }

          if (String(status).toLowerCase() === 'partially_paid') {
            //BsClockHistory
            return (
              <SoftBadge pill bg="info" className="me-2">
                Partially paid {<GiReceiveMoney size={16} />}
              </SoftBadge>
            );
          }

          if (String(status).toLowerCase() === 'declined') {
            //BsClockHistory
            return (
              <SoftBadge pill bg="danger" className="me-2">
                Declined {<AiOutlineStop size={16} />}
              </SoftBadge>
            );
          }

          return (
            <SoftBadge pill bg="success" className="me-2">
              Completed {<GiCheckMark size={16} />}
            </SoftBadge>
          );
        };
        return status(order_status);
      }
    },
    {
      accessor: 'shipping_type',
      Header: 'Shipping type',
      Cell: rowData => {
        const { shipping_type } = rowData.row.original;
        return `${shipping_type}`;
      }
    },
    {
      accessor: 'total_cost',
      Header: 'Total cost AED'
    },
    {
      accessor: 'paid_amount',
      Header: 'Paid AED',
      Cell: rowData => {
        const { paid_amount } = rowData.row.original;
        return paid_amount;
      }
    },
    {
      accessor: 'paid_controller',
      Header: 'Pay',
      Cell: rowData => {
        const { order_id, total_cost, shipping_type, order_status } =
          rowData.row.original;
        let amount = 0;
        const pay = e => {
          amount = e.target.value;
        };
        return (
          <div style={{ minWidth: '250px' }} className="">
            <form
              className="p-1"
              onSubmit={e => {
                e.preventDefault();
                DevLog({ val: amount });
              }}
              action=""
            >
              <InputGroup className="m-0 rounded-5">
                <FormControl
                  type="number"
                  // defaultValue={paid_amount || 0}
                  min={0}
                  disabled={
                    order_status !== 'AWAITING_PAYMENT' &&
                    order_status !== 'PARTIALLY_PAID'
                  }
                  max={total_cost}
                  className="p-1 rounded-start-4"
                  onChange={pay}
                  onKeyDown={e => {
                    if (e.key === 'Enter' || e.key === 'Return') {
                      e.preventDefault(); // Prevents the default behavior of the Enter key
                      paidHandler(
                        order_id,
                        { paid_amount: amount },
                        total_cost,
                        shipping_type
                      );
                    }
                  }}
                  aria-label="Dollar amount (with dot and two decimal places)"
                />
                <InputGroup.Text className="p-1">AED</InputGroup.Text>
                <InputGroup.Text className="p-1 rounded-end-4">
                  <SoftBadge
                    pill
                    role="button"
                    type="submit"
                    disabled={
                      order_status == 'DECLINED' || order_status == 'COMPLETED'
                    }
                    className={
                      'p-2 text-primary bg-soft-primary hover-button cursor-pointer'
                    }
                    onClick={() => {
                      DevLog({ order_status });
                      if (
                        order_status != 'DECLINED' &&
                        order_status != 'COMPLETED'
                      ) {
                        DevLog('first order');
                        paidHandler(
                          order_id,
                          { paid_amount: amount },
                          total_cost,
                          shipping_type
                        );
                      } else {
                        toast.error('Not authorized', { autoClose: true });
                      }
                    }}
                  >
                    <BsDatabaseFillAdd
                      style={{
                        cursor:
                          order_status !== 'AWAITING_PAYMENT' &&
                          order_status !== 'PARTIALLY_PAID'
                            ? 'not-allowed'
                            : 'pointer'
                      }}
                      size={20}
                    />
                  </SoftBadge>
                </InputGroup.Text>
              </InputGroup>
            </form>
          </div>
        );
      }
    },
    {
      accessor: 'remaining_amount',
      Header: 'Remaining AED',
      Cell: rowData => {
        const { remaining_amount } = rowData.row.original;
        return remaining_amount == null
          ? '-'
          : parseFloat(remaining_amount).toFixed(2);
      }
    },
    {
      accessor: 'approve',
      Header: 'Approve',
      Cell: rowData => {
        const { order_id } = rowData.row.original;

        return (
          <Button
            onClick={() => {
              changeOrderStatusHandler(order_id, {
                order_status: 'COMPLETED'
              });
            }}
            variant="outline-primary"
            className="rounded-pill me-1 px-2 py-1 fs--1 mb-1"
          >
            Complete <BsCheck2Circle />
          </Button>
        );
      }
    },
    {
      accessor: 'reject',
      Header: 'Reject',
      Cell: rowData => {
        const { order_id } = rowData.row.original;
        return (
          <Button
            onClick={() => {
              changeOrderStatusHandler(order_id, {
                order_status: 'DECLINED'
              });
            }}
            variant="outline-danger"
            className="rounded-pill me-1 px-2 py-1 fs--1 mb-1"
          >
            Decline <AiOutlineCloseCircle />
          </Button>
        );
      }
    },
    {
      accessor: 'comment',
      Header: 'Leave Comment',
      Cell: rowData => {
        const { order_id } = rowData.row.original;
        return (
          <CreateComment
            title={
              <>
                <p>Leave a comment</p>{' '}
                <b className="text-danger">
                  Comment length should not exceed from 200 letters (including
                  white space)
                </b>
              </>
            }
          >
            <LeaveComment id={order_id} />
          </CreateComment>
        );
      }
    }
  ];
  const handleSearch = event => {
    const query = event.target.value;
    setSearchQuery(query);
  };

  const handleSearchButton = async () => {
    await axios
      .get(
        BASE_URL +
          `/dashboard/orders/?customer__business_name__contains=${searchQuery}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `JWT ${decryptData('token')}`
          }
        }
      )
      .then(response => {
        const data = response.data.results?.map(a => {
          const {
            order_id,
            created_at,
            total_cost,
            customer,
            last_shipping_address,
            order_status
          } = a;
          const { shipping_type } = last_shipping_address;
          const { business_name } = customer;

          return {
            order_id,
            created_at,
            total_cost,
            shipping_type,
            business_name,
            order_status
          };
        });

        setFilteredItems(data);
      })
      .catch(err => DevLog(err));
  };

  const handleFilterByStatus = async () => {
    setIsLoading(true);
    DevLog({ options });
  };
  const fetchOrders = async () => {
    setIsLoading(true);
    await axios
      .get(BASE_URL + `/dashboard/orders/`, {
        headers: {
          Authorization: `JWT ${decryptData('token')}`
        }
      })
      .then(response => {
        DevLog({ orders: response });
        const { count } = response.data;

        setCount(count);
        setPageNumber(Math.ceil(Number(count / pageSize)));
        const data = response.data.results?.map(a => {
          const {
            order_id,
            created_at,
            total_cost,
            customer,
            last_shipping_address,
            order_status,
            paid_amount,
            payment_type,
            remaining_amount,
            bank_receipt_file,
            shipping_fee,
            tax_fee,
            is_new,
            total_product_cost
          } = a;
          const {
            shipping_type,
            address_line_1,
            address_line_2,
            city,
            contact_name,
            contact_number,
            country,
            postal_code
          } = last_shipping_address;
          const { business_name } = customer;

          return {
            is_new,
            payment_type,
            bank_receipt_file,
            order_id,
            paid_amount,
            created_at,
            total_cost,
            shipping_type,
            business_name,
            order_status,
            remaining_amount,
            address_line_1,
            address_line_2,
            city,
            contact_name,
            contact_number,
            country,
            postal_code,
            tax_fee,
            shipping_fee,
            total_product_cost
          };
        });

        setFilteredItems(data);
      })
      .catch(err => {
        DevLog(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const changePageByNumber = async n => {
    setIsLoading(true);
    setRequestNumber(n);
    await axios
      .get(BASE_URL + `/dashboard/orders/?page=${n}`, {
        headers: {
          Authorization: `JWT ${decryptData('token')}`
        }
      })
      .then(response => {
        DevLog({ response });
        const { count } = response.data;
        setPageNumber(Math.ceil(Number(count / pageSize)));
        const data = response.data.results?.map(a => {
          const {
            order_id,
            created_at,
            total_cost,
            customer,
            last_shipping_address,
            order_status,
            bank_receipt_file
          } = a;
          const { shipping_type } = last_shipping_address;
          const { business_name } = customer;
          return {
            order_id,
            created_at,
            total_cost,
            shipping_type,
            business_name,
            order_status,
            bank_receipt_file
          };
        });

        setFilteredItems(data);
      })
      .catch(err => {
        DevLog(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const changeNext = async n => {
    setIsLoading(true);
    setRequestNumber(n + 1);
    await axios
      .get(BASE_URL + `/dashboard/orders/?page=${n + 1}`, {
        headers: {
          Authorization: `JWT ${decryptData('token')}`
        }
      })
      .then(response => {
        DevLog({ response });
        const { count } = response.data;
        setPageNumber(Math.ceil(Number(count / pageSize)));
        const data = response.data.results?.map(a => {
          const {
            order_id,
            created_at,
            total_cost,
            customer,
            last_shipping_address,
            order_status,
            bank_receipt_file
          } = a;
          const { shipping_type } = last_shipping_address;
          const { business_name } = customer;
          return {
            order_id,
            created_at,
            total_cost,
            shipping_type,
            business_name,
            order_status,
            bank_receipt_file
          };
        });

        setFilteredItems(data);
      })
      .catch(err => {
        DevLog(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const changePrevious = async n => {
    setIsLoading(true);
    setRequestNumber(n);
    if (n !== 1) {
      setRequestNumber(n - 1);
    }
    await axios
      .get(BASE_URL + `/dashboard/orders/?page=${n - 1}`, {
        headers: {
          Authorization: `JWT ${decryptData('token')}`
        }
      })
      .then(response => {
        DevLog({ response });
        const { count } = response.data;
        setPageNumber(Math.ceil(Number(count / pageSize)));
        const data = response.data.results?.map(a => {
          const {
            order_id,
            created_at,
            total_cost,
            customer,
            last_shipping_address,
            order_status
          } = a;
          const { shipping_type } = last_shipping_address;
          const { business_name } = customer;
          return {
            order_id,
            created_at,
            total_cost,
            shipping_type,
            business_name,
            order_status
          };
        });

        setFilteredItems(data);
      })
      .catch(err => {
        DevLog(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    document.documentElement.style.zoom = '0.8';
    setPageNumber(0);
    fetchOrders();
    return () => {
      document.documentElement.style.zoom = '1';
    };
  }, [pageSize]);

  // if (isLoading) {
  //   return <TableSkeleton />;
  // }
  return (
    <Card className="p-3 text-800">
      <Divider>Advanced Filtering</Divider>
      <Row>
        <Col xs="auto" sm={12} lg={4}>
          <InputGroup className={'position-relative'}>
            <FormControl
              size="sm"
              id="search_by_business_name"
              placeholder={'search by business name'}
              type="search"
              value={searchQuery}
              onChange={handleSearch}
              className="shadow-none"
            />
            <Button
              size="sm"
              onClick={handleSearchButton}
              variant="outline-secondary"
              className="border-300 hover-border-secondary"
            >
              <FontAwesomeIcon icon="search" className="fs--1" />
            </Button>
          </InputGroup>
        </Col>
        <Col xs="auto" sm={12} lg={4}>
          <DropdownWithCheckbox
            // setOptionsParent={setOptions}
            setFilteredItems={setFilteredItems}
            setIsLoading={setIsLoading}
            updateStatusGlobally={handleFilterByStatus}
          />
        </Col>
        <Col xs="auto" sm={12} lg={4}>
          <AdminAdvancedDateRangePicker
            filter={setFilteredItems}
            filteredItems={filteredItems}
            setDateRange={setDateRange}
          />
        </Col>
      </Row>
      <Divider>Orders page</Divider>
      <AdvanceTableWrapper
        columns={columns}
        data={filteredItems}
        sortable
        pagination
        perPage={15}
      >
        <Row className="flex-end-center mb-3">
          <Col xs="auto" sm={6} lg={3}>
            <AdvanceTableSearchBox table />
          </Col>
        </Row>
        {isLoading ? (
          <TableSkeleton />
        ) : (
          <AdvanceTable
            table
            headerClassName="bg-200 text-900 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              bordered: true,
              striped: true,
              className: 'fs--1 mb-0 overflow-hidden'
            }}
          />
        )}

        {/* <div className="mt-3">
          <AdminAdvanceTableFooter
            rowCount={count}
            table
            rowInfo
            navButtons
            requestNumber={requestNumber}
            sendPageSize={setPageSize}
            rowsPerPageSelection
          />
        </div> */}
        <div className="mt-3 ">
          <div className=" d-flex justify-content-center align-items-center">
            {filteredItems.length > 0 && (
              <Pagination>
                <Pagination.Prev
                  disabled={requestNumber == 1}
                  onClick={() => changePrevious(requestNumber)}
                />
                {generateArray(pageNumber).map((e, i) => {
                  DevLog({ e, pageNumber, i });
                  return (
                    <Pagination.Item
                      active={requestNumber == e}
                      onClick={() => changePageByNumber(e)}
                      key={i}
                    >
                      {e}
                    </Pagination.Item>
                  );
                })}
                <Pagination.Next
                  disabled={requestNumber == generateArray(pageNumber).length}
                  onClick={() => changeNext(requestNumber)}
                />
              </Pagination>
            )}
          </div>
        </div>
      </AdvanceTableWrapper>
    </Card>
  );
};

export default AdminAllOrders;
