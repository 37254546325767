/* eslint-disable react/prop-types */
import React, { useContext } from 'react';
import { Card } from 'antd';
import { Link } from 'react-router-dom';
import './WidgetCard.css'; // Create this CSS file for styling
import AppContext from 'context/Context';
import useScreenSize from 'hooks/useScreenSize';

const WidgetCard = ({
  gradientColors,
  widgetName,
  widgetLink,
  children,
  color,
  isNew,
  newCount = 0,
  oldCount = 0,
  isCountNeeded = false,
  ...otherProps
}) => {
  const {
    config: { isDark }
  } = useContext(AppContext);
  const screen = useScreenSize();

  const cardStyle = {
    background: !isDark
      ? `linear-gradient(to bottom right, ${gradientColors[0]}, ${gradientColors[1]})`
      : '',
    color: 'white',
    height: screen == 'desktop' ? '130px' : 'auto'
  };

  return (
    <Link className=" text-decoration-none position-relative" to={widgetLink}>
      {isNew && newCount !== 0 && (
        <div
          style={{
            right: '-10px',
            top: '-30px'
          }}
          className=" d-flex justify-content-end position-absolute z-3 "
        >
          <div
            className="rounded-5 p-2 bg-danger "
            style={{
              minWidth: '40px',
              minHeight: '40px'
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%'
              }}
              className=""
            >
              <b className="text-white d-inline-block">{newCount}</b>
            </div>
          </div>
        </div>
      )}
      <Card
        className={`my-1 widget-card ${isDark && 'widget-card-blur'}`}
        style={cardStyle}
        {...otherProps}
      >
        {children}
        <h6
          className={`mt-2 ${screen == 'desktop' ? 'fs-2' : ''}`}
          style={{ color: color }}
        >
          {widgetName} {isCountNeeded && `(${oldCount})`}
        </h6>
      </Card>
    </Link>
  );
};

export default WidgetCard;
