import React, { useContext } from 'react';
import { Card } from 'react-bootstrap';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import PropTypes from 'prop-types';
import AppContext from 'context/Context';

const TableSkeleton = ({ lines = 6 }) => {
  const {
    config: { isDark }
  } = useContext(AppContext);

  return (
    <Card className="p-4">
      <SkeletonTheme
        baseColor={isDark ? '#333' : '#fafafa'}
        highlightColor={isDark ? '#444' : '#e5e5e5'}
      >
        <Skeleton width={250} height={25} className="mx-3" />
      </SkeletonTheme>
      <table className="table">
        <thead>
          <tr>
            <th>
              <SkeletonTheme
                baseColor={isDark ? '#333' : '#fafafa'}
                highlightColor={isDark ? '#444' : '#e5e5e5'}
              >
                <Skeleton />
              </SkeletonTheme>
            </th>
            <th>
              <SkeletonTheme
                baseColor={isDark ? '#333' : '#fafafa'}
                highlightColor={isDark ? '#444' : '#e5e5e5'}
              >
                <Skeleton />
              </SkeletonTheme>
            </th>
            <th>
              <SkeletonTheme
                baseColor={isDark ? '#333' : '#fafafa'}
                highlightColor={isDark ? '#444' : '#e5e5e5'}
              >
                <Skeleton />
              </SkeletonTheme>
            </th>

            <th>
              <SkeletonTheme
                baseColor={isDark ? '#333' : '#fafafa'}
                highlightColor={isDark ? '#444' : '#e5e5e5'}
              >
                <Skeleton />
              </SkeletonTheme>
            </th>
            <th>
              <SkeletonTheme
                baseColor={isDark ? '#333' : '#fafafa'}
                highlightColor={isDark ? '#444' : '#e5e5e5'}
              >
                <Skeleton />
              </SkeletonTheme>
            </th>
            <th>
              <SkeletonTheme
                baseColor={isDark ? '#333' : '#fafafa'}
                highlightColor={isDark ? '#444' : '#e5e5e5'}
              >
                <Skeleton />
              </SkeletonTheme>
            </th>
            <th>
              <SkeletonTheme
                baseColor={isDark ? '#333' : '#fafafa'}
                highlightColor={isDark ? '#444' : '#e5e5e5'}
              >
                <Skeleton />
              </SkeletonTheme>
            </th>
            <th>
              <SkeletonTheme
                baseColor={isDark ? '#333' : '#fafafa'}
                highlightColor={isDark ? '#444' : '#e5e5e5'}
              >
                <Skeleton />
              </SkeletonTheme>
            </th>

            {/* Add more table headers if needed */}
          </tr>
        </thead>
        <tbody>
          {Array.from({ length: lines }, (_, index) => (
            <tr key={index}>
              <td>
                <SkeletonTheme
                  baseColor={isDark ? '#333' : '#fafafa'}
                  highlightColor={isDark ? '#444' : '#e5e5e5'}
                >
                  <Skeleton />
                </SkeletonTheme>
              </td>
              <td>
                <SkeletonTheme
                  baseColor={isDark ? '#333' : '#fafafa'}
                  highlightColor={isDark ? '#444' : '#e5e5e5'}
                >
                  <Skeleton />
                </SkeletonTheme>
              </td>
              <td>
                <SkeletonTheme
                  baseColor={isDark ? '#333' : '#fafafa'}
                  highlightColor={isDark ? '#444' : '#e5e5e5'}
                >
                  <Skeleton />
                </SkeletonTheme>
              </td>
              <td>
                <SkeletonTheme
                  baseColor={isDark ? '#333' : '#fafafa'}
                  highlightColor={isDark ? '#444' : '#e5e5e5'}
                >
                  <Skeleton />
                </SkeletonTheme>
              </td>
              <td>
                <SkeletonTheme
                  baseColor={isDark ? '#333' : '#fafafa'}
                  highlightColor={isDark ? '#444' : '#e5e5e5'}
                >
                  <Skeleton />
                </SkeletonTheme>
              </td>
              <td>
                <SkeletonTheme
                  baseColor={isDark ? '#333' : '#fafafa'}
                  highlightColor={isDark ? '#444' : '#e5e5e5'}
                >
                  <Skeleton />
                </SkeletonTheme>
              </td>
              <td>
                <SkeletonTheme
                  baseColor={isDark ? '#333' : '#fafafa'}
                  highlightColor={isDark ? '#444' : '#e5e5e5'}
                >
                  <Skeleton />
                </SkeletonTheme>
              </td>
              <td>
                <SkeletonTheme
                  baseColor={isDark ? '#333' : '#fafafa'}
                  highlightColor={isDark ? '#444' : '#e5e5e5'}
                >
                  <Skeleton />
                </SkeletonTheme>
              </td>
              {/* Add more table cells if needed */}
            </tr>
          ))}
        </tbody>
      </table>
      <div className="d-flex justify-content-between">
        <SkeletonTheme
          baseColor={isDark ? '#333' : '#fafafa'}
          highlightColor={isDark ? '#444' : '#e5e5e5'}
        >
          <Skeleton width={150} height={25} className="mx-3" />
          <Skeleton width={150} height={25} className="mx-3" />
        </SkeletonTheme>
      </div>
    </Card>
  );
};
TableSkeleton.propTypes = {
  lines: PropTypes.number
};

export default TableSkeleton;
