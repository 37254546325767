/* eslint-disable react/prop-types */
import SoftBadge from 'components/common/SoftBadge';
import React, { useState } from 'react';
import { Offcanvas } from 'react-bootstrap';
import { AiOutlineHistory } from 'react-icons/ai';

const CreateComment = ({
  children,
  title,
  icon = <AiOutlineHistory className="text-primary cursor-pointer" size={25} />
}) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <SoftBadge
        bg="primary"
        onClick={handleShow}
        className="rounded-pill me-1 p-1  fs--1 mb-1 "
      >
        {' '}
        {icon}
      </SoftBadge>
      <Offcanvas
        className="custom-offcanvas"
        placement="end"
        show={show}
        onHide={handleClose}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{title}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>{children}</Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default CreateComment;
