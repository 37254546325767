/* eslint-disable react/prop-types */
import axios from 'axios';
import IconAlert from 'components/common/IconAlert';
import PenaltyCanvas from 'components/common/PenaltiesCanvas/PenaltyCanvas';
import SoftBadge from 'components/common/SoftBadge';
import { BASE_URL } from 'constants/url';
import useCurrencyStore from 'currency/useCurrencyStore';
import DevLog from 'helpers/DevLog';
import React, { useState } from 'react';
import { MdDone } from 'react-icons/md';
import { toast } from 'react-toastify';
import decryptData from 'secret/decryptData';

const CustomerNotification = ({ notifications, whole, user_type }) => {
  const { deposit_comment_id, action, amount, comment, created_at } =
    notifications;
  const [read, setRead] = useState(false);
  const { currency } = useCurrencyStore();
  const markAsRead = async () => {
    await axios
      .patch(
        BASE_URL +
          `/${user_type}/deposit/comment/${deposit_comment_id}/update/`,
        { is_new: false },
        {
          headers: {
            Authorization: `JWT ${decryptData('token')}`
          }
        }
      )
      .then(response => {
        DevLog({ response });
        return toast.success(`Successfully marked as read`, {
          autoClose: true
        });
      })
      .catch(err => toast.error(err, { autoClose: true }))
      .finally(() => {
        setRead(true);
      });
  };
  function formatISO8601(isoString) {
    const date = new Date(isoString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
  }
  return !read ? (
    <IconAlert variant={'warning'} className="">
      <div className="d-flex justify-content-between">
        <div className="d-flex align-items-center">
          {/* <div className="">❗️</div> */}
          <div className="">
            {action == 'WITHDRAWAL' ? `Withdrawn ${currency} ${amount}` : ''}.
            Reason: {comment}
          </div>{' '}
        </div>
        <div className="d-flex align-items-center">
          <p className="mx-3 my-0 p-0">{formatISO8601(created_at)}</p>

          <div
            onClick={markAsRead}
            className="bg-blur mx-1 px-2 py-1 cursor-pointer hover-1100 rounded-3"
          >
            Mark as read <MdDone />
          </div>
          <PenaltyCanvas data={whole} placement="end" title={'Penalties'}>
            {whole.map((w, i) => {
              return (
                <IconAlert key={i} variant={'warning'} className="px-1 py-1">
                  {`Withdrawal amount: ${w.amount} `} <br />
                  Reason: {w.comment} <br /> {formatISO8601(w.created_at)}{' '}
                  {w.is_new && (
                    <SoftBadge bg="danger" className="fs--1 mx-2">
                      NEW
                    </SoftBadge>
                  )}
                </IconAlert>
              );
            })}
          </PenaltyCanvas>
        </div>
      </div>
    </IconAlert>
  ) : (
    <></>
  );
};

export default CustomerNotification;
