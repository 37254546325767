import axios from 'axios';
import { BASE_URL } from 'constants/url';
import DevLog from 'helpers/DevLog';
import React, { useState } from 'react';
import {
  Button,
  Col,
  Form,
  FormControl,
  InputGroup,
  Row,
  Spinner
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import decryptData from 'secret/decryptData';
// TODO: ADD  INPUTS FOR FROM 0-100, 101-500, 501 - 1000, 1001+
const PerUnit = () => {
  const [formData, setFormData] = useState({
    name: '',
    documentation_fee: '',
    vat_percentage: '',
    price_per_unit: true,
    region: ''
    // region: 'Kyrgyzstan'÷
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = e => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? checked : value;

    if (name == 'documentation_fee' || name == 'vat_percentage') {
      if (/^-?\d*\.?\d*$/.test(value) || value === '') {
        setFormData(prevData => ({
          ...prevData,
          [name]: newValue
        }));
      }
    } else if (name == 'name' || name == 'region') {
      setFormData(prevData => ({
        ...prevData,
        [name]: newValue
      }));
    }
  };

  const handleSubmit = async e => {
    setIsLoading(true);
    e.preventDefault();
    const config = {
      headers: {
        Authorization: `JWT ${decryptData('token')}`,
        'Content-Type': 'application/json'
      }
    };

    try {
      await axios
        .post(
          `${BASE_URL}/dashboard/shipping-detail/add/`,
          { ...formData, price_per_unit: true },
          config
        )
        .then(response => {
          if (response.status === 201) {
            toast.success(`New shipping option has been successfully created`, {
              theme: 'colored',
              position: 'top-right',
              autoClose: true
            });
            const form = e.target;
            form.reset();
            setFormData({
              name: '',
              documentation_fee: '',
              price_per_kg: '',
              vat_percentage: ''
            });
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    } catch (error) {
      DevLog(error);
      toast.error(
        `New shipping option has not been created. Error: ${error.message} `,
        {
          theme: 'colored',
          position: 'top-right',
          autoClose: true
        }
      );
    }
  };
  return (
    <Form onSubmit={handleSubmit}>
      <Row>
        <Col className="my-2" sm={12} lg={6} md={6}>
          <Form.Label>Shipping type</Form.Label>

          <Form.Select
            onChange={handleChange}
            name="region"
            aria-label="Default select example"
          >
            <option>Please select option</option>
            <option value="INSIDE_UAE">Inside UAE</option>
            <option value="OUTSIDE_UAE">Outside UAE</option>
            <option value="FREE_ZONE">Free Zone</option>
            <option value="PICKUP">Pick up</option>
          </Form.Select>
        </Col>
        <Col className="my-2" sm={12} lg={6} md={6}>
          <Form.Group controlId="formName">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              name="name"
              required
              value={formData.name}
              onChange={handleChange}
              placeholder="Enter name"
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col className="my-2" sm={12} lg={6} md={6}>
          <Form.Group controlId="formDocumentationFee">
            <Form.Label>Documentation Fee</Form.Label>
            <Form.Control
              type="text"
              name="documentation_fee"
              value={formData.documentation_fee}
              onChange={handleChange}
              min={0}
              placeholder="Enter documentation fee"
            />
          </Form.Group>
        </Col>
        <Col className="my-2" sm={12} lg={6} md={6}>
          <Form.Label>VAT Percentage</Form.Label>

          <InputGroup className="mb-3">
            <FormControl
              name="vat_percentage"
              aria-label="VAT Percentage"
              type="text"
              min={0}
              value={formData.vat_percentage}
              onChange={handleChange}
              placeholder="Enter VAT percentage"
              aria-describedby="basic-addon2"
            />
            <InputGroup.Text id="basic-addon2">
              <b>%</b>
            </InputGroup.Text>
          </InputGroup>
        </Col>
        <Col className="my-1" sm={12} lg={6} md={6}></Col>
      </Row>
      <br />
      {isLoading ? (
        <Button
          variant="primary"
          className="d-inline-flex mt-3 flex-center gap-1"
          disabled
        >
          <Spinner
            as="span"
            animation="grow"
            size="sm"
            role="status"
            aria-hidden="true"
          />
          Loading...
        </Button>
      ) : (
        <Button type="submit" variant="primary">
          Create +
        </Button>
      )}
    </Form>
  );
};

export default PerUnit;
