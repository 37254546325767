import React from 'react';
import { Card, ListGroup } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
// import PropTypes from 'prop-types';
import SoftBadge from 'components/common/SoftBadge';
import Flex from 'components/common/Flex';

const OrderSummarySkeleton = () => {
  return (
    <Card className="my-2 p-0">
      <ListGroup.Item>
        <Flex className={''} justifyContent="between" alignItems="center">
          <p className="mt-1 mb-1">
            <Skeleton width={330} height={25} className="mx-3" />
            <Skeleton width={270} height={25} className="mx-3" />
          </p>
          <SoftBadge bg="secondary" className="">
            <Skeleton width={60} height={55} className="" />
          </SoftBadge>
        </Flex>
      </ListGroup.Item>
    </Card>
  );
};
// OrderSummarySkeleton.propTypes = {
//   lines: PropTypes.number
// };

export default OrderSummarySkeleton;
