/* eslint-disable react/react-in-jsx-scope */
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import { useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';

import axios from 'axios';
import SoftBadge from 'components/common/SoftBadge';
import { BASE_URL } from 'constants/url';
import {
  BsCheckCircle,
  BsFillCaretRightFill,
  BsFillCheckCircleFill,
  BsFillTrash2Fill,
  BsXLg
} from 'react-icons/bs';
import { HiBadgeCheck } from 'react-icons/hi';
import decryptData from 'secret/decryptData';
import useUserCart from 'state/cartStore';
import { toast } from 'react-toastify';
import IconAlert from 'components/common/IconAlert';
import { Link } from 'react-router-dom';
import DevLog from 'helpers/DevLog';

const SupplierCheckoutPage = () => {
  const { Cart, removeFromCart, clearCart } = useUserCart();
  let confirmedProducts = [];
  const handleSubmit = async () => {
    DevLog({
      conf: confirmedProducts.length,
      Cart: Cart.length
    });
    if (confirmedProducts.length !== Cart.length) {
      alert(
        'Please fill out all the required information and make sure you have confirmed all the products'
      );
    } else {
      await axios
        .post(BASE_URL + `/store/supplier/product/add/`, confirmedProducts, {
          headers: {
            Authorization: `JWT ${decryptData('token')}`
          }
        })
        .then(r => {
          DevLog({ r });
          if (r.status == 201) {
            clearCart();
            toast.success(`Successfully placed your new orders 🥳`, {
              autoClose: true
            });
          }
        })
        .catch(err => {
          DevLog(err);
        });
    }
  };
  const columns = [
    {
      accessor: 'brand',
      Header: 'Model',
      headerProps: { className: 'text-900' }
    },
    {
      accessor: 'name',
      Header: 'More',
      headerProps: { className: 'text-900' }
    },
    {
      accessor: 'lowest_price',
      Header: 'Lowest Price',
      headerProps: { className: 'text-900' }
    },
    {
      accessor: 'quantity',
      Header: 'Info',
      headerProps: { className: 'text-900' },
      Cell: rowData => {
        const [qty, setQty] = useState(0);
        const [price, setPrice] = useState(0);
        const [hasConfirmed, setHasConfirmed] = useState(false);

        return (
          <div className="d-flex" style={{ minWidth: '400px' }}>
            <Form.Control
              size="sm"
              disabled={hasConfirmed}
              type="number"
              className="mx-2"
              onChange={e => setQty(e.target.value)}
              placeholder={'Quantity'}
            />
            <Form.Control
              onChange={e => setPrice(e.target.value)}
              size="sm"
              disabled={hasConfirmed}
              type="number"
              placeholder={'price'}
            />
            <>
              {!hasConfirmed && (
                <>
                  <SoftBadge
                    pill
                    bg="danger"
                    className="d-flex justify-content-center align-items-center mx-1"
                  >
                    Not confirmed <BsXLg size={15} className="mx-1" />
                  </SoftBadge>
                  <Button
                    variant="success"
                    onClick={() => {
                      if (!price || !qty) {
                        alert('Please fill the quantity field and price field');
                        return;
                      } else {
                        setHasConfirmed(!hasConfirmed);
                        const { product_id } = rowData.row.original;
                        const confirmedItem = {
                          product: product_id,
                          price,
                          supplier_product_quantity: qty
                        };
                        confirmedProducts.push(confirmedItem);
                        DevLog({ confirmedProducts });
                      }
                    }}
                    className="mr-1"
                  >
                    <BsFillCheckCircleFill size={20} />
                  </Button>
                </>
              )}
            </>
            <>
              {hasConfirmed && (
                <>
                  <SoftBadge
                    pill
                    className="d-flex justify-content-center align-items-center mx-1"
                  >
                    Confirmed <BsCheckCircle size={15} className="mx-1" />
                  </SoftBadge>
                  <Button
                    onClick={() => {
                      const { product_id } = rowData.row.original;
                      setHasConfirmed(!hasConfirmed);
                      confirmedProducts = confirmedProducts.filter(
                        cp => cp.product != product_id
                      );
                      DevLog({
                        confirmedProducts
                      });
                    }}
                    className="mx-1"
                  >
                    <HiBadgeCheck size={20} />
                  </Button>
                </>
              )}
            </>
            <>
              <Button
                disabled={hasConfirmed}
                onClick={() => {
                  removeFromCart(rowData.row.original.product_id);
                  confirmedProducts = confirmedProducts.filter(
                    cp => cp.product != rowData.row.original.product_id
                  );
                  DevLog({
                    confirmedProducts
                  });
                }}
                variant="danger"
                className="mx-1"
              >
                <BsFillTrash2Fill size={20} className="mx-1" />
              </Button>
            </>
          </div>
        );
      }
    }
  ];

  if (Cart.length == 0 || Cart == null || Cart === undefined || Cart == '') {
    return (
      <Card className="p-3 pt-4">
        <IconAlert variant={'info'}>
          <h1 className="fs-2 text-info m-0">
            Your cart is empty{' '}
            <Link className="fs-2 text-dark m-0" to={'/supplier/user/profile'}>
              Go to home page <BsFillCaretRightFill />
            </Link>
          </h1>
        </IconAlert>
      </Card>
    );
  }
  return (
    <>
      {confirmedProducts?.map((p, i) => {
        return <h1 key={i}>{p.name} </h1>;
      })}
      <Card className="p-4">
        <AdvanceTableWrapper
          columns={columns}
          data={Cart}
          sortable
          pagination
          perPage={100}
        >
          <Row className="flex-end-center mb-3">
            <Col xs="auto" sm={6} lg={4}>
              <Button onClick={handleSubmit}>Place Order</Button>
            </Col>{' '}
            <Col xs="auto" sm={6} lg={4}></Col>
            <Col xs="auto" sm={6} lg={4}>
              <AdvanceTableSearchBox table />
            </Col>
          </Row>
          <AdvanceTable
            table
            headerClassName="bg-200 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              bordered: true,
              striped: true,
              className: 'fs--1 mb-0 overflow-hidden'
            }}
          />
          <div className="mt-3">
            <AdvanceTableFooter
              rowCount={Cart.length}
              table
              rowInfo
              navButtons
              rowsPerPageSelection
            />
          </div>
        </AdvanceTableWrapper>
      </Card>
    </>
  );
};

export default SupplierCheckoutPage;
