import useCurrencyStore from 'currency/useCurrencyStore';
import React from 'react';
import { Alert, Card } from 'react-bootstrap';

const TopUpBalance = () => {
  const { currency } = useCurrencyStore();
  //
  return (
    <Card style={{ height: '100vh !important' }} className="p-4">
      <Alert variant="info">
        <h1 className=" text-info">Your balance: {currency} 10,000</h1>
        <h4 className="text-info">
          {' '}
          To top-up your balance, please contact with Nasimxon, cashier
        </h4>
        <h4 className="text-info">
          Phone:{' '}
          <a className="text-dark" href="tel:+971 50 297 4001">
            +971 50 297 4001
          </a>{' '}
          <a className="text-dark" href="tel:+971 50 235 4007">
            +971 50 235 4007
          </a>
        </h4>
      </Alert>
    </Card>
  );
};

export default TopUpBalance;
