// LaptopCartContext.js
import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const LaptopCartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState([]);

  const addToCart = item => {
    setCartItems(prevItems => [...prevItems, item]);
  };

  const removeFromCart = item => {
    setCartItems(prevItems => prevItems.filter(prevItem => prevItem !== item));
  };

  return (
    <LaptopCartContext.Provider
      value={{ cartItems, addToCart, removeFromCart }}
    >
      {children}
    </LaptopCartContext.Provider>
  );
};
CartProvider.propTypes = {
  children: PropTypes.node.isRequired
};
