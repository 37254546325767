import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Alert, Button, Form } from 'react-bootstrap';
import classNames from 'classnames';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { BASE_URL } from 'constants/url';
import DevLog from 'helpers/DevLog';

const PasswordResetForm = ({ hasLabel }) => {
  // State
  const [formData, setFormData] = useState({
    new_password: '',
    re_new_password: '',
    token: '',
    uid: ''
  });

  const [status, setStatus] = useState('');
  const params = useParams();
  useEffect(() => {
    setFormData({ ...formData, uid: params.uid, token: params.token });
  }, []);

  // Handler
  const handleSubmit = async e => {
    e.preventDefault();
    await axios
      .post(BASE_URL + '/auth/users/reset_password_confirm/', formData, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        setStatus(response.status);
      })
      .catch(err => {
        DevLog(err);

        setStatus(400);
      });
    ////////////////////////////////////////////////////////
    toast.success('Login with your new password', {
      theme: 'colored'
    });
  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <Form
      className={classNames('mt-3', { 'text-left': hasLabel })}
      onSubmit={handleSubmit}
    >
      {status == 204 && (
        <Alert className="text-center" variant={'success'}>
          <>
            <b> Congratulations 😎</b> <br />
            Your password has been updated to <i>{formData.new_password}</i>
          </>
        </Alert>
      )}
      {status == 400 && (
        <Alert className="text-center" variant={'danger'}>
          <>
            <b> Oh Snap!</b> <br />
            Please try again :(
          </>
        </Alert>
      )}
      {status.length !== 0 ? (
        ''
      ) : (
        <>
          <Form.Group className="mb-3">
            {hasLabel && <Form.Label>New Password</Form.Label>}
            <Form.Control
              placeholder={!hasLabel ? 'New Password' : ''}
              value={formData.new_password}
              name="new_password"
              onChange={handleFieldChange}
              type="password"
            />
          </Form.Group>

          <Form.Group className="mb-3">
            {hasLabel && <Form.Label>Confirm Password</Form.Label>}
            <Form.Control
              placeholder={!hasLabel ? 'Confirm Password' : ''}
              value={formData.re_new_password}
              name="re_new_password"
              onChange={handleFieldChange}
              type="password"
            />
          </Form.Group>

          <Button
            type="submit"
            className="w-100"
            disabled={!formData.new_password || !formData.re_new_password}
          >
            Set password
          </Button>
        </>
      )}
    </Form>
  );
};

PasswordResetForm.propTypes = {
  hasLabel: PropTypes.bool
};

export default PasswordResetForm;
