import React, { useContext, useState } from 'react';
import { Card, Form } from 'react-bootstrap';

import FreeZoneShippingForm from './shipping address form by country/FreeZoneShippingForm';
import UAEShippingForm from './shipping address form by country/UAEShippingForm';
import OtherRegionsForm from './shipping address form by country/OtherRegionsForm';
import ShippingContext from './ShippingContext';

import IPickUp from './shipping address form by country/IPickUp';
import { useLanguage } from 'provider/LanguageProvider';

const ShippingFormCustom = () => {
  const [formType, setFormType] = useState('');
  const { data, setData } = useContext(ShippingContext);
  const { language } = useLanguage();
  const handleRadioChange = event => {
    setFormType(event.target.value);
    if (event.target.value === 'no_delivery') {
      let d = JSON.parse(data);
      setData({ ...d, vat: 0, price: 0, name: '' });
    }
    if (event.target.value === 'dubai') {
      let d = JSON.parse(data);
      setData({ ...d, vat: 0, price: 0 });
    }
    if (event.target.value === 'free_zone') {
      let d = JSON.parse(data);
      setData({ ...d, vat: 0, price: 120, name: '' });
    }
    if (event.target.value === 'other') {
      let d = JSON.parse(data);
      setData({ ...d, vat: 0, price: 0 });
    }
  };

  const renderForm = () => {
    switch (formType) {
      case 'no_delivery':
        return <IPickUp />;
      case 'free_zone':
        return <FreeZoneShippingForm />;
      case 'dubai':
        return <UAEShippingForm />;
      case 'other':
        return <OtherRegionsForm />;
      default:
        return null;
    }
  };
  const formTranslate = (label, language) => {
    if (label == 'I will pick my products' && language == 'en') {
      return 'I will pick my products';
    } else if (label == 'I will pick my products' && language == 'uz') {
      return 'Men mahsulotlarimni olib ketaman';
    } else if (label == 'I will pick my products' && language == 'ru') {
      return 'Я заберу свои продукты';
    }
    // next ---
    else if (label == 'United Arab Emirates' && language == 'en') {
      return 'United Arab Emirates';
    } else if (label == 'United Arab Emirates' && language == 'uz') {
      return 'Birlashgan Arab Amirliklari';
    } else if (label == 'United Arab Emirates' && language == 'ru') {
      return 'Объединенные Арабские Эмираты';
    }
    // next ---
    else if (label == 'Global' && language == 'en') {
      return 'Global';
    } else if (label == 'Global' && language == 'uz') {
      return 'Jahon';
    } else if (label == 'Global' && language == 'ru') {
      return 'Мировой';
    }
  };
  return (
    <div>
      <Card className="p-3 mb-2">
        <h3 className="my-2">
          {' '}
          {language == 'ru' && 'Адрес доставки'}
          {language == 'uz' && 'Yetkazib berish manzili'}
          {language == 'en' && 'Shipping address'}
        </h3>
        <Form.Group className="text-700">
          <Form.Check
            type="radio"
            label={formTranslate('I will pick my products', language)}
            name="formRadio"
            value="no_delivery"
            checked={formType === 'no_delivery'}
            onChange={handleRadioChange}
          />
          <Form.Check
            type="radio"
            label="Free Zone"
            name="formRadio"
            value="free_zone"
            checked={formType === 'free_zone'}
            onChange={handleRadioChange}
          />
          <Form.Check
            type="radio"
            label={formTranslate('United Arab Emirates', language)}
            name="formRadio"
            value="dubai"
            checked={formType === 'dubai'}
            onChange={handleRadioChange}
          />
          <Form.Check
            type="radio"
            label={formTranslate('Global', language)}
            name="formRadio"
            value="other"
            checked={formType === 'other'}
            onChange={handleRadioChange}
          />
        </Form.Group>
      </Card>
      {renderForm()}
    </div>
  );
};

export default ShippingFormCustom;
