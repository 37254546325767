import AppContext from 'context/Context';
import useScreenSize from 'hooks/useScreenSize';

import React, { useContext } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

const ProfileSkeleton = () => {
  const {
    config: { isDark }
  } = useContext(AppContext);
  const screen = useScreenSize();
  return (
    <Card className="p-4">
      <SkeletonTheme
        baseColor={isDark ? '#333' : '#eef2f3'}
        highlightColor={isDark ? '#444' : '#cad0ff'}
      >
        <Row>
          <Col>
            <Skeleton
              width={screen == 'mobile' ? '100%' : '100%'}
              height={100}
              className=""
            />
          </Col>
        </Row>
        <br />
        <Row>
          <Col className="mx-0">
            <Skeleton
              width={screen == 'mobile' ? '100%' : '100%'}
              height={40}
              className=""
            />
          </Col>
          <Col className="mx-0">
            <Skeleton
              width={screen == 'mobile' ? '100%' : '100%'}
              height={40}
              className=""
            />
          </Col>
        </Row>
        <br />
        <Row>
          <Col className="mx-0">
            <Skeleton
              width={screen == 'mobile' ? '100%' : '100%'}
              height={40}
              className=""
            />
          </Col>
          <Col className="mx-0">
            <Skeleton
              width={screen == 'mobile' ? '100%' : '100%'}
              height={40}
              className=""
            />
          </Col>
        </Row>
        <br />
        <Row>
          <Col className="mx-0">
            <Skeleton
              width={screen == 'mobile' ? '100%' : '100%'}
              height={40}
              className=""
            />
          </Col>
          <Col className="mx-0">
            <Skeleton
              width={screen == 'mobile' ? '100%' : '100%'}
              height={40}
              className=""
            />
          </Col>
        </Row>
        <br />
        <Row>
          <Col className="mx-0">
            <Skeleton
              width={screen == 'mobile' ? '100%' : '100%'}
              height={40}
              className=""
            />
          </Col>
          <Col className="mx-0">
            <Skeleton
              width={screen == 'mobile' ? '100%' : '100%'}
              height={40}
              className=""
            />
          </Col>
        </Row>
        <br />
        <Row>
          <Col className="mx-0">
            <Skeleton
              width={screen == 'mobile' ? '100%' : '100%'}
              height={40}
              className=""
            />
          </Col>
          <Col className="mx-0">
            <Skeleton
              width={screen == 'mobile' ? '100%' : '100%'}
              height={40}
              className=""
            />
          </Col>
        </Row>
        <br />
        <Row>
          <Col className="mx-0">
            <Skeleton
              width={screen == 'mobile' ? '100%' : '100%'}
              height={40}
              className=""
            />
          </Col>
          <Col className="mx-0">
            <Skeleton
              width={screen == 'mobile' ? '100%' : '100%'}
              height={40}
              className=""
            />
          </Col>
        </Row>
        <br />
        <Row>
          <Col className="mx-0">
            <Skeleton
              width={screen == 'mobile' ? '100%' : '100%'}
              height={40}
              className=""
            />
          </Col>
          <Col className="mx-0">
            <Skeleton
              width={screen == 'mobile' ? '100%' : '100%'}
              height={40}
              className=""
            />
          </Col>
        </Row>
        <br />
        <Row>
          <Col className="mx-0">
            <Skeleton
              width={screen == 'mobile' ? '100%' : '100%'}
              height={40}
              className=""
            />
          </Col>
          <Col className="mx-0">
            <Skeleton
              width={screen == 'mobile' ? '100%' : '100%'}
              height={40}
              className=""
            />
          </Col>
        </Row>
        {/* <Skeleton width={200} height={25} className="mx-3" />
        <Skeleton width={250} height={25} className="mx-3" /> */}
      </SkeletonTheme>
    </Card>
  );
};

export default ProfileSkeleton;
