import React, { useEffect, useState } from 'react';
import { Alert, Button, Card } from 'react-bootstrap';

import PendingCustomerForm from './customer-status/PendingCustomerForm';
import BlankCustomerForm from './customer-status/BlankCustomerForm';
import ApprovedCustomerForm from './customer-status/ApprovedCustomerForm';
import UpdateRequiredCustomer from './customer-status/UpdateRequiredCustomer';
import BlockedCustomerForm from './customer-status/BlockedCustomerForm';
import UpdatedCustomerForm from './customer-status/UpdatedCustomerForm';
import axios from 'axios';
import { BASE_URL } from 'constants/url';
import ProfileSkeleton from 'components/common/skeletons/Profile/ProfileSkeleton';
import decryptData from 'secret/decryptData';
import { useNavigate } from 'react-router-dom';
import { FiLogOut } from 'react-icons/fi';
import DevLog from 'helpers/DevLog';

const CustomerProfileSettings = () => {
  const [user, setUser] = useState({});
  const [status, setStatus] = useState('');
  const [customer, setCustomer] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const fetchUser = async () => {
    setIsLoading(true);
    await axios
      .get(BASE_URL + '/auth/users/me/', {
        headers: {
          Authorization: `JWT ${decryptData('token')}`,
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        setUser(response.data);
        setCustomer(response.data['customer']);
        setStatus(response.data['user_status']);
      })
      .catch(error => DevLog(error))
      .finally(() => {
        setIsLoading(false);
      });
  };
  useEffect(() => {
    fetchUser();
  }, []);

  const update_user_info_notifier = status => {
    if (status === 'PENDING') {
      return (
        <Alert variant={'info'}>
          Your Business details have been sent to our moderators. Please wait
          for further instructions
        </Alert>
      );
    } else if (status === 'verified') {
      return (
        <Alert className="mt-3" variant={'success'}>
          Your account has been updated and verified successfully
        </Alert>
      );
    }
  };

  if (isLoading) {
    return <ProfileSkeleton />;
  }

  return (
    <div className="container py-5">
      <>
        <Card className="text-700">
          {/* <FalconCardHeader title="Profile Settings" /> */}
          <Card.Body className="bg-light">
            {update_user_info_notifier(status)}
            {status.toLocaleUpperCase() === 'PENDING' && (
              <div>
                <PendingCustomerForm user={user} />
                <div className="my-2">
                  <Button
                    onClick={() => {
                      localStorage.clear();
                      navigate('/');
                    }}
                    className="fs-1"
                  >
                    Log in <FiLogOut size={28} />
                  </Button>
                </div>
              </div>
            )}
            {status.toLocaleUpperCase() === 'BLANK' && <BlankCustomerForm />}
            {status.toLocaleUpperCase() === 'APPROVED' && (
              <div>
                <ApprovedCustomerForm user={user} />
                <div className="my-2">
                  <Button
                    onClick={() => {
                      localStorage.clear();
                      navigate('/');
                    }}
                    className="fs-1"
                  >
                    Log in <FiLogOut size={28} />
                  </Button>
                </div>
              </div>
            )}
            {status.toLocaleUpperCase() === 'UPDATE_REQUIRED' && (
              <div>
                <h1 className="my-2 fs-2 text-center text-danger">
                  Your account is needed to be updated
                </h1>
                <UpdateRequiredCustomer c={customer} />
              </div>
            )}
            {status.toLocaleUpperCase() === 'UPDATED' && (
              <div>
                <UpdatedCustomerForm />
                <div className="my-2">
                  <Button
                    onClick={() => {
                      localStorage.clear();
                      navigate('/');
                    }}
                    className="fs-1"
                  >
                    Log in <FiLogOut size={28} />
                  </Button>
                </div>
              </div>
            )}
            {status.toLocaleUpperCase() === 'BLOCKED' && (
              <div>
                <BlockedCustomerForm />
                <div className="my-2">
                  <Button
                    onClick={() => {
                      localStorage.clear();
                      navigate('/');
                    }}
                    className="fs-1"
                  >
                    Log in <FiLogOut size={28} />
                  </Button>
                </div>
              </div>
            )}
          </Card.Body>
        </Card>
      </>
    </div>
  );
};

export default CustomerProfileSettings;
