import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { sessionByCountry } from 'data/dashboard/analytics';
import { countryData } from 'data/countryData';
import UsersByCountry from './users-by-country/UsersByCountry';

const Analytics = () => {
  return (
    <>
      <Row className="g-3 mb-3">
        <Col md={12} xxl={4}>
          <UsersByCountry chartData={sessionByCountry} mapData={countryData} />
        </Col>
      </Row>
    </>
  );
};

Analytics.propTypes = {};

export default Analytics;
