import { create } from 'zustand';

const useUserStore = create(set => ({
  userStatus: localStorage.getItem('user_status') || 'null',

  setUserStatus: status => {
    // Update the store state
    set({ userStatus: status });

    // Save the status to localStorage
    localStorage.setItem('user_status', status);
  }
}));

export default useUserStore;
