import axios from 'axios';
import FalconCardHeader from 'components/common/FalconCardHeader';
import React, { useEffect, useState } from 'react';
import { Alert, Card } from 'react-bootstrap';

import IconAlert from 'components/common/IconAlert';
import ProfileSkeleton from 'components/common/skeletons/Profile/ProfileSkeleton';
import { BASE_URL } from 'constants/url';
import DevLog from 'helpers/DevLog';
import { useNavigate } from 'react-router-dom';
import decryptData from 'secret/decryptData';
import ApprovedSupplierForm from './supplier-status/ApprovedSupplierForm';
import BlankSupplierForm from './supplier-status/BlankSupplierForm';
import BlockedSupplierForm from './supplier-status/BlockedSupplierForm';
import PendingSupplierForm from './supplier-status/PendingSupplierForm';
import UpdateRequiredSupplierForm from './supplier-status/UpdateRequiredSupplierForm';
import UpdatedSupplierForm from './supplier-status/UpdatedSupplierForm';
const SupplierProfileSettings = () => {
  const [formData, setFormData] = useState({
    business_name: '',
    business_address: '',
    contact_person: '',
    country: '',
    phone_number: '',
    bank_name: '',
    bank_address: '',
    account_number: '',
    vat_number: '',
    swift_code: '',
    iban: ''
  });

  const [user, setUser] = useState({});
  const [status, setStatus] = useState('');
  const navigate = useNavigate();
  const [supplier, setSupplier] = useState(
    decryptData('data-user')['supplier']
  );
  const [isLoading, setIsLoading] = useState(false);
  const update_user_info_notifier = status => {
    if (status === 'PENDING') {
      return (
        <Alert variant={'info'}>
          Your Business details have been sent to our moderators. Please wait
          for further instructions
        </Alert>
      );
    } else if (status === 'verified') {
      return (
        <Alert className="mt-3" variant={'success'}>
          Your account has been updated and verified successfully
        </Alert>
      );
    }
  };
  const refreshUser = async () => {
    setIsLoading(true);
    await axios
      .get(BASE_URL + '/auth/users/me/', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `JWT ${decryptData('token')}`
        }
      })
      .then(r => {
        if (r.data.user_status === 'APPROVED') {
          localStorage.removeItem('user_status');
          localStorage.setItem('user_status', r.data.user_status);

          // refreshUser();
        }
        setUser(r.data);
        setStatus(r.data.user_status);
        localStorage.setItem('userData', JSON.stringify(r.data));
        setSupplier(decryptData('data-user')['supplier']);
      })
      .catch(err => {
        DevLog({ err });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    refreshUser();

    setFormData({
      ...formData,
      user_account: decryptData('data-user').id
    });
  }, []);

  if (isLoading) {
    return <ProfileSkeleton />;
  }

  return (
    <Card>
      <FalconCardHeader title="Profile Settings" />
      <Card.Body className="bg-light">
        <div className="container">{update_user_info_notifier(status)}</div>
        {status.toLocaleUpperCase() === 'PENDING' && (
          <div className="container">
            {' '}
            <p
              onClick={() => {
                localStorage.clear();
                navigate('/');
                window.location.reload();
              }}
              className=" my-0 fs-1 text-primary"
            >
              Login
            </p>
            <PendingSupplierForm user={user} />
          </div>
        )}
        {status.toLocaleUpperCase() === 'BLANK' && (
          <div className="container">
            <IconAlert variant={'info'}>
              Please fill the following fields to complete registration{' '}
              <p
                onClick={() => {
                  localStorage.clear();
                  navigate('/');
                  window.location.reload();
                }}
                className="mx-5 my-0"
              >
                Login
              </p>
            </IconAlert>
            <BlankSupplierForm />
          </div>
        )}
        {status.toLocaleUpperCase() === 'APPROVED' && (
          <ApprovedSupplierForm user={user} />
        )}
        {status.toLocaleUpperCase() === 'UPDATE_REQUIRED' && (
          <UpdateRequiredSupplierForm sup={supplier} />
        )}
        {status.toLocaleUpperCase() === 'UPDATED' && <UpdatedSupplierForm />}
        {status.toLocaleUpperCase() === 'BLOCKED' && <BlockedSupplierForm />}
      </Card.Body>
    </Card>
  );
};

export default SupplierProfileSettings;
