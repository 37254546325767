import axios from 'axios';
import React, { useEffect, useState } from 'react';

import { Card, Col, Row } from 'react-bootstrap';
import SoftBadge from 'components/common/SoftBadge';
import { Link, useNavigate } from 'react-router-dom';
import { BASE_URL, BASE_URL_WITHOUT_API } from 'constants/url';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import TableSkeleton from 'components/common/skeletons/table skleton/TableSkleton';
import { AiFillEye } from 'react-icons/ai';
// import AdditionalFeeCanvas from '../suppliers/AdditionalFeeCanvas';
// import AdditionalFeeForm from '../suppliers/AdditionalFeeForm';
import { Select } from 'antd';
import { toast } from 'react-toastify';
import decryptData from 'secret/decryptData';
import DevLog from 'helpers/DevLog';

const ApprovedCustomers = () => {
  const [approvedCustomers, setApprovedCustomers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const fetchApprovedCustomers = async () => {
    setIsLoading(true);
    if (decryptData('token') !== null) {
      await axios
        .get(BASE_URL + '/dashboard/customers/?user_status=APPROVED', {
          headers: {
            Authorization: `JWT ${decryptData('token')}`
          }
        })
        .then(res => {
          DevLog(res);
          const data = res.data?.map(d => {
            return { ...d, ...d.customer };
          });
          setApprovedCustomers(data);
          DevLog({ approvedCustomers });
        })
        .catch(err => {
          DevLog(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      navigate('/');
    }
  };

  const columns = [
    {
      accessor: 'business_name',
      Header: 'Business Name'
    },
    {
      accessor: 'email',
      Header: 'Email'
    },
    {
      accessor: 'country',
      Header: 'Country'
    },
    {
      accessor: 'contact_person',
      Header: 'Contact Name'
    },
    {
      accessor: 'bank_name',
      Header: 'Bank Name'
    },
    // ----------------------------------------------------------------
    {
      accessor: 'business_entity',
      Header: 'Business Entity',
      Cell: rowData => {
        const { customer_id, business_entity } = rowData.row.original.customer;
        const [selectedValue, setSelectedValue] = useState(selectedValue);

        const handleChange = async value => {
          setSelectedValue(value);
          DevLog({ value });
          await axios
            .patch(
              BASE_URL + `/dashboard/customer/${customer_id}/update/`,
              {
                business_entity: value
              },
              {
                headers: {
                  Authorization: `JWT ${decryptData('token')}`
                }
              }
            )
            .then(r => {
              if (r.status == 200) {
                return toast.success(
                  `User's status  has been successfully updated `,
                  {
                    theme: 'colored',
                    position: 'top-right',
                    autoClose: false
                  }
                );
              }
            })
            .catch(err => {
              DevLog(err);
              return toast.error(`User's status  failed to change `, {
                theme: 'colored',
                position: 'top-right',
                autoClose: false
              });
            });
        };

        const { Option } = Select;
        return (
          <Select
            style={{ width: 120 }}
            defaultValue={business_entity}
            value={selectedValue}
            onChange={handleChange}
          >
            <Option value="AMIR_GROUP">Amir Group</Option>
            <Option value="OTHER_GROUP">Other Group</Option>
          </Select>
        );
      }
    },
    {
      accessor: 'deposit',
      Header: 'Deposit',
      Cell: rowData => {
        const [selectedValue, setSelectedValue] = useState(selectedValue);

        // const id = rowData.row.original.customer.customer_id;
        // {{base_url}}dashboard/customer/11/update/
        const changeDepositStatus = async (user_id, value) => {
          setSelectedValue(value);
          await axios
            .patch(
              BASE_URL + `/dashboard/customer/${user_id}/update/`,
              { is_deposit_active: value },
              {
                headers: {
                  Authorization: `JWT ${decryptData('token')}`
                }
              }
            )
            .then(r => {
              if (r.status == 200) {
                return toast.success(
                  `User's status  has been successfully updated `,
                  {
                    theme: 'colored',
                    position: 'top-right',
                    autoClose: false
                  }
                );
              }
            })
            .catch(err => {
              DevLog(err);
              return toast.error(`User's status  failed to change `, {
                theme: 'colored',
                position: 'top-right',
                autoClose: false
              });
            });
        };

        const { Option } = Select;
        return (
          <Select
            defaultValue={
              rowData.row.original.customer?.is_deposit_active
                ? 'true'
                : 'false'
            }
            style={{ width: 120 }}
            value={selectedValue}
            onChange={value => {
              changeDepositStatus(
                rowData.row.original.customer?.customer_id,
                value
              );
            }}
          >
            <Option value="true">Deposit</Option>
            <Option value="false">No Deposit</Option>
          </Select>
        );
      }
    },

    // {
    //   accessor: 'fee',
    //   Header: 'Addt. fee',
    //   Cell: () => {
    //     return (
    //       <center>
    //         <AdditionalFeeCanvas placement={'end'} name={'Add additional fee'}>
    //           <AdditionalFeeForm />
    //         </AdditionalFeeCanvas>
    //       </center>
    //     );
    //   }
    // },
    {
      accessor: 'license',
      Header: 'License',
      Cell: rowData => {
        const { license } = rowData.row.original;
        return license == undefined || license == null || license == '' ? (
          <p>Not uploaded yet</p>
        ) : (
          <Link target="blank" to={`${BASE_URL_WITHOUT_API}/` + license}>
            View License
          </Link>
        );
      }
    },
    {
      accessor: 'certificate',
      Header: 'Certificate',
      Cell: rowData => {
        const { vat_certificate } = rowData.row.original;
        return vat_certificate == undefined ||
          vat_certificate == null ||
          vat_certificate == '' ? (
          <p>Not uploaded yet</p>
        ) : (
          <Link
            target="blank"
            to={`${BASE_URL_WITHOUT_API}/` + vat_certificate}
          >
            View Certificate
          </Link>
        );
      }
    },
    {
      accessor: 'action',
      Header: 'Action',
      Cell: rowData => {
        const user = rowData.row.original;
        return (
          <Link
            to={'/admin/customers/customersPage'}
            state={user}
            className="d-flex justify-content-center"
          >
            <SoftBadge
              className="mx-0 cursor-pointer hover-button px-1 py-1 bg-soft-primary text-primary"
              bg="info"
              pill
            >
              <AiFillEye size={22} />
            </SoftBadge>
          </Link>
        );
      }
    }
  ];

  useEffect(() => {
    fetchApprovedCustomers();
  }, []);

  if (isLoading) {
    return <TableSkeleton />;
  }

  return (
    <Card className="p-4 text-800">
      <AdvanceTableWrapper
        columns={columns}
        data={approvedCustomers}
        sortable
        pagination
        perPage={5}
      >
        <Row className="flex-end-center mb-3">
          <Col xs="auto" sm={6} lg={6}>
            <h3 className="text-primary fw-light">
              List of approved customers
            </h3>
          </Col>
          <Col xs="auto" sm={6} lg={6}>
            <AdvanceTableSearchBox table />
          </Col>
        </Row>
        <AdvanceTable
          table
          headerClassName="bg-200 text-900 text-nowrap align-middle"
          rowClassName="align-middle white-space-nowrap"
          tableProps={{
            bordered: true,
            striped: true,
            className: 'fs--1 mb-0 overflow-hidden'
          }}
        />
        <div className="mt-3">
          <AdvanceTableFooter
            rowCount={approvedCustomers.length}
            table
            rowInfo
            navButtons
            rowsPerPageSelection
          />
        </div>
      </AdvanceTableWrapper>
    </Card>
  );
};

export default ApprovedCustomers;
