import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { Button, Form, Row, Col, Alert } from 'react-bootstrap';
import axios from 'axios';
import Lottie from 'lottie-react';
import infiniteLoop from '../../../assets/img/animated-icons/infinite-loop.json';
import { BASE_URL } from 'constants/url';
import { useLanguage } from 'provider/LanguageProvider';
import DevLog from 'helpers/DevLog';

const RegistrationForm = ({ hasLabel }) => {
  const { language } = useLanguage();
  // State
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    re_password: '',
    is_accepted: false
  });
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [fakeSimulation, setFakeSimulation] = useState('');
  // const navigate = useNavigate();

  const { user_type, email, password, re_password, is_accepted } = formData;
  // Handler
  const handleSubmit = async e => {
    setIsLoading(true);
    e.preventDefault();

    if (is_accepted) {
      await axios
        .post(BASE_URL + '/auth/users/', {
          user_type: String(user_type).toLowerCase(),
          email,
          password,
          re_password,
          is_accepted
        })
        .then(response => {
          if (response.status === 201) {
            setSuccessMessage(
              'Your registration was successful. Please confirm your email. Enjoy your journey with us!'
            );
          }
          setIsLoading(false);
          setErrorMessage('');
        })
        .catch(error => {
          DevLog({ error });
          setErrorMessage(error.response.data['email'][0]);

          setSuccessMessage('');
          setIsLoading(false);
        });
    } else {
      alert('Please accept terms and conditions');
    }
  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  if (errorMessage.length > 0) {
    return (
      <Alert className="text-center" variant={'danger'}>
        {errorMessage}
      </Alert>
    );
  }
  if (successMessage.length > 0) {
    return (
      <Alert className="text-center" variant={'info'}>
        {successMessage}
      </Alert>
    );
  }
  function getEmailLabel(language) {
    if (language === 'en') {
      return 'Email';
    } else if (language === 'uz') {
      return 'Elektron pochta manzili';
    } else if (language === 'ru') {
      return 'Адрес электронной почты';
    } else {
      // Handle other languages or a default value if needed
      return 'Unknown Language';
    }
  }
  function getConfirmPasswordLabel(language) {
    if (language === 'en') {
      return 'Confirm password';
    } else if (language === 'uz') {
      return 'Parolni tasdiqlang';
    } else if (language === 'ru') {
      return 'Подтвердите пароль';
    } else {
      // Handle other languages or a default value if needed
      return 'Unknown Language';
    }
  }
  function getPasswordLabel(language) {
    let passwordLabel = '';

    if (language === 'en') {
      passwordLabel = 'Password';
    } else if (language === 'uz') {
      passwordLabel = 'Parol';
    } else if (language === 'ru') {
      passwordLabel = 'Пароль';
    } else {
      // Handle other languages or a default value if needed
      passwordLabel = 'Unknown Language';
    }

    return passwordLabel;
  }
  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        <select
          className="form-select"
          onChange={e => {
            setFakeSimulation(e.target.value);
            setFormData({
              ...formData,
              user_type: e.target.value
            });
          }}
        >
          <option value={''}>
            {language == 'en' && 'Please, choose account type'}
            {language == 'uz' && 'Iltimos, hisob turini tanlang'}
            {language == 'ru' && 'Пожалуйста, выберите тип аккаунта'}
          </option>
          <option value="Supplier">
            {language == 'en' && 'Supplier'}
            {language == 'uz' && 'Yetkazib beruvchi'}
            {language == 'ru' && 'Поставщик'}
          </option>
          <option value="Customer">
            {language == 'en' && 'Customer'}
            {language == 'uz' && 'Xaridor'}
            {language == 'ru' && 'Клиент'}
          </option>
        </select>
      </Form.Group>
      <Form.Group className="mb-3">
        {hasLabel && (
          <Form.Label>
            {' '}
            {language == 'en' && 'Email'}
            {language == 'uz' && 'Elektron pochta manzili'}
            {language == 'ru' && 'Адрес электронной почты'}
          </Form.Label>
        )}
        <Form.Control
          placeholder={getEmailLabel(language)}
          value={formData.email}
          name="email"
          required
          onChange={handleFieldChange}
          type="text"
        />
      </Form.Group>
      <Row className="g-2 mb-3">
        <Form.Group as={Col} sm={6}>
          {hasLabel && (
            <Form.Label>
              {' '}
              {language == 'en' && 'Password'}
              {language == 'uz' && 'Parol'}
              {language == 'ru' && 'Пароль'}
            </Form.Label>
          )}
          <Form.Control
            placeholder={getPasswordLabel(language)}
            value={formData.password}
            name="password"
            required
            onChange={handleFieldChange}
            type="password"
          />
        </Form.Group>
        <Form.Group as={Col} sm={6}>
          {hasLabel && (
            <Form.Label>
              {language == 'en' && 'Confirm password'}
              {language == 'uz' && 'Parolni tasdiqlang'}
              {language == 'ru' && 'Подтвердите пароль'}
            </Form.Label>
          )}
          <Form.Control
            placeholder={getConfirmPasswordLabel(language)}
            value={formData.re_password}
            name="re_password"
            required
            onChange={handleFieldChange}
            type="password"
          />
        </Form.Group>
      </Row>
      {formData.re_password.length > 0 &&
        formData.password !== formData.re_password && (
          <Alert variant={'danger'}>
            {language == 'en' && 'Password does not match!'}
            {language == 'uz' && 'Parol mos emas!'}
            {language == 'ru' && 'Пароль не совпадает!'}
          </Alert>
        )}
      <Form.Group className="mb-3">
        <Form.Check type="checkbox" id="acceptCheckbox" className="form-check">
          <Form.Check.Input
            type="checkbox"
            name="is_accepted"
            checked={formData.is_accepted}
            onChange={e =>
              setFormData({
                ...formData,
                is_accepted: e.target.checked
              })
            }
          />
          <Form.Check.Label className="form-label">
            {language == 'en' && 'I accept'}
            {language == 'uz' && 'Men '}
            {language == 'ru' && 'Я принимаю '}{' '}
            <Link target="blank" to="/agt/terms-and-conditions">
              {language == 'en' && 'Terms and Conditions'}
              {language == 'uz' && 'Foydalanish shartlari'}
              {language == 'ru' && 'Правила и условия '}
            </Link>{' '}
            &{' '}
            <Link target="blank" to="/agt/privacy-and-policy">
              {' '}
              {language == 'en' && 'Privacy policy'}
              {language == 'uz' && 'Maxfiylik siyosatini qabul qilaman '}
              {language == 'ru' && 'Политика конфиденциальности '}{' '}
            </Link>
          </Form.Check.Label>
        </Form.Check>
      </Form.Group>

      <Form.Group className="mb-4">
        <Button
          disabled={
            formData.is_accepted == false || fakeSimulation.length == ''
          }
          variant={isLoading ? 'light' : 'primary'}
          className={`w-100 mt-3 w-100 d-flex ${
            !isLoading ? 'px-3 py-2' : 'p-0'
          } justify-content-center align-items-center`}
          type="submit"
        >
          {isLoading ? (
            <Lottie
              animationData={infiniteLoop}
              loop={true}
              style={{ height: '45px', width: '45px', margin: 0 }}
            />
          ) : (
            'Register'
          )}{' '}
        </Button>
      </Form.Group>
    </Form>
  );
};

RegistrationForm.propTypes = {
  hasLabel: PropTypes.bool
};

export default RegistrationForm;
