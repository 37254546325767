/* eslint-disable react/prop-types */
import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { useState } from 'react';
import DatePicker from 'react-datepicker';
import { BASE_URL } from 'constants/url';
import axios from 'axios';
import { timeParser } from 'helpers/timeParser';
import decryptData from 'secret/decryptData';
import DevLog from 'helpers/DevLog';

const AdminAdvancedDateRangePicker = ({ filter }) => {
  const date = new Date();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(date.setDate(date.getDate() + 7));
  const onChange = async dates => {
    const [start, end] = dates;
    DevLog({ dates });
    setStartDate(start);
    setEndDate(end);
    if (start && end) {
      DevLog({ start, end });
      await axios
        .get(
          BASE_URL +
            `/dashboard/orders/?start_date=${timeParser(
              start
            )}&end_date=${timeParser(end)}`,
          {
            headers: {
              Authorization: `JWT ${decryptData('token')}`
            }
          }
        )
        .then(response => {
          DevLog({ response });
          const data = response.data.results.map(a => {
            const {
              order_id,
              created_at,
              total_cost,
              customer,
              last_shipping_address,
              order_status
            } = a;
            const { shipping_type } = last_shipping_address;
            const { business_name } = customer;

            return {
              order_id,
              created_at,
              total_cost,
              shipping_type,
              business_name,
              order_status
            };
          });

          filter(data);
        })
        .catch(err => DevLog(err))
        .finally(() => {});
    }
  };
  return (
    <DatePicker
      selected={startDate}
      onChange={onChange}
      startDate={startDate}
      formatWeekDay={day => day.slice(0, 3)}
      endDate={endDate}
      selectsRange
      dateFormat="[yyyy.MM.dd]"
      className="form-control"
    />
  );
};

export default AdminAdvancedDateRangePicker;
