import React from 'react';
import { Col, Row } from 'react-bootstrap';
import CrmStats from './CrmStats';
import MostLeads from './most-leads/MostLeads';
import Revenue from './revenue/Revenue';

const Crm = () => {
  return (
    <>
      <Row className="g-3 mb-3">
        <Col xxl={9}>
          <CrmStats />
          <Revenue />
        </Col>
        <Col xxl={3}>
          <MostLeads />
        </Col>
      </Row>
    </>
  );
};

export default Crm;
