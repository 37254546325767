import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';

const PendingCustomerForm = ({ user }) => {
  return (
    <div>
      <Form onSubmit={() => {}}>
        <Row className="mb-3 g-3">
          <Form.Group as={Col} lg={12} controlId="business_name">
            <Form.Label> Business name </Form.Label>
            <Form.Control
              required
              readOnly
              type="text"
              placeholder={user.customer.business_name}
              name="business_name"
              value={user.customer.business_name}
            />
          </Form.Group>

          <Form.Group as={Col} lg={6} controlId="country">
            <Form.Label>Country</Form.Label>
            <Form.Control
              required
              readOnly
              type="text"
              placeholder="Country"
              value={user.customer.country}
              name="country"
            />
          </Form.Group>
        </Row>
        <Row className="mb-3 g-3">
          <Form.Group as={Col} lg={6} controlId="business_address">
            <Form.Label>Business address</Form.Label>
            <Form.Control
              required
              readOnly
              type="text"
              placeholder="Business address"
              value={user.customer.business_address}
              name="business_address"
            />
          </Form.Group>

          <Form.Group as={Col} lg={6} controlId="contact_person">
            <Form.Label>Contact person</Form.Label>
            <Form.Control
              required
              readOnly
              type="text"
              placeholder="Contact person"
              value={user.customer.contact_person}
              name="contact_person"
            />
          </Form.Group>

          <Form.Group
            as={Col}
            lg={6}
            readOnly
            className="mb-3"
            controlId="phone_number"
          >
            <Form.Label>Phone number</Form.Label>
            <Form.Control
              required
              readOnly
              type="text"
              placeholder="Phone number"
              value={user.customer.phone_number}
              name="phone_number"
            />
          </Form.Group>

          <Form.Group
            as={Col}
            lg={6}
            readOnly
            className="mb-3"
            controlId="bank_name"
          >
            <Form.Label>Bank name</Form.Label>
            <Form.Control
              required
              readOnly
              type="text"
              placeholder="Bank name"
              value={user.customer.bank_name}
              name="bank_name"
            />
          </Form.Group>
          <Form.Group
            as={Col}
            lg={6}
            readOnly
            className="mb-3"
            controlId="bank_address"
          >
            <Form.Label>Bank address</Form.Label>
            <Form.Control
              required
              readOnly
              type="text"
              placeholder="Bank address"
              value={user.customer.bank_address}
              name="bank_address"
            />
          </Form.Group>
          <Form.Group
            as={Col}
            readOnly
            lg={6}
            className="mb-3"
            controlId="account_number"
          >
            <Form.Label>Account number</Form.Label>
            <Form.Control
              required
              readOnly
              type="text"
              placeholder="Account number"
              value={user.customer.account_number}
              name="account_number"
            />
          </Form.Group>
          <Form.Group
            as={Col}
            lg={6}
            readOnly
            className="mb-3"
            controlId="vat_number"
          >
            <Form.Label>VAT number</Form.Label>
            <Form.Control
              required
              readOnly
              type="text"
              placeholder="VAT number"
              value={user.customer.vat_number}
              name="vat_number"
            />
          </Form.Group>
          <Form.Group
            as={Col}
            lg={6}
            readOnly
            className="mb-3"
            controlId="swift_code"
          >
            <Form.Label>SWIFT code</Form.Label>
            <Form.Control
              required
              readOnly
              type="text"
              maxLength={15}
              placeholder="SWIFT code"
              value={user.customer.swift_code}
              name="swift_code"
            />
          </Form.Group>
          <Form.Group as={Col} lg={6} className="mb-3" controlId="iban">
            <Form.Label>IBAN</Form.Label>
            <Form.Control
              required
              readOnly
              type="text"
              placeholder="IBAN code"
              value={user.customer.iban}
              name="iban"
            />
          </Form.Group>
        </Row>
      </Form>
    </div>
  );
};

PendingCustomerForm.propTypes = {
  user: PropTypes.object.isRequired
};
export default PendingCustomerForm;
