import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { toast } from 'react-toastify';
import { Button, Form } from 'react-bootstrap';
import SoftBadge from 'components/common/SoftBadge';
import axios from 'axios';
import { BASE_URL } from 'constants/url';
import DevLog from 'helpers/DevLog';
const ForgetPasswordForm = () => {
  // State

  const [formData, setFormData] = useState({
    email: ''
  });

  const [viewPassword, setViewPassword] = useState(true);
  // const [match, setMatch] = useState(f);

  useEffect(() => {}, []);
  const { email } = formData;
  // Handler
  const handleSubmit = async e => {
    e.preventDefault();

    await axios
      .post(BASE_URL + '/auth/users/reset_password/', formData, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => DevLog(response))
      .catch(err => DevLog(err));
    if (email) {
      toast.success(`An email is sent to ${email} with password reset link`, {
        theme: 'colored'
      });
    }
  };

  return (
    <Form className="mt-4" onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        <Form.Control
          placeholder={'Email address'}
          value={email}
          name="email"
          required
          className="mb-3"
          onChange={({ target }) =>
            setFormData({ ...formData, email: target.value })
          }
          type="email"
        />
      </Form.Group>
      <SoftBadge
        pill
        onClick={() => setViewPassword(!viewPassword)}
        bg="primary"
        className="mb-3"
      ></SoftBadge>

      <Form.Group className="mb-3">
        <Button className="w-100" type="submit" disabled={!email}>
          Send reset link
        </Button>
      </Form.Group>
    </Form>
  );
};

ForgetPasswordForm.propTypes = {
  layout: PropTypes.string
};

ForgetPasswordForm.defaultProps = { layout: 'simple' };

export default ForgetPasswordForm;
